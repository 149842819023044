/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.PlatformCRMWeb = (function() {

    /**
     * Namespace PlatformCRMWeb.
     * @exports PlatformCRMWeb
     * @namespace
     */
    var PlatformCRMWeb = {};

    /**
     * SortOrder enum.
     * @name PlatformCRMWeb.SortOrder
     * @enum {number}
     * @property {number} NONE=0 NONE value
     * @property {number} ASC=1 ASC value
     * @property {number} DESC=2 DESC value
     */
    PlatformCRMWeb.SortOrder = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NONE"] = 0;
        values[valuesById[1] = "ASC"] = 1;
        values[valuesById[2] = "DESC"] = 2;
        return values;
    })();

    PlatformCRMWeb.SortField = (function() {

        /**
         * Properties of a SortField.
         * @memberof PlatformCRMWeb
         * @interface ISortField
         * @property {string|null} [field] SortField field
         * @property {PlatformCRMWeb.SortOrder|null} [order] SortField order
         */

        /**
         * Constructs a new SortField.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SortField.
         * @implements ISortField
         * @constructor
         * @param {PlatformCRMWeb.ISortField=} [properties] Properties to set
         */
        function SortField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SortField field.
         * @member {string} field
         * @memberof PlatformCRMWeb.SortField
         * @instance
         */
        SortField.prototype.field = "";

        /**
         * SortField order.
         * @member {PlatformCRMWeb.SortOrder} order
         * @memberof PlatformCRMWeb.SortField
         * @instance
         */
        SortField.prototype.order = 0;

        /**
         * Encodes the specified SortField message. Does not implicitly {@link PlatformCRMWeb.SortField.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SortField
         * @static
         * @param {PlatformCRMWeb.ISortField} message SortField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SortField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.field != null && Object.hasOwnProperty.call(message, "field"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.field);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.order);
            return writer;
        };

        /**
         * Decodes a SortField message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SortField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SortField} SortField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SortField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SortField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.field = reader.string();
                    break;
                case 2:
                    message.order = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SortField;
    })();

    PlatformCRMWeb.Error = (function() {

        /**
         * Properties of an Error.
         * @memberof PlatformCRMWeb
         * @interface IError
         * @property {string|null} [type] Error type
         * @property {string|null} [message] Error message
         */

        /**
         * Constructs a new Error.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an Error.
         * @implements IError
         * @constructor
         * @param {PlatformCRMWeb.IError=} [properties] Properties to set
         */
        function Error(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Error type.
         * @member {string} type
         * @memberof PlatformCRMWeb.Error
         * @instance
         */
        Error.prototype.type = "";

        /**
         * Error message.
         * @member {string} message
         * @memberof PlatformCRMWeb.Error
         * @instance
         */
        Error.prototype.message = "";

        /**
         * Encodes the specified Error message. Does not implicitly {@link PlatformCRMWeb.Error.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Error
         * @static
         * @param {PlatformCRMWeb.IError} message Error message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.type);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            return writer;
        };

        /**
         * Decodes an Error message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Error();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.string();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Error;
    })();

    PlatformCRMWeb.Product = (function() {

        /**
         * Properties of a Product.
         * @memberof PlatformCRMWeb
         * @interface IProduct
         * @property {string|null} [productSlug] Product productSlug
         * @property {Array.<PlatformCRMWeb.IProductOption>|null} [options] Product options
         */

        /**
         * Constructs a new Product.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Product.
         * @implements IProduct
         * @constructor
         * @param {PlatformCRMWeb.IProduct=} [properties] Properties to set
         */
        function Product(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Product productSlug.
         * @member {string} productSlug
         * @memberof PlatformCRMWeb.Product
         * @instance
         */
        Product.prototype.productSlug = "";

        /**
         * Product options.
         * @member {Array.<PlatformCRMWeb.IProductOption>} options
         * @memberof PlatformCRMWeb.Product
         * @instance
         */
        Product.prototype.options = $util.emptyArray;

        /**
         * Encodes the specified Product message. Does not implicitly {@link PlatformCRMWeb.Product.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Product
         * @static
         * @param {PlatformCRMWeb.IProduct} message Product message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Product.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productSlug);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformCRMWeb.ProductOption.encode(message.options[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Product message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Product
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Product} Product
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Product.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Product();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productSlug = reader.string();
                    break;
                case 2:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformCRMWeb.ProductOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Product;
    })();

    PlatformCRMWeb.ProductOption = (function() {

        /**
         * Properties of a ProductOption.
         * @memberof PlatformCRMWeb
         * @interface IProductOption
         * @property {string|null} [slug] ProductOption slug
         * @property {number|null} [value] ProductOption value
         * @property {boolean|null} [isFree] ProductOption isFree
         * @property {number|null} [factor] ProductOption factor
         */

        /**
         * Constructs a new ProductOption.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ProductOption.
         * @implements IProductOption
         * @constructor
         * @param {PlatformCRMWeb.IProductOption=} [properties] Properties to set
         */
        function ProductOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProductOption slug.
         * @member {string} slug
         * @memberof PlatformCRMWeb.ProductOption
         * @instance
         */
        ProductOption.prototype.slug = "";

        /**
         * ProductOption value.
         * @member {number} value
         * @memberof PlatformCRMWeb.ProductOption
         * @instance
         */
        ProductOption.prototype.value = 0;

        /**
         * ProductOption isFree.
         * @member {boolean} isFree
         * @memberof PlatformCRMWeb.ProductOption
         * @instance
         */
        ProductOption.prototype.isFree = false;

        /**
         * ProductOption factor.
         * @member {number} factor
         * @memberof PlatformCRMWeb.ProductOption
         * @instance
         */
        ProductOption.prototype.factor = 0;

        /**
         * Encodes the specified ProductOption message. Does not implicitly {@link PlatformCRMWeb.ProductOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ProductOption
         * @static
         * @param {PlatformCRMWeb.IProductOption} message ProductOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.slug);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isFree);
            if (message.factor != null && Object.hasOwnProperty.call(message, "factor"))
                writer.uint32(/* id 4, wireType 5 =*/37).float(message.factor);
            return writer;
        };

        /**
         * Decodes a ProductOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ProductOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ProductOption} ProductOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ProductOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.slug = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                case 3:
                    message.isFree = reader.bool();
                    break;
                case 4:
                    message.factor = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProductOption;
    })();

    PlatformCRMWeb.FIO = (function() {

        /**
         * Properties of a FIO.
         * @memberof PlatformCRMWeb
         * @interface IFIO
         * @property {string|null} [fullName] FIO fullName
         * @property {string|null} [lastName] FIO lastName
         * @property {string|null} [middleName] FIO middleName
         * @property {string|null} [firstName] FIO firstName
         * @property {boolean|null} [isValid] FIO isValid
         * @property {number|null} [gender] FIO gender
         * @property {PlatformCRMWeb.IIDadataFIO|null} [object] FIO object
         */

        /**
         * Constructs a new FIO.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a FIO.
         * @implements IFIO
         * @constructor
         * @param {PlatformCRMWeb.IFIO=} [properties] Properties to set
         */
        function FIO(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FIO fullName.
         * @member {string} fullName
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.fullName = "";

        /**
         * FIO lastName.
         * @member {string} lastName
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.lastName = "";

        /**
         * FIO middleName.
         * @member {string} middleName
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.middleName = "";

        /**
         * FIO firstName.
         * @member {string} firstName
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.firstName = "";

        /**
         * FIO isValid.
         * @member {boolean} isValid
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.isValid = false;

        /**
         * FIO gender.
         * @member {number} gender
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.gender = 0;

        /**
         * FIO object.
         * @member {PlatformCRMWeb.IIDadataFIO|null|undefined} object
         * @memberof PlatformCRMWeb.FIO
         * @instance
         */
        FIO.prototype.object = null;

        /**
         * Encodes the specified FIO message. Does not implicitly {@link PlatformCRMWeb.FIO.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.FIO
         * @static
         * @param {PlatformCRMWeb.IFIO} message FIO message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FIO.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.fullName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.lastName);
            if (message.middleName != null && Object.hasOwnProperty.call(message, "middleName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.middleName);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.firstName);
            if (message.isValid != null && Object.hasOwnProperty.call(message, "isValid"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isValid);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.gender);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                $root.PlatformCRMWeb.IDadataFIO.encode(message.object, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FIO message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.FIO
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.FIO} FIO
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FIO.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.FIO();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.fullName = reader.string();
                    break;
                case 2:
                    message.lastName = reader.string();
                    break;
                case 3:
                    message.middleName = reader.string();
                    break;
                case 4:
                    message.firstName = reader.string();
                    break;
                case 5:
                    message.isValid = reader.bool();
                    break;
                case 6:
                    message.gender = reader.int32();
                    break;
                case 7:
                    message.object = $root.PlatformCRMWeb.IDadataFIO.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FIO;
    })();

    PlatformCRMWeb.IDadataFIO = (function() {

        /**
         * Properties of a IDadataFIO.
         * @memberof PlatformCRMWeb
         * @interface IIDadataFIO
         * @property {string|null} [source] IDadataFIO source
         * @property {string|null} [result] IDadataFIO result
         * @property {string|null} [resultGenitive] IDadataFIO resultGenitive
         * @property {string|null} [resultDative] IDadataFIO resultDative
         * @property {string|null} [resultAblative] IDadataFIO resultAblative
         * @property {string|null} [surname] IDadataFIO surname
         * @property {string|null} [name] IDadataFIO name
         * @property {string|null} [patronymic] IDadataFIO patronymic
         * @property {string|null} [gender] IDadataFIO gender
         * @property {number|null} [qc] IDadataFIO qc
         */

        /**
         * Constructs a new IDadataFIO.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a IDadataFIO.
         * @implements IIDadataFIO
         * @constructor
         * @param {PlatformCRMWeb.IIDadataFIO=} [properties] Properties to set
         */
        function IDadataFIO(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IDadataFIO source.
         * @member {string} source
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.source = "";

        /**
         * IDadataFIO result.
         * @member {string} result
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.result = "";

        /**
         * IDadataFIO resultGenitive.
         * @member {string} resultGenitive
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.resultGenitive = "";

        /**
         * IDadataFIO resultDative.
         * @member {string} resultDative
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.resultDative = "";

        /**
         * IDadataFIO resultAblative.
         * @member {string} resultAblative
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.resultAblative = "";

        /**
         * IDadataFIO surname.
         * @member {string} surname
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.surname = "";

        /**
         * IDadataFIO name.
         * @member {string} name
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.name = "";

        /**
         * IDadataFIO patronymic.
         * @member {string} patronymic
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.patronymic = "";

        /**
         * IDadataFIO gender.
         * @member {string} gender
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.gender = "";

        /**
         * IDadataFIO qc.
         * @member {number} qc
         * @memberof PlatformCRMWeb.IDadataFIO
         * @instance
         */
        IDadataFIO.prototype.qc = 0;

        /**
         * Encodes the specified IDadataFIO message. Does not implicitly {@link PlatformCRMWeb.IDadataFIO.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.IDadataFIO
         * @static
         * @param {PlatformCRMWeb.IIDadataFIO} message IDadataFIO message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IDadataFIO.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.source);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.result);
            if (message.resultGenitive != null && Object.hasOwnProperty.call(message, "resultGenitive"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.resultGenitive);
            if (message.resultDative != null && Object.hasOwnProperty.call(message, "resultDative"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.resultDative);
            if (message.resultAblative != null && Object.hasOwnProperty.call(message, "resultAblative"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.resultAblative);
            if (message.surname != null && Object.hasOwnProperty.call(message, "surname"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.surname);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.name);
            if (message.patronymic != null && Object.hasOwnProperty.call(message, "patronymic"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.patronymic);
            if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.gender);
            if (message.qc != null && Object.hasOwnProperty.call(message, "qc"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.qc);
            return writer;
        };

        /**
         * Decodes a IDadataFIO message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.IDadataFIO
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.IDadataFIO} IDadataFIO
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IDadataFIO.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.IDadataFIO();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.source = reader.string();
                    break;
                case 2:
                    message.result = reader.string();
                    break;
                case 3:
                    message.resultGenitive = reader.string();
                    break;
                case 4:
                    message.resultDative = reader.string();
                    break;
                case 5:
                    message.resultAblative = reader.string();
                    break;
                case 6:
                    message.surname = reader.string();
                    break;
                case 7:
                    message.name = reader.string();
                    break;
                case 8:
                    message.patronymic = reader.string();
                    break;
                case 9:
                    message.gender = reader.string();
                    break;
                case 10:
                    message.qc = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return IDadataFIO;
    })();

    PlatformCRMWeb.Person = (function() {

        /**
         * Properties of a Person.
         * @memberof PlatformCRMWeb
         * @interface IPerson
         * @property {string|null} [id] Person id
         * @property {string|null} [email] Person email
         * @property {string|null} [phone] Person phone
         * @property {string|null} [status] Person status
         * @property {string|null} [ssoId] Person ssoId
         * @property {string|null} [commentForExecutor] Person commentForExecutor
         * @property {string|null} [commentForSupport] Person commentForSupport
         * @property {PlatformCRMWeb.IFIO|null} [fio] Person fio
         * @property {string|null} [createdAt] Person createdAt
         * @property {string|null} [updatedAt] Person updatedAt
         * @property {string|null} [qleanAdminLink] Person qleanAdminLink
         * @property {string|null} [qleanCreditCardNumber] Person qleanCreditCardNumber
         * @property {string|null} [qleanClientId] Person qleanClientId
         */

        /**
         * Constructs a new Person.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Person.
         * @implements IPerson
         * @constructor
         * @param {PlatformCRMWeb.IPerson=} [properties] Properties to set
         */
        function Person(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Person id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.id = "";

        /**
         * Person email.
         * @member {string} email
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.email = "";

        /**
         * Person phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.phone = "";

        /**
         * Person status.
         * @member {string} status
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.status = "";

        /**
         * Person ssoId.
         * @member {string} ssoId
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.ssoId = "";

        /**
         * Person commentForExecutor.
         * @member {string} commentForExecutor
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.commentForExecutor = "";

        /**
         * Person commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.commentForSupport = "";

        /**
         * Person fio.
         * @member {PlatformCRMWeb.IFIO|null|undefined} fio
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.fio = null;

        /**
         * Person createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.createdAt = "";

        /**
         * Person updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.updatedAt = "";

        /**
         * Person qleanAdminLink.
         * @member {string} qleanAdminLink
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.qleanAdminLink = "";

        /**
         * Person qleanCreditCardNumber.
         * @member {string} qleanCreditCardNumber
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.qleanCreditCardNumber = "";

        /**
         * Person qleanClientId.
         * @member {string} qleanClientId
         * @memberof PlatformCRMWeb.Person
         * @instance
         */
        Person.prototype.qleanClientId = "";

        /**
         * Encodes the specified Person message. Does not implicitly {@link PlatformCRMWeb.Person.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Person
         * @static
         * @param {PlatformCRMWeb.IPerson} message Person message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Person.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.status);
            if (message.ssoId != null && Object.hasOwnProperty.call(message, "ssoId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.ssoId);
            if (message.commentForExecutor != null && Object.hasOwnProperty.call(message, "commentForExecutor"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.commentForExecutor);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.commentForSupport);
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                $root.PlatformCRMWeb.FIO.encode(message.fio, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.updatedAt);
            if (message.qleanAdminLink != null && Object.hasOwnProperty.call(message, "qleanAdminLink"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.qleanAdminLink);
            if (message.qleanCreditCardNumber != null && Object.hasOwnProperty.call(message, "qleanCreditCardNumber"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.qleanCreditCardNumber);
            if (message.qleanClientId != null && Object.hasOwnProperty.call(message, "qleanClientId"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.qleanClientId);
            return writer;
        };

        /**
         * Decodes a Person message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Person
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Person} Person
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Person.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Person();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.email = reader.string();
                    break;
                case 3:
                    message.phone = reader.string();
                    break;
                case 4:
                    message.status = reader.string();
                    break;
                case 5:
                    message.ssoId = reader.string();
                    break;
                case 6:
                    message.commentForExecutor = reader.string();
                    break;
                case 7:
                    message.commentForSupport = reader.string();
                    break;
                case 8:
                    message.fio = $root.PlatformCRMWeb.FIO.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.createdAt = reader.string();
                    break;
                case 10:
                    message.updatedAt = reader.string();
                    break;
                case 11:
                    message.qleanAdminLink = reader.string();
                    break;
                case 12:
                    message.qleanCreditCardNumber = reader.string();
                    break;
                case 13:
                    message.qleanClientId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Person;
    })();

    PlatformCRMWeb.CalculateOption = (function() {

        /**
         * Properties of a CalculateOption.
         * @memberof PlatformCRMWeb
         * @interface ICalculateOption
         * @property {string|null} [slug] CalculateOption slug
         * @property {number|null} [value] CalculateOption value
         * @property {number|null} [totalPrice] CalculateOption totalPrice
         * @property {number|null} [totalDuration] CalculateOption totalDuration
         * @property {string|null} [id] CalculateOption id
         * @property {string|null} [createdAt] CalculateOption createdAt
         * @property {string|null} [updatedAt] CalculateOption updatedAt
         * @property {boolean|null} [isFree] CalculateOption isFree
         * @property {number|null} [factor] CalculateOption factor
         */

        /**
         * Constructs a new CalculateOption.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CalculateOption.
         * @implements ICalculateOption
         * @constructor
         * @param {PlatformCRMWeb.ICalculateOption=} [properties] Properties to set
         */
        function CalculateOption(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalculateOption slug.
         * @member {string} slug
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.slug = "";

        /**
         * CalculateOption value.
         * @member {number} value
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.value = 0;

        /**
         * CalculateOption totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.totalPrice = 0;

        /**
         * CalculateOption totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.totalDuration = 0;

        /**
         * CalculateOption id.
         * @member {string} id
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.id = "";

        /**
         * CalculateOption createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.createdAt = "";

        /**
         * CalculateOption updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.updatedAt = "";

        /**
         * CalculateOption isFree.
         * @member {boolean} isFree
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.isFree = false;

        /**
         * CalculateOption factor.
         * @member {number} factor
         * @memberof PlatformCRMWeb.CalculateOption
         * @instance
         */
        CalculateOption.prototype.factor = 0;

        /**
         * Encodes the specified CalculateOption message. Does not implicitly {@link PlatformCRMWeb.CalculateOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CalculateOption
         * @static
         * @param {PlatformCRMWeb.ICalculateOption} message CalculateOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalculateOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.slug);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalDuration);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.updatedAt);
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isFree);
            if (message.factor != null && Object.hasOwnProperty.call(message, "factor"))
                writer.uint32(/* id 9, wireType 5 =*/77).float(message.factor);
            return writer;
        };

        /**
         * Decodes a CalculateOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CalculateOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CalculateOption} CalculateOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalculateOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CalculateOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.slug = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                case 3:
                    message.totalPrice = reader.int32();
                    break;
                case 4:
                    message.totalDuration = reader.int32();
                    break;
                case 5:
                    message.id = reader.string();
                    break;
                case 6:
                    message.createdAt = reader.string();
                    break;
                case 7:
                    message.updatedAt = reader.string();
                    break;
                case 8:
                    message.isFree = reader.bool();
                    break;
                case 9:
                    message.factor = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CalculateOption;
    })();

    PlatformCRMWeb.CalculateProduct = (function() {

        /**
         * Properties of a CalculateProduct.
         * @memberof PlatformCRMWeb
         * @interface ICalculateProduct
         * @property {string|null} [productSlug] CalculateProduct productSlug
         * @property {Array.<PlatformCRMWeb.ICalculateOption>|null} [options] CalculateProduct options
         * @property {number|null} [totalPrice] CalculateProduct totalPrice
         * @property {number|null} [totalDuration] CalculateProduct totalDuration
         * @property {number|null} [subTotalPrice] CalculateProduct subTotalPrice
         * @property {number|null} [baseCost] CalculateProduct baseCost
         * @property {number|null} [baseDuration] CalculateProduct baseDuration
         * @property {string|null} [id] CalculateProduct id
         * @property {string|null} [createdAt] CalculateProduct createdAt
         * @property {string|null} [updatedAt] CalculateProduct updatedAt
         */

        /**
         * Constructs a new CalculateProduct.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CalculateProduct.
         * @implements ICalculateProduct
         * @constructor
         * @param {PlatformCRMWeb.ICalculateProduct=} [properties] Properties to set
         */
        function CalculateProduct(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalculateProduct productSlug.
         * @member {string} productSlug
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.productSlug = "";

        /**
         * CalculateProduct options.
         * @member {Array.<PlatformCRMWeb.ICalculateOption>} options
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.options = $util.emptyArray;

        /**
         * CalculateProduct totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.totalPrice = 0;

        /**
         * CalculateProduct totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.totalDuration = 0;

        /**
         * CalculateProduct subTotalPrice.
         * @member {number} subTotalPrice
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.subTotalPrice = 0;

        /**
         * CalculateProduct baseCost.
         * @member {number} baseCost
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.baseCost = 0;

        /**
         * CalculateProduct baseDuration.
         * @member {number} baseDuration
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.baseDuration = 0;

        /**
         * CalculateProduct id.
         * @member {string} id
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.id = "";

        /**
         * CalculateProduct createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.createdAt = "";

        /**
         * CalculateProduct updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.CalculateProduct
         * @instance
         */
        CalculateProduct.prototype.updatedAt = "";

        /**
         * Encodes the specified CalculateProduct message. Does not implicitly {@link PlatformCRMWeb.CalculateProduct.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CalculateProduct
         * @static
         * @param {PlatformCRMWeb.ICalculateProduct} message CalculateProduct message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalculateProduct.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productSlug);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformCRMWeb.CalculateOption.encode(message.options[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.totalDuration);
            if (message.subTotalPrice != null && Object.hasOwnProperty.call(message, "subTotalPrice"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.subTotalPrice);
            if (message.baseCost != null && Object.hasOwnProperty.call(message, "baseCost"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.baseCost);
            if (message.baseDuration != null && Object.hasOwnProperty.call(message, "baseDuration"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.baseDuration);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.updatedAt);
            return writer;
        };

        /**
         * Decodes a CalculateProduct message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CalculateProduct
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CalculateProduct} CalculateProduct
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalculateProduct.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CalculateProduct();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productSlug = reader.string();
                    break;
                case 2:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformCRMWeb.CalculateOption.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.totalPrice = reader.int32();
                    break;
                case 4:
                    message.totalDuration = reader.int32();
                    break;
                case 5:
                    message.subTotalPrice = reader.int32();
                    break;
                case 6:
                    message.baseCost = reader.int32();
                    break;
                case 7:
                    message.baseDuration = reader.int32();
                    break;
                case 8:
                    message.id = reader.string();
                    break;
                case 9:
                    message.createdAt = reader.string();
                    break;
                case 10:
                    message.updatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CalculateProduct;
    })();

    PlatformCRMWeb.CalculatePrice = (function() {

        /**
         * Properties of a CalculatePrice.
         * @memberof PlatformCRMWeb
         * @interface ICalculatePrice
         * @property {Array.<PlatformCRMWeb.ICalculateProduct>|null} [products] CalculatePrice products
         * @property {number|null} [totalPrice] CalculatePrice totalPrice
         * @property {number|null} [totalDuration] CalculatePrice totalDuration
         * @property {number|null} [subTotalPrice] CalculatePrice subTotalPrice
         * @property {string|null} [relevantUntil] CalculatePrice relevantUntil
         * @property {string|null} [priceListTitle] CalculatePrice priceListTitle
         * @property {string|null} [priceListId] CalculatePrice priceListId
         * @property {string|null} [id] CalculatePrice id
         * @property {string|null} [createdAt] CalculatePrice createdAt
         * @property {string|null} [updatedAt] CalculatePrice updatedAt
         * @property {number|null} [bonusesUsed] CalculatePrice bonusesUsed
         */

        /**
         * Constructs a new CalculatePrice.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CalculatePrice.
         * @implements ICalculatePrice
         * @constructor
         * @param {PlatformCRMWeb.ICalculatePrice=} [properties] Properties to set
         */
        function CalculatePrice(properties) {
            this.products = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CalculatePrice products.
         * @member {Array.<PlatformCRMWeb.ICalculateProduct>} products
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.products = $util.emptyArray;

        /**
         * CalculatePrice totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.totalPrice = 0;

        /**
         * CalculatePrice totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.totalDuration = 0;

        /**
         * CalculatePrice subTotalPrice.
         * @member {number} subTotalPrice
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.subTotalPrice = 0;

        /**
         * CalculatePrice relevantUntil.
         * @member {string} relevantUntil
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.relevantUntil = "";

        /**
         * CalculatePrice priceListTitle.
         * @member {string} priceListTitle
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.priceListTitle = "";

        /**
         * CalculatePrice priceListId.
         * @member {string} priceListId
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.priceListId = "";

        /**
         * CalculatePrice id.
         * @member {string} id
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.id = "";

        /**
         * CalculatePrice createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.createdAt = "";

        /**
         * CalculatePrice updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.updatedAt = "";

        /**
         * CalculatePrice bonusesUsed.
         * @member {number} bonusesUsed
         * @memberof PlatformCRMWeb.CalculatePrice
         * @instance
         */
        CalculatePrice.prototype.bonusesUsed = 0;

        /**
         * Encodes the specified CalculatePrice message. Does not implicitly {@link PlatformCRMWeb.CalculatePrice.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CalculatePrice
         * @static
         * @param {PlatformCRMWeb.ICalculatePrice} message CalculatePrice message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CalculatePrice.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.products != null && message.products.length)
                for (var i = 0; i < message.products.length; ++i)
                    $root.PlatformCRMWeb.CalculateProduct.encode(message.products[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalDuration);
            if (message.subTotalPrice != null && Object.hasOwnProperty.call(message, "subTotalPrice"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.subTotalPrice);
            if (message.relevantUntil != null && Object.hasOwnProperty.call(message, "relevantUntil"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.relevantUntil);
            if (message.priceListTitle != null && Object.hasOwnProperty.call(message, "priceListTitle"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.priceListTitle);
            if (message.priceListId != null && Object.hasOwnProperty.call(message, "priceListId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.priceListId);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.updatedAt);
            if (message.bonusesUsed != null && Object.hasOwnProperty.call(message, "bonusesUsed"))
                writer.uint32(/* id 11, wireType 0 =*/88).int32(message.bonusesUsed);
            return writer;
        };

        /**
         * Decodes a CalculatePrice message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CalculatePrice
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CalculatePrice} CalculatePrice
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CalculatePrice.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CalculatePrice();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.products && message.products.length))
                        message.products = [];
                    message.products.push($root.PlatformCRMWeb.CalculateProduct.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.totalPrice = reader.int32();
                    break;
                case 3:
                    message.totalDuration = reader.int32();
                    break;
                case 4:
                    message.subTotalPrice = reader.int32();
                    break;
                case 5:
                    message.relevantUntil = reader.string();
                    break;
                case 6:
                    message.priceListTitle = reader.string();
                    break;
                case 7:
                    message.priceListId = reader.string();
                    break;
                case 8:
                    message.id = reader.string();
                    break;
                case 9:
                    message.createdAt = reader.string();
                    break;
                case 10:
                    message.updatedAt = reader.string();
                    break;
                case 11:
                    message.bonusesUsed = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CalculatePrice;
    })();

    PlatformCRMWeb.UuidCondition = (function() {

        /**
         * Properties of an UuidCondition.
         * @memberof PlatformCRMWeb
         * @interface IUuidCondition
         * @property {string|null} [eq] UuidCondition eq
         * @property {string|null} [not] UuidCondition not
         * @property {Array.<string>|null} ["in"] UuidCondition in
         * @property {Array.<string>|null} [notIn] UuidCondition notIn
         * @property {boolean|null} [isNull] UuidCondition isNull
         * @property {boolean|null} [isNotNull] UuidCondition isNotNull
         */

        /**
         * Constructs a new UuidCondition.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an UuidCondition.
         * @implements IUuidCondition
         * @constructor
         * @param {PlatformCRMWeb.IUuidCondition=} [properties] Properties to set
         */
        function UuidCondition(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UuidCondition eq.
         * @member {string} eq
         * @memberof PlatformCRMWeb.UuidCondition
         * @instance
         */
        UuidCondition.prototype.eq = "";

        /**
         * UuidCondition not.
         * @member {string} not
         * @memberof PlatformCRMWeb.UuidCondition
         * @instance
         */
        UuidCondition.prototype.not = "";

        /**
         * UuidCondition in.
         * @member {Array.<string>} in
         * @memberof PlatformCRMWeb.UuidCondition
         * @instance
         */
        UuidCondition.prototype["in"] = $util.emptyArray;

        /**
         * UuidCondition notIn.
         * @member {Array.<string>} notIn
         * @memberof PlatformCRMWeb.UuidCondition
         * @instance
         */
        UuidCondition.prototype.notIn = $util.emptyArray;

        /**
         * UuidCondition isNull.
         * @member {boolean} isNull
         * @memberof PlatformCRMWeb.UuidCondition
         * @instance
         */
        UuidCondition.prototype.isNull = false;

        /**
         * UuidCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof PlatformCRMWeb.UuidCondition
         * @instance
         */
        UuidCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified UuidCondition message. Does not implicitly {@link PlatformCRMWeb.UuidCondition.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UuidCondition
         * @static
         * @param {PlatformCRMWeb.IUuidCondition} message UuidCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UuidCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.not);
            if (message["in"] != null && message["in"].length)
                for (var i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (var i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.notIn[i]);
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes an UuidCondition message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UuidCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UuidCondition} UuidCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UuidCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UuidCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.string();
                    break;
                case 2:
                    message.not = reader.string();
                    break;
                case 3:
                    if (!(message["in"] && message["in"].length))
                        message["in"] = [];
                    message["in"].push(reader.string());
                    break;
                case 4:
                    if (!(message.notIn && message.notIn.length))
                        message.notIn = [];
                    message.notIn.push(reader.string());
                    break;
                case 5:
                    message.isNull = reader.bool();
                    break;
                case 6:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UuidCondition;
    })();

    PlatformCRMWeb.StringCondition = (function() {

        /**
         * Properties of a StringCondition.
         * @memberof PlatformCRMWeb
         * @interface IStringCondition
         * @property {string|null} [eq] StringCondition eq
         * @property {string|null} [not] StringCondition not
         * @property {Array.<string>|null} ["in"] StringCondition in
         * @property {Array.<string>|null} [notIn] StringCondition notIn
         * @property {string|null} [like] StringCondition like
         * @property {boolean|null} [isNull] StringCondition isNull
         * @property {boolean|null} [isNotNull] StringCondition isNotNull
         */

        /**
         * Constructs a new StringCondition.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a StringCondition.
         * @implements IStringCondition
         * @constructor
         * @param {PlatformCRMWeb.IStringCondition=} [properties] Properties to set
         */
        function StringCondition(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StringCondition eq.
         * @member {string} eq
         * @memberof PlatformCRMWeb.StringCondition
         * @instance
         */
        StringCondition.prototype.eq = "";

        /**
         * StringCondition not.
         * @member {string} not
         * @memberof PlatformCRMWeb.StringCondition
         * @instance
         */
        StringCondition.prototype.not = "";

        /**
         * StringCondition in.
         * @member {Array.<string>} in
         * @memberof PlatformCRMWeb.StringCondition
         * @instance
         */
        StringCondition.prototype["in"] = $util.emptyArray;

        /**
         * StringCondition notIn.
         * @member {Array.<string>} notIn
         * @memberof PlatformCRMWeb.StringCondition
         * @instance
         */
        StringCondition.prototype.notIn = $util.emptyArray;

        /**
         * StringCondition like.
         * @member {string} like
         * @memberof PlatformCRMWeb.StringCondition
         * @instance
         */
        StringCondition.prototype.like = "";

        /**
         * StringCondition isNull.
         * @member {boolean} isNull
         * @memberof PlatformCRMWeb.StringCondition
         * @instance
         */
        StringCondition.prototype.isNull = false;

        /**
         * StringCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof PlatformCRMWeb.StringCondition
         * @instance
         */
        StringCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified StringCondition message. Does not implicitly {@link PlatformCRMWeb.StringCondition.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.StringCondition
         * @static
         * @param {PlatformCRMWeb.IStringCondition} message StringCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.not);
            if (message["in"] != null && message["in"].length)
                for (var i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (var i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.notIn[i]);
            if (message.like != null && Object.hasOwnProperty.call(message, "like"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.like);
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes a StringCondition message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.StringCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.StringCondition} StringCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.StringCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.string();
                    break;
                case 2:
                    message.not = reader.string();
                    break;
                case 3:
                    if (!(message["in"] && message["in"].length))
                        message["in"] = [];
                    message["in"].push(reader.string());
                    break;
                case 4:
                    if (!(message.notIn && message.notIn.length))
                        message.notIn = [];
                    message.notIn.push(reader.string());
                    break;
                case 5:
                    message.like = reader.string();
                    break;
                case 6:
                    message.isNull = reader.bool();
                    break;
                case 7:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return StringCondition;
    })();

    PlatformCRMWeb.NumberBetweenOperation = (function() {

        /**
         * Properties of a NumberBetweenOperation.
         * @memberof PlatformCRMWeb
         * @interface INumberBetweenOperation
         * @property {number|null} [low] NumberBetweenOperation low
         * @property {number|null} [high] NumberBetweenOperation high
         */

        /**
         * Constructs a new NumberBetweenOperation.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a NumberBetweenOperation.
         * @implements INumberBetweenOperation
         * @constructor
         * @param {PlatformCRMWeb.INumberBetweenOperation=} [properties] Properties to set
         */
        function NumberBetweenOperation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NumberBetweenOperation low.
         * @member {number} low
         * @memberof PlatformCRMWeb.NumberBetweenOperation
         * @instance
         */
        NumberBetweenOperation.prototype.low = 0;

        /**
         * NumberBetweenOperation high.
         * @member {number} high
         * @memberof PlatformCRMWeb.NumberBetweenOperation
         * @instance
         */
        NumberBetweenOperation.prototype.high = 0;

        /**
         * Encodes the specified NumberBetweenOperation message. Does not implicitly {@link PlatformCRMWeb.NumberBetweenOperation.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.NumberBetweenOperation
         * @static
         * @param {PlatformCRMWeb.INumberBetweenOperation} message NumberBetweenOperation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NumberBetweenOperation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.low != null && Object.hasOwnProperty.call(message, "low"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.low);
            if (message.high != null && Object.hasOwnProperty.call(message, "high"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.high);
            return writer;
        };

        /**
         * Decodes a NumberBetweenOperation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.NumberBetweenOperation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.NumberBetweenOperation} NumberBetweenOperation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NumberBetweenOperation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.NumberBetweenOperation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.low = reader.int32();
                    break;
                case 2:
                    message.high = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return NumberBetweenOperation;
    })();

    PlatformCRMWeb.StringBetweenOperation = (function() {

        /**
         * Properties of a StringBetweenOperation.
         * @memberof PlatformCRMWeb
         * @interface IStringBetweenOperation
         * @property {string|null} [low] StringBetweenOperation low
         * @property {string|null} [high] StringBetweenOperation high
         */

        /**
         * Constructs a new StringBetweenOperation.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a StringBetweenOperation.
         * @implements IStringBetweenOperation
         * @constructor
         * @param {PlatformCRMWeb.IStringBetweenOperation=} [properties] Properties to set
         */
        function StringBetweenOperation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StringBetweenOperation low.
         * @member {string} low
         * @memberof PlatformCRMWeb.StringBetweenOperation
         * @instance
         */
        StringBetweenOperation.prototype.low = "";

        /**
         * StringBetweenOperation high.
         * @member {string} high
         * @memberof PlatformCRMWeb.StringBetweenOperation
         * @instance
         */
        StringBetweenOperation.prototype.high = "";

        /**
         * Encodes the specified StringBetweenOperation message. Does not implicitly {@link PlatformCRMWeb.StringBetweenOperation.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.StringBetweenOperation
         * @static
         * @param {PlatformCRMWeb.IStringBetweenOperation} message StringBetweenOperation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringBetweenOperation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.low != null && Object.hasOwnProperty.call(message, "low"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.low);
            if (message.high != null && Object.hasOwnProperty.call(message, "high"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.high);
            return writer;
        };

        /**
         * Decodes a StringBetweenOperation message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.StringBetweenOperation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.StringBetweenOperation} StringBetweenOperation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringBetweenOperation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.StringBetweenOperation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.low = reader.string();
                    break;
                case 2:
                    message.high = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return StringBetweenOperation;
    })();

    PlatformCRMWeb.NumberCondition = (function() {

        /**
         * Properties of a NumberCondition.
         * @memberof PlatformCRMWeb
         * @interface INumberCondition
         * @property {number|null} [eq] NumberCondition eq
         * @property {number|null} [not] NumberCondition not
         * @property {number|null} [less] NumberCondition less
         * @property {number|null} [lessEq] NumberCondition lessEq
         * @property {number|null} [more] NumberCondition more
         * @property {number|null} [moreEq] NumberCondition moreEq
         * @property {PlatformCRMWeb.INumberBetweenOperation|null} [between] NumberCondition between
         * @property {PlatformCRMWeb.INumberBetweenOperation|null} [notBetween] NumberCondition notBetween
         * @property {Array.<number>|null} ["in"] NumberCondition in
         * @property {Array.<number>|null} [notIn] NumberCondition notIn
         * @property {boolean|null} [isNull] NumberCondition isNull
         * @property {boolean|null} [isNotNull] NumberCondition isNotNull
         */

        /**
         * Constructs a new NumberCondition.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a NumberCondition.
         * @implements INumberCondition
         * @constructor
         * @param {PlatformCRMWeb.INumberCondition=} [properties] Properties to set
         */
        function NumberCondition(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NumberCondition eq.
         * @member {number} eq
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype.eq = 0;

        /**
         * NumberCondition not.
         * @member {number} not
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype.not = 0;

        /**
         * NumberCondition less.
         * @member {number} less
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype.less = 0;

        /**
         * NumberCondition lessEq.
         * @member {number} lessEq
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype.lessEq = 0;

        /**
         * NumberCondition more.
         * @member {number} more
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype.more = 0;

        /**
         * NumberCondition moreEq.
         * @member {number} moreEq
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype.moreEq = 0;

        /**
         * NumberCondition between.
         * @member {PlatformCRMWeb.INumberBetweenOperation|null|undefined} between
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype.between = null;

        /**
         * NumberCondition notBetween.
         * @member {PlatformCRMWeb.INumberBetweenOperation|null|undefined} notBetween
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype.notBetween = null;

        /**
         * NumberCondition in.
         * @member {Array.<number>} in
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype["in"] = $util.emptyArray;

        /**
         * NumberCondition notIn.
         * @member {Array.<number>} notIn
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype.notIn = $util.emptyArray;

        /**
         * NumberCondition isNull.
         * @member {boolean} isNull
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype.isNull = false;

        /**
         * NumberCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof PlatformCRMWeb.NumberCondition
         * @instance
         */
        NumberCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified NumberCondition message. Does not implicitly {@link PlatformCRMWeb.NumberCondition.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.NumberCondition
         * @static
         * @param {PlatformCRMWeb.INumberCondition} message NumberCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NumberCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.not);
            if (message.less != null && Object.hasOwnProperty.call(message, "less"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.less);
            if (message.lessEq != null && Object.hasOwnProperty.call(message, "lessEq"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lessEq);
            if (message.more != null && Object.hasOwnProperty.call(message, "more"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.more);
            if (message.moreEq != null && Object.hasOwnProperty.call(message, "moreEq"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.moreEq);
            if (message.between != null && Object.hasOwnProperty.call(message, "between"))
                $root.PlatformCRMWeb.NumberBetweenOperation.encode(message.between, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.notBetween != null && Object.hasOwnProperty.call(message, "notBetween"))
                $root.PlatformCRMWeb.NumberBetweenOperation.encode(message.notBetween, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message["in"] != null && message["in"].length) {
                writer.uint32(/* id 9, wireType 2 =*/74).fork();
                for (var i = 0; i < message["in"].length; ++i)
                    writer.int32(message["in"][i]);
                writer.ldelim();
            }
            if (message.notIn != null && message.notIn.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (var i = 0; i < message.notIn.length; ++i)
                    writer.int32(message.notIn[i]);
                writer.ldelim();
            }
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes a NumberCondition message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.NumberCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.NumberCondition} NumberCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NumberCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.NumberCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.int32();
                    break;
                case 2:
                    message.not = reader.int32();
                    break;
                case 3:
                    message.less = reader.int32();
                    break;
                case 4:
                    message.lessEq = reader.int32();
                    break;
                case 5:
                    message.more = reader.int32();
                    break;
                case 6:
                    message.moreEq = reader.int32();
                    break;
                case 7:
                    message.between = $root.PlatformCRMWeb.NumberBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.notBetween = $root.PlatformCRMWeb.NumberBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 9:
                    if (!(message["in"] && message["in"].length))
                        message["in"] = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message["in"].push(reader.int32());
                    } else
                        message["in"].push(reader.int32());
                    break;
                case 10:
                    if (!(message.notIn && message.notIn.length))
                        message.notIn = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.notIn.push(reader.int32());
                    } else
                        message.notIn.push(reader.int32());
                    break;
                case 11:
                    message.isNull = reader.bool();
                    break;
                case 12:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return NumberCondition;
    })();

    PlatformCRMWeb.DateCondition = (function() {

        /**
         * Properties of a DateCondition.
         * @memberof PlatformCRMWeb
         * @interface IDateCondition
         * @property {string|null} [eq] DateCondition eq
         * @property {string|null} [not] DateCondition not
         * @property {string|null} [less] DateCondition less
         * @property {string|null} [lessEq] DateCondition lessEq
         * @property {string|null} [more] DateCondition more
         * @property {string|null} [moreEq] DateCondition moreEq
         * @property {PlatformCRMWeb.IStringBetweenOperation|null} [between] DateCondition between
         * @property {PlatformCRMWeb.IStringBetweenOperation|null} [notBetween] DateCondition notBetween
         * @property {boolean|null} [isNull] DateCondition isNull
         * @property {boolean|null} [isNotNull] DateCondition isNotNull
         */

        /**
         * Constructs a new DateCondition.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a DateCondition.
         * @implements IDateCondition
         * @constructor
         * @param {PlatformCRMWeb.IDateCondition=} [properties] Properties to set
         */
        function DateCondition(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DateCondition eq.
         * @member {string} eq
         * @memberof PlatformCRMWeb.DateCondition
         * @instance
         */
        DateCondition.prototype.eq = "";

        /**
         * DateCondition not.
         * @member {string} not
         * @memberof PlatformCRMWeb.DateCondition
         * @instance
         */
        DateCondition.prototype.not = "";

        /**
         * DateCondition less.
         * @member {string} less
         * @memberof PlatformCRMWeb.DateCondition
         * @instance
         */
        DateCondition.prototype.less = "";

        /**
         * DateCondition lessEq.
         * @member {string} lessEq
         * @memberof PlatformCRMWeb.DateCondition
         * @instance
         */
        DateCondition.prototype.lessEq = "";

        /**
         * DateCondition more.
         * @member {string} more
         * @memberof PlatformCRMWeb.DateCondition
         * @instance
         */
        DateCondition.prototype.more = "";

        /**
         * DateCondition moreEq.
         * @member {string} moreEq
         * @memberof PlatformCRMWeb.DateCondition
         * @instance
         */
        DateCondition.prototype.moreEq = "";

        /**
         * DateCondition between.
         * @member {PlatformCRMWeb.IStringBetweenOperation|null|undefined} between
         * @memberof PlatformCRMWeb.DateCondition
         * @instance
         */
        DateCondition.prototype.between = null;

        /**
         * DateCondition notBetween.
         * @member {PlatformCRMWeb.IStringBetweenOperation|null|undefined} notBetween
         * @memberof PlatformCRMWeb.DateCondition
         * @instance
         */
        DateCondition.prototype.notBetween = null;

        /**
         * DateCondition isNull.
         * @member {boolean} isNull
         * @memberof PlatformCRMWeb.DateCondition
         * @instance
         */
        DateCondition.prototype.isNull = false;

        /**
         * DateCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof PlatformCRMWeb.DateCondition
         * @instance
         */
        DateCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified DateCondition message. Does not implicitly {@link PlatformCRMWeb.DateCondition.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.DateCondition
         * @static
         * @param {PlatformCRMWeb.IDateCondition} message DateCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.not);
            if (message.less != null && Object.hasOwnProperty.call(message, "less"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.less);
            if (message.lessEq != null && Object.hasOwnProperty.call(message, "lessEq"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lessEq);
            if (message.more != null && Object.hasOwnProperty.call(message, "more"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.more);
            if (message.moreEq != null && Object.hasOwnProperty.call(message, "moreEq"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.moreEq);
            if (message.between != null && Object.hasOwnProperty.call(message, "between"))
                $root.PlatformCRMWeb.StringBetweenOperation.encode(message.between, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.notBetween != null && Object.hasOwnProperty.call(message, "notBetween"))
                $root.PlatformCRMWeb.StringBetweenOperation.encode(message.notBetween, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes a DateCondition message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.DateCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.DateCondition} DateCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.DateCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.string();
                    break;
                case 2:
                    message.not = reader.string();
                    break;
                case 3:
                    message.less = reader.string();
                    break;
                case 4:
                    message.lessEq = reader.string();
                    break;
                case 5:
                    message.more = reader.string();
                    break;
                case 6:
                    message.moreEq = reader.string();
                    break;
                case 7:
                    message.between = $root.PlatformCRMWeb.StringBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.notBetween = $root.PlatformCRMWeb.StringBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.isNull = reader.bool();
                    break;
                case 12:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DateCondition;
    })();

    PlatformCRMWeb.AddressRes = (function() {

        /**
         * Properties of an AddressRes.
         * @memberof PlatformCRMWeb
         * @interface IAddressRes
         * @property {string|null} [id] AddressRes id
         * @property {string|null} [label] AddressRes label
         * @property {string|null} [value] AddressRes value
         * @property {string|null} [unrestrictedValue] AddressRes unrestrictedValue
         * @property {string|null} [object] AddressRes object
         * @property {sso.IAddressDetail|null} [detail] AddressRes detail
         * @property {sso.ICoordinates|null} [coordinates] AddressRes coordinates
         * @property {Array.<sso.IMetro>|null} [metro] AddressRes metro
         * @property {string|null} [type] AddressRes type
         */

        /**
         * Constructs a new AddressRes.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an AddressRes.
         * @implements IAddressRes
         * @constructor
         * @param {PlatformCRMWeb.IAddressRes=} [properties] Properties to set
         */
        function AddressRes(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddressRes id.
         * @member {string} id
         * @memberof PlatformCRMWeb.AddressRes
         * @instance
         */
        AddressRes.prototype.id = "";

        /**
         * AddressRes label.
         * @member {string} label
         * @memberof PlatformCRMWeb.AddressRes
         * @instance
         */
        AddressRes.prototype.label = "";

        /**
         * AddressRes value.
         * @member {string} value
         * @memberof PlatformCRMWeb.AddressRes
         * @instance
         */
        AddressRes.prototype.value = "";

        /**
         * AddressRes unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof PlatformCRMWeb.AddressRes
         * @instance
         */
        AddressRes.prototype.unrestrictedValue = "";

        /**
         * AddressRes object.
         * @member {string} object
         * @memberof PlatformCRMWeb.AddressRes
         * @instance
         */
        AddressRes.prototype.object = "";

        /**
         * AddressRes detail.
         * @member {sso.IAddressDetail|null|undefined} detail
         * @memberof PlatformCRMWeb.AddressRes
         * @instance
         */
        AddressRes.prototype.detail = null;

        /**
         * AddressRes coordinates.
         * @member {sso.ICoordinates|null|undefined} coordinates
         * @memberof PlatformCRMWeb.AddressRes
         * @instance
         */
        AddressRes.prototype.coordinates = null;

        /**
         * AddressRes metro.
         * @member {Array.<sso.IMetro>} metro
         * @memberof PlatformCRMWeb.AddressRes
         * @instance
         */
        AddressRes.prototype.metro = $util.emptyArray;

        /**
         * AddressRes type.
         * @member {string} type
         * @memberof PlatformCRMWeb.AddressRes
         * @instance
         */
        AddressRes.prototype.type = "";

        /**
         * Encodes the specified AddressRes message. Does not implicitly {@link PlatformCRMWeb.AddressRes.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.AddressRes
         * @static
         * @param {PlatformCRMWeb.IAddressRes} message AddressRes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddressRes.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.unrestrictedValue);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.object);
            if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                $root.sso.AddressDetail.encode(message.detail, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                $root.sso.Coordinates.encode(message.coordinates, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.sso.Metro.encode(message.metro[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.type);
            return writer;
        };

        /**
         * Decodes an AddressRes message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.AddressRes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.AddressRes} AddressRes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddressRes.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.AddressRes();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.unrestrictedValue = reader.string();
                    break;
                case 5:
                    message.object = reader.string();
                    break;
                case 6:
                    message.detail = $root.sso.AddressDetail.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.coordinates = $root.sso.Coordinates.decode(reader, reader.uint32());
                    break;
                case 8:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.sso.Metro.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddressRes;
    })();

    PlatformCRMWeb.CustomerCall = (function() {

        /**
         * Properties of a CustomerCall.
         * @memberof PlatformCRMWeb
         * @interface ICustomerCall
         * @property {number|null} [id] CustomerCall id
         * @property {number|null} [orderId] CustomerCall orderId
         * @property {string|null} [comment] CustomerCall comment
         * @property {string|null} [fullName] CustomerCall fullName
         * @property {string|null} [calledAt] CustomerCall calledAt
         * @property {string|null} [callTypes] CustomerCall callTypes
         * @property {string|null} [adminLink] CustomerCall adminLink
         */

        /**
         * Constructs a new CustomerCall.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CustomerCall.
         * @implements ICustomerCall
         * @constructor
         * @param {PlatformCRMWeb.ICustomerCall=} [properties] Properties to set
         */
        function CustomerCall(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CustomerCall id.
         * @member {number} id
         * @memberof PlatformCRMWeb.CustomerCall
         * @instance
         */
        CustomerCall.prototype.id = 0;

        /**
         * CustomerCall orderId.
         * @member {number} orderId
         * @memberof PlatformCRMWeb.CustomerCall
         * @instance
         */
        CustomerCall.prototype.orderId = 0;

        /**
         * CustomerCall comment.
         * @member {string} comment
         * @memberof PlatformCRMWeb.CustomerCall
         * @instance
         */
        CustomerCall.prototype.comment = "";

        /**
         * CustomerCall fullName.
         * @member {string} fullName
         * @memberof PlatformCRMWeb.CustomerCall
         * @instance
         */
        CustomerCall.prototype.fullName = "";

        /**
         * CustomerCall calledAt.
         * @member {string} calledAt
         * @memberof PlatformCRMWeb.CustomerCall
         * @instance
         */
        CustomerCall.prototype.calledAt = "";

        /**
         * CustomerCall callTypes.
         * @member {string} callTypes
         * @memberof PlatformCRMWeb.CustomerCall
         * @instance
         */
        CustomerCall.prototype.callTypes = "";

        /**
         * CustomerCall adminLink.
         * @member {string} adminLink
         * @memberof PlatformCRMWeb.CustomerCall
         * @instance
         */
        CustomerCall.prototype.adminLink = "";

        /**
         * Encodes the specified CustomerCall message. Does not implicitly {@link PlatformCRMWeb.CustomerCall.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CustomerCall
         * @static
         * @param {PlatformCRMWeb.ICustomerCall} message CustomerCall message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CustomerCall.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orderId);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.comment);
            if (message.fullName != null && Object.hasOwnProperty.call(message, "fullName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.fullName);
            if (message.calledAt != null && Object.hasOwnProperty.call(message, "calledAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.calledAt);
            if (message.callTypes != null && Object.hasOwnProperty.call(message, "callTypes"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.callTypes);
            if (message.adminLink != null && Object.hasOwnProperty.call(message, "adminLink"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.adminLink);
            return writer;
        };

        /**
         * Decodes a CustomerCall message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CustomerCall
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CustomerCall} CustomerCall
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CustomerCall.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CustomerCall();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.orderId = reader.int32();
                    break;
                case 3:
                    message.comment = reader.string();
                    break;
                case 4:
                    message.fullName = reader.string();
                    break;
                case 5:
                    message.calledAt = reader.string();
                    break;
                case 6:
                    message.callTypes = reader.string();
                    break;
                case 7:
                    message.adminLink = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CustomerCall;
    })();

    /**
     * AccountTicker enum.
     * @name PlatformCRMWeb.AccountTicker
     * @enum {number}
     * @property {number} BONUS=1 BONUS value
     * @property {number} MONEY=2 MONEY value
     */
    PlatformCRMWeb.AccountTicker = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "BONUS"] = 1;
        values[valuesById[2] = "MONEY"] = 2;
        return values;
    })();

    PlatformCRMWeb.SearchCustomerWhere = (function() {

        /**
         * Properties of a SearchCustomerWhere.
         * @memberof PlatformCRMWeb
         * @interface ISearchCustomerWhere
         * @property {PlatformCRMWeb.IStringCondition|null} [phone] SearchCustomerWhere phone
         */

        /**
         * Constructs a new SearchCustomerWhere.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SearchCustomerWhere.
         * @implements ISearchCustomerWhere
         * @constructor
         * @param {PlatformCRMWeb.ISearchCustomerWhere=} [properties] Properties to set
         */
        function SearchCustomerWhere(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchCustomerWhere phone.
         * @member {PlatformCRMWeb.IStringCondition|null|undefined} phone
         * @memberof PlatformCRMWeb.SearchCustomerWhere
         * @instance
         */
        SearchCustomerWhere.prototype.phone = null;

        /**
         * Encodes the specified SearchCustomerWhere message. Does not implicitly {@link PlatformCRMWeb.SearchCustomerWhere.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SearchCustomerWhere
         * @static
         * @param {PlatformCRMWeb.ISearchCustomerWhere} message SearchCustomerWhere message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchCustomerWhere.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                $root.PlatformCRMWeb.StringCondition.encode(message.phone, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchCustomerWhere message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SearchCustomerWhere
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SearchCustomerWhere} SearchCustomerWhere
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchCustomerWhere.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SearchCustomerWhere();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.phone = $root.PlatformCRMWeb.StringCondition.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchCustomerWhere;
    })();

    PlatformCRMWeb.GetCustomerOrdersRequest = (function() {

        /**
         * Properties of a GetCustomerOrdersRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetCustomerOrdersRequest
         * @property {string|null} [uid] GetCustomerOrdersRequest uid
         */

        /**
         * Constructs a new GetCustomerOrdersRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetCustomerOrdersRequest.
         * @implements IGetCustomerOrdersRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetCustomerOrdersRequest=} [properties] Properties to set
         */
        function GetCustomerOrdersRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCustomerOrdersRequest uid.
         * @member {string} uid
         * @memberof PlatformCRMWeb.GetCustomerOrdersRequest
         * @instance
         */
        GetCustomerOrdersRequest.prototype.uid = "";

        /**
         * Encodes the specified GetCustomerOrdersRequest message. Does not implicitly {@link PlatformCRMWeb.GetCustomerOrdersRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetCustomerOrdersRequest
         * @static
         * @param {PlatformCRMWeb.IGetCustomerOrdersRequest} message GetCustomerOrdersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCustomerOrdersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            return writer;
        };

        /**
         * Decodes a GetCustomerOrdersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetCustomerOrdersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetCustomerOrdersRequest} GetCustomerOrdersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCustomerOrdersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetCustomerOrdersRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetCustomerOrdersRequest;
    })();

    PlatformCRMWeb.GetCustomerCallsRequest = (function() {

        /**
         * Properties of a GetCustomerCallsRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetCustomerCallsRequest
         * @property {string|null} [clientId] GetCustomerCallsRequest clientId
         */

        /**
         * Constructs a new GetCustomerCallsRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetCustomerCallsRequest.
         * @implements IGetCustomerCallsRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetCustomerCallsRequest=} [properties] Properties to set
         */
        function GetCustomerCallsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCustomerCallsRequest clientId.
         * @member {string} clientId
         * @memberof PlatformCRMWeb.GetCustomerCallsRequest
         * @instance
         */
        GetCustomerCallsRequest.prototype.clientId = "";

        /**
         * Encodes the specified GetCustomerCallsRequest message. Does not implicitly {@link PlatformCRMWeb.GetCustomerCallsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetCustomerCallsRequest
         * @static
         * @param {PlatformCRMWeb.IGetCustomerCallsRequest} message GetCustomerCallsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCustomerCallsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientId);
            return writer;
        };

        /**
         * Decodes a GetCustomerCallsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetCustomerCallsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetCustomerCallsRequest} GetCustomerCallsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCustomerCallsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetCustomerCallsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.clientId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetCustomerCallsRequest;
    })();

    PlatformCRMWeb.GetCustomerInfoRequest = (function() {

        /**
         * Properties of a GetCustomerInfoRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetCustomerInfoRequest
         * @property {string|null} [uid] GetCustomerInfoRequest uid
         */

        /**
         * Constructs a new GetCustomerInfoRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetCustomerInfoRequest.
         * @implements IGetCustomerInfoRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetCustomerInfoRequest=} [properties] Properties to set
         */
        function GetCustomerInfoRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCustomerInfoRequest uid.
         * @member {string} uid
         * @memberof PlatformCRMWeb.GetCustomerInfoRequest
         * @instance
         */
        GetCustomerInfoRequest.prototype.uid = "";

        /**
         * Encodes the specified GetCustomerInfoRequest message. Does not implicitly {@link PlatformCRMWeb.GetCustomerInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetCustomerInfoRequest
         * @static
         * @param {PlatformCRMWeb.IGetCustomerInfoRequest} message GetCustomerInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCustomerInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            return writer;
        };

        /**
         * Decodes a GetCustomerInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetCustomerInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetCustomerInfoRequest} GetCustomerInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCustomerInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetCustomerInfoRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetCustomerInfoRequest;
    })();

    PlatformCRMWeb.GetCustomerAddressesRequest = (function() {

        /**
         * Properties of a GetCustomerAddressesRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetCustomerAddressesRequest
         * @property {string|null} [uid] GetCustomerAddressesRequest uid
         */

        /**
         * Constructs a new GetCustomerAddressesRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetCustomerAddressesRequest.
         * @implements IGetCustomerAddressesRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetCustomerAddressesRequest=} [properties] Properties to set
         */
        function GetCustomerAddressesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCustomerAddressesRequest uid.
         * @member {string} uid
         * @memberof PlatformCRMWeb.GetCustomerAddressesRequest
         * @instance
         */
        GetCustomerAddressesRequest.prototype.uid = "";

        /**
         * Encodes the specified GetCustomerAddressesRequest message. Does not implicitly {@link PlatformCRMWeb.GetCustomerAddressesRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetCustomerAddressesRequest
         * @static
         * @param {PlatformCRMWeb.IGetCustomerAddressesRequest} message GetCustomerAddressesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCustomerAddressesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uid);
            return writer;
        };

        /**
         * Decodes a GetCustomerAddressesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetCustomerAddressesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetCustomerAddressesRequest} GetCustomerAddressesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCustomerAddressesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetCustomerAddressesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uid = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetCustomerAddressesRequest;
    })();

    PlatformCRMWeb.UpdateRequest = (function() {

        /**
         * Properties of an UpdateRequest.
         * @memberof PlatformCRMWeb
         * @interface IUpdateRequest
         * @property {PlatformCRMWeb.IPerson|null} [person] UpdateRequest person
         * @property {PlatformCRMWeb.IProspect|null} [prospect] UpdateRequest prospect
         */

        /**
         * Constructs a new UpdateRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an UpdateRequest.
         * @implements IUpdateRequest
         * @constructor
         * @param {PlatformCRMWeb.IUpdateRequest=} [properties] Properties to set
         */
        function UpdateRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateRequest person.
         * @member {PlatformCRMWeb.IPerson|null|undefined} person
         * @memberof PlatformCRMWeb.UpdateRequest
         * @instance
         */
        UpdateRequest.prototype.person = null;

        /**
         * UpdateRequest prospect.
         * @member {PlatformCRMWeb.IProspect|null|undefined} prospect
         * @memberof PlatformCRMWeb.UpdateRequest
         * @instance
         */
        UpdateRequest.prototype.prospect = null;

        /**
         * Encodes the specified UpdateRequest message. Does not implicitly {@link PlatformCRMWeb.UpdateRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UpdateRequest
         * @static
         * @param {PlatformCRMWeb.IUpdateRequest} message UpdateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.person != null && Object.hasOwnProperty.call(message, "person"))
                $root.PlatformCRMWeb.Person.encode(message.person, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.prospect != null && Object.hasOwnProperty.call(message, "prospect"))
                $root.PlatformCRMWeb.Prospect.encode(message.prospect, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UpdateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UpdateRequest} UpdateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UpdateRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.person = $root.PlatformCRMWeb.Person.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.prospect = $root.PlatformCRMWeb.Prospect.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateRequest;
    })();

    PlatformCRMWeb.AttachCardRequest = (function() {

        /**
         * Properties of an AttachCardRequest.
         * @memberof PlatformCRMWeb
         * @interface IAttachCardRequest
         * @property {string|null} [personId] AttachCardRequest personId
         */

        /**
         * Constructs a new AttachCardRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an AttachCardRequest.
         * @implements IAttachCardRequest
         * @constructor
         * @param {PlatformCRMWeb.IAttachCardRequest=} [properties] Properties to set
         */
        function AttachCardRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AttachCardRequest personId.
         * @member {string} personId
         * @memberof PlatformCRMWeb.AttachCardRequest
         * @instance
         */
        AttachCardRequest.prototype.personId = "";

        /**
         * Encodes the specified AttachCardRequest message. Does not implicitly {@link PlatformCRMWeb.AttachCardRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.AttachCardRequest
         * @static
         * @param {PlatformCRMWeb.IAttachCardRequest} message AttachCardRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttachCardRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.personId != null && Object.hasOwnProperty.call(message, "personId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.personId);
            return writer;
        };

        /**
         * Decodes an AttachCardRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.AttachCardRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.AttachCardRequest} AttachCardRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttachCardRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.AttachCardRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.personId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AttachCardRequest;
    })();

    PlatformCRMWeb.GetBonusesAccountRequest = (function() {

        /**
         * Properties of a GetBonusesAccountRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetBonusesAccountRequest
         * @property {string|null} [userId] GetBonusesAccountRequest userId
         * @property {string|null} [tenantId] GetBonusesAccountRequest tenantId
         * @property {PlatformCRMWeb.AccountTicker|null} [ticker] GetBonusesAccountRequest ticker
         */

        /**
         * Constructs a new GetBonusesAccountRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetBonusesAccountRequest.
         * @implements IGetBonusesAccountRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetBonusesAccountRequest=} [properties] Properties to set
         */
        function GetBonusesAccountRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBonusesAccountRequest userId.
         * @member {string} userId
         * @memberof PlatformCRMWeb.GetBonusesAccountRequest
         * @instance
         */
        GetBonusesAccountRequest.prototype.userId = "";

        /**
         * GetBonusesAccountRequest tenantId.
         * @member {string} tenantId
         * @memberof PlatformCRMWeb.GetBonusesAccountRequest
         * @instance
         */
        GetBonusesAccountRequest.prototype.tenantId = "";

        /**
         * GetBonusesAccountRequest ticker.
         * @member {PlatformCRMWeb.AccountTicker} ticker
         * @memberof PlatformCRMWeb.GetBonusesAccountRequest
         * @instance
         */
        GetBonusesAccountRequest.prototype.ticker = 1;

        /**
         * Encodes the specified GetBonusesAccountRequest message. Does not implicitly {@link PlatformCRMWeb.GetBonusesAccountRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetBonusesAccountRequest
         * @static
         * @param {PlatformCRMWeb.IGetBonusesAccountRequest} message GetBonusesAccountRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBonusesAccountRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.tenantId != null && Object.hasOwnProperty.call(message, "tenantId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tenantId);
            if (message.ticker != null && Object.hasOwnProperty.call(message, "ticker"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ticker);
            return writer;
        };

        /**
         * Decodes a GetBonusesAccountRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetBonusesAccountRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetBonusesAccountRequest} GetBonusesAccountRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBonusesAccountRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetBonusesAccountRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.tenantId = reader.string();
                    break;
                case 3:
                    message.ticker = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetBonusesAccountRequest;
    })();

    PlatformCRMWeb.SearchCustomerRequest = (function() {

        /**
         * Properties of a SearchCustomerRequest.
         * @memberof PlatformCRMWeb
         * @interface ISearchCustomerRequest
         * @property {number|null} [limit] SearchCustomerRequest limit
         * @property {number|null} [page] SearchCustomerRequest page
         * @property {Array.<PlatformCRMWeb.ISearchCustomerWhere>|null} [where] SearchCustomerRequest where
         */

        /**
         * Constructs a new SearchCustomerRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SearchCustomerRequest.
         * @implements ISearchCustomerRequest
         * @constructor
         * @param {PlatformCRMWeb.ISearchCustomerRequest=} [properties] Properties to set
         */
        function SearchCustomerRequest(properties) {
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchCustomerRequest limit.
         * @member {number} limit
         * @memberof PlatformCRMWeb.SearchCustomerRequest
         * @instance
         */
        SearchCustomerRequest.prototype.limit = 0;

        /**
         * SearchCustomerRequest page.
         * @member {number} page
         * @memberof PlatformCRMWeb.SearchCustomerRequest
         * @instance
         */
        SearchCustomerRequest.prototype.page = 0;

        /**
         * SearchCustomerRequest where.
         * @member {Array.<PlatformCRMWeb.ISearchCustomerWhere>} where
         * @memberof PlatformCRMWeb.SearchCustomerRequest
         * @instance
         */
        SearchCustomerRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchCustomerRequest message. Does not implicitly {@link PlatformCRMWeb.SearchCustomerRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SearchCustomerRequest
         * @static
         * @param {PlatformCRMWeb.ISearchCustomerRequest} message SearchCustomerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchCustomerRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.limit);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformCRMWeb.SearchCustomerWhere.encode(message.where[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchCustomerRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SearchCustomerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SearchCustomerRequest} SearchCustomerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchCustomerRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SearchCustomerRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.limit = reader.int32();
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformCRMWeb.SearchCustomerWhere.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchCustomerRequest;
    })();

    PlatformCRMWeb.GetCustomerOrdersResponse = (function() {

        /**
         * Properties of a GetCustomerOrdersResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetCustomerOrdersResponse
         * @property {Array.<PlatformCRMWeb.IOrder>|null} [orders] GetCustomerOrdersResponse orders
         */

        /**
         * Constructs a new GetCustomerOrdersResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetCustomerOrdersResponse.
         * @implements IGetCustomerOrdersResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetCustomerOrdersResponse=} [properties] Properties to set
         */
        function GetCustomerOrdersResponse(properties) {
            this.orders = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCustomerOrdersResponse orders.
         * @member {Array.<PlatformCRMWeb.IOrder>} orders
         * @memberof PlatformCRMWeb.GetCustomerOrdersResponse
         * @instance
         */
        GetCustomerOrdersResponse.prototype.orders = $util.emptyArray;

        /**
         * Encodes the specified GetCustomerOrdersResponse message. Does not implicitly {@link PlatformCRMWeb.GetCustomerOrdersResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetCustomerOrdersResponse
         * @static
         * @param {PlatformCRMWeb.IGetCustomerOrdersResponse} message GetCustomerOrdersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCustomerOrdersResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orders != null && message.orders.length)
                for (var i = 0; i < message.orders.length; ++i)
                    $root.PlatformCRMWeb.Order.encode(message.orders[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetCustomerOrdersResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetCustomerOrdersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetCustomerOrdersResponse} GetCustomerOrdersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCustomerOrdersResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetCustomerOrdersResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.orders && message.orders.length))
                        message.orders = [];
                    message.orders.push($root.PlatformCRMWeb.Order.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetCustomerOrdersResponse;
    })();

    PlatformCRMWeb.GetCustomerCallsResponse = (function() {

        /**
         * Properties of a GetCustomerCallsResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetCustomerCallsResponse
         * @property {Array.<PlatformCRMWeb.ICustomerCall>|null} [calls] GetCustomerCallsResponse calls
         */

        /**
         * Constructs a new GetCustomerCallsResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetCustomerCallsResponse.
         * @implements IGetCustomerCallsResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetCustomerCallsResponse=} [properties] Properties to set
         */
        function GetCustomerCallsResponse(properties) {
            this.calls = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCustomerCallsResponse calls.
         * @member {Array.<PlatformCRMWeb.ICustomerCall>} calls
         * @memberof PlatformCRMWeb.GetCustomerCallsResponse
         * @instance
         */
        GetCustomerCallsResponse.prototype.calls = $util.emptyArray;

        /**
         * Encodes the specified GetCustomerCallsResponse message. Does not implicitly {@link PlatformCRMWeb.GetCustomerCallsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetCustomerCallsResponse
         * @static
         * @param {PlatformCRMWeb.IGetCustomerCallsResponse} message GetCustomerCallsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCustomerCallsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.calls != null && message.calls.length)
                for (var i = 0; i < message.calls.length; ++i)
                    $root.PlatformCRMWeb.CustomerCall.encode(message.calls[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetCustomerCallsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetCustomerCallsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetCustomerCallsResponse} GetCustomerCallsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCustomerCallsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetCustomerCallsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.calls && message.calls.length))
                        message.calls = [];
                    message.calls.push($root.PlatformCRMWeb.CustomerCall.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetCustomerCallsResponse;
    })();

    PlatformCRMWeb.GetCustomerInfoResponse = (function() {

        /**
         * Properties of a GetCustomerInfoResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetCustomerInfoResponse
         * @property {PlatformCRMWeb.IPerson|null} [customer] GetCustomerInfoResponse customer
         */

        /**
         * Constructs a new GetCustomerInfoResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetCustomerInfoResponse.
         * @implements IGetCustomerInfoResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetCustomerInfoResponse=} [properties] Properties to set
         */
        function GetCustomerInfoResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCustomerInfoResponse customer.
         * @member {PlatformCRMWeb.IPerson|null|undefined} customer
         * @memberof PlatformCRMWeb.GetCustomerInfoResponse
         * @instance
         */
        GetCustomerInfoResponse.prototype.customer = null;

        /**
         * Encodes the specified GetCustomerInfoResponse message. Does not implicitly {@link PlatformCRMWeb.GetCustomerInfoResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetCustomerInfoResponse
         * @static
         * @param {PlatformCRMWeb.IGetCustomerInfoResponse} message GetCustomerInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCustomerInfoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.customer != null && Object.hasOwnProperty.call(message, "customer"))
                $root.PlatformCRMWeb.Person.encode(message.customer, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetCustomerInfoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetCustomerInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetCustomerInfoResponse} GetCustomerInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCustomerInfoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetCustomerInfoResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.customer = $root.PlatformCRMWeb.Person.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetCustomerInfoResponse;
    })();

    PlatformCRMWeb.GetCustomerAddressesResponse = (function() {

        /**
         * Properties of a GetCustomerAddressesResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetCustomerAddressesResponse
         * @property {Array.<PlatformCRMWeb.IAddressRes>|null} [addresses] GetCustomerAddressesResponse addresses
         */

        /**
         * Constructs a new GetCustomerAddressesResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetCustomerAddressesResponse.
         * @implements IGetCustomerAddressesResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetCustomerAddressesResponse=} [properties] Properties to set
         */
        function GetCustomerAddressesResponse(properties) {
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCustomerAddressesResponse addresses.
         * @member {Array.<PlatformCRMWeb.IAddressRes>} addresses
         * @memberof PlatformCRMWeb.GetCustomerAddressesResponse
         * @instance
         */
        GetCustomerAddressesResponse.prototype.addresses = $util.emptyArray;

        /**
         * Encodes the specified GetCustomerAddressesResponse message. Does not implicitly {@link PlatformCRMWeb.GetCustomerAddressesResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetCustomerAddressesResponse
         * @static
         * @param {PlatformCRMWeb.IGetCustomerAddressesResponse} message GetCustomerAddressesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCustomerAddressesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.PlatformCRMWeb.AddressRes.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetCustomerAddressesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetCustomerAddressesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetCustomerAddressesResponse} GetCustomerAddressesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCustomerAddressesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetCustomerAddressesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.PlatformCRMWeb.AddressRes.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetCustomerAddressesResponse;
    })();

    PlatformCRMWeb.UpdateResponse = (function() {

        /**
         * Properties of an UpdateResponse.
         * @memberof PlatformCRMWeb
         * @interface IUpdateResponse
         * @property {PlatformCRMWeb.IPerson|null} [person] UpdateResponse person
         */

        /**
         * Constructs a new UpdateResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an UpdateResponse.
         * @implements IUpdateResponse
         * @constructor
         * @param {PlatformCRMWeb.IUpdateResponse=} [properties] Properties to set
         */
        function UpdateResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateResponse person.
         * @member {PlatformCRMWeb.IPerson|null|undefined} person
         * @memberof PlatformCRMWeb.UpdateResponse
         * @instance
         */
        UpdateResponse.prototype.person = null;

        /**
         * Encodes the specified UpdateResponse message. Does not implicitly {@link PlatformCRMWeb.UpdateResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UpdateResponse
         * @static
         * @param {PlatformCRMWeb.IUpdateResponse} message UpdateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.person != null && Object.hasOwnProperty.call(message, "person"))
                $root.PlatformCRMWeb.Person.encode(message.person, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UpdateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UpdateResponse} UpdateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UpdateResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.person = $root.PlatformCRMWeb.Person.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateResponse;
    })();

    PlatformCRMWeb.AttachCardResponse = (function() {

        /**
         * Properties of an AttachCardResponse.
         * @memberof PlatformCRMWeb
         * @interface IAttachCardResponse
         */

        /**
         * Constructs a new AttachCardResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an AttachCardResponse.
         * @implements IAttachCardResponse
         * @constructor
         * @param {PlatformCRMWeb.IAttachCardResponse=} [properties] Properties to set
         */
        function AttachCardResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified AttachCardResponse message. Does not implicitly {@link PlatformCRMWeb.AttachCardResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.AttachCardResponse
         * @static
         * @param {PlatformCRMWeb.IAttachCardResponse} message AttachCardResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AttachCardResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an AttachCardResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.AttachCardResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.AttachCardResponse} AttachCardResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AttachCardResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.AttachCardResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AttachCardResponse;
    })();

    PlatformCRMWeb.GetBonusesAccountResponse = (function() {

        /**
         * Properties of a GetBonusesAccountResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetBonusesAccountResponse
         * @property {number|null} [id] GetBonusesAccountResponse id
         * @property {number|null} [total] GetBonusesAccountResponse total
         * @property {number|null} [blocked] GetBonusesAccountResponse blocked
         */

        /**
         * Constructs a new GetBonusesAccountResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetBonusesAccountResponse.
         * @implements IGetBonusesAccountResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetBonusesAccountResponse=} [properties] Properties to set
         */
        function GetBonusesAccountResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBonusesAccountResponse id.
         * @member {number} id
         * @memberof PlatformCRMWeb.GetBonusesAccountResponse
         * @instance
         */
        GetBonusesAccountResponse.prototype.id = 0;

        /**
         * GetBonusesAccountResponse total.
         * @member {number} total
         * @memberof PlatformCRMWeb.GetBonusesAccountResponse
         * @instance
         */
        GetBonusesAccountResponse.prototype.total = 0;

        /**
         * GetBonusesAccountResponse blocked.
         * @member {number} blocked
         * @memberof PlatformCRMWeb.GetBonusesAccountResponse
         * @instance
         */
        GetBonusesAccountResponse.prototype.blocked = 0;

        /**
         * Encodes the specified GetBonusesAccountResponse message. Does not implicitly {@link PlatformCRMWeb.GetBonusesAccountResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetBonusesAccountResponse
         * @static
         * @param {PlatformCRMWeb.IGetBonusesAccountResponse} message GetBonusesAccountResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBonusesAccountResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.total);
            if (message.blocked != null && Object.hasOwnProperty.call(message, "blocked"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.blocked);
            return writer;
        };

        /**
         * Decodes a GetBonusesAccountResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetBonusesAccountResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetBonusesAccountResponse} GetBonusesAccountResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBonusesAccountResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetBonusesAccountResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                case 2:
                    message.total = reader.int32();
                    break;
                case 3:
                    message.blocked = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetBonusesAccountResponse;
    })();

    PlatformCRMWeb.SearchPerson = (function() {

        /**
         * Properties of a SearchPerson.
         * @memberof PlatformCRMWeb
         * @interface ISearchPerson
         * @property {string|null} [id] SearchPerson id
         * @property {PlatformCRMWeb.IFIO|null} [fio] SearchPerson fio
         * @property {string|null} [ssoId] SearchPerson ssoId
         * @property {string|null} [phone] SearchPerson phone
         */

        /**
         * Constructs a new SearchPerson.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SearchPerson.
         * @implements ISearchPerson
         * @constructor
         * @param {PlatformCRMWeb.ISearchPerson=} [properties] Properties to set
         */
        function SearchPerson(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPerson id.
         * @member {string} id
         * @memberof PlatformCRMWeb.SearchPerson
         * @instance
         */
        SearchPerson.prototype.id = "";

        /**
         * SearchPerson fio.
         * @member {PlatformCRMWeb.IFIO|null|undefined} fio
         * @memberof PlatformCRMWeb.SearchPerson
         * @instance
         */
        SearchPerson.prototype.fio = null;

        /**
         * SearchPerson ssoId.
         * @member {string} ssoId
         * @memberof PlatformCRMWeb.SearchPerson
         * @instance
         */
        SearchPerson.prototype.ssoId = "";

        /**
         * SearchPerson phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.SearchPerson
         * @instance
         */
        SearchPerson.prototype.phone = "";

        /**
         * Encodes the specified SearchPerson message. Does not implicitly {@link PlatformCRMWeb.SearchPerson.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SearchPerson
         * @static
         * @param {PlatformCRMWeb.ISearchPerson} message SearchPerson message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPerson.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                $root.PlatformCRMWeb.FIO.encode(message.fio, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.ssoId != null && Object.hasOwnProperty.call(message, "ssoId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.ssoId);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
            return writer;
        };

        /**
         * Decodes a SearchPerson message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SearchPerson
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SearchPerson} SearchPerson
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPerson.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SearchPerson();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.fio = $root.PlatformCRMWeb.FIO.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.ssoId = reader.string();
                    break;
                case 4:
                    message.phone = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchPerson;
    })();

    PlatformCRMWeb.SearchCustomerResponse = (function() {

        /**
         * Properties of a SearchCustomerResponse.
         * @memberof PlatformCRMWeb
         * @interface ISearchCustomerResponse
         * @property {Array.<PlatformCRMWeb.ISearchPerson>|null} [data] SearchCustomerResponse data
         * @property {number|null} [page] SearchCustomerResponse page
         * @property {number|null} [pageCount] SearchCustomerResponse pageCount
         * @property {number|null} [limit] SearchCustomerResponse limit
         * @property {number|null} [count] SearchCustomerResponse count
         * @property {number|null} [total] SearchCustomerResponse total
         * @property {Array.<PlatformCRMWeb.IProspect>|null} [prospects] SearchCustomerResponse prospects
         */

        /**
         * Constructs a new SearchCustomerResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SearchCustomerResponse.
         * @implements ISearchCustomerResponse
         * @constructor
         * @param {PlatformCRMWeb.ISearchCustomerResponse=} [properties] Properties to set
         */
        function SearchCustomerResponse(properties) {
            this.data = [];
            this.prospects = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchCustomerResponse data.
         * @member {Array.<PlatformCRMWeb.ISearchPerson>} data
         * @memberof PlatformCRMWeb.SearchCustomerResponse
         * @instance
         */
        SearchCustomerResponse.prototype.data = $util.emptyArray;

        /**
         * SearchCustomerResponse page.
         * @member {number} page
         * @memberof PlatformCRMWeb.SearchCustomerResponse
         * @instance
         */
        SearchCustomerResponse.prototype.page = 0;

        /**
         * SearchCustomerResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformCRMWeb.SearchCustomerResponse
         * @instance
         */
        SearchCustomerResponse.prototype.pageCount = 0;

        /**
         * SearchCustomerResponse limit.
         * @member {number} limit
         * @memberof PlatformCRMWeb.SearchCustomerResponse
         * @instance
         */
        SearchCustomerResponse.prototype.limit = 0;

        /**
         * SearchCustomerResponse count.
         * @member {number} count
         * @memberof PlatformCRMWeb.SearchCustomerResponse
         * @instance
         */
        SearchCustomerResponse.prototype.count = 0;

        /**
         * SearchCustomerResponse total.
         * @member {number} total
         * @memberof PlatformCRMWeb.SearchCustomerResponse
         * @instance
         */
        SearchCustomerResponse.prototype.total = 0;

        /**
         * SearchCustomerResponse prospects.
         * @member {Array.<PlatformCRMWeb.IProspect>} prospects
         * @memberof PlatformCRMWeb.SearchCustomerResponse
         * @instance
         */
        SearchCustomerResponse.prototype.prospects = $util.emptyArray;

        /**
         * Encodes the specified SearchCustomerResponse message. Does not implicitly {@link PlatformCRMWeb.SearchCustomerResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SearchCustomerResponse
         * @static
         * @param {PlatformCRMWeb.ISearchCustomerResponse} message SearchCustomerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchCustomerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformCRMWeb.SearchPerson.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            if (message.prospects != null && message.prospects.length)
                for (var i = 0; i < message.prospects.length; ++i)
                    $root.PlatformCRMWeb.Prospect.encode(message.prospects[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchCustomerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SearchCustomerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SearchCustomerResponse} SearchCustomerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchCustomerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SearchCustomerResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformCRMWeb.SearchPerson.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                case 7:
                    if (!(message.prospects && message.prospects.length))
                        message.prospects = [];
                    message.prospects.push($root.PlatformCRMWeb.Prospect.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchCustomerResponse;
    })();

    PlatformCRMWeb.CustomerService = (function() {

        /**
         * Constructs a new CustomerService service.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CustomerService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function CustomerService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (CustomerService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CustomerService;

        /**
         * Callback as used by {@link PlatformCRMWeb.CustomerService#getCustomerOrders}.
         * @memberof PlatformCRMWeb.CustomerService
         * @typedef GetCustomerOrdersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetCustomerOrdersResponse} [response] GetCustomerOrdersResponse
         */

        /**
         * Calls GetCustomerOrders.
         * @function getCustomerOrders
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IGetCustomerOrdersRequest} request GetCustomerOrdersRequest message or plain object
         * @param {PlatformCRMWeb.CustomerService.GetCustomerOrdersCallback} callback Node-style callback called with the error, if any, and GetCustomerOrdersResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.getCustomerOrders = function getCustomerOrders(request, callback) {
            return this.rpcCall(getCustomerOrders, $root.PlatformCRMWeb.GetCustomerOrdersRequest, $root.PlatformCRMWeb.GetCustomerOrdersResponse, request, callback);
        }, "name", { value: "GetCustomerOrders" });

        /**
         * Calls GetCustomerOrders.
         * @function getCustomerOrders
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IGetCustomerOrdersRequest} request GetCustomerOrdersRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetCustomerOrdersResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CustomerService#getCustomerCalls}.
         * @memberof PlatformCRMWeb.CustomerService
         * @typedef GetCustomerCallsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetCustomerCallsResponse} [response] GetCustomerCallsResponse
         */

        /**
         * Calls GetCustomerCalls.
         * @function getCustomerCalls
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IGetCustomerCallsRequest} request GetCustomerCallsRequest message or plain object
         * @param {PlatformCRMWeb.CustomerService.GetCustomerCallsCallback} callback Node-style callback called with the error, if any, and GetCustomerCallsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.getCustomerCalls = function getCustomerCalls(request, callback) {
            return this.rpcCall(getCustomerCalls, $root.PlatformCRMWeb.GetCustomerCallsRequest, $root.PlatformCRMWeb.GetCustomerCallsResponse, request, callback);
        }, "name", { value: "GetCustomerCalls" });

        /**
         * Calls GetCustomerCalls.
         * @function getCustomerCalls
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IGetCustomerCallsRequest} request GetCustomerCallsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetCustomerCallsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CustomerService#customerInfo}.
         * @memberof PlatformCRMWeb.CustomerService
         * @typedef CustomerInfoCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetCustomerInfoResponse} [response] GetCustomerInfoResponse
         */

        /**
         * Calls CustomerInfo.
         * @function customerInfo
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IGetCustomerInfoRequest} request GetCustomerInfoRequest message or plain object
         * @param {PlatformCRMWeb.CustomerService.CustomerInfoCallback} callback Node-style callback called with the error, if any, and GetCustomerInfoResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.customerInfo = function customerInfo(request, callback) {
            return this.rpcCall(customerInfo, $root.PlatformCRMWeb.GetCustomerInfoRequest, $root.PlatformCRMWeb.GetCustomerInfoResponse, request, callback);
        }, "name", { value: "CustomerInfo" });

        /**
         * Calls CustomerInfo.
         * @function customerInfo
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IGetCustomerInfoRequest} request GetCustomerInfoRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetCustomerInfoResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CustomerService#getClientAddresses}.
         * @memberof PlatformCRMWeb.CustomerService
         * @typedef GetClientAddressesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetCustomerAddressesResponse} [response] GetCustomerAddressesResponse
         */

        /**
         * Calls GetClientAddresses.
         * @function getClientAddresses
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IGetCustomerAddressesRequest} request GetCustomerAddressesRequest message or plain object
         * @param {PlatformCRMWeb.CustomerService.GetClientAddressesCallback} callback Node-style callback called with the error, if any, and GetCustomerAddressesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.getClientAddresses = function getClientAddresses(request, callback) {
            return this.rpcCall(getClientAddresses, $root.PlatformCRMWeb.GetCustomerAddressesRequest, $root.PlatformCRMWeb.GetCustomerAddressesResponse, request, callback);
        }, "name", { value: "GetClientAddresses" });

        /**
         * Calls GetClientAddresses.
         * @function getClientAddresses
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IGetCustomerAddressesRequest} request GetCustomerAddressesRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetCustomerAddressesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CustomerService#update}.
         * @memberof PlatformCRMWeb.CustomerService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.UpdateResponse} [response] UpdateResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IUpdateRequest} request UpdateRequest message or plain object
         * @param {PlatformCRMWeb.CustomerService.UpdateCallback} callback Node-style callback called with the error, if any, and UpdateResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformCRMWeb.UpdateRequest, $root.PlatformCRMWeb.UpdateResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IUpdateRequest} request UpdateRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.UpdateResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CustomerService#attachCard}.
         * @memberof PlatformCRMWeb.CustomerService
         * @typedef AttachCardCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.AttachCardResponse} [response] AttachCardResponse
         */

        /**
         * Calls AttachCard.
         * @function attachCard
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IAttachCardRequest} request AttachCardRequest message or plain object
         * @param {PlatformCRMWeb.CustomerService.AttachCardCallback} callback Node-style callback called with the error, if any, and AttachCardResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.attachCard = function attachCard(request, callback) {
            return this.rpcCall(attachCard, $root.PlatformCRMWeb.AttachCardRequest, $root.PlatformCRMWeb.AttachCardResponse, request, callback);
        }, "name", { value: "AttachCard" });

        /**
         * Calls AttachCard.
         * @function attachCard
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IAttachCardRequest} request AttachCardRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.AttachCardResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CustomerService#getBonusesAccount}.
         * @memberof PlatformCRMWeb.CustomerService
         * @typedef GetBonusesAccountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetBonusesAccountResponse} [response] GetBonusesAccountResponse
         */

        /**
         * Calls GetBonusesAccount.
         * @function getBonusesAccount
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IGetBonusesAccountRequest} request GetBonusesAccountRequest message or plain object
         * @param {PlatformCRMWeb.CustomerService.GetBonusesAccountCallback} callback Node-style callback called with the error, if any, and GetBonusesAccountResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.getBonusesAccount = function getBonusesAccount(request, callback) {
            return this.rpcCall(getBonusesAccount, $root.PlatformCRMWeb.GetBonusesAccountRequest, $root.PlatformCRMWeb.GetBonusesAccountResponse, request, callback);
        }, "name", { value: "GetBonusesAccount" });

        /**
         * Calls GetBonusesAccount.
         * @function getBonusesAccount
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.IGetBonusesAccountRequest} request GetBonusesAccountRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetBonusesAccountResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CustomerService#searchCustomer}.
         * @memberof PlatformCRMWeb.CustomerService
         * @typedef SearchCustomerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.SearchCustomerResponse} [response] SearchCustomerResponse
         */

        /**
         * Calls SearchCustomer.
         * @function searchCustomer
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.ISearchCustomerRequest} request SearchCustomerRequest message or plain object
         * @param {PlatformCRMWeb.CustomerService.SearchCustomerCallback} callback Node-style callback called with the error, if any, and SearchCustomerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CustomerService.prototype.searchCustomer = function searchCustomer(request, callback) {
            return this.rpcCall(searchCustomer, $root.PlatformCRMWeb.SearchCustomerRequest, $root.PlatformCRMWeb.SearchCustomerResponse, request, callback);
        }, "name", { value: "SearchCustomer" });

        /**
         * Calls SearchCustomer.
         * @function searchCustomer
         * @memberof PlatformCRMWeb.CustomerService
         * @instance
         * @param {PlatformCRMWeb.ISearchCustomerRequest} request SearchCustomerRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.SearchCustomerResponse>} Promise
         * @variation 2
         */

        return CustomerService;
    })();

    PlatformCRMWeb.Order = (function() {

        /**
         * Properties of an Order.
         * @memberof PlatformCRMWeb
         * @interface IOrder
         * @property {string|null} [id] Order id
         * @property {number|null} [orderNumber] Order orderNumber
         * @property {string|null} [status] Order status
         * @property {PlatformCRMWeb.IPerson|null} [person] Order person
         * @property {string|null} [prospectUID] Order prospectUID
         * @property {number|null} [executorId] Order executorId
         * @property {string|null} [startDate] Order startDate
         * @property {sso.IAddress|null} [address] Order address
         * @property {number|null} [regionId] Order regionId
         * @property {PlatformCRMWeb.ICalculatePrice|null} [price] Order price
         * @property {string|null} [promocode] Order promocode
         * @property {string|null} [serviceType] Order serviceType
         * @property {string|null} [paymentType] Order paymentType
         * @property {string|null} [cardNumber] Order cardNumber
         * @property {string|null} [qleanOrderId] Order qleanOrderId
         * @property {string|null} [commentForExecutor] Order commentForExecutor
         * @property {string|null} [commentForSupport] Order commentForSupport
         * @property {number|null} [score] Order score
         * @property {string|null} [cleanerId] Order cleanerId
         * @property {string|null} [cancelReason] Order cancelReason
         * @property {number|null} [totalPrice] Order totalPrice
         * @property {number|null} [totalDuration] Order totalDuration
         * @property {boolean|null} [isOvertime] Order isOvertime
         * @property {string|null} [commentFromCustomer] Order commentFromCustomer
         * @property {string|null} [updatedAt] Order updatedAt
         * @property {number|null} [discount] Order discount
         * @property {sso.IAddress|null} [keysDelivery] Order keysDelivery
         * @property {sso.IAddress|null} [keysPickup] Order keysPickup
         * @property {string|null} [prospectId] Order prospectId
         * @property {string|null} [paymentTypeMeta] Order paymentTypeMeta
         * @property {string|null} [paymentStatus] Order paymentStatus
         * @property {number|null} [numberOfPayments] Order numberOfPayments
         */

        /**
         * Constructs a new Order.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an Order.
         * @implements IOrder
         * @constructor
         * @param {PlatformCRMWeb.IOrder=} [properties] Properties to set
         */
        function Order(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Order id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.id = "";

        /**
         * Order orderNumber.
         * @member {number} orderNumber
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.orderNumber = 0;

        /**
         * Order status.
         * @member {string} status
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.status = "";

        /**
         * Order person.
         * @member {PlatformCRMWeb.IPerson|null|undefined} person
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.person = null;

        /**
         * Order prospectUID.
         * @member {string} prospectUID
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.prospectUID = "";

        /**
         * Order executorId.
         * @member {number} executorId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.executorId = 0;

        /**
         * Order startDate.
         * @member {string} startDate
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.startDate = "";

        /**
         * Order address.
         * @member {sso.IAddress|null|undefined} address
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.address = null;

        /**
         * Order regionId.
         * @member {number} regionId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.regionId = 0;

        /**
         * Order price.
         * @member {PlatformCRMWeb.ICalculatePrice|null|undefined} price
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.price = null;

        /**
         * Order promocode.
         * @member {string} promocode
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.promocode = "";

        /**
         * Order serviceType.
         * @member {string} serviceType
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.serviceType = "";

        /**
         * Order paymentType.
         * @member {string} paymentType
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.paymentType = "";

        /**
         * Order cardNumber.
         * @member {string} cardNumber
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.cardNumber = "";

        /**
         * Order qleanOrderId.
         * @member {string} qleanOrderId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.qleanOrderId = "";

        /**
         * Order commentForExecutor.
         * @member {string} commentForExecutor
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.commentForExecutor = "";

        /**
         * Order commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.commentForSupport = "";

        /**
         * Order score.
         * @member {number} score
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.score = 0;

        /**
         * Order cleanerId.
         * @member {string} cleanerId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.cleanerId = "";

        /**
         * Order cancelReason.
         * @member {string} cancelReason
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.cancelReason = "";

        /**
         * Order totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.totalPrice = 0;

        /**
         * Order totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.totalDuration = 0;

        /**
         * Order isOvertime.
         * @member {boolean} isOvertime
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.isOvertime = false;

        /**
         * Order commentFromCustomer.
         * @member {string} commentFromCustomer
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.commentFromCustomer = "";

        /**
         * Order updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.updatedAt = "";

        /**
         * Order discount.
         * @member {number} discount
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.discount = 0;

        /**
         * Order keysDelivery.
         * @member {sso.IAddress|null|undefined} keysDelivery
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.keysDelivery = null;

        /**
         * Order keysPickup.
         * @member {sso.IAddress|null|undefined} keysPickup
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.keysPickup = null;

        /**
         * Order prospectId.
         * @member {string} prospectId
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.prospectId = "";

        /**
         * Order paymentTypeMeta.
         * @member {string} paymentTypeMeta
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.paymentTypeMeta = "";

        /**
         * Order paymentStatus.
         * @member {string|null|undefined} paymentStatus
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.paymentStatus = null;

        /**
         * Order numberOfPayments.
         * @member {number} numberOfPayments
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Order.prototype.numberOfPayments = 0;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Order _paymentStatus.
         * @member {"paymentStatus"|undefined} _paymentStatus
         * @memberof PlatformCRMWeb.Order
         * @instance
         */
        Object.defineProperty(Order.prototype, "_paymentStatus", {
            get: $util.oneOfGetter($oneOfFields = ["paymentStatus"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified Order message. Does not implicitly {@link PlatformCRMWeb.Order.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Order
         * @static
         * @param {PlatformCRMWeb.IOrder} message Order message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Order.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.orderNumber != null && Object.hasOwnProperty.call(message, "orderNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orderNumber);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.status);
            if (message.person != null && Object.hasOwnProperty.call(message, "person"))
                $root.PlatformCRMWeb.Person.encode(message.person, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.prospectUID != null && Object.hasOwnProperty.call(message, "prospectUID"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.prospectUID);
            if (message.executorId != null && Object.hasOwnProperty.call(message, "executorId"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.executorId);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.startDate);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.sso.Address.encode(message.address, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.regionId);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                $root.PlatformCRMWeb.CalculatePrice.encode(message.price, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.promocode);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.serviceType);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.paymentType);
            if (message.cardNumber != null && Object.hasOwnProperty.call(message, "cardNumber"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.cardNumber);
            if (message.qleanOrderId != null && Object.hasOwnProperty.call(message, "qleanOrderId"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.qleanOrderId);
            if (message.commentForExecutor != null && Object.hasOwnProperty.call(message, "commentForExecutor"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.commentForExecutor);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.commentForSupport);
            if (message.score != null && Object.hasOwnProperty.call(message, "score"))
                writer.uint32(/* id 18, wireType 0 =*/144).int32(message.score);
            if (message.cleanerId != null && Object.hasOwnProperty.call(message, "cleanerId"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.cleanerId);
            if (message.cancelReason != null && Object.hasOwnProperty.call(message, "cancelReason"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.cancelReason);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 21, wireType 0 =*/168).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 22, wireType 0 =*/176).int32(message.totalDuration);
            if (message.isOvertime != null && Object.hasOwnProperty.call(message, "isOvertime"))
                writer.uint32(/* id 23, wireType 0 =*/184).bool(message.isOvertime);
            if (message.commentFromCustomer != null && Object.hasOwnProperty.call(message, "commentFromCustomer"))
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.commentFromCustomer);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 25, wireType 2 =*/202).string(message.updatedAt);
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 26, wireType 0 =*/208).int32(message.discount);
            if (message.keysDelivery != null && Object.hasOwnProperty.call(message, "keysDelivery"))
                $root.sso.Address.encode(message.keysDelivery, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.keysPickup != null && Object.hasOwnProperty.call(message, "keysPickup"))
                $root.sso.Address.encode(message.keysPickup, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.prospectId != null && Object.hasOwnProperty.call(message, "prospectId"))
                writer.uint32(/* id 29, wireType 2 =*/234).string(message.prospectId);
            if (message.paymentTypeMeta != null && Object.hasOwnProperty.call(message, "paymentTypeMeta"))
                writer.uint32(/* id 30, wireType 2 =*/242).string(message.paymentTypeMeta);
            if (message.paymentStatus != null && Object.hasOwnProperty.call(message, "paymentStatus"))
                writer.uint32(/* id 31, wireType 2 =*/250).string(message.paymentStatus);
            if (message.numberOfPayments != null && Object.hasOwnProperty.call(message, "numberOfPayments"))
                writer.uint32(/* id 32, wireType 0 =*/256).int32(message.numberOfPayments);
            return writer;
        };

        /**
         * Decodes an Order message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Order
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Order} Order
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Order.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Order();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.orderNumber = reader.int32();
                    break;
                case 3:
                    message.status = reader.string();
                    break;
                case 4:
                    message.person = $root.PlatformCRMWeb.Person.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.prospectUID = reader.string();
                    break;
                case 6:
                    message.executorId = reader.int32();
                    break;
                case 7:
                    message.startDate = reader.string();
                    break;
                case 8:
                    message.address = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.regionId = reader.int32();
                    break;
                case 10:
                    message.price = $root.PlatformCRMWeb.CalculatePrice.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.promocode = reader.string();
                    break;
                case 12:
                    message.serviceType = reader.string();
                    break;
                case 13:
                    message.paymentType = reader.string();
                    break;
                case 14:
                    message.cardNumber = reader.string();
                    break;
                case 15:
                    message.qleanOrderId = reader.string();
                    break;
                case 16:
                    message.commentForExecutor = reader.string();
                    break;
                case 17:
                    message.commentForSupport = reader.string();
                    break;
                case 18:
                    message.score = reader.int32();
                    break;
                case 19:
                    message.cleanerId = reader.string();
                    break;
                case 20:
                    message.cancelReason = reader.string();
                    break;
                case 21:
                    message.totalPrice = reader.int32();
                    break;
                case 22:
                    message.totalDuration = reader.int32();
                    break;
                case 23:
                    message.isOvertime = reader.bool();
                    break;
                case 24:
                    message.commentFromCustomer = reader.string();
                    break;
                case 25:
                    message.updatedAt = reader.string();
                    break;
                case 26:
                    message.discount = reader.int32();
                    break;
                case 27:
                    message.keysDelivery = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.keysPickup = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.prospectId = reader.string();
                    break;
                case 30:
                    message.paymentTypeMeta = reader.string();
                    break;
                case 31:
                    message.paymentStatus = reader.string();
                    break;
                case 32:
                    message.numberOfPayments = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Order;
    })();

    PlatformCRMWeb.ShortOrder = (function() {

        /**
         * Properties of a ShortOrder.
         * @memberof PlatformCRMWeb
         * @interface IShortOrder
         * @property {string|null} [id] ShortOrder id
         * @property {number|null} [orderNumber] ShortOrder orderNumber
         * @property {string|null} [status] ShortOrder status
         * @property {sso.IAddress|null} [address] ShortOrder address
         * @property {number|null} [regionId] ShortOrder regionId
         * @property {string|null} [startDate] ShortOrder startDate
         * @property {string|null} [serviceType] ShortOrder serviceType
         * @property {string|null} [qleanOrderId] ShortOrder qleanOrderId
         * @property {string|null} [promocode] ShortOrder promocode
         * @property {number|null} [totalPrice] ShortOrder totalPrice
         * @property {PlatformCRMWeb.ICalculatePrice|null} [price] ShortOrder price
         */

        /**
         * Constructs a new ShortOrder.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ShortOrder.
         * @implements IShortOrder
         * @constructor
         * @param {PlatformCRMWeb.IShortOrder=} [properties] Properties to set
         */
        function ShortOrder(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ShortOrder id.
         * @member {string} id
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.id = "";

        /**
         * ShortOrder orderNumber.
         * @member {number} orderNumber
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.orderNumber = 0;

        /**
         * ShortOrder status.
         * @member {string} status
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.status = "";

        /**
         * ShortOrder address.
         * @member {sso.IAddress|null|undefined} address
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.address = null;

        /**
         * ShortOrder regionId.
         * @member {number} regionId
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.regionId = 0;

        /**
         * ShortOrder startDate.
         * @member {string} startDate
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.startDate = "";

        /**
         * ShortOrder serviceType.
         * @member {string} serviceType
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.serviceType = "";

        /**
         * ShortOrder qleanOrderId.
         * @member {string} qleanOrderId
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.qleanOrderId = "";

        /**
         * ShortOrder promocode.
         * @member {string} promocode
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.promocode = "";

        /**
         * ShortOrder totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.totalPrice = 0;

        /**
         * ShortOrder price.
         * @member {PlatformCRMWeb.ICalculatePrice|null|undefined} price
         * @memberof PlatformCRMWeb.ShortOrder
         * @instance
         */
        ShortOrder.prototype.price = null;

        /**
         * Encodes the specified ShortOrder message. Does not implicitly {@link PlatformCRMWeb.ShortOrder.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ShortOrder
         * @static
         * @param {PlatformCRMWeb.IShortOrder} message ShortOrder message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ShortOrder.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.orderNumber != null && Object.hasOwnProperty.call(message, "orderNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orderNumber);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.status);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.sso.Address.encode(message.address, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.regionId);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.startDate);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.serviceType);
            if (message.qleanOrderId != null && Object.hasOwnProperty.call(message, "qleanOrderId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.qleanOrderId);
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.promocode);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.totalPrice);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                $root.PlatformCRMWeb.CalculatePrice.encode(message.price, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ShortOrder message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ShortOrder
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ShortOrder} ShortOrder
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ShortOrder.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ShortOrder();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.orderNumber = reader.int32();
                    break;
                case 3:
                    message.status = reader.string();
                    break;
                case 4:
                    message.address = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.regionId = reader.int32();
                    break;
                case 6:
                    message.startDate = reader.string();
                    break;
                case 7:
                    message.serviceType = reader.string();
                    break;
                case 8:
                    message.qleanOrderId = reader.string();
                    break;
                case 9:
                    message.promocode = reader.string();
                    break;
                case 10:
                    message.totalPrice = reader.int32();
                    break;
                case 11:
                    message.price = $root.PlatformCRMWeb.CalculatePrice.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ShortOrder;
    })();

    PlatformCRMWeb.RejectionReason = (function() {

        /**
         * Properties of a RejectionReason.
         * @memberof PlatformCRMWeb
         * @interface IRejectionReason
         * @property {string|null} [id] RejectionReason id
         * @property {string|null} [title] RejectionReason title
         * @property {string|null} [slug] RejectionReason slug
         */

        /**
         * Constructs a new RejectionReason.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a RejectionReason.
         * @implements IRejectionReason
         * @constructor
         * @param {PlatformCRMWeb.IRejectionReason=} [properties] Properties to set
         */
        function RejectionReason(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RejectionReason id.
         * @member {string} id
         * @memberof PlatformCRMWeb.RejectionReason
         * @instance
         */
        RejectionReason.prototype.id = "";

        /**
         * RejectionReason title.
         * @member {string} title
         * @memberof PlatformCRMWeb.RejectionReason
         * @instance
         */
        RejectionReason.prototype.title = "";

        /**
         * RejectionReason slug.
         * @member {string} slug
         * @memberof PlatformCRMWeb.RejectionReason
         * @instance
         */
        RejectionReason.prototype.slug = "";

        /**
         * Encodes the specified RejectionReason message. Does not implicitly {@link PlatformCRMWeb.RejectionReason.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.RejectionReason
         * @static
         * @param {PlatformCRMWeb.IRejectionReason} message RejectionReason message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RejectionReason.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.slug);
            return writer;
        };

        /**
         * Decodes a RejectionReason message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.RejectionReason
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.RejectionReason} RejectionReason
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RejectionReason.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.RejectionReason();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.slug = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RejectionReason;
    })();

    /**
     * REJECTION_GROUP_TYPE enum.
     * @name PlatformCRMWeb.REJECTION_GROUP_TYPE
     * @enum {number}
     * @property {number} DEFAULT=1 DEFAULT value
     * @property {number} BEFORE_EXECUTE=2 BEFORE_EXECUTE value
     * @property {number} ON_EXECUTING=3 ON_EXECUTING value
     * @property {number} BY_SERVICE=4 BY_SERVICE value
     * @property {number} ON_CONFIRM=5 ON_CONFIRM value
     * @property {number} BY_CLIENT=6 BY_CLIENT value
     * @property {number} NOT_SATISFIED_WITH_CONDITIONS=7 NOT_SATISFIED_WITH_CONDITIONS value
     * @property {number} BY_TECHNOLOGIST=8 BY_TECHNOLOGIST value
     */
    PlatformCRMWeb.REJECTION_GROUP_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "DEFAULT"] = 1;
        values[valuesById[2] = "BEFORE_EXECUTE"] = 2;
        values[valuesById[3] = "ON_EXECUTING"] = 3;
        values[valuesById[4] = "BY_SERVICE"] = 4;
        values[valuesById[5] = "ON_CONFIRM"] = 5;
        values[valuesById[6] = "BY_CLIENT"] = 6;
        values[valuesById[7] = "NOT_SATISFIED_WITH_CONDITIONS"] = 7;
        values[valuesById[8] = "BY_TECHNOLOGIST"] = 8;
        return values;
    })();

    PlatformCRMWeb.RejectionGroup = (function() {

        /**
         * Properties of a RejectionGroup.
         * @memberof PlatformCRMWeb
         * @interface IRejectionGroup
         * @property {string|null} [id] RejectionGroup id
         * @property {string|null} [title] RejectionGroup title
         * @property {PlatformCRMWeb.REJECTION_GROUP_TYPE|null} [type] RejectionGroup type
         * @property {Array.<PlatformCRMWeb.IRejectionReason>|null} [reasons] RejectionGroup reasons
         * @property {string|null} [parentGroupId] RejectionGroup parentGroupId
         */

        /**
         * Constructs a new RejectionGroup.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a RejectionGroup.
         * @implements IRejectionGroup
         * @constructor
         * @param {PlatformCRMWeb.IRejectionGroup=} [properties] Properties to set
         */
        function RejectionGroup(properties) {
            this.reasons = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RejectionGroup id.
         * @member {string} id
         * @memberof PlatformCRMWeb.RejectionGroup
         * @instance
         */
        RejectionGroup.prototype.id = "";

        /**
         * RejectionGroup title.
         * @member {string} title
         * @memberof PlatformCRMWeb.RejectionGroup
         * @instance
         */
        RejectionGroup.prototype.title = "";

        /**
         * RejectionGroup type.
         * @member {PlatformCRMWeb.REJECTION_GROUP_TYPE} type
         * @memberof PlatformCRMWeb.RejectionGroup
         * @instance
         */
        RejectionGroup.prototype.type = 1;

        /**
         * RejectionGroup reasons.
         * @member {Array.<PlatformCRMWeb.IRejectionReason>} reasons
         * @memberof PlatformCRMWeb.RejectionGroup
         * @instance
         */
        RejectionGroup.prototype.reasons = $util.emptyArray;

        /**
         * RejectionGroup parentGroupId.
         * @member {string} parentGroupId
         * @memberof PlatformCRMWeb.RejectionGroup
         * @instance
         */
        RejectionGroup.prototype.parentGroupId = "";

        /**
         * Encodes the specified RejectionGroup message. Does not implicitly {@link PlatformCRMWeb.RejectionGroup.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.RejectionGroup
         * @static
         * @param {PlatformCRMWeb.IRejectionGroup} message RejectionGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RejectionGroup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.reasons != null && message.reasons.length)
                for (var i = 0; i < message.reasons.length; ++i)
                    $root.PlatformCRMWeb.RejectionReason.encode(message.reasons[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.parentGroupId != null && Object.hasOwnProperty.call(message, "parentGroupId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.parentGroupId);
            return writer;
        };

        /**
         * Decodes a RejectionGroup message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.RejectionGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.RejectionGroup} RejectionGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RejectionGroup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.RejectionGroup();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    if (!(message.reasons && message.reasons.length))
                        message.reasons = [];
                    message.reasons.push($root.PlatformCRMWeb.RejectionReason.decode(reader, reader.uint32()));
                    break;
                case 5:
                    message.parentGroupId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RejectionGroup;
    })();

    PlatformCRMWeb.GetOrderByIdRequest = (function() {

        /**
         * Properties of a GetOrderByIdRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetOrderByIdRequest
         * @property {string|null} [id] GetOrderByIdRequest id
         */

        /**
         * Constructs a new GetOrderByIdRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetOrderByIdRequest.
         * @implements IGetOrderByIdRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetOrderByIdRequest=} [properties] Properties to set
         */
        function GetOrderByIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOrderByIdRequest id.
         * @member {string} id
         * @memberof PlatformCRMWeb.GetOrderByIdRequest
         * @instance
         */
        GetOrderByIdRequest.prototype.id = "";

        /**
         * Encodes the specified GetOrderByIdRequest message. Does not implicitly {@link PlatformCRMWeb.GetOrderByIdRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetOrderByIdRequest
         * @static
         * @param {PlatformCRMWeb.IGetOrderByIdRequest} message GetOrderByIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOrderByIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a GetOrderByIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetOrderByIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetOrderByIdRequest} GetOrderByIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOrderByIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetOrderByIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetOrderByIdRequest;
    })();

    PlatformCRMWeb.UpdateOrderRequest = (function() {

        /**
         * Properties of an UpdateOrderRequest.
         * @memberof PlatformCRMWeb
         * @interface IUpdateOrderRequest
         * @property {string|null} [id] UpdateOrderRequest id
         * @property {Array.<PlatformCRMWeb.IProduct>|null} [products] UpdateOrderRequest products
         * @property {string|null} [startDate] UpdateOrderRequest startDate
         * @property {number|null} [regionId] UpdateOrderRequest regionId
         * @property {string|null} [qleanAddress] UpdateOrderRequest qleanAddress
         * @property {sso.IAddress|null} [address] UpdateOrderRequest address
         * @property {string|null} [paymentType] UpdateOrderRequest paymentType
         * @property {string|null} [promocode] UpdateOrderRequest promocode
         * @property {string|null} [commentForExecutor] UpdateOrderRequest commentForExecutor
         * @property {string|null} [commentForSupport] UpdateOrderRequest commentForSupport
         * @property {string|null} [qleanPromocodeId] UpdateOrderRequest qleanPromocodeId
         * @property {number|null} [discount] UpdateOrderRequest discount
         * @property {sso.IAddress|null} [keysPickup] UpdateOrderRequest keysPickup
         * @property {sso.IAddress|null} [keysDelivery] UpdateOrderRequest keysDelivery
         * @property {string|null} [paymentTypeMeta] UpdateOrderRequest paymentTypeMeta
         */

        /**
         * Constructs a new UpdateOrderRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an UpdateOrderRequest.
         * @implements IUpdateOrderRequest
         * @constructor
         * @param {PlatformCRMWeb.IUpdateOrderRequest=} [properties] Properties to set
         */
        function UpdateOrderRequest(properties) {
            this.products = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateOrderRequest id.
         * @member {string} id
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.id = "";

        /**
         * UpdateOrderRequest products.
         * @member {Array.<PlatformCRMWeb.IProduct>} products
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.products = $util.emptyArray;

        /**
         * UpdateOrderRequest startDate.
         * @member {string} startDate
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.startDate = "";

        /**
         * UpdateOrderRequest regionId.
         * @member {number} regionId
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.regionId = 0;

        /**
         * UpdateOrderRequest qleanAddress.
         * @member {string} qleanAddress
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.qleanAddress = "";

        /**
         * UpdateOrderRequest address.
         * @member {sso.IAddress|null|undefined} address
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.address = null;

        /**
         * UpdateOrderRequest paymentType.
         * @member {string} paymentType
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.paymentType = "";

        /**
         * UpdateOrderRequest promocode.
         * @member {string} promocode
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.promocode = "";

        /**
         * UpdateOrderRequest commentForExecutor.
         * @member {string} commentForExecutor
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.commentForExecutor = "";

        /**
         * UpdateOrderRequest commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.commentForSupport = "";

        /**
         * UpdateOrderRequest qleanPromocodeId.
         * @member {string} qleanPromocodeId
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.qleanPromocodeId = "";

        /**
         * UpdateOrderRequest discount.
         * @member {number} discount
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.discount = 0;

        /**
         * UpdateOrderRequest keysPickup.
         * @member {sso.IAddress|null|undefined} keysPickup
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.keysPickup = null;

        /**
         * UpdateOrderRequest keysDelivery.
         * @member {sso.IAddress|null|undefined} keysDelivery
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.keysDelivery = null;

        /**
         * UpdateOrderRequest paymentTypeMeta.
         * @member {string} paymentTypeMeta
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @instance
         */
        UpdateOrderRequest.prototype.paymentTypeMeta = "";

        /**
         * Encodes the specified UpdateOrderRequest message. Does not implicitly {@link PlatformCRMWeb.UpdateOrderRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @static
         * @param {PlatformCRMWeb.IUpdateOrderRequest} message UpdateOrderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateOrderRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.products != null && message.products.length)
                for (var i = 0; i < message.products.length; ++i)
                    $root.PlatformCRMWeb.Product.encode(message.products[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.startDate);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.regionId);
            if (message.qleanAddress != null && Object.hasOwnProperty.call(message, "qleanAddress"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.qleanAddress);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.sso.Address.encode(message.address, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.paymentType);
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.promocode);
            if (message.commentForExecutor != null && Object.hasOwnProperty.call(message, "commentForExecutor"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.commentForExecutor);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.commentForSupport);
            if (message.qleanPromocodeId != null && Object.hasOwnProperty.call(message, "qleanPromocodeId"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.qleanPromocodeId);
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.discount);
            if (message.keysPickup != null && Object.hasOwnProperty.call(message, "keysPickup"))
                $root.sso.Address.encode(message.keysPickup, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.keysDelivery != null && Object.hasOwnProperty.call(message, "keysDelivery"))
                $root.sso.Address.encode(message.keysDelivery, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.paymentTypeMeta != null && Object.hasOwnProperty.call(message, "paymentTypeMeta"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.paymentTypeMeta);
            return writer;
        };

        /**
         * Decodes an UpdateOrderRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UpdateOrderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UpdateOrderRequest} UpdateOrderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateOrderRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UpdateOrderRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    if (!(message.products && message.products.length))
                        message.products = [];
                    message.products.push($root.PlatformCRMWeb.Product.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.startDate = reader.string();
                    break;
                case 4:
                    message.regionId = reader.int32();
                    break;
                case 5:
                    message.qleanAddress = reader.string();
                    break;
                case 6:
                    message.address = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.paymentType = reader.string();
                    break;
                case 8:
                    message.promocode = reader.string();
                    break;
                case 9:
                    message.commentForExecutor = reader.string();
                    break;
                case 10:
                    message.commentForSupport = reader.string();
                    break;
                case 11:
                    message.qleanPromocodeId = reader.string();
                    break;
                case 12:
                    message.discount = reader.int32();
                    break;
                case 13:
                    message.keysPickup = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.keysDelivery = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.paymentTypeMeta = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateOrderRequest;
    })();

    PlatformCRMWeb.AssignExecutorRequest = (function() {

        /**
         * Properties of an AssignExecutorRequest.
         * @memberof PlatformCRMWeb
         * @interface IAssignExecutorRequest
         * @property {string|null} [orderId] AssignExecutorRequest orderId
         * @property {string|null} [executorId] AssignExecutorRequest executorId
         * @property {number|null} [type] AssignExecutorRequest type
         * @property {string|null} [startDate] AssignExecutorRequest startDate
         * @property {number|null} [role] AssignExecutorRequest role
         */

        /**
         * Constructs a new AssignExecutorRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an AssignExecutorRequest.
         * @implements IAssignExecutorRequest
         * @constructor
         * @param {PlatformCRMWeb.IAssignExecutorRequest=} [properties] Properties to set
         */
        function AssignExecutorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AssignExecutorRequest orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.AssignExecutorRequest
         * @instance
         */
        AssignExecutorRequest.prototype.orderId = "";

        /**
         * AssignExecutorRequest executorId.
         * @member {string} executorId
         * @memberof PlatformCRMWeb.AssignExecutorRequest
         * @instance
         */
        AssignExecutorRequest.prototype.executorId = "";

        /**
         * AssignExecutorRequest type.
         * @member {number} type
         * @memberof PlatformCRMWeb.AssignExecutorRequest
         * @instance
         */
        AssignExecutorRequest.prototype.type = 0;

        /**
         * AssignExecutorRequest startDate.
         * @member {string} startDate
         * @memberof PlatformCRMWeb.AssignExecutorRequest
         * @instance
         */
        AssignExecutorRequest.prototype.startDate = "";

        /**
         * AssignExecutorRequest role.
         * @member {number} role
         * @memberof PlatformCRMWeb.AssignExecutorRequest
         * @instance
         */
        AssignExecutorRequest.prototype.role = 0;

        /**
         * Encodes the specified AssignExecutorRequest message. Does not implicitly {@link PlatformCRMWeb.AssignExecutorRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.AssignExecutorRequest
         * @static
         * @param {PlatformCRMWeb.IAssignExecutorRequest} message AssignExecutorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AssignExecutorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            if (message.executorId != null && Object.hasOwnProperty.call(message, "executorId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.executorId);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.startDate);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.role);
            return writer;
        };

        /**
         * Decodes an AssignExecutorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.AssignExecutorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.AssignExecutorRequest} AssignExecutorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AssignExecutorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.AssignExecutorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                case 2:
                    message.executorId = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.startDate = reader.string();
                    break;
                case 5:
                    message.role = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AssignExecutorRequest;
    })();

    PlatformCRMWeb.OrderChangeStatusRequest = (function() {

        /**
         * Properties of an OrderChangeStatusRequest.
         * @memberof PlatformCRMWeb
         * @interface IOrderChangeStatusRequest
         * @property {string|null} [id] OrderChangeStatusRequest id
         * @property {string|null} [status] OrderChangeStatusRequest status
         * @property {string|null} [cancelReason] OrderChangeStatusRequest cancelReason
         */

        /**
         * Constructs a new OrderChangeStatusRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an OrderChangeStatusRequest.
         * @implements IOrderChangeStatusRequest
         * @constructor
         * @param {PlatformCRMWeb.IOrderChangeStatusRequest=} [properties] Properties to set
         */
        function OrderChangeStatusRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderChangeStatusRequest id.
         * @member {string} id
         * @memberof PlatformCRMWeb.OrderChangeStatusRequest
         * @instance
         */
        OrderChangeStatusRequest.prototype.id = "";

        /**
         * OrderChangeStatusRequest status.
         * @member {string} status
         * @memberof PlatformCRMWeb.OrderChangeStatusRequest
         * @instance
         */
        OrderChangeStatusRequest.prototype.status = "";

        /**
         * OrderChangeStatusRequest cancelReason.
         * @member {string} cancelReason
         * @memberof PlatformCRMWeb.OrderChangeStatusRequest
         * @instance
         */
        OrderChangeStatusRequest.prototype.cancelReason = "";

        /**
         * Encodes the specified OrderChangeStatusRequest message. Does not implicitly {@link PlatformCRMWeb.OrderChangeStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.OrderChangeStatusRequest
         * @static
         * @param {PlatformCRMWeb.IOrderChangeStatusRequest} message OrderChangeStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderChangeStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.status);
            if (message.cancelReason != null && Object.hasOwnProperty.call(message, "cancelReason"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.cancelReason);
            return writer;
        };

        /**
         * Decodes an OrderChangeStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.OrderChangeStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.OrderChangeStatusRequest} OrderChangeStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderChangeStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.OrderChangeStatusRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.status = reader.string();
                    break;
                case 3:
                    message.cancelReason = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderChangeStatusRequest;
    })();

    PlatformCRMWeb.GetExecutorsListRequest = (function() {

        /**
         * Properties of a GetExecutorsListRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetExecutorsListRequest
         * @property {string|null} [role] GetExecutorsListRequest role
         * @property {string|null} [product] GetExecutorsListRequest product
         */

        /**
         * Constructs a new GetExecutorsListRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetExecutorsListRequest.
         * @implements IGetExecutorsListRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetExecutorsListRequest=} [properties] Properties to set
         */
        function GetExecutorsListRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetExecutorsListRequest role.
         * @member {string} role
         * @memberof PlatformCRMWeb.GetExecutorsListRequest
         * @instance
         */
        GetExecutorsListRequest.prototype.role = "";

        /**
         * GetExecutorsListRequest product.
         * @member {string} product
         * @memberof PlatformCRMWeb.GetExecutorsListRequest
         * @instance
         */
        GetExecutorsListRequest.prototype.product = "";

        /**
         * Encodes the specified GetExecutorsListRequest message. Does not implicitly {@link PlatformCRMWeb.GetExecutorsListRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetExecutorsListRequest
         * @static
         * @param {PlatformCRMWeb.IGetExecutorsListRequest} message GetExecutorsListRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetExecutorsListRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.role);
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.product);
            return writer;
        };

        /**
         * Decodes a GetExecutorsListRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetExecutorsListRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetExecutorsListRequest} GetExecutorsListRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetExecutorsListRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetExecutorsListRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.role = reader.string();
                    break;
                case 2:
                    message.product = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetExecutorsListRequest;
    })();

    PlatformCRMWeb.UnassignExecutorRequest = (function() {

        /**
         * Properties of an UnassignExecutorRequest.
         * @memberof PlatformCRMWeb
         * @interface IUnassignExecutorRequest
         * @property {string|null} [orderId] UnassignExecutorRequest orderId
         * @property {string|null} [executorId] UnassignExecutorRequest executorId
         */

        /**
         * Constructs a new UnassignExecutorRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an UnassignExecutorRequest.
         * @implements IUnassignExecutorRequest
         * @constructor
         * @param {PlatformCRMWeb.IUnassignExecutorRequest=} [properties] Properties to set
         */
        function UnassignExecutorRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnassignExecutorRequest orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.UnassignExecutorRequest
         * @instance
         */
        UnassignExecutorRequest.prototype.orderId = "";

        /**
         * UnassignExecutorRequest executorId.
         * @member {string} executorId
         * @memberof PlatformCRMWeb.UnassignExecutorRequest
         * @instance
         */
        UnassignExecutorRequest.prototype.executorId = "";

        /**
         * Encodes the specified UnassignExecutorRequest message. Does not implicitly {@link PlatformCRMWeb.UnassignExecutorRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UnassignExecutorRequest
         * @static
         * @param {PlatformCRMWeb.IUnassignExecutorRequest} message UnassignExecutorRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnassignExecutorRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            if (message.executorId != null && Object.hasOwnProperty.call(message, "executorId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.executorId);
            return writer;
        };

        /**
         * Decodes an UnassignExecutorRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UnassignExecutorRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UnassignExecutorRequest} UnassignExecutorRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnassignExecutorRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UnassignExecutorRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                case 2:
                    message.executorId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UnassignExecutorRequest;
    })();

    PlatformCRMWeb.SendInvoiceRequest = (function() {

        /**
         * Properties of a SendInvoiceRequest.
         * @memberof PlatformCRMWeb
         * @interface ISendInvoiceRequest
         * @property {string|null} [orderId] SendInvoiceRequest orderId
         */

        /**
         * Constructs a new SendInvoiceRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SendInvoiceRequest.
         * @implements ISendInvoiceRequest
         * @constructor
         * @param {PlatformCRMWeb.ISendInvoiceRequest=} [properties] Properties to set
         */
        function SendInvoiceRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendInvoiceRequest orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.SendInvoiceRequest
         * @instance
         */
        SendInvoiceRequest.prototype.orderId = "";

        /**
         * Encodes the specified SendInvoiceRequest message. Does not implicitly {@link PlatformCRMWeb.SendInvoiceRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SendInvoiceRequest
         * @static
         * @param {PlatformCRMWeb.ISendInvoiceRequest} message SendInvoiceRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendInvoiceRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            return writer;
        };

        /**
         * Decodes a SendInvoiceRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SendInvoiceRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SendInvoiceRequest} SendInvoiceRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendInvoiceRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SendInvoiceRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SendInvoiceRequest;
    })();

    PlatformCRMWeb.SearchOrdersRequest = (function() {

        /**
         * Properties of a SearchOrdersRequest.
         * @memberof PlatformCRMWeb
         * @interface ISearchOrdersRequest
         * @property {number|null} [page] SearchOrdersRequest page
         * @property {number|null} [limit] SearchOrdersRequest limit
         * @property {Array.<PlatformCRMWeb.ISortField>|null} [sort] SearchOrdersRequest sort
         * @property {PlatformCRMWeb.SearchOrdersRequest.IWhereFields|null} [where] SearchOrdersRequest where
         */

        /**
         * Constructs a new SearchOrdersRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SearchOrdersRequest.
         * @implements ISearchOrdersRequest
         * @constructor
         * @param {PlatformCRMWeb.ISearchOrdersRequest=} [properties] Properties to set
         */
        function SearchOrdersRequest(properties) {
            this.sort = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchOrdersRequest page.
         * @member {number} page
         * @memberof PlatformCRMWeb.SearchOrdersRequest
         * @instance
         */
        SearchOrdersRequest.prototype.page = 0;

        /**
         * SearchOrdersRequest limit.
         * @member {number} limit
         * @memberof PlatformCRMWeb.SearchOrdersRequest
         * @instance
         */
        SearchOrdersRequest.prototype.limit = 0;

        /**
         * SearchOrdersRequest sort.
         * @member {Array.<PlatformCRMWeb.ISortField>} sort
         * @memberof PlatformCRMWeb.SearchOrdersRequest
         * @instance
         */
        SearchOrdersRequest.prototype.sort = $util.emptyArray;

        /**
         * SearchOrdersRequest where.
         * @member {PlatformCRMWeb.SearchOrdersRequest.IWhereFields|null|undefined} where
         * @memberof PlatformCRMWeb.SearchOrdersRequest
         * @instance
         */
        SearchOrdersRequest.prototype.where = null;

        /**
         * Encodes the specified SearchOrdersRequest message. Does not implicitly {@link PlatformCRMWeb.SearchOrdersRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SearchOrdersRequest
         * @static
         * @param {PlatformCRMWeb.ISearchOrdersRequest} message SearchOrdersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchOrdersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.page);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.sort != null && message.sort.length)
                for (var i = 0; i < message.sort.length; ++i)
                    $root.PlatformCRMWeb.SortField.encode(message.sort[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.where != null && Object.hasOwnProperty.call(message, "where"))
                $root.PlatformCRMWeb.SearchOrdersRequest.WhereFields.encode(message.where, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchOrdersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SearchOrdersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SearchOrdersRequest} SearchOrdersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchOrdersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SearchOrdersRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.page = reader.int32();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    if (!(message.sort && message.sort.length))
                        message.sort = [];
                    message.sort.push($root.PlatformCRMWeb.SortField.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.where = $root.PlatformCRMWeb.SearchOrdersRequest.WhereFields.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        SearchOrdersRequest.WhereFields = (function() {

            /**
             * Properties of a WhereFields.
             * @memberof PlatformCRMWeb.SearchOrdersRequest
             * @interface IWhereFields
             * @property {PlatformCRMWeb.IStringCondition|null} [qleanOrderId] WhereFields qleanOrderId
             * @property {PlatformCRMWeb.IUuidCondition|null} [person] WhereFields person
             * @property {PlatformCRMWeb.IStringCondition|null} [serviceType] WhereFields serviceType
             * @property {PlatformCRMWeb.IStringCondition|null} [status] WhereFields status
             */

            /**
             * Constructs a new WhereFields.
             * @memberof PlatformCRMWeb.SearchOrdersRequest
             * @classdesc Represents a WhereFields.
             * @implements IWhereFields
             * @constructor
             * @param {PlatformCRMWeb.SearchOrdersRequest.IWhereFields=} [properties] Properties to set
             */
            function WhereFields(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * WhereFields qleanOrderId.
             * @member {PlatformCRMWeb.IStringCondition|null|undefined} qleanOrderId
             * @memberof PlatformCRMWeb.SearchOrdersRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.qleanOrderId = null;

            /**
             * WhereFields person.
             * @member {PlatformCRMWeb.IUuidCondition|null|undefined} person
             * @memberof PlatformCRMWeb.SearchOrdersRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.person = null;

            /**
             * WhereFields serviceType.
             * @member {PlatformCRMWeb.IStringCondition|null|undefined} serviceType
             * @memberof PlatformCRMWeb.SearchOrdersRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.serviceType = null;

            /**
             * WhereFields status.
             * @member {PlatformCRMWeb.IStringCondition|null|undefined} status
             * @memberof PlatformCRMWeb.SearchOrdersRequest.WhereFields
             * @instance
             */
            WhereFields.prototype.status = null;

            /**
             * Encodes the specified WhereFields message. Does not implicitly {@link PlatformCRMWeb.SearchOrdersRequest.WhereFields.verify|verify} messages.
             * @function encode
             * @memberof PlatformCRMWeb.SearchOrdersRequest.WhereFields
             * @static
             * @param {PlatformCRMWeb.SearchOrdersRequest.IWhereFields} message WhereFields message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WhereFields.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.qleanOrderId != null && Object.hasOwnProperty.call(message, "qleanOrderId"))
                    $root.PlatformCRMWeb.StringCondition.encode(message.qleanOrderId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.person != null && Object.hasOwnProperty.call(message, "person"))
                    $root.PlatformCRMWeb.UuidCondition.encode(message.person, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                    $root.PlatformCRMWeb.StringCondition.encode(message.serviceType, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    $root.PlatformCRMWeb.StringCondition.encode(message.status, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a WhereFields message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformCRMWeb.SearchOrdersRequest.WhereFields
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformCRMWeb.SearchOrdersRequest.WhereFields} WhereFields
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WhereFields.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SearchOrdersRequest.WhereFields();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.qleanOrderId = $root.PlatformCRMWeb.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.person = $root.PlatformCRMWeb.UuidCondition.decode(reader, reader.uint32());
                        break;
                    case 3:
                        message.serviceType = $root.PlatformCRMWeb.StringCondition.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.status = $root.PlatformCRMWeb.StringCondition.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return WhereFields;
        })();

        return SearchOrdersRequest;
    })();

    PlatformCRMWeb.GetOrderByIdResponse = (function() {

        /**
         * Properties of a GetOrderByIdResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetOrderByIdResponse
         * @property {PlatformCRMWeb.IOrder|null} [order] GetOrderByIdResponse order
         * @property {Array.<PlatformCRMWeb.IExecutor>|null} [executors] GetOrderByIdResponse executors
         */

        /**
         * Constructs a new GetOrderByIdResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetOrderByIdResponse.
         * @implements IGetOrderByIdResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetOrderByIdResponse=} [properties] Properties to set
         */
        function GetOrderByIdResponse(properties) {
            this.executors = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOrderByIdResponse order.
         * @member {PlatformCRMWeb.IOrder|null|undefined} order
         * @memberof PlatformCRMWeb.GetOrderByIdResponse
         * @instance
         */
        GetOrderByIdResponse.prototype.order = null;

        /**
         * GetOrderByIdResponse executors.
         * @member {Array.<PlatformCRMWeb.IExecutor>} executors
         * @memberof PlatformCRMWeb.GetOrderByIdResponse
         * @instance
         */
        GetOrderByIdResponse.prototype.executors = $util.emptyArray;

        /**
         * Encodes the specified GetOrderByIdResponse message. Does not implicitly {@link PlatformCRMWeb.GetOrderByIdResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetOrderByIdResponse
         * @static
         * @param {PlatformCRMWeb.IGetOrderByIdResponse} message GetOrderByIdResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOrderByIdResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                $root.PlatformCRMWeb.Order.encode(message.order, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.executors != null && message.executors.length)
                for (var i = 0; i < message.executors.length; ++i)
                    $root.PlatformCRMWeb.Executor.encode(message.executors[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetOrderByIdResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetOrderByIdResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetOrderByIdResponse} GetOrderByIdResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOrderByIdResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetOrderByIdResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.order = $root.PlatformCRMWeb.Order.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.executors && message.executors.length))
                        message.executors = [];
                    message.executors.push($root.PlatformCRMWeb.Executor.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetOrderByIdResponse;
    })();

    PlatformCRMWeb.UpdateOrderResponse = (function() {

        /**
         * Properties of an UpdateOrderResponse.
         * @memberof PlatformCRMWeb
         * @interface IUpdateOrderResponse
         * @property {number|null} [totalDuration] UpdateOrderResponse totalDuration
         * @property {number|null} [totalPrice] UpdateOrderResponse totalPrice
         * @property {boolean|null} [isOvertime] UpdateOrderResponse isOvertime
         * @property {PlatformCRMWeb.ICalculatePrice|null} [price] UpdateOrderResponse price
         */

        /**
         * Constructs a new UpdateOrderResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an UpdateOrderResponse.
         * @implements IUpdateOrderResponse
         * @constructor
         * @param {PlatformCRMWeb.IUpdateOrderResponse=} [properties] Properties to set
         */
        function UpdateOrderResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateOrderResponse totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformCRMWeb.UpdateOrderResponse
         * @instance
         */
        UpdateOrderResponse.prototype.totalDuration = 0;

        /**
         * UpdateOrderResponse totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.UpdateOrderResponse
         * @instance
         */
        UpdateOrderResponse.prototype.totalPrice = 0;

        /**
         * UpdateOrderResponse isOvertime.
         * @member {boolean} isOvertime
         * @memberof PlatformCRMWeb.UpdateOrderResponse
         * @instance
         */
        UpdateOrderResponse.prototype.isOvertime = false;

        /**
         * UpdateOrderResponse price.
         * @member {PlatformCRMWeb.ICalculatePrice|null|undefined} price
         * @memberof PlatformCRMWeb.UpdateOrderResponse
         * @instance
         */
        UpdateOrderResponse.prototype.price = null;

        /**
         * Encodes the specified UpdateOrderResponse message. Does not implicitly {@link PlatformCRMWeb.UpdateOrderResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UpdateOrderResponse
         * @static
         * @param {PlatformCRMWeb.IUpdateOrderResponse} message UpdateOrderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateOrderResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.totalDuration);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalPrice);
            if (message.isOvertime != null && Object.hasOwnProperty.call(message, "isOvertime"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isOvertime);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                $root.PlatformCRMWeb.CalculatePrice.encode(message.price, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpdateOrderResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UpdateOrderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UpdateOrderResponse} UpdateOrderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateOrderResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UpdateOrderResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.totalDuration = reader.int32();
                    break;
                case 2:
                    message.totalPrice = reader.int32();
                    break;
                case 3:
                    message.isOvertime = reader.bool();
                    break;
                case 4:
                    message.price = $root.PlatformCRMWeb.CalculatePrice.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdateOrderResponse;
    })();

    PlatformCRMWeb.GetExecutorsListResponse = (function() {

        /**
         * Properties of a GetExecutorsListResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetExecutorsListResponse
         * @property {Array.<PlatformCRMWeb.IExecutor>|null} [executors] GetExecutorsListResponse executors
         */

        /**
         * Constructs a new GetExecutorsListResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetExecutorsListResponse.
         * @implements IGetExecutorsListResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetExecutorsListResponse=} [properties] Properties to set
         */
        function GetExecutorsListResponse(properties) {
            this.executors = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetExecutorsListResponse executors.
         * @member {Array.<PlatformCRMWeb.IExecutor>} executors
         * @memberof PlatformCRMWeb.GetExecutorsListResponse
         * @instance
         */
        GetExecutorsListResponse.prototype.executors = $util.emptyArray;

        /**
         * Encodes the specified GetExecutorsListResponse message. Does not implicitly {@link PlatformCRMWeb.GetExecutorsListResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetExecutorsListResponse
         * @static
         * @param {PlatformCRMWeb.IGetExecutorsListResponse} message GetExecutorsListResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetExecutorsListResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.executors != null && message.executors.length)
                for (var i = 0; i < message.executors.length; ++i)
                    $root.PlatformCRMWeb.Executor.encode(message.executors[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetExecutorsListResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetExecutorsListResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetExecutorsListResponse} GetExecutorsListResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetExecutorsListResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetExecutorsListResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.executors && message.executors.length))
                        message.executors = [];
                    message.executors.push($root.PlatformCRMWeb.Executor.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetExecutorsListResponse;
    })();

    PlatformCRMWeb.Executor = (function() {

        /**
         * Properties of an Executor.
         * @memberof PlatformCRMWeb
         * @interface IExecutor
         * @property {string|null} [id] Executor id
         * @property {number|null} [rate] Executor rate
         * @property {string|null} [name] Executor name
         * @property {string|null} [phone] Executor phone
         * @property {number|null} [role] Executor role
         * @property {string|null} [ssoId] Executor ssoId
         * @property {number|null} [qleanId] Executor qleanId
         */

        /**
         * Constructs a new Executor.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an Executor.
         * @implements IExecutor
         * @constructor
         * @param {PlatformCRMWeb.IExecutor=} [properties] Properties to set
         */
        function Executor(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Executor id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Executor
         * @instance
         */
        Executor.prototype.id = "";

        /**
         * Executor rate.
         * @member {number} rate
         * @memberof PlatformCRMWeb.Executor
         * @instance
         */
        Executor.prototype.rate = 0;

        /**
         * Executor name.
         * @member {string} name
         * @memberof PlatformCRMWeb.Executor
         * @instance
         */
        Executor.prototype.name = "";

        /**
         * Executor phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.Executor
         * @instance
         */
        Executor.prototype.phone = "";

        /**
         * Executor role.
         * @member {number} role
         * @memberof PlatformCRMWeb.Executor
         * @instance
         */
        Executor.prototype.role = 0;

        /**
         * Executor ssoId.
         * @member {string} ssoId
         * @memberof PlatformCRMWeb.Executor
         * @instance
         */
        Executor.prototype.ssoId = "";

        /**
         * Executor qleanId.
         * @member {number} qleanId
         * @memberof PlatformCRMWeb.Executor
         * @instance
         */
        Executor.prototype.qleanId = 0;

        /**
         * Encodes the specified Executor message. Does not implicitly {@link PlatformCRMWeb.Executor.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Executor
         * @static
         * @param {PlatformCRMWeb.IExecutor} message Executor message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Executor.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.rate != null && Object.hasOwnProperty.call(message, "rate"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.rate);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.phone);
            if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.role);
            if (message.ssoId != null && Object.hasOwnProperty.call(message, "ssoId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.ssoId);
            if (message.qleanId != null && Object.hasOwnProperty.call(message, "qleanId"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.qleanId);
            return writer;
        };

        /**
         * Decodes an Executor message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Executor
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Executor} Executor
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Executor.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Executor();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.rate = reader.int32();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.phone = reader.string();
                    break;
                case 6:
                    message.role = reader.int32();
                    break;
                case 7:
                    message.ssoId = reader.string();
                    break;
                case 8:
                    message.qleanId = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Executor;
    })();

    PlatformCRMWeb.AssignExecutorResponse = (function() {

        /**
         * Properties of an AssignExecutorResponse.
         * @memberof PlatformCRMWeb
         * @interface IAssignExecutorResponse
         * @property {Array.<PlatformCRMWeb.IExecutor>|null} [executors] AssignExecutorResponse executors
         */

        /**
         * Constructs a new AssignExecutorResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an AssignExecutorResponse.
         * @implements IAssignExecutorResponse
         * @constructor
         * @param {PlatformCRMWeb.IAssignExecutorResponse=} [properties] Properties to set
         */
        function AssignExecutorResponse(properties) {
            this.executors = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AssignExecutorResponse executors.
         * @member {Array.<PlatformCRMWeb.IExecutor>} executors
         * @memberof PlatformCRMWeb.AssignExecutorResponse
         * @instance
         */
        AssignExecutorResponse.prototype.executors = $util.emptyArray;

        /**
         * Encodes the specified AssignExecutorResponse message. Does not implicitly {@link PlatformCRMWeb.AssignExecutorResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.AssignExecutorResponse
         * @static
         * @param {PlatformCRMWeb.IAssignExecutorResponse} message AssignExecutorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AssignExecutorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.executors != null && message.executors.length)
                for (var i = 0; i < message.executors.length; ++i)
                    $root.PlatformCRMWeb.Executor.encode(message.executors[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an AssignExecutorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.AssignExecutorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.AssignExecutorResponse} AssignExecutorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AssignExecutorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.AssignExecutorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.executors && message.executors.length))
                        message.executors = [];
                    message.executors.push($root.PlatformCRMWeb.Executor.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AssignExecutorResponse;
    })();

    PlatformCRMWeb.UnassignExecutorResponse = (function() {

        /**
         * Properties of an UnassignExecutorResponse.
         * @memberof PlatformCRMWeb
         * @interface IUnassignExecutorResponse
         * @property {Array.<PlatformCRMWeb.IExecutor>|null} [executors] UnassignExecutorResponse executors
         */

        /**
         * Constructs a new UnassignExecutorResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an UnassignExecutorResponse.
         * @implements IUnassignExecutorResponse
         * @constructor
         * @param {PlatformCRMWeb.IUnassignExecutorResponse=} [properties] Properties to set
         */
        function UnassignExecutorResponse(properties) {
            this.executors = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnassignExecutorResponse executors.
         * @member {Array.<PlatformCRMWeb.IExecutor>} executors
         * @memberof PlatformCRMWeb.UnassignExecutorResponse
         * @instance
         */
        UnassignExecutorResponse.prototype.executors = $util.emptyArray;

        /**
         * Encodes the specified UnassignExecutorResponse message. Does not implicitly {@link PlatformCRMWeb.UnassignExecutorResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UnassignExecutorResponse
         * @static
         * @param {PlatformCRMWeb.IUnassignExecutorResponse} message UnassignExecutorResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnassignExecutorResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.executors != null && message.executors.length)
                for (var i = 0; i < message.executors.length; ++i)
                    $root.PlatformCRMWeb.Executor.encode(message.executors[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UnassignExecutorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UnassignExecutorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UnassignExecutorResponse} UnassignExecutorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnassignExecutorResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UnassignExecutorResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.executors && message.executors.length))
                        message.executors = [];
                    message.executors.push($root.PlatformCRMWeb.Executor.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UnassignExecutorResponse;
    })();

    PlatformCRMWeb.SendInvoiceResponse = (function() {

        /**
         * Properties of a SendInvoiceResponse.
         * @memberof PlatformCRMWeb
         * @interface ISendInvoiceResponse
         */

        /**
         * Constructs a new SendInvoiceResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SendInvoiceResponse.
         * @implements ISendInvoiceResponse
         * @constructor
         * @param {PlatformCRMWeb.ISendInvoiceResponse=} [properties] Properties to set
         */
        function SendInvoiceResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified SendInvoiceResponse message. Does not implicitly {@link PlatformCRMWeb.SendInvoiceResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SendInvoiceResponse
         * @static
         * @param {PlatformCRMWeb.ISendInvoiceResponse} message SendInvoiceResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendInvoiceResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a SendInvoiceResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SendInvoiceResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SendInvoiceResponse} SendInvoiceResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendInvoiceResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SendInvoiceResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SendInvoiceResponse;
    })();

    PlatformCRMWeb.OrderChangeStatusResponse = (function() {

        /**
         * Properties of an OrderChangeStatusResponse.
         * @memberof PlatformCRMWeb
         * @interface IOrderChangeStatusResponse
         * @property {string|null} [message] OrderChangeStatusResponse message
         */

        /**
         * Constructs a new OrderChangeStatusResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an OrderChangeStatusResponse.
         * @implements IOrderChangeStatusResponse
         * @constructor
         * @param {PlatformCRMWeb.IOrderChangeStatusResponse=} [properties] Properties to set
         */
        function OrderChangeStatusResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OrderChangeStatusResponse message.
         * @member {string} message
         * @memberof PlatformCRMWeb.OrderChangeStatusResponse
         * @instance
         */
        OrderChangeStatusResponse.prototype.message = "";

        /**
         * Encodes the specified OrderChangeStatusResponse message. Does not implicitly {@link PlatformCRMWeb.OrderChangeStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.OrderChangeStatusResponse
         * @static
         * @param {PlatformCRMWeb.IOrderChangeStatusResponse} message OrderChangeStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OrderChangeStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.message);
            return writer;
        };

        /**
         * Decodes an OrderChangeStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.OrderChangeStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.OrderChangeStatusResponse} OrderChangeStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OrderChangeStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.OrderChangeStatusResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OrderChangeStatusResponse;
    })();

    PlatformCRMWeb.SearchOrdersResponse = (function() {

        /**
         * Properties of a SearchOrdersResponse.
         * @memberof PlatformCRMWeb
         * @interface ISearchOrdersResponse
         * @property {Array.<PlatformCRMWeb.IShortOrder>|null} [data] SearchOrdersResponse data
         * @property {number|null} [page] SearchOrdersResponse page
         * @property {number|null} [pageCount] SearchOrdersResponse pageCount
         * @property {number|null} [limit] SearchOrdersResponse limit
         * @property {number|null} [count] SearchOrdersResponse count
         * @property {number|null} [total] SearchOrdersResponse total
         */

        /**
         * Constructs a new SearchOrdersResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SearchOrdersResponse.
         * @implements ISearchOrdersResponse
         * @constructor
         * @param {PlatformCRMWeb.ISearchOrdersResponse=} [properties] Properties to set
         */
        function SearchOrdersResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchOrdersResponse data.
         * @member {Array.<PlatformCRMWeb.IShortOrder>} data
         * @memberof PlatformCRMWeb.SearchOrdersResponse
         * @instance
         */
        SearchOrdersResponse.prototype.data = $util.emptyArray;

        /**
         * SearchOrdersResponse page.
         * @member {number} page
         * @memberof PlatformCRMWeb.SearchOrdersResponse
         * @instance
         */
        SearchOrdersResponse.prototype.page = 0;

        /**
         * SearchOrdersResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformCRMWeb.SearchOrdersResponse
         * @instance
         */
        SearchOrdersResponse.prototype.pageCount = 0;

        /**
         * SearchOrdersResponse limit.
         * @member {number} limit
         * @memberof PlatformCRMWeb.SearchOrdersResponse
         * @instance
         */
        SearchOrdersResponse.prototype.limit = 0;

        /**
         * SearchOrdersResponse count.
         * @member {number} count
         * @memberof PlatformCRMWeb.SearchOrdersResponse
         * @instance
         */
        SearchOrdersResponse.prototype.count = 0;

        /**
         * SearchOrdersResponse total.
         * @member {number} total
         * @memberof PlatformCRMWeb.SearchOrdersResponse
         * @instance
         */
        SearchOrdersResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchOrdersResponse message. Does not implicitly {@link PlatformCRMWeb.SearchOrdersResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SearchOrdersResponse
         * @static
         * @param {PlatformCRMWeb.ISearchOrdersResponse} message SearchOrdersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchOrdersResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformCRMWeb.ShortOrder.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchOrdersResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SearchOrdersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SearchOrdersResponse} SearchOrdersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchOrdersResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SearchOrdersResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformCRMWeb.ShortOrder.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchOrdersResponse;
    })();

    PlatformCRMWeb.SendPaymentLinkRequest = (function() {

        /**
         * Properties of a SendPaymentLinkRequest.
         * @memberof PlatformCRMWeb
         * @interface ISendPaymentLinkRequest
         * @property {string|null} [orderId] SendPaymentLinkRequest orderId
         */

        /**
         * Constructs a new SendPaymentLinkRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SendPaymentLinkRequest.
         * @implements ISendPaymentLinkRequest
         * @constructor
         * @param {PlatformCRMWeb.ISendPaymentLinkRequest=} [properties] Properties to set
         */
        function SendPaymentLinkRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendPaymentLinkRequest orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.SendPaymentLinkRequest
         * @instance
         */
        SendPaymentLinkRequest.prototype.orderId = "";

        /**
         * Encodes the specified SendPaymentLinkRequest message. Does not implicitly {@link PlatformCRMWeb.SendPaymentLinkRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SendPaymentLinkRequest
         * @static
         * @param {PlatformCRMWeb.ISendPaymentLinkRequest} message SendPaymentLinkRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendPaymentLinkRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            return writer;
        };

        /**
         * Decodes a SendPaymentLinkRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SendPaymentLinkRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SendPaymentLinkRequest} SendPaymentLinkRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendPaymentLinkRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SendPaymentLinkRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SendPaymentLinkRequest;
    })();

    PlatformCRMWeb.SendPaymentLinkResponse = (function() {

        /**
         * Properties of a SendPaymentLinkResponse.
         * @memberof PlatformCRMWeb
         * @interface ISendPaymentLinkResponse
         */

        /**
         * Constructs a new SendPaymentLinkResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SendPaymentLinkResponse.
         * @implements ISendPaymentLinkResponse
         * @constructor
         * @param {PlatformCRMWeb.ISendPaymentLinkResponse=} [properties] Properties to set
         */
        function SendPaymentLinkResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified SendPaymentLinkResponse message. Does not implicitly {@link PlatformCRMWeb.SendPaymentLinkResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SendPaymentLinkResponse
         * @static
         * @param {PlatformCRMWeb.ISendPaymentLinkResponse} message SendPaymentLinkResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendPaymentLinkResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a SendPaymentLinkResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SendPaymentLinkResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SendPaymentLinkResponse} SendPaymentLinkResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendPaymentLinkResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SendPaymentLinkResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SendPaymentLinkResponse;
    })();

    PlatformCRMWeb.RejectionGroupsRequest = (function() {

        /**
         * Properties of a RejectionGroupsRequest.
         * @memberof PlatformCRMWeb
         * @interface IRejectionGroupsRequest
         * @property {string|null} [productSlug] RejectionGroupsRequest productSlug
         */

        /**
         * Constructs a new RejectionGroupsRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a RejectionGroupsRequest.
         * @implements IRejectionGroupsRequest
         * @constructor
         * @param {PlatformCRMWeb.IRejectionGroupsRequest=} [properties] Properties to set
         */
        function RejectionGroupsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RejectionGroupsRequest productSlug.
         * @member {string} productSlug
         * @memberof PlatformCRMWeb.RejectionGroupsRequest
         * @instance
         */
        RejectionGroupsRequest.prototype.productSlug = "";

        /**
         * Encodes the specified RejectionGroupsRequest message. Does not implicitly {@link PlatformCRMWeb.RejectionGroupsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.RejectionGroupsRequest
         * @static
         * @param {PlatformCRMWeb.IRejectionGroupsRequest} message RejectionGroupsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RejectionGroupsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productSlug);
            return writer;
        };

        /**
         * Decodes a RejectionGroupsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.RejectionGroupsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.RejectionGroupsRequest} RejectionGroupsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RejectionGroupsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.RejectionGroupsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.productSlug = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RejectionGroupsRequest;
    })();

    PlatformCRMWeb.RejectionGroupsResponse = (function() {

        /**
         * Properties of a RejectionGroupsResponse.
         * @memberof PlatformCRMWeb
         * @interface IRejectionGroupsResponse
         * @property {Array.<PlatformCRMWeb.IRejectionGroup>|null} [data] RejectionGroupsResponse data
         */

        /**
         * Constructs a new RejectionGroupsResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a RejectionGroupsResponse.
         * @implements IRejectionGroupsResponse
         * @constructor
         * @param {PlatformCRMWeb.IRejectionGroupsResponse=} [properties] Properties to set
         */
        function RejectionGroupsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RejectionGroupsResponse data.
         * @member {Array.<PlatformCRMWeb.IRejectionGroup>} data
         * @memberof PlatformCRMWeb.RejectionGroupsResponse
         * @instance
         */
        RejectionGroupsResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified RejectionGroupsResponse message. Does not implicitly {@link PlatformCRMWeb.RejectionGroupsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.RejectionGroupsResponse
         * @static
         * @param {PlatformCRMWeb.IRejectionGroupsResponse} message RejectionGroupsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RejectionGroupsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformCRMWeb.RejectionGroup.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a RejectionGroupsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.RejectionGroupsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.RejectionGroupsResponse} RejectionGroupsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RejectionGroupsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.RejectionGroupsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformCRMWeb.RejectionGroup.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RejectionGroupsResponse;
    })();

    PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest = (function() {

        /**
         * Properties of an UpdatePaymentTypeInOmsOrderRequest.
         * @memberof PlatformCRMWeb
         * @interface IUpdatePaymentTypeInOmsOrderRequest
         * @property {string|null} [orderId] UpdatePaymentTypeInOmsOrderRequest orderId
         * @property {string|null} [paymentType] UpdatePaymentTypeInOmsOrderRequest paymentType
         * @property {string|null} [paymentTypeMeta] UpdatePaymentTypeInOmsOrderRequest paymentTypeMeta
         */

        /**
         * Constructs a new UpdatePaymentTypeInOmsOrderRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an UpdatePaymentTypeInOmsOrderRequest.
         * @implements IUpdatePaymentTypeInOmsOrderRequest
         * @constructor
         * @param {PlatformCRMWeb.IUpdatePaymentTypeInOmsOrderRequest=} [properties] Properties to set
         */
        function UpdatePaymentTypeInOmsOrderRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePaymentTypeInOmsOrderRequest orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest
         * @instance
         */
        UpdatePaymentTypeInOmsOrderRequest.prototype.orderId = "";

        /**
         * UpdatePaymentTypeInOmsOrderRequest paymentType.
         * @member {string} paymentType
         * @memberof PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest
         * @instance
         */
        UpdatePaymentTypeInOmsOrderRequest.prototype.paymentType = "";

        /**
         * UpdatePaymentTypeInOmsOrderRequest paymentTypeMeta.
         * @member {string|null|undefined} paymentTypeMeta
         * @memberof PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest
         * @instance
         */
        UpdatePaymentTypeInOmsOrderRequest.prototype.paymentTypeMeta = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * UpdatePaymentTypeInOmsOrderRequest _paymentTypeMeta.
         * @member {"paymentTypeMeta"|undefined} _paymentTypeMeta
         * @memberof PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest
         * @instance
         */
        Object.defineProperty(UpdatePaymentTypeInOmsOrderRequest.prototype, "_paymentTypeMeta", {
            get: $util.oneOfGetter($oneOfFields = ["paymentTypeMeta"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified UpdatePaymentTypeInOmsOrderRequest message. Does not implicitly {@link PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest
         * @static
         * @param {PlatformCRMWeb.IUpdatePaymentTypeInOmsOrderRequest} message UpdatePaymentTypeInOmsOrderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePaymentTypeInOmsOrderRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.paymentType);
            if (message.paymentTypeMeta != null && Object.hasOwnProperty.call(message, "paymentTypeMeta"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.paymentTypeMeta);
            return writer;
        };

        /**
         * Decodes an UpdatePaymentTypeInOmsOrderRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest} UpdatePaymentTypeInOmsOrderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePaymentTypeInOmsOrderRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.orderId = reader.string();
                    break;
                case 2:
                    message.paymentType = reader.string();
                    break;
                case 3:
                    message.paymentTypeMeta = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdatePaymentTypeInOmsOrderRequest;
    })();

    PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse = (function() {

        /**
         * Properties of an UpdatePaymentTypeInOmsOrderResponse.
         * @memberof PlatformCRMWeb
         * @interface IUpdatePaymentTypeInOmsOrderResponse
         * @property {string|null} [paymentStatus] UpdatePaymentTypeInOmsOrderResponse paymentStatus
         */

        /**
         * Constructs a new UpdatePaymentTypeInOmsOrderResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an UpdatePaymentTypeInOmsOrderResponse.
         * @implements IUpdatePaymentTypeInOmsOrderResponse
         * @constructor
         * @param {PlatformCRMWeb.IUpdatePaymentTypeInOmsOrderResponse=} [properties] Properties to set
         */
        function UpdatePaymentTypeInOmsOrderResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePaymentTypeInOmsOrderResponse paymentStatus.
         * @member {string} paymentStatus
         * @memberof PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse
         * @instance
         */
        UpdatePaymentTypeInOmsOrderResponse.prototype.paymentStatus = "";

        /**
         * Encodes the specified UpdatePaymentTypeInOmsOrderResponse message. Does not implicitly {@link PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse
         * @static
         * @param {PlatformCRMWeb.IUpdatePaymentTypeInOmsOrderResponse} message UpdatePaymentTypeInOmsOrderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePaymentTypeInOmsOrderResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentStatus != null && Object.hasOwnProperty.call(message, "paymentStatus"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentStatus);
            return writer;
        };

        /**
         * Decodes an UpdatePaymentTypeInOmsOrderResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse} UpdatePaymentTypeInOmsOrderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePaymentTypeInOmsOrderResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.paymentStatus = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpdatePaymentTypeInOmsOrderResponse;
    })();

    PlatformCRMWeb.OrderService = (function() {

        /**
         * Constructs a new OrderService service.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an OrderService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function OrderService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (OrderService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = OrderService;

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#getOrderById}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef GetOrderByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetOrderByIdResponse} [response] GetOrderByIdResponse
         */

        /**
         * Calls GetOrderById.
         * @function getOrderById
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IGetOrderByIdRequest} request GetOrderByIdRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.GetOrderByIdCallback} callback Node-style callback called with the error, if any, and GetOrderByIdResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.getOrderById = function getOrderById(request, callback) {
            return this.rpcCall(getOrderById, $root.PlatformCRMWeb.GetOrderByIdRequest, $root.PlatformCRMWeb.GetOrderByIdResponse, request, callback);
        }, "name", { value: "GetOrderById" });

        /**
         * Calls GetOrderById.
         * @function getOrderById
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IGetOrderByIdRequest} request GetOrderByIdRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetOrderByIdResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#changeStatus}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef ChangeStatusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.OrderChangeStatusResponse} [response] OrderChangeStatusResponse
         */

        /**
         * Calls ChangeStatus.
         * @function changeStatus
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IOrderChangeStatusRequest} request OrderChangeStatusRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.ChangeStatusCallback} callback Node-style callback called with the error, if any, and OrderChangeStatusResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.changeStatus = function changeStatus(request, callback) {
            return this.rpcCall(changeStatus, $root.PlatformCRMWeb.OrderChangeStatusRequest, $root.PlatformCRMWeb.OrderChangeStatusResponse, request, callback);
        }, "name", { value: "ChangeStatus" });

        /**
         * Calls ChangeStatus.
         * @function changeStatus
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IOrderChangeStatusRequest} request OrderChangeStatusRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.OrderChangeStatusResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#update}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef UpdateCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.UpdateOrderResponse} [response] UpdateOrderResponse
         */

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IUpdateOrderRequest} request UpdateOrderRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.UpdateCallback} callback Node-style callback called with the error, if any, and UpdateOrderResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.update = function update(request, callback) {
            return this.rpcCall(update, $root.PlatformCRMWeb.UpdateOrderRequest, $root.PlatformCRMWeb.UpdateOrderResponse, request, callback);
        }, "name", { value: "Update" });

        /**
         * Calls Update.
         * @function update
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IUpdateOrderRequest} request UpdateOrderRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.UpdateOrderResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#updatePaymentTypeInOmsOrder}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef UpdatePaymentTypeInOmsOrderCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse} [response] UpdatePaymentTypeInOmsOrderResponse
         */

        /**
         * Calls UpdatePaymentTypeInOmsOrder.
         * @function updatePaymentTypeInOmsOrder
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IUpdatePaymentTypeInOmsOrderRequest} request UpdatePaymentTypeInOmsOrderRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.UpdatePaymentTypeInOmsOrderCallback} callback Node-style callback called with the error, if any, and UpdatePaymentTypeInOmsOrderResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.updatePaymentTypeInOmsOrder = function updatePaymentTypeInOmsOrder(request, callback) {
            return this.rpcCall(updatePaymentTypeInOmsOrder, $root.PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest, $root.PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse, request, callback);
        }, "name", { value: "UpdatePaymentTypeInOmsOrder" });

        /**
         * Calls UpdatePaymentTypeInOmsOrder.
         * @function updatePaymentTypeInOmsOrder
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IUpdatePaymentTypeInOmsOrderRequest} request UpdatePaymentTypeInOmsOrderRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#executorsList}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef ExecutorsListCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetExecutorsListResponse} [response] GetExecutorsListResponse
         */

        /**
         * Calls ExecutorsList.
         * @function executorsList
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IGetExecutorsListRequest} request GetExecutorsListRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.ExecutorsListCallback} callback Node-style callback called with the error, if any, and GetExecutorsListResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.executorsList = function executorsList(request, callback) {
            return this.rpcCall(executorsList, $root.PlatformCRMWeb.GetExecutorsListRequest, $root.PlatformCRMWeb.GetExecutorsListResponse, request, callback);
        }, "name", { value: "ExecutorsList" });

        /**
         * Calls ExecutorsList.
         * @function executorsList
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IGetExecutorsListRequest} request GetExecutorsListRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetExecutorsListResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#assignExecutor}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef AssignExecutorCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.AssignExecutorResponse} [response] AssignExecutorResponse
         */

        /**
         * Calls AssignExecutor.
         * @function assignExecutor
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IAssignExecutorRequest} request AssignExecutorRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.AssignExecutorCallback} callback Node-style callback called with the error, if any, and AssignExecutorResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.assignExecutor = function assignExecutor(request, callback) {
            return this.rpcCall(assignExecutor, $root.PlatformCRMWeb.AssignExecutorRequest, $root.PlatformCRMWeb.AssignExecutorResponse, request, callback);
        }, "name", { value: "AssignExecutor" });

        /**
         * Calls AssignExecutor.
         * @function assignExecutor
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IAssignExecutorRequest} request AssignExecutorRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.AssignExecutorResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#unassignExecutor}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef UnassignExecutorCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.UnassignExecutorResponse} [response] UnassignExecutorResponse
         */

        /**
         * Calls UnassignExecutor.
         * @function unassignExecutor
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IUnassignExecutorRequest} request UnassignExecutorRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.UnassignExecutorCallback} callback Node-style callback called with the error, if any, and UnassignExecutorResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.unassignExecutor = function unassignExecutor(request, callback) {
            return this.rpcCall(unassignExecutor, $root.PlatformCRMWeb.UnassignExecutorRequest, $root.PlatformCRMWeb.UnassignExecutorResponse, request, callback);
        }, "name", { value: "UnassignExecutor" });

        /**
         * Calls UnassignExecutor.
         * @function unassignExecutor
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IUnassignExecutorRequest} request UnassignExecutorRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.UnassignExecutorResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#sendInvoice}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef SendInvoiceCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.SendInvoiceResponse} [response] SendInvoiceResponse
         */

        /**
         * Calls SendInvoice.
         * @function sendInvoice
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.ISendInvoiceRequest} request SendInvoiceRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.SendInvoiceCallback} callback Node-style callback called with the error, if any, and SendInvoiceResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.sendInvoice = function sendInvoice(request, callback) {
            return this.rpcCall(sendInvoice, $root.PlatformCRMWeb.SendInvoiceRequest, $root.PlatformCRMWeb.SendInvoiceResponse, request, callback);
        }, "name", { value: "SendInvoice" });

        /**
         * Calls SendInvoice.
         * @function sendInvoice
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.ISendInvoiceRequest} request SendInvoiceRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.SendInvoiceResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#searchOrders}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef SearchOrdersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.SearchOrdersResponse} [response] SearchOrdersResponse
         */

        /**
         * Calls SearchOrders.
         * @function searchOrders
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.ISearchOrdersRequest} request SearchOrdersRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.SearchOrdersCallback} callback Node-style callback called with the error, if any, and SearchOrdersResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.searchOrders = function searchOrders(request, callback) {
            return this.rpcCall(searchOrders, $root.PlatformCRMWeb.SearchOrdersRequest, $root.PlatformCRMWeb.SearchOrdersResponse, request, callback);
        }, "name", { value: "SearchOrders" });

        /**
         * Calls SearchOrders.
         * @function searchOrders
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.ISearchOrdersRequest} request SearchOrdersRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.SearchOrdersResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#sendPaymentLink}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef SendPaymentLinkCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.SendPaymentLinkResponse} [response] SendPaymentLinkResponse
         */

        /**
         * Calls SendPaymentLink.
         * @function sendPaymentLink
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.ISendPaymentLinkRequest} request SendPaymentLinkRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.SendPaymentLinkCallback} callback Node-style callback called with the error, if any, and SendPaymentLinkResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.sendPaymentLink = function sendPaymentLink(request, callback) {
            return this.rpcCall(sendPaymentLink, $root.PlatformCRMWeb.SendPaymentLinkRequest, $root.PlatformCRMWeb.SendPaymentLinkResponse, request, callback);
        }, "name", { value: "SendPaymentLink" });

        /**
         * Calls SendPaymentLink.
         * @function sendPaymentLink
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.ISendPaymentLinkRequest} request SendPaymentLinkRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.SendPaymentLinkResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.OrderService#searchRejectionGroups}.
         * @memberof PlatformCRMWeb.OrderService
         * @typedef SearchRejectionGroupsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.RejectionGroupsResponse} [response] RejectionGroupsResponse
         */

        /**
         * Calls SearchRejectionGroups.
         * @function searchRejectionGroups
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IRejectionGroupsRequest} request RejectionGroupsRequest message or plain object
         * @param {PlatformCRMWeb.OrderService.SearchRejectionGroupsCallback} callback Node-style callback called with the error, if any, and RejectionGroupsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(OrderService.prototype.searchRejectionGroups = function searchRejectionGroups(request, callback) {
            return this.rpcCall(searchRejectionGroups, $root.PlatformCRMWeb.RejectionGroupsRequest, $root.PlatformCRMWeb.RejectionGroupsResponse, request, callback);
        }, "name", { value: "SearchRejectionGroups" });

        /**
         * Calls SearchRejectionGroups.
         * @function searchRejectionGroups
         * @memberof PlatformCRMWeb.OrderService
         * @instance
         * @param {PlatformCRMWeb.IRejectionGroupsRequest} request RejectionGroupsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.RejectionGroupsResponse>} Promise
         * @variation 2
         */

        return OrderService;
    })();

    /**
     * ManagerState enum.
     * @name PlatformCRMWeb.ManagerState
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} ONLINE=1 ONLINE value
     * @property {number} OFFLINE=2 OFFLINE value
     */
    PlatformCRMWeb.ManagerState = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "ONLINE"] = 1;
        values[valuesById[2] = "OFFLINE"] = 2;
        return values;
    })();

    PlatformCRMWeb.ManagerSkill = (function() {

        /**
         * Properties of a ManagerSkill.
         * @memberof PlatformCRMWeb
         * @interface IManagerSkill
         * @property {string|null} [id] ManagerSkill id
         * @property {string|null} [managerId] ManagerSkill managerId
         * @property {string|null} [skillId] ManagerSkill skillId
         * @property {string|null} [createdAt] ManagerSkill createdAt
         * @property {string|null} [updatedAt] ManagerSkill updatedAt
         * @property {boolean|null} [isDeleted] ManagerSkill isDeleted
         * @property {string|null} [deletedAt] ManagerSkill deletedAt
         */

        /**
         * Constructs a new ManagerSkill.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ManagerSkill.
         * @implements IManagerSkill
         * @constructor
         * @param {PlatformCRMWeb.IManagerSkill=} [properties] Properties to set
         */
        function ManagerSkill(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ManagerSkill id.
         * @member {string} id
         * @memberof PlatformCRMWeb.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.id = "";

        /**
         * ManagerSkill managerId.
         * @member {string} managerId
         * @memberof PlatformCRMWeb.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.managerId = "";

        /**
         * ManagerSkill skillId.
         * @member {string} skillId
         * @memberof PlatformCRMWeb.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.skillId = "";

        /**
         * ManagerSkill createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.createdAt = "";

        /**
         * ManagerSkill updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.updatedAt = "";

        /**
         * ManagerSkill isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformCRMWeb.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.isDeleted = false;

        /**
         * ManagerSkill deletedAt.
         * @member {string|null|undefined} deletedAt
         * @memberof PlatformCRMWeb.ManagerSkill
         * @instance
         */
        ManagerSkill.prototype.deletedAt = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ManagerSkill _deletedAt.
         * @member {"deletedAt"|undefined} _deletedAt
         * @memberof PlatformCRMWeb.ManagerSkill
         * @instance
         */
        Object.defineProperty(ManagerSkill.prototype, "_deletedAt", {
            get: $util.oneOfGetter($oneOfFields = ["deletedAt"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified ManagerSkill message. Does not implicitly {@link PlatformCRMWeb.ManagerSkill.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ManagerSkill
         * @static
         * @param {PlatformCRMWeb.IManagerSkill} message ManagerSkill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManagerSkill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.managerId != null && Object.hasOwnProperty.call(message, "managerId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.managerId);
            if (message.skillId != null && Object.hasOwnProperty.call(message, "skillId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.skillId);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a ManagerSkill message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ManagerSkill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ManagerSkill} ManagerSkill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManagerSkill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ManagerSkill();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.managerId = reader.string();
                    break;
                case 3:
                    message.skillId = reader.string();
                    break;
                case 4:
                    message.createdAt = reader.string();
                    break;
                case 5:
                    message.updatedAt = reader.string();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ManagerSkill;
    })();

    PlatformCRMWeb.Manager = (function() {

        /**
         * Properties of a Manager.
         * @memberof PlatformCRMWeb
         * @interface IManager
         * @property {string|null} [id] Manager id
         * @property {string|null} [name] Manager name
         * @property {string|null} [ssoUid] Manager ssoUid
         * @property {PlatformCRMWeb.ManagerState|null} [state] Manager state
         * @property {Array.<PlatformCRMWeb.IManagerSkill>|null} [skills] Manager skills
         */

        /**
         * Constructs a new Manager.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Manager.
         * @implements IManager
         * @constructor
         * @param {PlatformCRMWeb.IManager=} [properties] Properties to set
         */
        function Manager(properties) {
            this.skills = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Manager id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Manager
         * @instance
         */
        Manager.prototype.id = "";

        /**
         * Manager name.
         * @member {string} name
         * @memberof PlatformCRMWeb.Manager
         * @instance
         */
        Manager.prototype.name = "";

        /**
         * Manager ssoUid.
         * @member {string} ssoUid
         * @memberof PlatformCRMWeb.Manager
         * @instance
         */
        Manager.prototype.ssoUid = "";

        /**
         * Manager state.
         * @member {PlatformCRMWeb.ManagerState} state
         * @memberof PlatformCRMWeb.Manager
         * @instance
         */
        Manager.prototype.state = 0;

        /**
         * Manager skills.
         * @member {Array.<PlatformCRMWeb.IManagerSkill>} skills
         * @memberof PlatformCRMWeb.Manager
         * @instance
         */
        Manager.prototype.skills = $util.emptyArray;

        /**
         * Encodes the specified Manager message. Does not implicitly {@link PlatformCRMWeb.Manager.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Manager
         * @static
         * @param {PlatformCRMWeb.IManager} message Manager message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Manager.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.ssoUid != null && Object.hasOwnProperty.call(message, "ssoUid"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.ssoUid);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.state);
            if (message.skills != null && message.skills.length)
                for (var i = 0; i < message.skills.length; ++i)
                    $root.PlatformCRMWeb.ManagerSkill.encode(message.skills[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Manager message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Manager
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Manager} Manager
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Manager.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Manager();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.ssoUid = reader.string();
                    break;
                case 4:
                    message.state = reader.int32();
                    break;
                case 5:
                    if (!(message.skills && message.skills.length))
                        message.skills = [];
                    message.skills.push($root.PlatformCRMWeb.ManagerSkill.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Manager;
    })();

    PlatformCRMWeb.CurrentManagerRequest = (function() {

        /**
         * Properties of a CurrentManagerRequest.
         * @memberof PlatformCRMWeb
         * @interface ICurrentManagerRequest
         */

        /**
         * Constructs a new CurrentManagerRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CurrentManagerRequest.
         * @implements ICurrentManagerRequest
         * @constructor
         * @param {PlatformCRMWeb.ICurrentManagerRequest=} [properties] Properties to set
         */
        function CurrentManagerRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified CurrentManagerRequest message. Does not implicitly {@link PlatformCRMWeb.CurrentManagerRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CurrentManagerRequest
         * @static
         * @param {PlatformCRMWeb.ICurrentManagerRequest} message CurrentManagerRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CurrentManagerRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a CurrentManagerRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CurrentManagerRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CurrentManagerRequest} CurrentManagerRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CurrentManagerRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CurrentManagerRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CurrentManagerRequest;
    })();

    PlatformCRMWeb.ChangeManagerStatusRequest = (function() {

        /**
         * Properties of a ChangeManagerStatusRequest.
         * @memberof PlatformCRMWeb
         * @interface IChangeManagerStatusRequest
         */

        /**
         * Constructs a new ChangeManagerStatusRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ChangeManagerStatusRequest.
         * @implements IChangeManagerStatusRequest
         * @constructor
         * @param {PlatformCRMWeb.IChangeManagerStatusRequest=} [properties] Properties to set
         */
        function ChangeManagerStatusRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified ChangeManagerStatusRequest message. Does not implicitly {@link PlatformCRMWeb.ChangeManagerStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ChangeManagerStatusRequest
         * @static
         * @param {PlatformCRMWeb.IChangeManagerStatusRequest} message ChangeManagerStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChangeManagerStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a ChangeManagerStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ChangeManagerStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ChangeManagerStatusRequest} ChangeManagerStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChangeManagerStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ChangeManagerStatusRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ChangeManagerStatusRequest;
    })();

    PlatformCRMWeb.CurrentManagerResponse = (function() {

        /**
         * Properties of a CurrentManagerResponse.
         * @memberof PlatformCRMWeb
         * @interface ICurrentManagerResponse
         * @property {PlatformCRMWeb.IManager|null} [manager] CurrentManagerResponse manager
         * @property {PlatformCRMWeb.ITask|null} [activeTask] CurrentManagerResponse activeTask
         */

        /**
         * Constructs a new CurrentManagerResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CurrentManagerResponse.
         * @implements ICurrentManagerResponse
         * @constructor
         * @param {PlatformCRMWeb.ICurrentManagerResponse=} [properties] Properties to set
         */
        function CurrentManagerResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CurrentManagerResponse manager.
         * @member {PlatformCRMWeb.IManager|null|undefined} manager
         * @memberof PlatformCRMWeb.CurrentManagerResponse
         * @instance
         */
        CurrentManagerResponse.prototype.manager = null;

        /**
         * CurrentManagerResponse activeTask.
         * @member {PlatformCRMWeb.ITask|null|undefined} activeTask
         * @memberof PlatformCRMWeb.CurrentManagerResponse
         * @instance
         */
        CurrentManagerResponse.prototype.activeTask = null;

        /**
         * Encodes the specified CurrentManagerResponse message. Does not implicitly {@link PlatformCRMWeb.CurrentManagerResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CurrentManagerResponse
         * @static
         * @param {PlatformCRMWeb.ICurrentManagerResponse} message CurrentManagerResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CurrentManagerResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.manager != null && Object.hasOwnProperty.call(message, "manager"))
                $root.PlatformCRMWeb.Manager.encode(message.manager, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.activeTask != null && Object.hasOwnProperty.call(message, "activeTask"))
                $root.PlatformCRMWeb.Task.encode(message.activeTask, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CurrentManagerResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CurrentManagerResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CurrentManagerResponse} CurrentManagerResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CurrentManagerResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CurrentManagerResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.manager = $root.PlatformCRMWeb.Manager.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.activeTask = $root.PlatformCRMWeb.Task.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CurrentManagerResponse;
    })();

    PlatformCRMWeb.ManagerService = (function() {

        /**
         * Constructs a new ManagerService service.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ManagerService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ManagerService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ManagerService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ManagerService;

        /**
         * Callback as used by {@link PlatformCRMWeb.ManagerService#getCurrentManager}.
         * @memberof PlatformCRMWeb.ManagerService
         * @typedef GetCurrentManagerCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.CurrentManagerResponse} [response] CurrentManagerResponse
         */

        /**
         * Calls GetCurrentManager.
         * @function getCurrentManager
         * @memberof PlatformCRMWeb.ManagerService
         * @instance
         * @param {PlatformCRMWeb.ICurrentManagerRequest} request CurrentManagerRequest message or plain object
         * @param {PlatformCRMWeb.ManagerService.GetCurrentManagerCallback} callback Node-style callback called with the error, if any, and CurrentManagerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagerService.prototype.getCurrentManager = function getCurrentManager(request, callback) {
            return this.rpcCall(getCurrentManager, $root.PlatformCRMWeb.CurrentManagerRequest, $root.PlatformCRMWeb.CurrentManagerResponse, request, callback);
        }, "name", { value: "GetCurrentManager" });

        /**
         * Calls GetCurrentManager.
         * @function getCurrentManager
         * @memberof PlatformCRMWeb.ManagerService
         * @instance
         * @param {PlatformCRMWeb.ICurrentManagerRequest} request CurrentManagerRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.CurrentManagerResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.ManagerService#changeManagerStatus}.
         * @memberof PlatformCRMWeb.ManagerService
         * @typedef ChangeManagerStatusCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.CurrentManagerResponse} [response] CurrentManagerResponse
         */

        /**
         * Calls ChangeManagerStatus.
         * @function changeManagerStatus
         * @memberof PlatformCRMWeb.ManagerService
         * @instance
         * @param {PlatformCRMWeb.IChangeManagerStatusRequest} request ChangeManagerStatusRequest message or plain object
         * @param {PlatformCRMWeb.ManagerService.ChangeManagerStatusCallback} callback Node-style callback called with the error, if any, and CurrentManagerResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ManagerService.prototype.changeManagerStatus = function changeManagerStatus(request, callback) {
            return this.rpcCall(changeManagerStatus, $root.PlatformCRMWeb.ChangeManagerStatusRequest, $root.PlatformCRMWeb.CurrentManagerResponse, request, callback);
        }, "name", { value: "ChangeManagerStatus" });

        /**
         * Calls ChangeManagerStatus.
         * @function changeManagerStatus
         * @memberof PlatformCRMWeb.ManagerService
         * @instance
         * @param {PlatformCRMWeb.IChangeManagerStatusRequest} request ChangeManagerStatusRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.CurrentManagerResponse>} Promise
         * @variation 2
         */

        return ManagerService;
    })();

    PlatformCRMWeb.Prospect = (function() {

        /**
         * Properties of a Prospect.
         * @memberof PlatformCRMWeb
         * @interface IProspect
         * @property {string|null} [id] Prospect id
         * @property {number|null} [prospectNumber] Prospect prospectNumber
         * @property {string|null} [publicId] Prospect publicId
         * @property {boolean|null} [isShortForm] Prospect isShortForm
         * @property {string|null} [phone] Prospect phone
         * @property {PlatformCRMWeb.IFIO|null} [fio] Prospect fio
         * @property {string|null} [email] Prospect email
         * @property {PlatformCRMWeb.ICalculatePrice|null} [price] Prospect price
         * @property {string|null} [status] Prospect status
         * @property {string|null} [usp] Prospect usp
         * @property {string|null} [strategy] Prospect strategy
         * @property {string|null} [cancelReason] Prospect cancelReason
         * @property {string|null} [platform] Prospect platform
         * @property {PlatformCRMWeb.IPerson|null} [person] Prospect person
         * @property {string|null} [startDate] Prospect startDate
         * @property {string|null} [orderId] Prospect orderId
         * @property {boolean|null} [isManualProcessing] Prospect isManualProcessing
         * @property {sso.IAddress|null} [address] Prospect address
         * @property {number|null} [regionId] Prospect regionId
         * @property {string|null} [promocode] Prospect promocode
         * @property {string|null} [utmCampaign] Prospect utmCampaign
         * @property {string|null} [utmContent] Prospect utmContent
         * @property {string|null} [utmData] Prospect utmData
         * @property {string|null} [utmMedium] Prospect utmMedium
         * @property {string|null} [utmSource] Prospect utmSource
         * @property {string|null} [utmTerm] Prospect utmTerm
         * @property {string|null} [serviceType] Prospect serviceType
         * @property {string|null} [paymentType] Prospect paymentType
         * @property {string|null} [regularity] Prospect regularity
         * @property {string|null} [cardNumber] Prospect cardNumber
         * @property {string|null} [qleanClientId] Prospect qleanClientId
         * @property {string|null} [qleanOrderId] Prospect qleanOrderId
         * @property {boolean|null} [isOvertime] Prospect isOvertime
         * @property {number|null} [totalPrice] Prospect totalPrice
         * @property {number|null} [totalDuration] Prospect totalDuration
         * @property {string|null} [commentForExecutor] Prospect commentForExecutor
         * @property {string|null} [commentForSupport] Prospect commentForSupport
         * @property {string|null} [commentFromCustomer] Prospect commentFromCustomer
         * @property {string|null} [createdAt] Prospect createdAt
         * @property {string|null} [updatedAt] Prospect updatedAt
         * @property {number|null} [discount] Prospect discount
         * @property {sso.IAddress|null} [keysDelivery] Prospect keysDelivery
         * @property {sso.IAddress|null} [keysPickup] Prospect keysPickup
         * @property {string|null} [externalId] Prospect externalId
         * @property {string|null} [adminPanelLink] Prospect adminPanelLink
         * @property {string|null} [qleanAddress] Prospect qleanAddress
         * @property {PlatformCRMWeb.IDrycleaningMeta|null} [drycleaningMeta] Prospect drycleaningMeta
         * @property {string|null} [taskId] Prospect taskId
         * @property {string|null} [paymentTypeMeta] Prospect paymentTypeMeta
         * @property {number|null} [numberOfPayments] Prospect numberOfPayments
         */

        /**
         * Constructs a new Prospect.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Prospect.
         * @implements IProspect
         * @constructor
         * @param {PlatformCRMWeb.IProspect=} [properties] Properties to set
         */
        function Prospect(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Prospect id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.id = "";

        /**
         * Prospect prospectNumber.
         * @member {number} prospectNumber
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.prospectNumber = 0;

        /**
         * Prospect publicId.
         * @member {string} publicId
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.publicId = "";

        /**
         * Prospect isShortForm.
         * @member {boolean} isShortForm
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.isShortForm = false;

        /**
         * Prospect phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.phone = "";

        /**
         * Prospect fio.
         * @member {PlatformCRMWeb.IFIO|null|undefined} fio
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.fio = null;

        /**
         * Prospect email.
         * @member {string} email
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.email = "";

        /**
         * Prospect price.
         * @member {PlatformCRMWeb.ICalculatePrice|null|undefined} price
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.price = null;

        /**
         * Prospect status.
         * @member {string} status
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.status = "";

        /**
         * Prospect usp.
         * @member {string} usp
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.usp = "";

        /**
         * Prospect strategy.
         * @member {string} strategy
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.strategy = "";

        /**
         * Prospect cancelReason.
         * @member {string} cancelReason
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.cancelReason = "";

        /**
         * Prospect platform.
         * @member {string} platform
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.platform = "";

        /**
         * Prospect person.
         * @member {PlatformCRMWeb.IPerson|null|undefined} person
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.person = null;

        /**
         * Prospect startDate.
         * @member {string} startDate
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.startDate = "";

        /**
         * Prospect orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.orderId = "";

        /**
         * Prospect isManualProcessing.
         * @member {boolean} isManualProcessing
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.isManualProcessing = false;

        /**
         * Prospect address.
         * @member {sso.IAddress|null|undefined} address
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.address = null;

        /**
         * Prospect regionId.
         * @member {number} regionId
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.regionId = 0;

        /**
         * Prospect promocode.
         * @member {string} promocode
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.promocode = "";

        /**
         * Prospect utmCampaign.
         * @member {string} utmCampaign
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.utmCampaign = "";

        /**
         * Prospect utmContent.
         * @member {string} utmContent
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.utmContent = "";

        /**
         * Prospect utmData.
         * @member {string} utmData
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.utmData = "";

        /**
         * Prospect utmMedium.
         * @member {string} utmMedium
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.utmMedium = "";

        /**
         * Prospect utmSource.
         * @member {string} utmSource
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.utmSource = "";

        /**
         * Prospect utmTerm.
         * @member {string} utmTerm
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.utmTerm = "";

        /**
         * Prospect serviceType.
         * @member {string} serviceType
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.serviceType = "";

        /**
         * Prospect paymentType.
         * @member {string} paymentType
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.paymentType = "";

        /**
         * Prospect regularity.
         * @member {string} regularity
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.regularity = "";

        /**
         * Prospect cardNumber.
         * @member {string} cardNumber
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.cardNumber = "";

        /**
         * Prospect qleanClientId.
         * @member {string} qleanClientId
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.qleanClientId = "";

        /**
         * Prospect qleanOrderId.
         * @member {string} qleanOrderId
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.qleanOrderId = "";

        /**
         * Prospect isOvertime.
         * @member {boolean} isOvertime
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.isOvertime = false;

        /**
         * Prospect totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.totalPrice = 0;

        /**
         * Prospect totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.totalDuration = 0;

        /**
         * Prospect commentForExecutor.
         * @member {string} commentForExecutor
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.commentForExecutor = "";

        /**
         * Prospect commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.commentForSupport = "";

        /**
         * Prospect commentFromCustomer.
         * @member {string} commentFromCustomer
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.commentFromCustomer = "";

        /**
         * Prospect createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.createdAt = "";

        /**
         * Prospect updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.updatedAt = "";

        /**
         * Prospect discount.
         * @member {number} discount
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.discount = 0;

        /**
         * Prospect keysDelivery.
         * @member {sso.IAddress|null|undefined} keysDelivery
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.keysDelivery = null;

        /**
         * Prospect keysPickup.
         * @member {sso.IAddress|null|undefined} keysPickup
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.keysPickup = null;

        /**
         * Prospect externalId.
         * @member {string} externalId
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.externalId = "";

        /**
         * Prospect adminPanelLink.
         * @member {string} adminPanelLink
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.adminPanelLink = "";

        /**
         * Prospect qleanAddress.
         * @member {string} qleanAddress
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.qleanAddress = "";

        /**
         * Prospect drycleaningMeta.
         * @member {PlatformCRMWeb.IDrycleaningMeta|null|undefined} drycleaningMeta
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.drycleaningMeta = null;

        /**
         * Prospect taskId.
         * @member {string} taskId
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.taskId = "";

        /**
         * Prospect paymentTypeMeta.
         * @member {string} paymentTypeMeta
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.paymentTypeMeta = "";

        /**
         * Prospect numberOfPayments.
         * @member {number} numberOfPayments
         * @memberof PlatformCRMWeb.Prospect
         * @instance
         */
        Prospect.prototype.numberOfPayments = 0;

        /**
         * Encodes the specified Prospect message. Does not implicitly {@link PlatformCRMWeb.Prospect.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Prospect
         * @static
         * @param {PlatformCRMWeb.IProspect} message Prospect message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Prospect.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.prospectNumber != null && Object.hasOwnProperty.call(message, "prospectNumber"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.prospectNumber);
            if (message.publicId != null && Object.hasOwnProperty.call(message, "publicId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.publicId);
            if (message.isShortForm != null && Object.hasOwnProperty.call(message, "isShortForm"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isShortForm);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.phone);
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                $root.PlatformCRMWeb.FIO.encode(message.fio, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.email);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                $root.PlatformCRMWeb.CalculatePrice.encode(message.price, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.status);
            if (message.usp != null && Object.hasOwnProperty.call(message, "usp"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.usp);
            if (message.strategy != null && Object.hasOwnProperty.call(message, "strategy"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.strategy);
            if (message.cancelReason != null && Object.hasOwnProperty.call(message, "cancelReason"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.cancelReason);
            if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.platform);
            if (message.person != null && Object.hasOwnProperty.call(message, "person"))
                $root.PlatformCRMWeb.Person.encode(message.person, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.startDate);
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.orderId);
            if (message.isManualProcessing != null && Object.hasOwnProperty.call(message, "isManualProcessing"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.isManualProcessing);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.sso.Address.encode(message.address, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 20, wireType 0 =*/160).int32(message.regionId);
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.promocode);
            if (message.utmCampaign != null && Object.hasOwnProperty.call(message, "utmCampaign"))
                writer.uint32(/* id 22, wireType 2 =*/178).string(message.utmCampaign);
            if (message.utmContent != null && Object.hasOwnProperty.call(message, "utmContent"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.utmContent);
            if (message.utmData != null && Object.hasOwnProperty.call(message, "utmData"))
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.utmData);
            if (message.utmMedium != null && Object.hasOwnProperty.call(message, "utmMedium"))
                writer.uint32(/* id 25, wireType 2 =*/202).string(message.utmMedium);
            if (message.utmSource != null && Object.hasOwnProperty.call(message, "utmSource"))
                writer.uint32(/* id 26, wireType 2 =*/210).string(message.utmSource);
            if (message.utmTerm != null && Object.hasOwnProperty.call(message, "utmTerm"))
                writer.uint32(/* id 27, wireType 2 =*/218).string(message.utmTerm);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 28, wireType 2 =*/226).string(message.serviceType);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 29, wireType 2 =*/234).string(message.paymentType);
            if (message.regularity != null && Object.hasOwnProperty.call(message, "regularity"))
                writer.uint32(/* id 30, wireType 2 =*/242).string(message.regularity);
            if (message.cardNumber != null && Object.hasOwnProperty.call(message, "cardNumber"))
                writer.uint32(/* id 31, wireType 2 =*/250).string(message.cardNumber);
            if (message.qleanClientId != null && Object.hasOwnProperty.call(message, "qleanClientId"))
                writer.uint32(/* id 32, wireType 2 =*/258).string(message.qleanClientId);
            if (message.qleanOrderId != null && Object.hasOwnProperty.call(message, "qleanOrderId"))
                writer.uint32(/* id 33, wireType 2 =*/266).string(message.qleanOrderId);
            if (message.isOvertime != null && Object.hasOwnProperty.call(message, "isOvertime"))
                writer.uint32(/* id 34, wireType 0 =*/272).bool(message.isOvertime);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 35, wireType 0 =*/280).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 36, wireType 0 =*/288).int32(message.totalDuration);
            if (message.commentForExecutor != null && Object.hasOwnProperty.call(message, "commentForExecutor"))
                writer.uint32(/* id 37, wireType 2 =*/298).string(message.commentForExecutor);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 38, wireType 2 =*/306).string(message.commentForSupport);
            if (message.commentFromCustomer != null && Object.hasOwnProperty.call(message, "commentFromCustomer"))
                writer.uint32(/* id 39, wireType 2 =*/314).string(message.commentFromCustomer);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 40, wireType 2 =*/322).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 41, wireType 2 =*/330).string(message.updatedAt);
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 42, wireType 0 =*/336).int32(message.discount);
            if (message.keysDelivery != null && Object.hasOwnProperty.call(message, "keysDelivery"))
                $root.sso.Address.encode(message.keysDelivery, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
            if (message.keysPickup != null && Object.hasOwnProperty.call(message, "keysPickup"))
                $root.sso.Address.encode(message.keysPickup, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
            if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
                writer.uint32(/* id 45, wireType 2 =*/362).string(message.externalId);
            if (message.adminPanelLink != null && Object.hasOwnProperty.call(message, "adminPanelLink"))
                writer.uint32(/* id 46, wireType 2 =*/370).string(message.adminPanelLink);
            if (message.qleanAddress != null && Object.hasOwnProperty.call(message, "qleanAddress"))
                writer.uint32(/* id 47, wireType 2 =*/378).string(message.qleanAddress);
            if (message.drycleaningMeta != null && Object.hasOwnProperty.call(message, "drycleaningMeta"))
                $root.PlatformCRMWeb.DrycleaningMeta.encode(message.drycleaningMeta, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 49, wireType 2 =*/394).string(message.taskId);
            if (message.paymentTypeMeta != null && Object.hasOwnProperty.call(message, "paymentTypeMeta"))
                writer.uint32(/* id 50, wireType 2 =*/402).string(message.paymentTypeMeta);
            if (message.numberOfPayments != null && Object.hasOwnProperty.call(message, "numberOfPayments"))
                writer.uint32(/* id 51, wireType 0 =*/408).int32(message.numberOfPayments);
            return writer;
        };

        /**
         * Decodes a Prospect message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Prospect
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Prospect} Prospect
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Prospect.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Prospect();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.prospectNumber = reader.int32();
                    break;
                case 3:
                    message.publicId = reader.string();
                    break;
                case 4:
                    message.isShortForm = reader.bool();
                    break;
                case 5:
                    message.phone = reader.string();
                    break;
                case 6:
                    message.fio = $root.PlatformCRMWeb.FIO.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.email = reader.string();
                    break;
                case 8:
                    message.price = $root.PlatformCRMWeb.CalculatePrice.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.status = reader.string();
                    break;
                case 10:
                    message.usp = reader.string();
                    break;
                case 11:
                    message.strategy = reader.string();
                    break;
                case 12:
                    message.cancelReason = reader.string();
                    break;
                case 13:
                    message.platform = reader.string();
                    break;
                case 15:
                    message.person = $root.PlatformCRMWeb.Person.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.startDate = reader.string();
                    break;
                case 17:
                    message.orderId = reader.string();
                    break;
                case 18:
                    message.isManualProcessing = reader.bool();
                    break;
                case 19:
                    message.address = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.regionId = reader.int32();
                    break;
                case 21:
                    message.promocode = reader.string();
                    break;
                case 22:
                    message.utmCampaign = reader.string();
                    break;
                case 23:
                    message.utmContent = reader.string();
                    break;
                case 24:
                    message.utmData = reader.string();
                    break;
                case 25:
                    message.utmMedium = reader.string();
                    break;
                case 26:
                    message.utmSource = reader.string();
                    break;
                case 27:
                    message.utmTerm = reader.string();
                    break;
                case 28:
                    message.serviceType = reader.string();
                    break;
                case 29:
                    message.paymentType = reader.string();
                    break;
                case 30:
                    message.regularity = reader.string();
                    break;
                case 31:
                    message.cardNumber = reader.string();
                    break;
                case 32:
                    message.qleanClientId = reader.string();
                    break;
                case 33:
                    message.qleanOrderId = reader.string();
                    break;
                case 34:
                    message.isOvertime = reader.bool();
                    break;
                case 35:
                    message.totalPrice = reader.int32();
                    break;
                case 36:
                    message.totalDuration = reader.int32();
                    break;
                case 37:
                    message.commentForExecutor = reader.string();
                    break;
                case 38:
                    message.commentForSupport = reader.string();
                    break;
                case 39:
                    message.commentFromCustomer = reader.string();
                    break;
                case 40:
                    message.createdAt = reader.string();
                    break;
                case 41:
                    message.updatedAt = reader.string();
                    break;
                case 42:
                    message.discount = reader.int32();
                    break;
                case 43:
                    message.keysDelivery = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 44:
                    message.keysPickup = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 45:
                    message.externalId = reader.string();
                    break;
                case 46:
                    message.adminPanelLink = reader.string();
                    break;
                case 47:
                    message.qleanAddress = reader.string();
                    break;
                case 48:
                    message.drycleaningMeta = $root.PlatformCRMWeb.DrycleaningMeta.decode(reader, reader.uint32());
                    break;
                case 49:
                    message.taskId = reader.string();
                    break;
                case 50:
                    message.paymentTypeMeta = reader.string();
                    break;
                case 51:
                    message.numberOfPayments = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Prospect;
    })();

    PlatformCRMWeb.GetProspectByIdRequest = (function() {

        /**
         * Properties of a GetProspectByIdRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetProspectByIdRequest
         * @property {string|null} [id] GetProspectByIdRequest id
         */

        /**
         * Constructs a new GetProspectByIdRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetProspectByIdRequest.
         * @implements IGetProspectByIdRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetProspectByIdRequest=} [properties] Properties to set
         */
        function GetProspectByIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProspectByIdRequest id.
         * @member {string} id
         * @memberof PlatformCRMWeb.GetProspectByIdRequest
         * @instance
         */
        GetProspectByIdRequest.prototype.id = "";

        /**
         * Encodes the specified GetProspectByIdRequest message. Does not implicitly {@link PlatformCRMWeb.GetProspectByIdRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetProspectByIdRequest
         * @static
         * @param {PlatformCRMWeb.IGetProspectByIdRequest} message GetProspectByIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProspectByIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a GetProspectByIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetProspectByIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetProspectByIdRequest} GetProspectByIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProspectByIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetProspectByIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetProspectByIdRequest;
    })();

    PlatformCRMWeb.UpsertProspectRequest = (function() {

        /**
         * Properties of an UpsertProspectRequest.
         * @memberof PlatformCRMWeb
         * @interface IUpsertProspectRequest
         * @property {string|null} [publicId] UpsertProspectRequest publicId
         * @property {boolean|null} [isShortForm] UpsertProspectRequest isShortForm
         * @property {string|null} [phone] UpsertProspectRequest phone
         * @property {string|null} [name] UpsertProspectRequest name
         * @property {string|null} [email] UpsertProspectRequest email
         * @property {string|null} [cancelReason] UpsertProspectRequest cancelReason
         * @property {string|null} [platform] UpsertProspectRequest platform
         * @property {string|null} [startDate] UpsertProspectRequest startDate
         * @property {sso.IAddress|null} [address] UpsertProspectRequest address
         * @property {number|null} [regionId] UpsertProspectRequest regionId
         * @property {string|null} [promocode] UpsertProspectRequest promocode
         * @property {string|null} [utmCampaign] UpsertProspectRequest utmCampaign
         * @property {string|null} [utmContent] UpsertProspectRequest utmContent
         * @property {string|null} [utmData] UpsertProspectRequest utmData
         * @property {string|null} [utmMedium] UpsertProspectRequest utmMedium
         * @property {string|null} [utmSource] UpsertProspectRequest utmSource
         * @property {string|null} [utmTerm] UpsertProspectRequest utmTerm
         * @property {string|null} [serviceType] UpsertProspectRequest serviceType
         * @property {string|null} [paymentType] UpsertProspectRequest paymentType
         * @property {string|null} [regularity] UpsertProspectRequest regularity
         * @property {string|null} [cardNumber] UpsertProspectRequest cardNumber
         * @property {string|null} [commentForExecutor] UpsertProspectRequest commentForExecutor
         * @property {string|null} [commentForSupport] UpsertProspectRequest commentForSupport
         * @property {string|null} [id] UpsertProspectRequest id
         * @property {Array.<PlatformCRMWeb.IProduct>|null} [products] UpsertProspectRequest products
         * @property {number|null} [discount] UpsertProspectRequest discount
         * @property {sso.IAddress|null} [keysPickup] UpsertProspectRequest keysPickup
         * @property {sso.IAddress|null} [keysDelivery] UpsertProspectRequest keysDelivery
         * @property {string|null} [externalId] UpsertProspectRequest externalId
         * @property {PlatformCRMWeb.IDrycleaningMeta|null} [drycleaningMeta] UpsertProspectRequest drycleaningMeta
         * @property {PlatformCRMWeb.IFIO|null} [fio] UpsertProspectRequest fio
         * @property {string|null} [paymentTypeMeta] UpsertProspectRequest paymentTypeMeta
         * @property {number|null} [numberOfPayments] UpsertProspectRequest numberOfPayments
         */

        /**
         * Constructs a new UpsertProspectRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an UpsertProspectRequest.
         * @implements IUpsertProspectRequest
         * @constructor
         * @param {PlatformCRMWeb.IUpsertProspectRequest=} [properties] Properties to set
         */
        function UpsertProspectRequest(properties) {
            this.products = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertProspectRequest publicId.
         * @member {string} publicId
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.publicId = "";

        /**
         * UpsertProspectRequest isShortForm.
         * @member {boolean} isShortForm
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.isShortForm = false;

        /**
         * UpsertProspectRequest phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.phone = "";

        /**
         * UpsertProspectRequest name.
         * @member {string} name
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.name = "";

        /**
         * UpsertProspectRequest email.
         * @member {string} email
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.email = "";

        /**
         * UpsertProspectRequest cancelReason.
         * @member {string} cancelReason
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.cancelReason = "";

        /**
         * UpsertProspectRequest platform.
         * @member {string} platform
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.platform = "";

        /**
         * UpsertProspectRequest startDate.
         * @member {string} startDate
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.startDate = "";

        /**
         * UpsertProspectRequest address.
         * @member {sso.IAddress|null|undefined} address
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.address = null;

        /**
         * UpsertProspectRequest regionId.
         * @member {number} regionId
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.regionId = 0;

        /**
         * UpsertProspectRequest promocode.
         * @member {string} promocode
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.promocode = "";

        /**
         * UpsertProspectRequest utmCampaign.
         * @member {string} utmCampaign
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.utmCampaign = "";

        /**
         * UpsertProspectRequest utmContent.
         * @member {string} utmContent
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.utmContent = "";

        /**
         * UpsertProspectRequest utmData.
         * @member {string} utmData
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.utmData = "";

        /**
         * UpsertProspectRequest utmMedium.
         * @member {string} utmMedium
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.utmMedium = "";

        /**
         * UpsertProspectRequest utmSource.
         * @member {string} utmSource
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.utmSource = "";

        /**
         * UpsertProspectRequest utmTerm.
         * @member {string} utmTerm
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.utmTerm = "";

        /**
         * UpsertProspectRequest serviceType.
         * @member {string} serviceType
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.serviceType = "";

        /**
         * UpsertProspectRequest paymentType.
         * @member {string} paymentType
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.paymentType = "";

        /**
         * UpsertProspectRequest regularity.
         * @member {string} regularity
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.regularity = "";

        /**
         * UpsertProspectRequest cardNumber.
         * @member {string} cardNumber
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.cardNumber = "";

        /**
         * UpsertProspectRequest commentForExecutor.
         * @member {string} commentForExecutor
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.commentForExecutor = "";

        /**
         * UpsertProspectRequest commentForSupport.
         * @member {string} commentForSupport
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.commentForSupport = "";

        /**
         * UpsertProspectRequest id.
         * @member {string} id
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.id = "";

        /**
         * UpsertProspectRequest products.
         * @member {Array.<PlatformCRMWeb.IProduct>} products
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.products = $util.emptyArray;

        /**
         * UpsertProspectRequest discount.
         * @member {number} discount
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.discount = 0;

        /**
         * UpsertProspectRequest keysPickup.
         * @member {sso.IAddress|null|undefined} keysPickup
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.keysPickup = null;

        /**
         * UpsertProspectRequest keysDelivery.
         * @member {sso.IAddress|null|undefined} keysDelivery
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.keysDelivery = null;

        /**
         * UpsertProspectRequest externalId.
         * @member {string} externalId
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.externalId = "";

        /**
         * UpsertProspectRequest drycleaningMeta.
         * @member {PlatformCRMWeb.IDrycleaningMeta|null|undefined} drycleaningMeta
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.drycleaningMeta = null;

        /**
         * UpsertProspectRequest fio.
         * @member {PlatformCRMWeb.IFIO|null|undefined} fio
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.fio = null;

        /**
         * UpsertProspectRequest paymentTypeMeta.
         * @member {string} paymentTypeMeta
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.paymentTypeMeta = "";

        /**
         * UpsertProspectRequest numberOfPayments.
         * @member {number} numberOfPayments
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @instance
         */
        UpsertProspectRequest.prototype.numberOfPayments = 0;

        /**
         * Encodes the specified UpsertProspectRequest message. Does not implicitly {@link PlatformCRMWeb.UpsertProspectRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @static
         * @param {PlatformCRMWeb.IUpsertProspectRequest} message UpsertProspectRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProspectRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.publicId != null && Object.hasOwnProperty.call(message, "publicId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.publicId);
            if (message.isShortForm != null && Object.hasOwnProperty.call(message, "isShortForm"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isShortForm);
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.email);
            if (message.cancelReason != null && Object.hasOwnProperty.call(message, "cancelReason"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.cancelReason);
            if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.platform);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.startDate);
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                $root.sso.Address.encode(message.address, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.regionId);
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.promocode);
            if (message.utmCampaign != null && Object.hasOwnProperty.call(message, "utmCampaign"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.utmCampaign);
            if (message.utmContent != null && Object.hasOwnProperty.call(message, "utmContent"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.utmContent);
            if (message.utmData != null && Object.hasOwnProperty.call(message, "utmData"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.utmData);
            if (message.utmMedium != null && Object.hasOwnProperty.call(message, "utmMedium"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.utmMedium);
            if (message.utmSource != null && Object.hasOwnProperty.call(message, "utmSource"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.utmSource);
            if (message.utmTerm != null && Object.hasOwnProperty.call(message, "utmTerm"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.utmTerm);
            if (message.serviceType != null && Object.hasOwnProperty.call(message, "serviceType"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.serviceType);
            if (message.paymentType != null && Object.hasOwnProperty.call(message, "paymentType"))
                writer.uint32(/* id 19, wireType 2 =*/154).string(message.paymentType);
            if (message.regularity != null && Object.hasOwnProperty.call(message, "regularity"))
                writer.uint32(/* id 20, wireType 2 =*/162).string(message.regularity);
            if (message.cardNumber != null && Object.hasOwnProperty.call(message, "cardNumber"))
                writer.uint32(/* id 21, wireType 2 =*/170).string(message.cardNumber);
            if (message.commentForExecutor != null && Object.hasOwnProperty.call(message, "commentForExecutor"))
                writer.uint32(/* id 22, wireType 2 =*/178).string(message.commentForExecutor);
            if (message.commentForSupport != null && Object.hasOwnProperty.call(message, "commentForSupport"))
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.commentForSupport);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 24, wireType 2 =*/194).string(message.id);
            if (message.products != null && message.products.length)
                for (var i = 0; i < message.products.length; ++i)
                    $root.PlatformCRMWeb.Product.encode(message.products[i], writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                writer.uint32(/* id 26, wireType 0 =*/208).int32(message.discount);
            if (message.keysPickup != null && Object.hasOwnProperty.call(message, "keysPickup"))
                $root.sso.Address.encode(message.keysPickup, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.keysDelivery != null && Object.hasOwnProperty.call(message, "keysDelivery"))
                $root.sso.Address.encode(message.keysDelivery, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.externalId != null && Object.hasOwnProperty.call(message, "externalId"))
                writer.uint32(/* id 29, wireType 2 =*/234).string(message.externalId);
            if (message.drycleaningMeta != null && Object.hasOwnProperty.call(message, "drycleaningMeta"))
                $root.PlatformCRMWeb.DrycleaningMeta.encode(message.drycleaningMeta, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.fio != null && Object.hasOwnProperty.call(message, "fio"))
                $root.PlatformCRMWeb.FIO.encode(message.fio, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.paymentTypeMeta != null && Object.hasOwnProperty.call(message, "paymentTypeMeta"))
                writer.uint32(/* id 32, wireType 2 =*/258).string(message.paymentTypeMeta);
            if (message.numberOfPayments != null && Object.hasOwnProperty.call(message, "numberOfPayments"))
                writer.uint32(/* id 33, wireType 0 =*/264).int32(message.numberOfPayments);
            return writer;
        };

        /**
         * Decodes an UpsertProspectRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UpsertProspectRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UpsertProspectRequest} UpsertProspectRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProspectRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UpsertProspectRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.publicId = reader.string();
                    break;
                case 2:
                    message.isShortForm = reader.bool();
                    break;
                case 3:
                    message.phone = reader.string();
                    break;
                case 4:
                    message.name = reader.string();
                    break;
                case 5:
                    message.email = reader.string();
                    break;
                case 6:
                    message.cancelReason = reader.string();
                    break;
                case 7:
                    message.platform = reader.string();
                    break;
                case 8:
                    message.startDate = reader.string();
                    break;
                case 9:
                    message.address = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.regionId = reader.int32();
                    break;
                case 11:
                    message.promocode = reader.string();
                    break;
                case 12:
                    message.utmCampaign = reader.string();
                    break;
                case 13:
                    message.utmContent = reader.string();
                    break;
                case 14:
                    message.utmData = reader.string();
                    break;
                case 15:
                    message.utmMedium = reader.string();
                    break;
                case 16:
                    message.utmSource = reader.string();
                    break;
                case 17:
                    message.utmTerm = reader.string();
                    break;
                case 18:
                    message.serviceType = reader.string();
                    break;
                case 19:
                    message.paymentType = reader.string();
                    break;
                case 20:
                    message.regularity = reader.string();
                    break;
                case 21:
                    message.cardNumber = reader.string();
                    break;
                case 22:
                    message.commentForExecutor = reader.string();
                    break;
                case 23:
                    message.commentForSupport = reader.string();
                    break;
                case 24:
                    message.id = reader.string();
                    break;
                case 25:
                    if (!(message.products && message.products.length))
                        message.products = [];
                    message.products.push($root.PlatformCRMWeb.Product.decode(reader, reader.uint32()));
                    break;
                case 26:
                    message.discount = reader.int32();
                    break;
                case 27:
                    message.keysPickup = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.keysDelivery = $root.sso.Address.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.externalId = reader.string();
                    break;
                case 30:
                    message.drycleaningMeta = $root.PlatformCRMWeb.DrycleaningMeta.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.fio = $root.PlatformCRMWeb.FIO.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.paymentTypeMeta = reader.string();
                    break;
                case 33:
                    message.numberOfPayments = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpsertProspectRequest;
    })();

    PlatformCRMWeb.DrycleaningMeta = (function() {

        /**
         * Properties of a DrycleaningMeta.
         * @memberof PlatformCRMWeb
         * @interface IDrycleaningMeta
         * @property {string|null} [id] DrycleaningMeta id
         * @property {PlatformCRMWeb.DrycleaningMeta.IDrycleaningTimeslot|null} [returnTimeslot] DrycleaningMeta returnTimeslot
         * @property {PlatformCRMWeb.DrycleaningMeta.IDrycleaningTimeslot|null} [pickupTimeslot] DrycleaningMeta pickupTimeslot
         * @property {string|null} [creditCardId] DrycleaningMeta creditCardId
         * @property {string|null} [planId] DrycleaningMeta planId
         */

        /**
         * Constructs a new DrycleaningMeta.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a DrycleaningMeta.
         * @implements IDrycleaningMeta
         * @constructor
         * @param {PlatformCRMWeb.IDrycleaningMeta=} [properties] Properties to set
         */
        function DrycleaningMeta(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DrycleaningMeta id.
         * @member {string} id
         * @memberof PlatformCRMWeb.DrycleaningMeta
         * @instance
         */
        DrycleaningMeta.prototype.id = "";

        /**
         * DrycleaningMeta returnTimeslot.
         * @member {PlatformCRMWeb.DrycleaningMeta.IDrycleaningTimeslot|null|undefined} returnTimeslot
         * @memberof PlatformCRMWeb.DrycleaningMeta
         * @instance
         */
        DrycleaningMeta.prototype.returnTimeslot = null;

        /**
         * DrycleaningMeta pickupTimeslot.
         * @member {PlatformCRMWeb.DrycleaningMeta.IDrycleaningTimeslot|null|undefined} pickupTimeslot
         * @memberof PlatformCRMWeb.DrycleaningMeta
         * @instance
         */
        DrycleaningMeta.prototype.pickupTimeslot = null;

        /**
         * DrycleaningMeta creditCardId.
         * @member {string} creditCardId
         * @memberof PlatformCRMWeb.DrycleaningMeta
         * @instance
         */
        DrycleaningMeta.prototype.creditCardId = "";

        /**
         * DrycleaningMeta planId.
         * @member {string} planId
         * @memberof PlatformCRMWeb.DrycleaningMeta
         * @instance
         */
        DrycleaningMeta.prototype.planId = "";

        /**
         * Encodes the specified DrycleaningMeta message. Does not implicitly {@link PlatformCRMWeb.DrycleaningMeta.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.DrycleaningMeta
         * @static
         * @param {PlatformCRMWeb.IDrycleaningMeta} message DrycleaningMeta message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrycleaningMeta.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.returnTimeslot != null && Object.hasOwnProperty.call(message, "returnTimeslot"))
                $root.PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot.encode(message.returnTimeslot, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.pickupTimeslot != null && Object.hasOwnProperty.call(message, "pickupTimeslot"))
                $root.PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot.encode(message.pickupTimeslot, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.creditCardId != null && Object.hasOwnProperty.call(message, "creditCardId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.creditCardId);
            if (message.planId != null && Object.hasOwnProperty.call(message, "planId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.planId);
            return writer;
        };

        /**
         * Decodes a DrycleaningMeta message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.DrycleaningMeta
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.DrycleaningMeta} DrycleaningMeta
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrycleaningMeta.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.DrycleaningMeta();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.returnTimeslot = $root.PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.pickupTimeslot = $root.PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.creditCardId = reader.string();
                    break;
                case 5:
                    message.planId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        DrycleaningMeta.DrycleaningTimeslot = (function() {

            /**
             * Properties of a DrycleaningTimeslot.
             * @memberof PlatformCRMWeb.DrycleaningMeta
             * @interface IDrycleaningTimeslot
             * @property {string|null} [id] DrycleaningTimeslot id
             * @property {string|null} [date] DrycleaningTimeslot date
             * @property {string|null} [startAt] DrycleaningTimeslot startAt
             * @property {number|null} [duration] DrycleaningTimeslot duration
             * @property {string|null} [timeRange] DrycleaningTimeslot timeRange
             * @property {number|null} [price] DrycleaningTimeslot price
             */

            /**
             * Constructs a new DrycleaningTimeslot.
             * @memberof PlatformCRMWeb.DrycleaningMeta
             * @classdesc Represents a DrycleaningTimeslot.
             * @implements IDrycleaningTimeslot
             * @constructor
             * @param {PlatformCRMWeb.DrycleaningMeta.IDrycleaningTimeslot=} [properties] Properties to set
             */
            function DrycleaningTimeslot(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DrycleaningTimeslot id.
             * @member {string} id
             * @memberof PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot
             * @instance
             */
            DrycleaningTimeslot.prototype.id = "";

            /**
             * DrycleaningTimeslot date.
             * @member {string} date
             * @memberof PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot
             * @instance
             */
            DrycleaningTimeslot.prototype.date = "";

            /**
             * DrycleaningTimeslot startAt.
             * @member {string} startAt
             * @memberof PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot
             * @instance
             */
            DrycleaningTimeslot.prototype.startAt = "";

            /**
             * DrycleaningTimeslot duration.
             * @member {number} duration
             * @memberof PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot
             * @instance
             */
            DrycleaningTimeslot.prototype.duration = 0;

            /**
             * DrycleaningTimeslot timeRange.
             * @member {string} timeRange
             * @memberof PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot
             * @instance
             */
            DrycleaningTimeslot.prototype.timeRange = "";

            /**
             * DrycleaningTimeslot price.
             * @member {number} price
             * @memberof PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot
             * @instance
             */
            DrycleaningTimeslot.prototype.price = 0;

            /**
             * Encodes the specified DrycleaningTimeslot message. Does not implicitly {@link PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot.verify|verify} messages.
             * @function encode
             * @memberof PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot
             * @static
             * @param {PlatformCRMWeb.DrycleaningMeta.IDrycleaningTimeslot} message DrycleaningTimeslot message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DrycleaningTimeslot.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.date);
                if (message.startAt != null && Object.hasOwnProperty.call(message, "startAt"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.startAt);
                if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.duration);
                if (message.timeRange != null && Object.hasOwnProperty.call(message, "timeRange"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.timeRange);
                if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                    writer.uint32(/* id 6, wireType 1 =*/49).double(message.price);
                return writer;
            };

            /**
             * Decodes a DrycleaningTimeslot message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot} DrycleaningTimeslot
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DrycleaningTimeslot.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.DrycleaningMeta.DrycleaningTimeslot();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        message.date = reader.string();
                        break;
                    case 3:
                        message.startAt = reader.string();
                        break;
                    case 4:
                        message.duration = reader.int32();
                        break;
                    case 5:
                        message.timeRange = reader.string();
                        break;
                    case 6:
                        message.price = reader.double();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return DrycleaningTimeslot;
        })();

        return DrycleaningMeta;
    })();

    PlatformCRMWeb.GetProspectByIdResponse = (function() {

        /**
         * Properties of a GetProspectByIdResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetProspectByIdResponse
         * @property {PlatformCRMWeb.IProspect|null} [prospect] GetProspectByIdResponse prospect
         */

        /**
         * Constructs a new GetProspectByIdResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetProspectByIdResponse.
         * @implements IGetProspectByIdResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetProspectByIdResponse=} [properties] Properties to set
         */
        function GetProspectByIdResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetProspectByIdResponse prospect.
         * @member {PlatformCRMWeb.IProspect|null|undefined} prospect
         * @memberof PlatformCRMWeb.GetProspectByIdResponse
         * @instance
         */
        GetProspectByIdResponse.prototype.prospect = null;

        /**
         * Encodes the specified GetProspectByIdResponse message. Does not implicitly {@link PlatformCRMWeb.GetProspectByIdResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetProspectByIdResponse
         * @static
         * @param {PlatformCRMWeb.IGetProspectByIdResponse} message GetProspectByIdResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetProspectByIdResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.prospect != null && Object.hasOwnProperty.call(message, "prospect"))
                $root.PlatformCRMWeb.Prospect.encode(message.prospect, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetProspectByIdResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetProspectByIdResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetProspectByIdResponse} GetProspectByIdResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetProspectByIdResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetProspectByIdResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.prospect = $root.PlatformCRMWeb.Prospect.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetProspectByIdResponse;
    })();

    PlatformCRMWeb.UpsertProspectResponse = (function() {

        /**
         * Properties of an UpsertProspectResponse.
         * @memberof PlatformCRMWeb
         * @interface IUpsertProspectResponse
         * @property {string|null} [customerId] UpsertProspectResponse customerId
         * @property {number|null} [totalPrice] UpsertProspectResponse totalPrice
         * @property {number|null} [totalDuration] UpsertProspectResponse totalDuration
         * @property {boolean|null} [isOvertime] UpsertProspectResponse isOvertime
         * @property {PlatformCRMWeb.ICalculatePrice|null} [price] UpsertProspectResponse price
         * @property {string|null} [qleanAddress] UpsertProspectResponse qleanAddress
         * @property {string|null} [qleanOrderId] UpsertProspectResponse qleanOrderId
         * @property {Array.<PlatformCRMWeb.IError>|null} [errors] UpsertProspectResponse errors
         */

        /**
         * Constructs a new UpsertProspectResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an UpsertProspectResponse.
         * @implements IUpsertProspectResponse
         * @constructor
         * @param {PlatformCRMWeb.IUpsertProspectResponse=} [properties] Properties to set
         */
        function UpsertProspectResponse(properties) {
            this.errors = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpsertProspectResponse customerId.
         * @member {string} customerId
         * @memberof PlatformCRMWeb.UpsertProspectResponse
         * @instance
         */
        UpsertProspectResponse.prototype.customerId = "";

        /**
         * UpsertProspectResponse totalPrice.
         * @member {number} totalPrice
         * @memberof PlatformCRMWeb.UpsertProspectResponse
         * @instance
         */
        UpsertProspectResponse.prototype.totalPrice = 0;

        /**
         * UpsertProspectResponse totalDuration.
         * @member {number} totalDuration
         * @memberof PlatformCRMWeb.UpsertProspectResponse
         * @instance
         */
        UpsertProspectResponse.prototype.totalDuration = 0;

        /**
         * UpsertProspectResponse isOvertime.
         * @member {boolean} isOvertime
         * @memberof PlatformCRMWeb.UpsertProspectResponse
         * @instance
         */
        UpsertProspectResponse.prototype.isOvertime = false;

        /**
         * UpsertProspectResponse price.
         * @member {PlatformCRMWeb.ICalculatePrice|null|undefined} price
         * @memberof PlatformCRMWeb.UpsertProspectResponse
         * @instance
         */
        UpsertProspectResponse.prototype.price = null;

        /**
         * UpsertProspectResponse qleanAddress.
         * @member {string} qleanAddress
         * @memberof PlatformCRMWeb.UpsertProspectResponse
         * @instance
         */
        UpsertProspectResponse.prototype.qleanAddress = "";

        /**
         * UpsertProspectResponse qleanOrderId.
         * @member {string} qleanOrderId
         * @memberof PlatformCRMWeb.UpsertProspectResponse
         * @instance
         */
        UpsertProspectResponse.prototype.qleanOrderId = "";

        /**
         * UpsertProspectResponse errors.
         * @member {Array.<PlatformCRMWeb.IError>} errors
         * @memberof PlatformCRMWeb.UpsertProspectResponse
         * @instance
         */
        UpsertProspectResponse.prototype.errors = $util.emptyArray;

        /**
         * Encodes the specified UpsertProspectResponse message. Does not implicitly {@link PlatformCRMWeb.UpsertProspectResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UpsertProspectResponse
         * @static
         * @param {PlatformCRMWeb.IUpsertProspectResponse} message UpsertProspectResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpsertProspectResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.customerId != null && Object.hasOwnProperty.call(message, "customerId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerId);
            if (message.totalPrice != null && Object.hasOwnProperty.call(message, "totalPrice"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.totalPrice);
            if (message.totalDuration != null && Object.hasOwnProperty.call(message, "totalDuration"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.totalDuration);
            if (message.isOvertime != null && Object.hasOwnProperty.call(message, "isOvertime"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isOvertime);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                $root.PlatformCRMWeb.CalculatePrice.encode(message.price, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.qleanAddress != null && Object.hasOwnProperty.call(message, "qleanAddress"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.qleanAddress);
            if (message.qleanOrderId != null && Object.hasOwnProperty.call(message, "qleanOrderId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.qleanOrderId);
            if (message.errors != null && message.errors.length)
                for (var i = 0; i < message.errors.length; ++i)
                    $root.PlatformCRMWeb.Error.encode(message.errors[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes an UpsertProspectResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UpsertProspectResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UpsertProspectResponse} UpsertProspectResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpsertProspectResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UpsertProspectResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.customerId = reader.string();
                    break;
                case 2:
                    message.totalPrice = reader.int32();
                    break;
                case 3:
                    message.totalDuration = reader.int32();
                    break;
                case 4:
                    message.isOvertime = reader.bool();
                    break;
                case 5:
                    message.price = $root.PlatformCRMWeb.CalculatePrice.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.qleanAddress = reader.string();
                    break;
                case 7:
                    message.qleanOrderId = reader.string();
                    break;
                case 8:
                    if (!(message.errors && message.errors.length))
                        message.errors = [];
                    message.errors.push($root.PlatformCRMWeb.Error.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UpsertProspectResponse;
    })();

    PlatformCRMWeb.ProspectService = (function() {

        /**
         * Constructs a new ProspectService service.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ProspectService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function ProspectService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (ProspectService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ProspectService;

        /**
         * Callback as used by {@link PlatformCRMWeb.ProspectService#getProspectById}.
         * @memberof PlatformCRMWeb.ProspectService
         * @typedef GetProspectByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetProspectByIdResponse} [response] GetProspectByIdResponse
         */

        /**
         * Calls GetProspectById.
         * @function getProspectById
         * @memberof PlatformCRMWeb.ProspectService
         * @instance
         * @param {PlatformCRMWeb.IGetProspectByIdRequest} request GetProspectByIdRequest message or plain object
         * @param {PlatformCRMWeb.ProspectService.GetProspectByIdCallback} callback Node-style callback called with the error, if any, and GetProspectByIdResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProspectService.prototype.getProspectById = function getProspectById(request, callback) {
            return this.rpcCall(getProspectById, $root.PlatformCRMWeb.GetProspectByIdRequest, $root.PlatformCRMWeb.GetProspectByIdResponse, request, callback);
        }, "name", { value: "GetProspectById" });

        /**
         * Calls GetProspectById.
         * @function getProspectById
         * @memberof PlatformCRMWeb.ProspectService
         * @instance
         * @param {PlatformCRMWeb.IGetProspectByIdRequest} request GetProspectByIdRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetProspectByIdResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.ProspectService#upsert}.
         * @memberof PlatformCRMWeb.ProspectService
         * @typedef UpsertCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.UpsertProspectResponse} [response] UpsertProspectResponse
         */

        /**
         * Calls Upsert.
         * @function upsert
         * @memberof PlatformCRMWeb.ProspectService
         * @instance
         * @param {PlatformCRMWeb.IUpsertProspectRequest} request UpsertProspectRequest message or plain object
         * @param {PlatformCRMWeb.ProspectService.UpsertCallback} callback Node-style callback called with the error, if any, and UpsertProspectResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(ProspectService.prototype.upsert = function upsert(request, callback) {
            return this.rpcCall(upsert, $root.PlatformCRMWeb.UpsertProspectRequest, $root.PlatformCRMWeb.UpsertProspectResponse, request, callback);
        }, "name", { value: "Upsert" });

        /**
         * Calls Upsert.
         * @function upsert
         * @memberof PlatformCRMWeb.ProspectService
         * @instance
         * @param {PlatformCRMWeb.IUpsertProspectRequest} request UpsertProspectRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.UpsertProspectResponse>} Promise
         * @variation 2
         */

        return ProspectService;
    })();

    PlatformCRMWeb.LoginService = (function() {

        /**
         * Constructs a new LoginService service.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a LoginService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function LoginService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (LoginService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = LoginService;

        /**
         * Callback as used by {@link PlatformCRMWeb.LoginService#login}.
         * @memberof PlatformCRMWeb.LoginService
         * @typedef LoginCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.TokenResponse} [response] TokenResponse
         */

        /**
         * Calls Login.
         * @function login
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.ILoginRequest} request LoginRequest message or plain object
         * @param {PlatformCRMWeb.LoginService.LoginCallback} callback Node-style callback called with the error, if any, and TokenResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoginService.prototype.login = function login(request, callback) {
            return this.rpcCall(login, $root.PlatformCRMWeb.LoginRequest, $root.PlatformCRMWeb.TokenResponse, request, callback);
        }, "name", { value: "Login" });

        /**
         * Calls Login.
         * @function login
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.ILoginRequest} request LoginRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.TokenResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.LoginService#requestOtp}.
         * @memberof PlatformCRMWeb.LoginService
         * @typedef RequestOtpCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.OtpResponse} [response] OtpResponse
         */

        /**
         * Calls RequestOtp.
         * @function requestOtp
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.IOtpRequest} request OtpRequest message or plain object
         * @param {PlatformCRMWeb.LoginService.RequestOtpCallback} callback Node-style callback called with the error, if any, and OtpResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoginService.prototype.requestOtp = function requestOtp(request, callback) {
            return this.rpcCall(requestOtp, $root.PlatformCRMWeb.OtpRequest, $root.PlatformCRMWeb.OtpResponse, request, callback);
        }, "name", { value: "RequestOtp" });

        /**
         * Calls RequestOtp.
         * @function requestOtp
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.IOtpRequest} request OtpRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.OtpResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.LoginService#refreshToken}.
         * @memberof PlatformCRMWeb.LoginService
         * @typedef RefreshTokenCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.TokenResponse} [response] TokenResponse
         */

        /**
         * Calls RefreshToken.
         * @function refreshToken
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.IRefreshTokenRequest} request RefreshTokenRequest message or plain object
         * @param {PlatformCRMWeb.LoginService.RefreshTokenCallback} callback Node-style callback called with the error, if any, and TokenResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoginService.prototype.refreshToken = function refreshToken(request, callback) {
            return this.rpcCall(refreshToken, $root.PlatformCRMWeb.RefreshTokenRequest, $root.PlatformCRMWeb.TokenResponse, request, callback);
        }, "name", { value: "RefreshToken" });

        /**
         * Calls RefreshToken.
         * @function refreshToken
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.IRefreshTokenRequest} request RefreshTokenRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.TokenResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.LoginService#sendEmailAndPass}.
         * @memberof PlatformCRMWeb.LoginService
         * @typedef SendEmailAndPassCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.TokenResponse} [response] TokenResponse
         */

        /**
         * Calls SendEmailAndPass.
         * @function sendEmailAndPass
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.ISendEmailAndPassRequest} request SendEmailAndPassRequest message or plain object
         * @param {PlatformCRMWeb.LoginService.SendEmailAndPassCallback} callback Node-style callback called with the error, if any, and TokenResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(LoginService.prototype.sendEmailAndPass = function sendEmailAndPass(request, callback) {
            return this.rpcCall(sendEmailAndPass, $root.PlatformCRMWeb.SendEmailAndPassRequest, $root.PlatformCRMWeb.TokenResponse, request, callback);
        }, "name", { value: "SendEmailAndPass" });

        /**
         * Calls SendEmailAndPass.
         * @function sendEmailAndPass
         * @memberof PlatformCRMWeb.LoginService
         * @instance
         * @param {PlatformCRMWeb.ISendEmailAndPassRequest} request SendEmailAndPassRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.TokenResponse>} Promise
         * @variation 2
         */

        return LoginService;
    })();

    PlatformCRMWeb.OtpRequest = (function() {

        /**
         * Properties of an OtpRequest.
         * @memberof PlatformCRMWeb
         * @interface IOtpRequest
         * @property {number|null} [connection] OtpRequest connection
         * @property {number|null} [userType] OtpRequest userType
         * @property {string|null} [login] OtpRequest login
         * @property {number|null} [send] OtpRequest send
         * @property {string|null} [userAgent] OtpRequest userAgent
         * @property {string|null} [csrfToken] OtpRequest csrfToken
         */

        /**
         * Constructs a new OtpRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an OtpRequest.
         * @implements IOtpRequest
         * @constructor
         * @param {PlatformCRMWeb.IOtpRequest=} [properties] Properties to set
         */
        function OtpRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OtpRequest connection.
         * @member {number} connection
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.connection = 0;

        /**
         * OtpRequest userType.
         * @member {number} userType
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.userType = 0;

        /**
         * OtpRequest login.
         * @member {string} login
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.login = "";

        /**
         * OtpRequest send.
         * @member {number} send
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.send = 0;

        /**
         * OtpRequest userAgent.
         * @member {string} userAgent
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.userAgent = "";

        /**
         * OtpRequest csrfToken.
         * @member {string} csrfToken
         * @memberof PlatformCRMWeb.OtpRequest
         * @instance
         */
        OtpRequest.prototype.csrfToken = "";

        /**
         * Encodes the specified OtpRequest message. Does not implicitly {@link PlatformCRMWeb.OtpRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.OtpRequest
         * @static
         * @param {PlatformCRMWeb.IOtpRequest} message OtpRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OtpRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.connection != null && Object.hasOwnProperty.call(message, "connection"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.connection);
            if (message.userType != null && Object.hasOwnProperty.call(message, "userType"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.userType);
            if (message.login != null && Object.hasOwnProperty.call(message, "login"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.login);
            if (message.send != null && Object.hasOwnProperty.call(message, "send"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.send);
            if (message.userAgent != null && Object.hasOwnProperty.call(message, "userAgent"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.userAgent);
            if (message.csrfToken != null && Object.hasOwnProperty.call(message, "csrfToken"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.csrfToken);
            return writer;
        };

        /**
         * Decodes an OtpRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.OtpRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.OtpRequest} OtpRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OtpRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.OtpRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.connection = reader.int32();
                    break;
                case 3:
                    message.userType = reader.int32();
                    break;
                case 4:
                    message.login = reader.string();
                    break;
                case 5:
                    message.send = reader.int32();
                    break;
                case 6:
                    message.userAgent = reader.string();
                    break;
                case 7:
                    message.csrfToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OtpRequest;
    })();

    PlatformCRMWeb.OtpResponse = (function() {

        /**
         * Properties of an OtpResponse.
         * @memberof PlatformCRMWeb
         * @interface IOtpResponse
         * @property {string|null} [code] OtpResponse code
         */

        /**
         * Constructs a new OtpResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an OtpResponse.
         * @implements IOtpResponse
         * @constructor
         * @param {PlatformCRMWeb.IOtpResponse=} [properties] Properties to set
         */
        function OtpResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OtpResponse code.
         * @member {string} code
         * @memberof PlatformCRMWeb.OtpResponse
         * @instance
         */
        OtpResponse.prototype.code = "";

        /**
         * Encodes the specified OtpResponse message. Does not implicitly {@link PlatformCRMWeb.OtpResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.OtpResponse
         * @static
         * @param {PlatformCRMWeb.IOtpResponse} message OtpResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OtpResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
            return writer;
        };

        /**
         * Decodes an OtpResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.OtpResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.OtpResponse} OtpResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OtpResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.OtpResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return OtpResponse;
    })();

    PlatformCRMWeb.LoginRequest = (function() {

        /**
         * Properties of a LoginRequest.
         * @memberof PlatformCRMWeb
         * @interface ILoginRequest
         * @property {string|null} [phone] LoginRequest phone
         * @property {string|null} [code] LoginRequest code
         */

        /**
         * Constructs a new LoginRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a LoginRequest.
         * @implements ILoginRequest
         * @constructor
         * @param {PlatformCRMWeb.ILoginRequest=} [properties] Properties to set
         */
        function LoginRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginRequest phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.LoginRequest
         * @instance
         */
        LoginRequest.prototype.phone = "";

        /**
         * LoginRequest code.
         * @member {string} code
         * @memberof PlatformCRMWeb.LoginRequest
         * @instance
         */
        LoginRequest.prototype.code = "";

        /**
         * Encodes the specified LoginRequest message. Does not implicitly {@link PlatformCRMWeb.LoginRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.LoginRequest
         * @static
         * @param {PlatformCRMWeb.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
            return writer;
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.LoginRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.phone = reader.string();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return LoginRequest;
    })();

    PlatformCRMWeb.RefreshTokenRequest = (function() {

        /**
         * Properties of a RefreshTokenRequest.
         * @memberof PlatformCRMWeb
         * @interface IRefreshTokenRequest
         * @property {string|null} [refreshToken] RefreshTokenRequest refreshToken
         */

        /**
         * Constructs a new RefreshTokenRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a RefreshTokenRequest.
         * @implements IRefreshTokenRequest
         * @constructor
         * @param {PlatformCRMWeb.IRefreshTokenRequest=} [properties] Properties to set
         */
        function RefreshTokenRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RefreshTokenRequest refreshToken.
         * @member {string} refreshToken
         * @memberof PlatformCRMWeb.RefreshTokenRequest
         * @instance
         */
        RefreshTokenRequest.prototype.refreshToken = "";

        /**
         * Encodes the specified RefreshTokenRequest message. Does not implicitly {@link PlatformCRMWeb.RefreshTokenRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.RefreshTokenRequest
         * @static
         * @param {PlatformCRMWeb.IRefreshTokenRequest} message RefreshTokenRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RefreshTokenRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.refreshToken);
            return writer;
        };

        /**
         * Decodes a RefreshTokenRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.RefreshTokenRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.RefreshTokenRequest} RefreshTokenRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RefreshTokenRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.RefreshTokenRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.refreshToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RefreshTokenRequest;
    })();

    PlatformCRMWeb.SendEmailAndPassRequest = (function() {

        /**
         * Properties of a SendEmailAndPassRequest.
         * @memberof PlatformCRMWeb
         * @interface ISendEmailAndPassRequest
         * @property {string|null} [email] SendEmailAndPassRequest email
         * @property {string|null} [password] SendEmailAndPassRequest password
         */

        /**
         * Constructs a new SendEmailAndPassRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SendEmailAndPassRequest.
         * @implements ISendEmailAndPassRequest
         * @constructor
         * @param {PlatformCRMWeb.ISendEmailAndPassRequest=} [properties] Properties to set
         */
        function SendEmailAndPassRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendEmailAndPassRequest email.
         * @member {string} email
         * @memberof PlatformCRMWeb.SendEmailAndPassRequest
         * @instance
         */
        SendEmailAndPassRequest.prototype.email = "";

        /**
         * SendEmailAndPassRequest password.
         * @member {string} password
         * @memberof PlatformCRMWeb.SendEmailAndPassRequest
         * @instance
         */
        SendEmailAndPassRequest.prototype.password = "";

        /**
         * Encodes the specified SendEmailAndPassRequest message. Does not implicitly {@link PlatformCRMWeb.SendEmailAndPassRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SendEmailAndPassRequest
         * @static
         * @param {PlatformCRMWeb.ISendEmailAndPassRequest} message SendEmailAndPassRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendEmailAndPassRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Decodes a SendEmailAndPassRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SendEmailAndPassRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SendEmailAndPassRequest} SendEmailAndPassRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendEmailAndPassRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SendEmailAndPassRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.email = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SendEmailAndPassRequest;
    })();

    PlatformCRMWeb.TokenResponse = (function() {

        /**
         * Properties of a TokenResponse.
         * @memberof PlatformCRMWeb
         * @interface ITokenResponse
         * @property {string|null} [accessToken] TokenResponse accessToken
         * @property {string|null} [refreshToken] TokenResponse refreshToken
         */

        /**
         * Constructs a new TokenResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a TokenResponse.
         * @implements ITokenResponse
         * @constructor
         * @param {PlatformCRMWeb.ITokenResponse=} [properties] Properties to set
         */
        function TokenResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TokenResponse accessToken.
         * @member {string} accessToken
         * @memberof PlatformCRMWeb.TokenResponse
         * @instance
         */
        TokenResponse.prototype.accessToken = "";

        /**
         * TokenResponse refreshToken.
         * @member {string} refreshToken
         * @memberof PlatformCRMWeb.TokenResponse
         * @instance
         */
        TokenResponse.prototype.refreshToken = "";

        /**
         * Encodes the specified TokenResponse message. Does not implicitly {@link PlatformCRMWeb.TokenResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.TokenResponse
         * @static
         * @param {PlatformCRMWeb.ITokenResponse} message TokenResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TokenResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.accessToken != null && Object.hasOwnProperty.call(message, "accessToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.accessToken);
            if (message.refreshToken != null && Object.hasOwnProperty.call(message, "refreshToken"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.refreshToken);
            return writer;
        };

        /**
         * Decodes a TokenResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.TokenResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.TokenResponse} TokenResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TokenResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.TokenResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.accessToken = reader.string();
                    break;
                case 2:
                    message.refreshToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TokenResponse;
    })();

    PlatformCRMWeb.EmptyResponse = (function() {

        /**
         * Properties of an EmptyResponse.
         * @memberof PlatformCRMWeb
         * @interface IEmptyResponse
         */

        /**
         * Constructs a new EmptyResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an EmptyResponse.
         * @implements IEmptyResponse
         * @constructor
         * @param {PlatformCRMWeb.IEmptyResponse=} [properties] Properties to set
         */
        function EmptyResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified EmptyResponse message. Does not implicitly {@link PlatformCRMWeb.EmptyResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.EmptyResponse
         * @static
         * @param {PlatformCRMWeb.IEmptyResponse} message EmptyResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmptyResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an EmptyResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.EmptyResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.EmptyResponse} EmptyResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmptyResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.EmptyResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return EmptyResponse;
    })();

    /**
     * OperationType enum.
     * @name PlatformCRMWeb.OperationType
     * @enum {number}
     * @property {number} OperationType_IN=1 OperationType_IN value
     * @property {number} OperationType_NIN=2 OperationType_NIN value
     * @property {number} OperationType_LT=3 OperationType_LT value
     * @property {number} OperationType_LTE=4 OperationType_LTE value
     * @property {number} OperationType_BTW=5 OperationType_BTW value
     * @property {number} OperationType_EQ=6 OperationType_EQ value
     * @property {number} OperationType_NEQ=7 OperationType_NEQ value
     * @property {number} OperationType_GT=8 OperationType_GT value
     * @property {number} OperationType_GTE=9 OperationType_GTE value
     */
    PlatformCRMWeb.OperationType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "OperationType_IN"] = 1;
        values[valuesById[2] = "OperationType_NIN"] = 2;
        values[valuesById[3] = "OperationType_LT"] = 3;
        values[valuesById[4] = "OperationType_LTE"] = 4;
        values[valuesById[5] = "OperationType_BTW"] = 5;
        values[valuesById[6] = "OperationType_EQ"] = 6;
        values[valuesById[7] = "OperationType_NEQ"] = 7;
        values[valuesById[8] = "OperationType_GT"] = 8;
        values[valuesById[9] = "OperationType_GTE"] = 9;
        return values;
    })();

    /**
     * RestrictionType enum.
     * @name PlatformCRMWeb.RestrictionType
     * @enum {number}
     * @property {number} RestrictionType_USER=1 RestrictionType_USER value
     * @property {number} RestrictionType_DATE=2 RestrictionType_DATE value
     * @property {number} RestrictionType_WEEK_DAY=3 RestrictionType_WEEK_DAY value
     * @property {number} RestrictionType_ORDER_COUNT=4 RestrictionType_ORDER_COUNT value
     * @property {number} RestrictionType_REGION=5 RestrictionType_REGION value
     */
    PlatformCRMWeb.RestrictionType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "RestrictionType_USER"] = 1;
        values[valuesById[2] = "RestrictionType_DATE"] = 2;
        values[valuesById[3] = "RestrictionType_WEEK_DAY"] = 3;
        values[valuesById[4] = "RestrictionType_ORDER_COUNT"] = 4;
        values[valuesById[5] = "RestrictionType_REGION"] = 5;
        return values;
    })();

    /**
     * RewardType enum.
     * @name PlatformCRMWeb.RewardType
     * @enum {number}
     * @property {number} RewardType_DISCOUNT_PERCENT=1 RewardType_DISCOUNT_PERCENT value
     * @property {number} RewardType_DISCOUNT_ABSOLUTE=2 RewardType_DISCOUNT_ABSOLUTE value
     * @property {number} RewardType_BONUS=3 RewardType_BONUS value
     * @property {number} RewardType_SERVICE=4 RewardType_SERVICE value
     */
    PlatformCRMWeb.RewardType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "RewardType_DISCOUNT_PERCENT"] = 1;
        values[valuesById[2] = "RewardType_DISCOUNT_ABSOLUTE"] = 2;
        values[valuesById[3] = "RewardType_BONUS"] = 3;
        values[valuesById[4] = "RewardType_SERVICE"] = 4;
        return values;
    })();

    /**
     * LimitType enum.
     * @name PlatformCRMWeb.LimitType
     * @enum {number}
     * @property {number} LimitType_USAGE_COUNT=1 LimitType_USAGE_COUNT value
     * @property {number} LimitType_ACTIVATION_COUNT=2 LimitType_ACTIVATION_COUNT value
     * @property {number} LimitType_USER_COUNT=3 LimitType_USER_COUNT value
     * @property {number} LimitType_DATE=4 LimitType_DATE value
     */
    PlatformCRMWeb.LimitType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "LimitType_USAGE_COUNT"] = 1;
        values[valuesById[2] = "LimitType_ACTIVATION_COUNT"] = 2;
        values[valuesById[3] = "LimitType_USER_COUNT"] = 3;
        values[valuesById[4] = "LimitType_DATE"] = 4;
        return values;
    })();

    PlatformCRMWeb.Restriction = (function() {

        /**
         * Properties of a Restriction.
         * @memberof PlatformCRMWeb
         * @interface IRestriction
         * @property {PlatformCRMWeb.RestrictionType|null} [type] Restriction type
         * @property {Array.<string>|null} [values] Restriction values
         * @property {Array.<PlatformCRMWeb.OperationType>|null} [operations] Restriction operations
         */

        /**
         * Constructs a new Restriction.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Restriction.
         * @implements IRestriction
         * @constructor
         * @param {PlatformCRMWeb.IRestriction=} [properties] Properties to set
         */
        function Restriction(properties) {
            this.values = [];
            this.operations = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Restriction type.
         * @member {PlatformCRMWeb.RestrictionType} type
         * @memberof PlatformCRMWeb.Restriction
         * @instance
         */
        Restriction.prototype.type = 1;

        /**
         * Restriction values.
         * @member {Array.<string>} values
         * @memberof PlatformCRMWeb.Restriction
         * @instance
         */
        Restriction.prototype.values = $util.emptyArray;

        /**
         * Restriction operations.
         * @member {Array.<PlatformCRMWeb.OperationType>} operations
         * @memberof PlatformCRMWeb.Restriction
         * @instance
         */
        Restriction.prototype.operations = $util.emptyArray;

        /**
         * Encodes the specified Restriction message. Does not implicitly {@link PlatformCRMWeb.Restriction.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Restriction
         * @static
         * @param {PlatformCRMWeb.IRestriction} message Restriction message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Restriction.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
            if (message.values != null && message.values.length)
                for (var i = 0; i < message.values.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.values[i]);
            if (message.operations != null && message.operations.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.operations.length; ++i)
                    writer.int32(message.operations[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a Restriction message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Restriction
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Restriction} Restriction
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Restriction.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Restriction();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.type = reader.int32();
                    break;
                case 2:
                    if (!(message.values && message.values.length))
                        message.values = [];
                    message.values.push(reader.string());
                    break;
                case 3:
                    if (!(message.operations && message.operations.length))
                        message.operations = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.operations.push(reader.int32());
                    } else
                        message.operations.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Restriction;
    })();

    PlatformCRMWeb.Limit = (function() {

        /**
         * Properties of a Limit.
         * @memberof PlatformCRMWeb
         * @interface ILimit
         * @property {string|null} [id] Limit id
         * @property {PlatformCRMWeb.LimitType|null} [type] Limit type
         * @property {string|null} [value] Limit value
         */

        /**
         * Constructs a new Limit.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Limit.
         * @implements ILimit
         * @constructor
         * @param {PlatformCRMWeb.ILimit=} [properties] Properties to set
         */
        function Limit(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Limit id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Limit
         * @instance
         */
        Limit.prototype.id = "";

        /**
         * Limit type.
         * @member {PlatformCRMWeb.LimitType} type
         * @memberof PlatformCRMWeb.Limit
         * @instance
         */
        Limit.prototype.type = 1;

        /**
         * Limit value.
         * @member {string} value
         * @memberof PlatformCRMWeb.Limit
         * @instance
         */
        Limit.prototype.value = "";

        /**
         * Encodes the specified Limit message. Does not implicitly {@link PlatformCRMWeb.Limit.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Limit
         * @static
         * @param {PlatformCRMWeb.ILimit} message Limit message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Limit.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            return writer;
        };

        /**
         * Decodes a Limit message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Limit
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Limit} Limit
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Limit.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Limit();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Limit;
    })();

    PlatformCRMWeb.Reward = (function() {

        /**
         * Properties of a Reward.
         * @memberof PlatformCRMWeb
         * @interface IReward
         * @property {string|null} [id] Reward id
         * @property {PlatformCRMWeb.RewardType|null} [type] Reward type
         * @property {string|null} [value] Reward value
         */

        /**
         * Constructs a new Reward.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Reward.
         * @implements IReward
         * @constructor
         * @param {PlatformCRMWeb.IReward=} [properties] Properties to set
         */
        function Reward(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Reward id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Reward
         * @instance
         */
        Reward.prototype.id = "";

        /**
         * Reward type.
         * @member {PlatformCRMWeb.RewardType} type
         * @memberof PlatformCRMWeb.Reward
         * @instance
         */
        Reward.prototype.type = 1;

        /**
         * Reward value.
         * @member {string} value
         * @memberof PlatformCRMWeb.Reward
         * @instance
         */
        Reward.prototype.value = "";

        /**
         * Encodes the specified Reward message. Does not implicitly {@link PlatformCRMWeb.Reward.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Reward
         * @static
         * @param {PlatformCRMWeb.IReward} message Reward message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reward.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            return writer;
        };

        /**
         * Decodes a Reward message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Reward
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Reward} Reward
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reward.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Reward();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Reward;
    })();

    PlatformCRMWeb.UserPromocode = (function() {

        /**
         * Properties of a UserPromocode.
         * @memberof PlatformCRMWeb
         * @interface IUserPromocode
         * @property {string|null} [id] UserPromocode id
         * @property {string|null} [userId] UserPromocode userId
         * @property {string|null} [promocodeId] UserPromocode promocodeId
         * @property {PlatformCRMWeb.IPromocode|null} [promocode] UserPromocode promocode
         * @property {number|null} [UserPromocodeStatus] UserPromocode UserPromocodeStatus
         */

        /**
         * Constructs a new UserPromocode.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a UserPromocode.
         * @implements IUserPromocode
         * @constructor
         * @param {PlatformCRMWeb.IUserPromocode=} [properties] Properties to set
         */
        function UserPromocode(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserPromocode id.
         * @member {string} id
         * @memberof PlatformCRMWeb.UserPromocode
         * @instance
         */
        UserPromocode.prototype.id = "";

        /**
         * UserPromocode userId.
         * @member {string} userId
         * @memberof PlatformCRMWeb.UserPromocode
         * @instance
         */
        UserPromocode.prototype.userId = "";

        /**
         * UserPromocode promocodeId.
         * @member {string} promocodeId
         * @memberof PlatformCRMWeb.UserPromocode
         * @instance
         */
        UserPromocode.prototype.promocodeId = "";

        /**
         * UserPromocode promocode.
         * @member {PlatformCRMWeb.IPromocode|null|undefined} promocode
         * @memberof PlatformCRMWeb.UserPromocode
         * @instance
         */
        UserPromocode.prototype.promocode = null;

        /**
         * UserPromocode UserPromocodeStatus.
         * @member {number} UserPromocodeStatus
         * @memberof PlatformCRMWeb.UserPromocode
         * @instance
         */
        UserPromocode.prototype.UserPromocodeStatus = 0;

        /**
         * Encodes the specified UserPromocode message. Does not implicitly {@link PlatformCRMWeb.UserPromocode.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.UserPromocode
         * @static
         * @param {PlatformCRMWeb.IUserPromocode} message UserPromocode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserPromocode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            if (message.promocodeId != null && Object.hasOwnProperty.call(message, "promocodeId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.promocodeId);
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                $root.PlatformCRMWeb.Promocode.encode(message.promocode, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.UserPromocodeStatus != null && Object.hasOwnProperty.call(message, "UserPromocodeStatus"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.UserPromocodeStatus);
            return writer;
        };

        /**
         * Decodes a UserPromocode message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.UserPromocode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.UserPromocode} UserPromocode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserPromocode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.UserPromocode();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                case 3:
                    message.promocodeId = reader.string();
                    break;
                case 4:
                    message.promocode = $root.PlatformCRMWeb.Promocode.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.UserPromocodeStatus = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UserPromocode;
    })();

    PlatformCRMWeb.Promocode = (function() {

        /**
         * Properties of a Promocode.
         * @memberof PlatformCRMWeb
         * @interface IPromocode
         * @property {string|null} [id] Promocode id
         * @property {string|null} [title] Promocode title
         * @property {string|null} [code] Promocode code
         * @property {number|null} [usageCount] Promocode usageCount
         * @property {number|null} [activationCount] Promocode activationCount
         * @property {Array.<PlatformCRMWeb.IRestriction>|null} [restrictions] Promocode restrictions
         * @property {Array.<PlatformCRMWeb.ILimit>|null} [limits] Promocode limits
         * @property {Array.<PlatformCRMWeb.IReward>|null} [rewards] Promocode rewards
         * @property {Array.<PlatformCRMWeb.IUserPromocode>|null} [userPromocodes] Promocode userPromocodes
         */

        /**
         * Constructs a new Promocode.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Promocode.
         * @implements IPromocode
         * @constructor
         * @param {PlatformCRMWeb.IPromocode=} [properties] Properties to set
         */
        function Promocode(properties) {
            this.restrictions = [];
            this.limits = [];
            this.rewards = [];
            this.userPromocodes = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Promocode id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Promocode
         * @instance
         */
        Promocode.prototype.id = "";

        /**
         * Promocode title.
         * @member {string} title
         * @memberof PlatformCRMWeb.Promocode
         * @instance
         */
        Promocode.prototype.title = "";

        /**
         * Promocode code.
         * @member {string} code
         * @memberof PlatformCRMWeb.Promocode
         * @instance
         */
        Promocode.prototype.code = "";

        /**
         * Promocode usageCount.
         * @member {number} usageCount
         * @memberof PlatformCRMWeb.Promocode
         * @instance
         */
        Promocode.prototype.usageCount = 0;

        /**
         * Promocode activationCount.
         * @member {number} activationCount
         * @memberof PlatformCRMWeb.Promocode
         * @instance
         */
        Promocode.prototype.activationCount = 0;

        /**
         * Promocode restrictions.
         * @member {Array.<PlatformCRMWeb.IRestriction>} restrictions
         * @memberof PlatformCRMWeb.Promocode
         * @instance
         */
        Promocode.prototype.restrictions = $util.emptyArray;

        /**
         * Promocode limits.
         * @member {Array.<PlatformCRMWeb.ILimit>} limits
         * @memberof PlatformCRMWeb.Promocode
         * @instance
         */
        Promocode.prototype.limits = $util.emptyArray;

        /**
         * Promocode rewards.
         * @member {Array.<PlatformCRMWeb.IReward>} rewards
         * @memberof PlatformCRMWeb.Promocode
         * @instance
         */
        Promocode.prototype.rewards = $util.emptyArray;

        /**
         * Promocode userPromocodes.
         * @member {Array.<PlatformCRMWeb.IUserPromocode>} userPromocodes
         * @memberof PlatformCRMWeb.Promocode
         * @instance
         */
        Promocode.prototype.userPromocodes = $util.emptyArray;

        /**
         * Encodes the specified Promocode message. Does not implicitly {@link PlatformCRMWeb.Promocode.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Promocode
         * @static
         * @param {PlatformCRMWeb.IPromocode} message Promocode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Promocode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.code);
            if (message.usageCount != null && Object.hasOwnProperty.call(message, "usageCount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.usageCount);
            if (message.activationCount != null && Object.hasOwnProperty.call(message, "activationCount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.activationCount);
            if (message.restrictions != null && message.restrictions.length)
                for (var i = 0; i < message.restrictions.length; ++i)
                    $root.PlatformCRMWeb.Restriction.encode(message.restrictions[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.limits != null && message.limits.length)
                for (var i = 0; i < message.limits.length; ++i)
                    $root.PlatformCRMWeb.Limit.encode(message.limits[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.rewards != null && message.rewards.length)
                for (var i = 0; i < message.rewards.length; ++i)
                    $root.PlatformCRMWeb.Reward.encode(message.rewards[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.userPromocodes != null && message.userPromocodes.length)
                for (var i = 0; i < message.userPromocodes.length; ++i)
                    $root.PlatformCRMWeb.UserPromocode.encode(message.userPromocodes[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Promocode message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Promocode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Promocode} Promocode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Promocode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Promocode();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.code = reader.string();
                    break;
                case 4:
                    message.usageCount = reader.int32();
                    break;
                case 5:
                    message.activationCount = reader.int32();
                    break;
                case 6:
                    if (!(message.restrictions && message.restrictions.length))
                        message.restrictions = [];
                    message.restrictions.push($root.PlatformCRMWeb.Restriction.decode(reader, reader.uint32()));
                    break;
                case 7:
                    if (!(message.limits && message.limits.length))
                        message.limits = [];
                    message.limits.push($root.PlatformCRMWeb.Limit.decode(reader, reader.uint32()));
                    break;
                case 8:
                    if (!(message.rewards && message.rewards.length))
                        message.rewards = [];
                    message.rewards.push($root.PlatformCRMWeb.Reward.decode(reader, reader.uint32()));
                    break;
                case 9:
                    if (!(message.userPromocodes && message.userPromocodes.length))
                        message.userPromocodes = [];
                    message.userPromocodes.push($root.PlatformCRMWeb.UserPromocode.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Promocode;
    })();

    PlatformCRMWeb.SearchPromocodeWhere = (function() {

        /**
         * Properties of a SearchPromocodeWhere.
         * @memberof PlatformCRMWeb
         * @interface ISearchPromocodeWhere
         * @property {PlatformCRMWeb.IUuidCondition|null} [id] SearchPromocodeWhere id
         * @property {PlatformCRMWeb.INumberCondition|null} [activationCount] SearchPromocodeWhere activationCount
         * @property {PlatformCRMWeb.INumberCondition|null} [usageCount] SearchPromocodeWhere usageCount
         * @property {PlatformCRMWeb.IStringCondition|null} [title] SearchPromocodeWhere title
         */

        /**
         * Constructs a new SearchPromocodeWhere.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SearchPromocodeWhere.
         * @implements ISearchPromocodeWhere
         * @constructor
         * @param {PlatformCRMWeb.ISearchPromocodeWhere=} [properties] Properties to set
         */
        function SearchPromocodeWhere(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPromocodeWhere id.
         * @member {PlatformCRMWeb.IUuidCondition|null|undefined} id
         * @memberof PlatformCRMWeb.SearchPromocodeWhere
         * @instance
         */
        SearchPromocodeWhere.prototype.id = null;

        /**
         * SearchPromocodeWhere activationCount.
         * @member {PlatformCRMWeb.INumberCondition|null|undefined} activationCount
         * @memberof PlatformCRMWeb.SearchPromocodeWhere
         * @instance
         */
        SearchPromocodeWhere.prototype.activationCount = null;

        /**
         * SearchPromocodeWhere usageCount.
         * @member {PlatformCRMWeb.INumberCondition|null|undefined} usageCount
         * @memberof PlatformCRMWeb.SearchPromocodeWhere
         * @instance
         */
        SearchPromocodeWhere.prototype.usageCount = null;

        /**
         * SearchPromocodeWhere title.
         * @member {PlatformCRMWeb.IStringCondition|null|undefined} title
         * @memberof PlatformCRMWeb.SearchPromocodeWhere
         * @instance
         */
        SearchPromocodeWhere.prototype.title = null;

        /**
         * Encodes the specified SearchPromocodeWhere message. Does not implicitly {@link PlatformCRMWeb.SearchPromocodeWhere.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SearchPromocodeWhere
         * @static
         * @param {PlatformCRMWeb.ISearchPromocodeWhere} message SearchPromocodeWhere message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPromocodeWhere.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                $root.PlatformCRMWeb.UuidCondition.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.activationCount != null && Object.hasOwnProperty.call(message, "activationCount"))
                $root.PlatformCRMWeb.NumberCondition.encode(message.activationCount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.usageCount != null && Object.hasOwnProperty.call(message, "usageCount"))
                $root.PlatformCRMWeb.NumberCondition.encode(message.usageCount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                $root.PlatformCRMWeb.StringCondition.encode(message.title, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchPromocodeWhere message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SearchPromocodeWhere
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SearchPromocodeWhere} SearchPromocodeWhere
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPromocodeWhere.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SearchPromocodeWhere();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.PlatformCRMWeb.UuidCondition.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.activationCount = $root.PlatformCRMWeb.NumberCondition.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.usageCount = $root.PlatformCRMWeb.NumberCondition.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.title = $root.PlatformCRMWeb.StringCondition.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchPromocodeWhere;
    })();

    PlatformCRMWeb.GetAvailableDatesRequest = (function() {

        /**
         * Properties of a GetAvailableDatesRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetAvailableDatesRequest
         * @property {string|null} [prospectId] GetAvailableDatesRequest prospectId
         */

        /**
         * Constructs a new GetAvailableDatesRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetAvailableDatesRequest.
         * @implements IGetAvailableDatesRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetAvailableDatesRequest=} [properties] Properties to set
         */
        function GetAvailableDatesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableDatesRequest prospectId.
         * @member {string} prospectId
         * @memberof PlatformCRMWeb.GetAvailableDatesRequest
         * @instance
         */
        GetAvailableDatesRequest.prototype.prospectId = "";

        /**
         * Encodes the specified GetAvailableDatesRequest message. Does not implicitly {@link PlatformCRMWeb.GetAvailableDatesRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetAvailableDatesRequest
         * @static
         * @param {PlatformCRMWeb.IGetAvailableDatesRequest} message GetAvailableDatesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableDatesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.prospectId != null && Object.hasOwnProperty.call(message, "prospectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.prospectId);
            return writer;
        };

        /**
         * Decodes a GetAvailableDatesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetAvailableDatesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetAvailableDatesRequest} GetAvailableDatesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableDatesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetAvailableDatesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.prospectId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAvailableDatesRequest;
    })();

    PlatformCRMWeb.GetAvailableTimeSlotsRequest = (function() {

        /**
         * Properties of a GetAvailableTimeSlotsRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetAvailableTimeSlotsRequest
         * @property {string|null} [prospectId] GetAvailableTimeSlotsRequest prospectId
         * @property {string|null} [date] GetAvailableTimeSlotsRequest date
         */

        /**
         * Constructs a new GetAvailableTimeSlotsRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetAvailableTimeSlotsRequest.
         * @implements IGetAvailableTimeSlotsRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsRequest=} [properties] Properties to set
         */
        function GetAvailableTimeSlotsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableTimeSlotsRequest prospectId.
         * @member {string} prospectId
         * @memberof PlatformCRMWeb.GetAvailableTimeSlotsRequest
         * @instance
         */
        GetAvailableTimeSlotsRequest.prototype.prospectId = "";

        /**
         * GetAvailableTimeSlotsRequest date.
         * @member {string} date
         * @memberof PlatformCRMWeb.GetAvailableTimeSlotsRequest
         * @instance
         */
        GetAvailableTimeSlotsRequest.prototype.date = "";

        /**
         * Encodes the specified GetAvailableTimeSlotsRequest message. Does not implicitly {@link PlatformCRMWeb.GetAvailableTimeSlotsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetAvailableTimeSlotsRequest
         * @static
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsRequest} message GetAvailableTimeSlotsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableTimeSlotsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.prospectId != null && Object.hasOwnProperty.call(message, "prospectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.prospectId);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.date);
            return writer;
        };

        /**
         * Decodes a GetAvailableTimeSlotsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetAvailableTimeSlotsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetAvailableTimeSlotsRequest} GetAvailableTimeSlotsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableTimeSlotsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetAvailableTimeSlotsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.prospectId = reader.string();
                    break;
                case 2:
                    message.date = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAvailableTimeSlotsRequest;
    })();

    PlatformCRMWeb.SearchPromocodeRequest = (function() {

        /**
         * Properties of a SearchPromocodeRequest.
         * @memberof PlatformCRMWeb
         * @interface ISearchPromocodeRequest
         * @property {number|null} [limit] SearchPromocodeRequest limit
         * @property {number|null} [page] SearchPromocodeRequest page
         * @property {Array.<PlatformCRMWeb.ISearchPromocodeWhere>|null} [where] SearchPromocodeRequest where
         */

        /**
         * Constructs a new SearchPromocodeRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SearchPromocodeRequest.
         * @implements ISearchPromocodeRequest
         * @constructor
         * @param {PlatformCRMWeb.ISearchPromocodeRequest=} [properties] Properties to set
         */
        function SearchPromocodeRequest(properties) {
            this.where = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPromocodeRequest limit.
         * @member {number} limit
         * @memberof PlatformCRMWeb.SearchPromocodeRequest
         * @instance
         */
        SearchPromocodeRequest.prototype.limit = 0;

        /**
         * SearchPromocodeRequest page.
         * @member {number} page
         * @memberof PlatformCRMWeb.SearchPromocodeRequest
         * @instance
         */
        SearchPromocodeRequest.prototype.page = 0;

        /**
         * SearchPromocodeRequest where.
         * @member {Array.<PlatformCRMWeb.ISearchPromocodeWhere>} where
         * @memberof PlatformCRMWeb.SearchPromocodeRequest
         * @instance
         */
        SearchPromocodeRequest.prototype.where = $util.emptyArray;

        /**
         * Encodes the specified SearchPromocodeRequest message. Does not implicitly {@link PlatformCRMWeb.SearchPromocodeRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SearchPromocodeRequest
         * @static
         * @param {PlatformCRMWeb.ISearchPromocodeRequest} message SearchPromocodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPromocodeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.limit);
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.where != null && message.where.length)
                for (var i = 0; i < message.where.length; ++i)
                    $root.PlatformCRMWeb.SearchPromocodeWhere.encode(message.where[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a SearchPromocodeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SearchPromocodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SearchPromocodeRequest} SearchPromocodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPromocodeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SearchPromocodeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.limit = reader.int32();
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    if (!(message.where && message.where.length))
                        message.where = [];
                    message.where.push($root.PlatformCRMWeb.SearchPromocodeWhere.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchPromocodeRequest;
    })();

    PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest = (function() {

        /**
         * Properties of a GetAvailableTimeSlotsFromLmsRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetAvailableTimeSlotsFromLmsRequest
         * @property {string|null} [prospectId] GetAvailableTimeSlotsFromLmsRequest prospectId
         */

        /**
         * Constructs a new GetAvailableTimeSlotsFromLmsRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetAvailableTimeSlotsFromLmsRequest.
         * @implements IGetAvailableTimeSlotsFromLmsRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsFromLmsRequest=} [properties] Properties to set
         */
        function GetAvailableTimeSlotsFromLmsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableTimeSlotsFromLmsRequest prospectId.
         * @member {string} prospectId
         * @memberof PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest
         * @instance
         */
        GetAvailableTimeSlotsFromLmsRequest.prototype.prospectId = "";

        /**
         * Encodes the specified GetAvailableTimeSlotsFromLmsRequest message. Does not implicitly {@link PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest
         * @static
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsFromLmsRequest} message GetAvailableTimeSlotsFromLmsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableTimeSlotsFromLmsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.prospectId != null && Object.hasOwnProperty.call(message, "prospectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.prospectId);
            return writer;
        };

        /**
         * Decodes a GetAvailableTimeSlotsFromLmsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest} GetAvailableTimeSlotsFromLmsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableTimeSlotsFromLmsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.prospectId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAvailableTimeSlotsFromLmsRequest;
    })();

    PlatformCRMWeb.GetAvailableDatesResponse = (function() {

        /**
         * Properties of a GetAvailableDatesResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetAvailableDatesResponse
         * @property {Array.<string>|null} [dates] GetAvailableDatesResponse dates
         */

        /**
         * Constructs a new GetAvailableDatesResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetAvailableDatesResponse.
         * @implements IGetAvailableDatesResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetAvailableDatesResponse=} [properties] Properties to set
         */
        function GetAvailableDatesResponse(properties) {
            this.dates = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableDatesResponse dates.
         * @member {Array.<string>} dates
         * @memberof PlatformCRMWeb.GetAvailableDatesResponse
         * @instance
         */
        GetAvailableDatesResponse.prototype.dates = $util.emptyArray;

        /**
         * Encodes the specified GetAvailableDatesResponse message. Does not implicitly {@link PlatformCRMWeb.GetAvailableDatesResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetAvailableDatesResponse
         * @static
         * @param {PlatformCRMWeb.IGetAvailableDatesResponse} message GetAvailableDatesResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableDatesResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.dates != null && message.dates.length)
                for (var i = 0; i < message.dates.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.dates[i]);
            return writer;
        };

        /**
         * Decodes a GetAvailableDatesResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetAvailableDatesResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetAvailableDatesResponse} GetAvailableDatesResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableDatesResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetAvailableDatesResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.dates && message.dates.length))
                        message.dates = [];
                    message.dates.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAvailableDatesResponse;
    })();

    PlatformCRMWeb.GetAvailableTimeSlotsResponse = (function() {

        /**
         * Properties of a GetAvailableTimeSlotsResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetAvailableTimeSlotsResponse
         * @property {Array.<string>|null} [slots] GetAvailableTimeSlotsResponse slots
         */

        /**
         * Constructs a new GetAvailableTimeSlotsResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetAvailableTimeSlotsResponse.
         * @implements IGetAvailableTimeSlotsResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsResponse=} [properties] Properties to set
         */
        function GetAvailableTimeSlotsResponse(properties) {
            this.slots = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetAvailableTimeSlotsResponse slots.
         * @member {Array.<string>} slots
         * @memberof PlatformCRMWeb.GetAvailableTimeSlotsResponse
         * @instance
         */
        GetAvailableTimeSlotsResponse.prototype.slots = $util.emptyArray;

        /**
         * Encodes the specified GetAvailableTimeSlotsResponse message. Does not implicitly {@link PlatformCRMWeb.GetAvailableTimeSlotsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetAvailableTimeSlotsResponse
         * @static
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsResponse} message GetAvailableTimeSlotsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetAvailableTimeSlotsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.slots != null && message.slots.length)
                for (var i = 0; i < message.slots.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.slots[i]);
            return writer;
        };

        /**
         * Decodes a GetAvailableTimeSlotsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetAvailableTimeSlotsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetAvailableTimeSlotsResponse} GetAvailableTimeSlotsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetAvailableTimeSlotsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetAvailableTimeSlotsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.slots && message.slots.length))
                        message.slots = [];
                    message.slots.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetAvailableTimeSlotsResponse;
    })();

    PlatformCRMWeb.SearchPromocodeResponse = (function() {

        /**
         * Properties of a SearchPromocodeResponse.
         * @memberof PlatformCRMWeb
         * @interface ISearchPromocodeResponse
         * @property {Array.<PlatformCRMWeb.IPromocode>|null} [promocodes] SearchPromocodeResponse promocodes
         * @property {number|null} [page] SearchPromocodeResponse page
         * @property {number|null} [pageCount] SearchPromocodeResponse pageCount
         * @property {number|null} [limit] SearchPromocodeResponse limit
         * @property {number|null} [count] SearchPromocodeResponse count
         * @property {number|null} [total] SearchPromocodeResponse total
         */

        /**
         * Constructs a new SearchPromocodeResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a SearchPromocodeResponse.
         * @implements ISearchPromocodeResponse
         * @constructor
         * @param {PlatformCRMWeb.ISearchPromocodeResponse=} [properties] Properties to set
         */
        function SearchPromocodeResponse(properties) {
            this.promocodes = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchPromocodeResponse promocodes.
         * @member {Array.<PlatformCRMWeb.IPromocode>} promocodes
         * @memberof PlatformCRMWeb.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.promocodes = $util.emptyArray;

        /**
         * SearchPromocodeResponse page.
         * @member {number} page
         * @memberof PlatformCRMWeb.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.page = 0;

        /**
         * SearchPromocodeResponse pageCount.
         * @member {number} pageCount
         * @memberof PlatformCRMWeb.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.pageCount = 0;

        /**
         * SearchPromocodeResponse limit.
         * @member {number} limit
         * @memberof PlatformCRMWeb.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.limit = 0;

        /**
         * SearchPromocodeResponse count.
         * @member {number} count
         * @memberof PlatformCRMWeb.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.count = 0;

        /**
         * SearchPromocodeResponse total.
         * @member {number} total
         * @memberof PlatformCRMWeb.SearchPromocodeResponse
         * @instance
         */
        SearchPromocodeResponse.prototype.total = 0;

        /**
         * Encodes the specified SearchPromocodeResponse message. Does not implicitly {@link PlatformCRMWeb.SearchPromocodeResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.SearchPromocodeResponse
         * @static
         * @param {PlatformCRMWeb.ISearchPromocodeResponse} message SearchPromocodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchPromocodeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.promocodes != null && message.promocodes.length)
                for (var i = 0; i < message.promocodes.length; ++i)
                    $root.PlatformCRMWeb.Promocode.encode(message.promocodes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.page != null && Object.hasOwnProperty.call(message, "page"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.page);
            if (message.pageCount != null && Object.hasOwnProperty.call(message, "pageCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.pageCount);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
            if (message.count != null && Object.hasOwnProperty.call(message, "count"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.count);
            if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.total);
            return writer;
        };

        /**
         * Decodes a SearchPromocodeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.SearchPromocodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.SearchPromocodeResponse} SearchPromocodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchPromocodeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.SearchPromocodeResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.promocodes && message.promocodes.length))
                        message.promocodes = [];
                    message.promocodes.push($root.PlatformCRMWeb.Promocode.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.page = reader.int32();
                    break;
                case 3:
                    message.pageCount = reader.int32();
                    break;
                case 4:
                    message.limit = reader.int32();
                    break;
                case 5:
                    message.count = reader.int32();
                    break;
                case 6:
                    message.total = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SearchPromocodeResponse;
    })();

    PlatformCRMWeb.GetPriceElementRequest = (function() {

        /**
         * Properties of a GetPriceElementRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetPriceElementRequest
         * @property {number|null} [regionId] GetPriceElementRequest regionId
         * @property {string|null} [productSlug] GetPriceElementRequest productSlug
         */

        /**
         * Constructs a new GetPriceElementRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetPriceElementRequest.
         * @implements IGetPriceElementRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetPriceElementRequest=} [properties] Properties to set
         */
        function GetPriceElementRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPriceElementRequest regionId.
         * @member {number} regionId
         * @memberof PlatformCRMWeb.GetPriceElementRequest
         * @instance
         */
        GetPriceElementRequest.prototype.regionId = 0;

        /**
         * GetPriceElementRequest productSlug.
         * @member {string} productSlug
         * @memberof PlatformCRMWeb.GetPriceElementRequest
         * @instance
         */
        GetPriceElementRequest.prototype.productSlug = "";

        /**
         * Encodes the specified GetPriceElementRequest message. Does not implicitly {@link PlatformCRMWeb.GetPriceElementRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetPriceElementRequest
         * @static
         * @param {PlatformCRMWeb.IGetPriceElementRequest} message GetPriceElementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPriceElementRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.regionId);
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.productSlug);
            return writer;
        };

        /**
         * Decodes a GetPriceElementRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetPriceElementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetPriceElementRequest} GetPriceElementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPriceElementRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetPriceElementRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.regionId = reader.int32();
                    break;
                case 2:
                    message.productSlug = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetPriceElementRequest;
    })();

    PlatformCRMWeb.GetPriceElementResponse = (function() {

        /**
         * Properties of a GetPriceElementResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetPriceElementResponse
         * @property {PlatformCRMWeb.IPriceElement|null} [priceElement] GetPriceElementResponse priceElement
         */

        /**
         * Constructs a new GetPriceElementResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetPriceElementResponse.
         * @implements IGetPriceElementResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetPriceElementResponse=} [properties] Properties to set
         */
        function GetPriceElementResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPriceElementResponse priceElement.
         * @member {PlatformCRMWeb.IPriceElement|null|undefined} priceElement
         * @memberof PlatformCRMWeb.GetPriceElementResponse
         * @instance
         */
        GetPriceElementResponse.prototype.priceElement = null;

        /**
         * Encodes the specified GetPriceElementResponse message. Does not implicitly {@link PlatformCRMWeb.GetPriceElementResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetPriceElementResponse
         * @static
         * @param {PlatformCRMWeb.IGetPriceElementResponse} message GetPriceElementResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPriceElementResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.priceElement != null && Object.hasOwnProperty.call(message, "priceElement"))
                $root.PlatformCRMWeb.PriceElement.encode(message.priceElement, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetPriceElementResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetPriceElementResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetPriceElementResponse} GetPriceElementResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPriceElementResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetPriceElementResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.priceElement = $root.PlatformCRMWeb.PriceElement.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetPriceElementResponse;
    })();

    PlatformCRMWeb.GetClientAddressesRequest = (function() {

        /**
         * Properties of a GetClientAddressesRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetClientAddressesRequest
         * @property {string|null} [clientId] GetClientAddressesRequest clientId
         */

        /**
         * Constructs a new GetClientAddressesRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetClientAddressesRequest.
         * @implements IGetClientAddressesRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetClientAddressesRequest=} [properties] Properties to set
         */
        function GetClientAddressesRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetClientAddressesRequest clientId.
         * @member {string} clientId
         * @memberof PlatformCRMWeb.GetClientAddressesRequest
         * @instance
         */
        GetClientAddressesRequest.prototype.clientId = "";

        /**
         * Encodes the specified GetClientAddressesRequest message. Does not implicitly {@link PlatformCRMWeb.GetClientAddressesRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetClientAddressesRequest
         * @static
         * @param {PlatformCRMWeb.IGetClientAddressesRequest} message GetClientAddressesRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetClientAddressesRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientId);
            return writer;
        };

        /**
         * Decodes a GetClientAddressesRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetClientAddressesRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetClientAddressesRequest} GetClientAddressesRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetClientAddressesRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetClientAddressesRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.clientId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetClientAddressesRequest;
    })();

    PlatformCRMWeb.GetClientAddressResponse = (function() {

        /**
         * Properties of a GetClientAddressResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetClientAddressResponse
         * @property {Array.<sso.IAddress>|null} [addresses] GetClientAddressResponse addresses
         */

        /**
         * Constructs a new GetClientAddressResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetClientAddressResponse.
         * @implements IGetClientAddressResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetClientAddressResponse=} [properties] Properties to set
         */
        function GetClientAddressResponse(properties) {
            this.addresses = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetClientAddressResponse addresses.
         * @member {Array.<sso.IAddress>} addresses
         * @memberof PlatformCRMWeb.GetClientAddressResponse
         * @instance
         */
        GetClientAddressResponse.prototype.addresses = $util.emptyArray;

        /**
         * Encodes the specified GetClientAddressResponse message. Does not implicitly {@link PlatformCRMWeb.GetClientAddressResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetClientAddressResponse
         * @static
         * @param {PlatformCRMWeb.IGetClientAddressResponse} message GetClientAddressResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetClientAddressResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.addresses != null && message.addresses.length)
                for (var i = 0; i < message.addresses.length; ++i)
                    $root.sso.Address.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetClientAddressResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetClientAddressResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetClientAddressResponse} GetClientAddressResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetClientAddressResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetClientAddressResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.addresses && message.addresses.length))
                        message.addresses = [];
                    message.addresses.push($root.sso.Address.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetClientAddressResponse;
    })();

    PlatformCRMWeb.DrycleaningSlot = (function() {

        /**
         * Properties of a DrycleaningSlot.
         * @memberof PlatformCRMWeb
         * @interface IDrycleaningSlot
         * @property {string|null} [id] DrycleaningSlot id
         * @property {string|null} [date] DrycleaningSlot date
         * @property {string|null} [startAt] DrycleaningSlot startAt
         * @property {number|null} [duration] DrycleaningSlot duration
         * @property {string|null} [timeRange] DrycleaningSlot timeRange
         * @property {boolean|null} [pickup] DrycleaningSlot pickup
         * @property {boolean|null} ["return"] DrycleaningSlot return
         * @property {number|null} [price] DrycleaningSlot price
         */

        /**
         * Constructs a new DrycleaningSlot.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a DrycleaningSlot.
         * @implements IDrycleaningSlot
         * @constructor
         * @param {PlatformCRMWeb.IDrycleaningSlot=} [properties] Properties to set
         */
        function DrycleaningSlot(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DrycleaningSlot id.
         * @member {string} id
         * @memberof PlatformCRMWeb.DrycleaningSlot
         * @instance
         */
        DrycleaningSlot.prototype.id = "";

        /**
         * DrycleaningSlot date.
         * @member {string} date
         * @memberof PlatformCRMWeb.DrycleaningSlot
         * @instance
         */
        DrycleaningSlot.prototype.date = "";

        /**
         * DrycleaningSlot startAt.
         * @member {string} startAt
         * @memberof PlatformCRMWeb.DrycleaningSlot
         * @instance
         */
        DrycleaningSlot.prototype.startAt = "";

        /**
         * DrycleaningSlot duration.
         * @member {number} duration
         * @memberof PlatformCRMWeb.DrycleaningSlot
         * @instance
         */
        DrycleaningSlot.prototype.duration = 0;

        /**
         * DrycleaningSlot timeRange.
         * @member {string} timeRange
         * @memberof PlatformCRMWeb.DrycleaningSlot
         * @instance
         */
        DrycleaningSlot.prototype.timeRange = "";

        /**
         * DrycleaningSlot pickup.
         * @member {boolean} pickup
         * @memberof PlatformCRMWeb.DrycleaningSlot
         * @instance
         */
        DrycleaningSlot.prototype.pickup = false;

        /**
         * DrycleaningSlot return.
         * @member {boolean} return
         * @memberof PlatformCRMWeb.DrycleaningSlot
         * @instance
         */
        DrycleaningSlot.prototype["return"] = false;

        /**
         * DrycleaningSlot price.
         * @member {number} price
         * @memberof PlatformCRMWeb.DrycleaningSlot
         * @instance
         */
        DrycleaningSlot.prototype.price = 0;

        /**
         * Encodes the specified DrycleaningSlot message. Does not implicitly {@link PlatformCRMWeb.DrycleaningSlot.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.DrycleaningSlot
         * @static
         * @param {PlatformCRMWeb.IDrycleaningSlot} message DrycleaningSlot message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrycleaningSlot.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.date);
            if (message.startAt != null && Object.hasOwnProperty.call(message, "startAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.startAt);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.duration);
            if (message.timeRange != null && Object.hasOwnProperty.call(message, "timeRange"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.timeRange);
            if (message.pickup != null && Object.hasOwnProperty.call(message, "pickup"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.pickup);
            if (message["return"] != null && Object.hasOwnProperty.call(message, "return"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message["return"]);
            if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                writer.uint32(/* id 8, wireType 5 =*/69).float(message.price);
            return writer;
        };

        /**
         * Decodes a DrycleaningSlot message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.DrycleaningSlot
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.DrycleaningSlot} DrycleaningSlot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrycleaningSlot.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.DrycleaningSlot();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.date = reader.string();
                    break;
                case 3:
                    message.startAt = reader.string();
                    break;
                case 4:
                    message.duration = reader.int32();
                    break;
                case 5:
                    message.timeRange = reader.string();
                    break;
                case 6:
                    message.pickup = reader.bool();
                    break;
                case 7:
                    message["return"] = reader.bool();
                    break;
                case 8:
                    message.price = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DrycleaningSlot;
    })();

    PlatformCRMWeb.DrycleaningCard = (function() {

        /**
         * Properties of a DrycleaningCard.
         * @memberof PlatformCRMWeb
         * @interface IDrycleaningCard
         * @property {string|null} [id] DrycleaningCard id
         * @property {string|null} [mask] DrycleaningCard mask
         * @property {boolean|null} ["default"] DrycleaningCard default
         */

        /**
         * Constructs a new DrycleaningCard.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a DrycleaningCard.
         * @implements IDrycleaningCard
         * @constructor
         * @param {PlatformCRMWeb.IDrycleaningCard=} [properties] Properties to set
         */
        function DrycleaningCard(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DrycleaningCard id.
         * @member {string} id
         * @memberof PlatformCRMWeb.DrycleaningCard
         * @instance
         */
        DrycleaningCard.prototype.id = "";

        /**
         * DrycleaningCard mask.
         * @member {string} mask
         * @memberof PlatformCRMWeb.DrycleaningCard
         * @instance
         */
        DrycleaningCard.prototype.mask = "";

        /**
         * DrycleaningCard default.
         * @member {boolean} default
         * @memberof PlatformCRMWeb.DrycleaningCard
         * @instance
         */
        DrycleaningCard.prototype["default"] = false;

        /**
         * Encodes the specified DrycleaningCard message. Does not implicitly {@link PlatformCRMWeb.DrycleaningCard.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.DrycleaningCard
         * @static
         * @param {PlatformCRMWeb.IDrycleaningCard} message DrycleaningCard message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrycleaningCard.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.mask != null && Object.hasOwnProperty.call(message, "mask"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.mask);
            if (message["default"] != null && Object.hasOwnProperty.call(message, "default"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message["default"]);
            return writer;
        };

        /**
         * Decodes a DrycleaningCard message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.DrycleaningCard
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.DrycleaningCard} DrycleaningCard
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrycleaningCard.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.DrycleaningCard();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.mask = reader.string();
                    break;
                case 3:
                    message["default"] = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DrycleaningCard;
    })();

    PlatformCRMWeb.Offer = (function() {

        /**
         * Properties of an Offer.
         * @memberof PlatformCRMWeb
         * @interface IOffer
         * @property {string|null} [promocode] Offer promocode
         * @property {string|null} [title] Offer title
         */

        /**
         * Constructs a new Offer.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an Offer.
         * @implements IOffer
         * @constructor
         * @param {PlatformCRMWeb.IOffer=} [properties] Properties to set
         */
        function Offer(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Offer promocode.
         * @member {string} promocode
         * @memberof PlatformCRMWeb.Offer
         * @instance
         */
        Offer.prototype.promocode = "";

        /**
         * Offer title.
         * @member {string} title
         * @memberof PlatformCRMWeb.Offer
         * @instance
         */
        Offer.prototype.title = "";

        /**
         * Encodes the specified Offer message. Does not implicitly {@link PlatformCRMWeb.Offer.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Offer
         * @static
         * @param {PlatformCRMWeb.IOffer} message Offer message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Offer.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.promocode);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            return writer;
        };

        /**
         * Decodes an Offer message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Offer
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Offer} Offer
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Offer.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Offer();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.promocode = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Offer;
    })();

    PlatformCRMWeb.GetDrycleaningTimeslotsRequest = (function() {

        /**
         * Properties of a GetDrycleaningTimeslotsRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetDrycleaningTimeslotsRequest
         * @property {string|null} [address] GetDrycleaningTimeslotsRequest address
         * @property {string|null} [planId] GetDrycleaningTimeslotsRequest planId
         */

        /**
         * Constructs a new GetDrycleaningTimeslotsRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetDrycleaningTimeslotsRequest.
         * @implements IGetDrycleaningTimeslotsRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetDrycleaningTimeslotsRequest=} [properties] Properties to set
         */
        function GetDrycleaningTimeslotsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetDrycleaningTimeslotsRequest address.
         * @member {string} address
         * @memberof PlatformCRMWeb.GetDrycleaningTimeslotsRequest
         * @instance
         */
        GetDrycleaningTimeslotsRequest.prototype.address = "";

        /**
         * GetDrycleaningTimeslotsRequest planId.
         * @member {string} planId
         * @memberof PlatformCRMWeb.GetDrycleaningTimeslotsRequest
         * @instance
         */
        GetDrycleaningTimeslotsRequest.prototype.planId = "";

        /**
         * Encodes the specified GetDrycleaningTimeslotsRequest message. Does not implicitly {@link PlatformCRMWeb.GetDrycleaningTimeslotsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetDrycleaningTimeslotsRequest
         * @static
         * @param {PlatformCRMWeb.IGetDrycleaningTimeslotsRequest} message GetDrycleaningTimeslotsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDrycleaningTimeslotsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
            if (message.planId != null && Object.hasOwnProperty.call(message, "planId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.planId);
            return writer;
        };

        /**
         * Decodes a GetDrycleaningTimeslotsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetDrycleaningTimeslotsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetDrycleaningTimeslotsRequest} GetDrycleaningTimeslotsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDrycleaningTimeslotsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetDrycleaningTimeslotsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.address = reader.string();
                    break;
                case 2:
                    message.planId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetDrycleaningTimeslotsRequest;
    })();

    PlatformCRMWeb.GetDrycleaningTimeslotsResponse = (function() {

        /**
         * Properties of a GetDrycleaningTimeslotsResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetDrycleaningTimeslotsResponse
         * @property {Array.<PlatformCRMWeb.IDrycleaningSlot>|null} [data] GetDrycleaningTimeslotsResponse data
         */

        /**
         * Constructs a new GetDrycleaningTimeslotsResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetDrycleaningTimeslotsResponse.
         * @implements IGetDrycleaningTimeslotsResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetDrycleaningTimeslotsResponse=} [properties] Properties to set
         */
        function GetDrycleaningTimeslotsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetDrycleaningTimeslotsResponse data.
         * @member {Array.<PlatformCRMWeb.IDrycleaningSlot>} data
         * @memberof PlatformCRMWeb.GetDrycleaningTimeslotsResponse
         * @instance
         */
        GetDrycleaningTimeslotsResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified GetDrycleaningTimeslotsResponse message. Does not implicitly {@link PlatformCRMWeb.GetDrycleaningTimeslotsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetDrycleaningTimeslotsResponse
         * @static
         * @param {PlatformCRMWeb.IGetDrycleaningTimeslotsResponse} message GetDrycleaningTimeslotsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDrycleaningTimeslotsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformCRMWeb.DrycleaningSlot.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetDrycleaningTimeslotsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetDrycleaningTimeslotsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetDrycleaningTimeslotsResponse} GetDrycleaningTimeslotsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDrycleaningTimeslotsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetDrycleaningTimeslotsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformCRMWeb.DrycleaningSlot.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetDrycleaningTimeslotsResponse;
    })();

    PlatformCRMWeb.GetDrycleaningCardsRequest = (function() {

        /**
         * Properties of a GetDrycleaningCardsRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetDrycleaningCardsRequest
         * @property {string|null} [userId] GetDrycleaningCardsRequest userId
         */

        /**
         * Constructs a new GetDrycleaningCardsRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetDrycleaningCardsRequest.
         * @implements IGetDrycleaningCardsRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetDrycleaningCardsRequest=} [properties] Properties to set
         */
        function GetDrycleaningCardsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetDrycleaningCardsRequest userId.
         * @member {string} userId
         * @memberof PlatformCRMWeb.GetDrycleaningCardsRequest
         * @instance
         */
        GetDrycleaningCardsRequest.prototype.userId = "";

        /**
         * Encodes the specified GetDrycleaningCardsRequest message. Does not implicitly {@link PlatformCRMWeb.GetDrycleaningCardsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetDrycleaningCardsRequest
         * @static
         * @param {PlatformCRMWeb.IGetDrycleaningCardsRequest} message GetDrycleaningCardsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDrycleaningCardsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            return writer;
        };

        /**
         * Decodes a GetDrycleaningCardsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetDrycleaningCardsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetDrycleaningCardsRequest} GetDrycleaningCardsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDrycleaningCardsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetDrycleaningCardsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetDrycleaningCardsRequest;
    })();

    PlatformCRMWeb.GetDrycleaningCardsResponse = (function() {

        /**
         * Properties of a GetDrycleaningCardsResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetDrycleaningCardsResponse
         * @property {Array.<PlatformCRMWeb.IDrycleaningCard>|null} [data] GetDrycleaningCardsResponse data
         */

        /**
         * Constructs a new GetDrycleaningCardsResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetDrycleaningCardsResponse.
         * @implements IGetDrycleaningCardsResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetDrycleaningCardsResponse=} [properties] Properties to set
         */
        function GetDrycleaningCardsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetDrycleaningCardsResponse data.
         * @member {Array.<PlatformCRMWeb.IDrycleaningCard>} data
         * @memberof PlatformCRMWeb.GetDrycleaningCardsResponse
         * @instance
         */
        GetDrycleaningCardsResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified GetDrycleaningCardsResponse message. Does not implicitly {@link PlatformCRMWeb.GetDrycleaningCardsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetDrycleaningCardsResponse
         * @static
         * @param {PlatformCRMWeb.IGetDrycleaningCardsResponse} message GetDrycleaningCardsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetDrycleaningCardsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformCRMWeb.DrycleaningCard.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetDrycleaningCardsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetDrycleaningCardsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetDrycleaningCardsResponse} GetDrycleaningCardsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetDrycleaningCardsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetDrycleaningCardsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformCRMWeb.DrycleaningCard.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetDrycleaningCardsResponse;
    })();

    PlatformCRMWeb.GetPlatformCardsRequest = (function() {

        /**
         * Properties of a GetPlatformCardsRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetPlatformCardsRequest
         * @property {string|null} [userId] GetPlatformCardsRequest userId
         */

        /**
         * Constructs a new GetPlatformCardsRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetPlatformCardsRequest.
         * @implements IGetPlatformCardsRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetPlatformCardsRequest=} [properties] Properties to set
         */
        function GetPlatformCardsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPlatformCardsRequest userId.
         * @member {string} userId
         * @memberof PlatformCRMWeb.GetPlatformCardsRequest
         * @instance
         */
        GetPlatformCardsRequest.prototype.userId = "";

        /**
         * Encodes the specified GetPlatformCardsRequest message. Does not implicitly {@link PlatformCRMWeb.GetPlatformCardsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetPlatformCardsRequest
         * @static
         * @param {PlatformCRMWeb.IGetPlatformCardsRequest} message GetPlatformCardsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlatformCardsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            return writer;
        };

        /**
         * Decodes a GetPlatformCardsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetPlatformCardsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetPlatformCardsRequest} GetPlatformCardsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlatformCardsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetPlatformCardsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetPlatformCardsRequest;
    })();

    PlatformCRMWeb.GetPlatformCardsResponse = (function() {

        /**
         * Properties of a GetPlatformCardsResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetPlatformCardsResponse
         * @property {Array.<PlatformCRMWeb.IPlatformCard>|null} [data] GetPlatformCardsResponse data
         */

        /**
         * Constructs a new GetPlatformCardsResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetPlatformCardsResponse.
         * @implements IGetPlatformCardsResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetPlatformCardsResponse=} [properties] Properties to set
         */
        function GetPlatformCardsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetPlatformCardsResponse data.
         * @member {Array.<PlatformCRMWeb.IPlatformCard>} data
         * @memberof PlatformCRMWeb.GetPlatformCardsResponse
         * @instance
         */
        GetPlatformCardsResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified GetPlatformCardsResponse message. Does not implicitly {@link PlatformCRMWeb.GetPlatformCardsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetPlatformCardsResponse
         * @static
         * @param {PlatformCRMWeb.IGetPlatformCardsResponse} message GetPlatformCardsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetPlatformCardsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformCRMWeb.PlatformCard.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetPlatformCardsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetPlatformCardsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetPlatformCardsResponse} GetPlatformCardsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetPlatformCardsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetPlatformCardsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformCRMWeb.PlatformCard.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetPlatformCardsResponse;
    })();

    PlatformCRMWeb.PlatformCard = (function() {

        /**
         * Properties of a PlatformCard.
         * @memberof PlatformCRMWeb
         * @interface IPlatformCard
         * @property {string|null} [cardId] PlatformCard cardId
         * @property {string|null} [cardNumber] PlatformCard cardNumber
         */

        /**
         * Constructs a new PlatformCard.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PlatformCard.
         * @implements IPlatformCard
         * @constructor
         * @param {PlatformCRMWeb.IPlatformCard=} [properties] Properties to set
         */
        function PlatformCard(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PlatformCard cardId.
         * @member {string} cardId
         * @memberof PlatformCRMWeb.PlatformCard
         * @instance
         */
        PlatformCard.prototype.cardId = "";

        /**
         * PlatformCard cardNumber.
         * @member {string} cardNumber
         * @memberof PlatformCRMWeb.PlatformCard
         * @instance
         */
        PlatformCard.prototype.cardNumber = "";

        /**
         * Encodes the specified PlatformCard message. Does not implicitly {@link PlatformCRMWeb.PlatformCard.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PlatformCard
         * @static
         * @param {PlatformCRMWeb.IPlatformCard} message PlatformCard message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PlatformCard.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cardId != null && Object.hasOwnProperty.call(message, "cardId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.cardId);
            if (message.cardNumber != null && Object.hasOwnProperty.call(message, "cardNumber"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.cardNumber);
            return writer;
        };

        /**
         * Decodes a PlatformCard message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PlatformCard
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PlatformCard} PlatformCard
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PlatformCard.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PlatformCard();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cardId = reader.string();
                    break;
                case 2:
                    message.cardNumber = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PlatformCard;
    })();

    PlatformCRMWeb.GetOffersRequest = (function() {

        /**
         * Properties of a GetOffersRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetOffersRequest
         * @property {string|null} [product] GetOffersRequest product
         * @property {string|null} [userId] GetOffersRequest userId
         * @property {string|null} [regionId] GetOffersRequest regionId
         * @property {string|null} [promocode] GetOffersRequest promocode
         */

        /**
         * Constructs a new GetOffersRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetOffersRequest.
         * @implements IGetOffersRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetOffersRequest=} [properties] Properties to set
         */
        function GetOffersRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOffersRequest product.
         * @member {string} product
         * @memberof PlatformCRMWeb.GetOffersRequest
         * @instance
         */
        GetOffersRequest.prototype.product = "";

        /**
         * GetOffersRequest userId.
         * @member {string} userId
         * @memberof PlatformCRMWeb.GetOffersRequest
         * @instance
         */
        GetOffersRequest.prototype.userId = "";

        /**
         * GetOffersRequest regionId.
         * @member {string} regionId
         * @memberof PlatformCRMWeb.GetOffersRequest
         * @instance
         */
        GetOffersRequest.prototype.regionId = "";

        /**
         * GetOffersRequest promocode.
         * @member {string} promocode
         * @memberof PlatformCRMWeb.GetOffersRequest
         * @instance
         */
        GetOffersRequest.prototype.promocode = "";

        /**
         * Encodes the specified GetOffersRequest message. Does not implicitly {@link PlatformCRMWeb.GetOffersRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetOffersRequest
         * @static
         * @param {PlatformCRMWeb.IGetOffersRequest} message GetOffersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOffersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.product);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.userId);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.regionId);
            if (message.promocode != null && Object.hasOwnProperty.call(message, "promocode"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.promocode);
            return writer;
        };

        /**
         * Decodes a GetOffersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetOffersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetOffersRequest} GetOffersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOffersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetOffersRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.product = reader.string();
                    break;
                case 2:
                    message.userId = reader.string();
                    break;
                case 3:
                    message.regionId = reader.string();
                    break;
                case 4:
                    message.promocode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetOffersRequest;
    })();

    PlatformCRMWeb.GetOffersResponse = (function() {

        /**
         * Properties of a GetOffersResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetOffersResponse
         * @property {Array.<PlatformCRMWeb.IOffer>|null} [data] GetOffersResponse data
         */

        /**
         * Constructs a new GetOffersResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetOffersResponse.
         * @implements IGetOffersResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetOffersResponse=} [properties] Properties to set
         */
        function GetOffersResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetOffersResponse data.
         * @member {Array.<PlatformCRMWeb.IOffer>} data
         * @memberof PlatformCRMWeb.GetOffersResponse
         * @instance
         */
        GetOffersResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified GetOffersResponse message. Does not implicitly {@link PlatformCRMWeb.GetOffersResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetOffersResponse
         * @static
         * @param {PlatformCRMWeb.IGetOffersResponse} message GetOffersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetOffersResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformCRMWeb.Offer.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetOffersResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetOffersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetOffersResponse} GetOffersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetOffersResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetOffersResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformCRMWeb.Offer.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetOffersResponse;
    })();

    PlatformCRMWeb.EmptyRequest = (function() {

        /**
         * Properties of an EmptyRequest.
         * @memberof PlatformCRMWeb
         * @interface IEmptyRequest
         */

        /**
         * Constructs a new EmptyRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an EmptyRequest.
         * @implements IEmptyRequest
         * @constructor
         * @param {PlatformCRMWeb.IEmptyRequest=} [properties] Properties to set
         */
        function EmptyRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified EmptyRequest message. Does not implicitly {@link PlatformCRMWeb.EmptyRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.EmptyRequest
         * @static
         * @param {PlatformCRMWeb.IEmptyRequest} message EmptyRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmptyRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an EmptyRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.EmptyRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.EmptyRequest} EmptyRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmptyRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.EmptyRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return EmptyRequest;
    })();

    PlatformCRMWeb.GetCrmWebURLResponse = (function() {

        /**
         * Properties of a GetCrmWebURLResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetCrmWebURLResponse
         * @property {string|null} [url] GetCrmWebURLResponse url
         */

        /**
         * Constructs a new GetCrmWebURLResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetCrmWebURLResponse.
         * @implements IGetCrmWebURLResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetCrmWebURLResponse=} [properties] Properties to set
         */
        function GetCrmWebURLResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetCrmWebURLResponse url.
         * @member {string} url
         * @memberof PlatformCRMWeb.GetCrmWebURLResponse
         * @instance
         */
        GetCrmWebURLResponse.prototype.url = "";

        /**
         * Encodes the specified GetCrmWebURLResponse message. Does not implicitly {@link PlatformCRMWeb.GetCrmWebURLResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetCrmWebURLResponse
         * @static
         * @param {PlatformCRMWeb.IGetCrmWebURLResponse} message GetCrmWebURLResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetCrmWebURLResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
            return writer;
        };

        /**
         * Decodes a GetCrmWebURLResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetCrmWebURLResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetCrmWebURLResponse} GetCrmWebURLResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetCrmWebURLResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetCrmWebURLResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetCrmWebURLResponse;
    })();

    PlatformCRMWeb.MetaService = (function() {

        /**
         * Constructs a new MetaService service.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a MetaService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function MetaService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (MetaService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MetaService;

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#getClientAddresses}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef GetClientAddressesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetClientAddressResponse} [response] GetClientAddressResponse
         */

        /**
         * Calls GetClientAddresses.
         * @function getClientAddresses
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetClientAddressesRequest} request GetClientAddressesRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.GetClientAddressesCallback} callback Node-style callback called with the error, if any, and GetClientAddressResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.getClientAddresses = function getClientAddresses(request, callback) {
            return this.rpcCall(getClientAddresses, $root.PlatformCRMWeb.GetClientAddressesRequest, $root.PlatformCRMWeb.GetClientAddressResponse, request, callback);
        }, "name", { value: "GetClientAddresses" });

        /**
         * Calls GetClientAddresses.
         * @function getClientAddresses
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetClientAddressesRequest} request GetClientAddressesRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetClientAddressResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#getAvailableDates}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef GetAvailableDatesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetAvailableDatesResponse} [response] GetAvailableDatesResponse
         */

        /**
         * Calls GetAvailableDates.
         * @function getAvailableDates
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetAvailableDatesRequest} request GetAvailableDatesRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.GetAvailableDatesCallback} callback Node-style callback called with the error, if any, and GetAvailableDatesResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.getAvailableDates = function getAvailableDates(request, callback) {
            return this.rpcCall(getAvailableDates, $root.PlatformCRMWeb.GetAvailableDatesRequest, $root.PlatformCRMWeb.GetAvailableDatesResponse, request, callback);
        }, "name", { value: "GetAvailableDates" });

        /**
         * Calls GetAvailableDates.
         * @function getAvailableDates
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetAvailableDatesRequest} request GetAvailableDatesRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetAvailableDatesResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#getAvailableTimeSlots}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef GetAvailableTimeSlotsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetAvailableTimeSlotsResponse} [response] GetAvailableTimeSlotsResponse
         */

        /**
         * Calls GetAvailableTimeSlots.
         * @function getAvailableTimeSlots
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsRequest} request GetAvailableTimeSlotsRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.GetAvailableTimeSlotsCallback} callback Node-style callback called with the error, if any, and GetAvailableTimeSlotsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.getAvailableTimeSlots = function getAvailableTimeSlots(request, callback) {
            return this.rpcCall(getAvailableTimeSlots, $root.PlatformCRMWeb.GetAvailableTimeSlotsRequest, $root.PlatformCRMWeb.GetAvailableTimeSlotsResponse, request, callback);
        }, "name", { value: "GetAvailableTimeSlots" });

        /**
         * Calls GetAvailableTimeSlots.
         * @function getAvailableTimeSlots
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsRequest} request GetAvailableTimeSlotsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetAvailableTimeSlotsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#getAvailableTimeSlotsFromLmsByAddressId}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef GetAvailableTimeSlotsFromLmsByAddressIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetAvailableTimeSlotsResponse} [response] GetAvailableTimeSlotsResponse
         */

        /**
         * Calls GetAvailableTimeSlotsFromLmsByAddressId.
         * @function getAvailableTimeSlotsFromLmsByAddressId
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsFromLmsRequest} request GetAvailableTimeSlotsFromLmsRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.GetAvailableTimeSlotsFromLmsByAddressIdCallback} callback Node-style callback called with the error, if any, and GetAvailableTimeSlotsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.getAvailableTimeSlotsFromLmsByAddressId = function getAvailableTimeSlotsFromLmsByAddressId(request, callback) {
            return this.rpcCall(getAvailableTimeSlotsFromLmsByAddressId, $root.PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest, $root.PlatformCRMWeb.GetAvailableTimeSlotsResponse, request, callback);
        }, "name", { value: "GetAvailableTimeSlotsFromLmsByAddressId" });

        /**
         * Calls GetAvailableTimeSlotsFromLmsByAddressId.
         * @function getAvailableTimeSlotsFromLmsByAddressId
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsFromLmsRequest} request GetAvailableTimeSlotsFromLmsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetAvailableTimeSlotsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#getAvailableTimeSlotsFromLmsByCoordinates}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef GetAvailableTimeSlotsFromLmsByCoordinatesCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetAvailableTimeSlotsResponse} [response] GetAvailableTimeSlotsResponse
         */

        /**
         * Calls GetAvailableTimeSlotsFromLmsByCoordinates.
         * @function getAvailableTimeSlotsFromLmsByCoordinates
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsFromLmsRequest} request GetAvailableTimeSlotsFromLmsRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.GetAvailableTimeSlotsFromLmsByCoordinatesCallback} callback Node-style callback called with the error, if any, and GetAvailableTimeSlotsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.getAvailableTimeSlotsFromLmsByCoordinates = function getAvailableTimeSlotsFromLmsByCoordinates(request, callback) {
            return this.rpcCall(getAvailableTimeSlotsFromLmsByCoordinates, $root.PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest, $root.PlatformCRMWeb.GetAvailableTimeSlotsResponse, request, callback);
        }, "name", { value: "GetAvailableTimeSlotsFromLmsByCoordinates" });

        /**
         * Calls GetAvailableTimeSlotsFromLmsByCoordinates.
         * @function getAvailableTimeSlotsFromLmsByCoordinates
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetAvailableTimeSlotsFromLmsRequest} request GetAvailableTimeSlotsFromLmsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetAvailableTimeSlotsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#searchPromocode}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef SearchPromocodeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.SearchPromocodeResponse} [response] SearchPromocodeResponse
         */

        /**
         * Calls SearchPromocode.
         * @function searchPromocode
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.ISearchPromocodeRequest} request SearchPromocodeRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.SearchPromocodeCallback} callback Node-style callback called with the error, if any, and SearchPromocodeResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.searchPromocode = function searchPromocode(request, callback) {
            return this.rpcCall(searchPromocode, $root.PlatformCRMWeb.SearchPromocodeRequest, $root.PlatformCRMWeb.SearchPromocodeResponse, request, callback);
        }, "name", { value: "SearchPromocode" });

        /**
         * Calls SearchPromocode.
         * @function searchPromocode
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.ISearchPromocodeRequest} request SearchPromocodeRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.SearchPromocodeResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#getPriceElement}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef GetPriceElementCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetPriceElementResponse} [response] GetPriceElementResponse
         */

        /**
         * Calls GetPriceElement.
         * @function getPriceElement
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetPriceElementRequest} request GetPriceElementRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.GetPriceElementCallback} callback Node-style callback called with the error, if any, and GetPriceElementResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.getPriceElement = function getPriceElement(request, callback) {
            return this.rpcCall(getPriceElement, $root.PlatformCRMWeb.GetPriceElementRequest, $root.PlatformCRMWeb.GetPriceElementResponse, request, callback);
        }, "name", { value: "GetPriceElement" });

        /**
         * Calls GetPriceElement.
         * @function getPriceElement
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetPriceElementRequest} request GetPriceElementRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetPriceElementResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#getDrycleaningTimeslots}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef GetDrycleaningTimeslotsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetDrycleaningTimeslotsResponse} [response] GetDrycleaningTimeslotsResponse
         */

        /**
         * Calls GetDrycleaningTimeslots.
         * @function getDrycleaningTimeslots
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetDrycleaningTimeslotsRequest} request GetDrycleaningTimeslotsRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.GetDrycleaningTimeslotsCallback} callback Node-style callback called with the error, if any, and GetDrycleaningTimeslotsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.getDrycleaningTimeslots = function getDrycleaningTimeslots(request, callback) {
            return this.rpcCall(getDrycleaningTimeslots, $root.PlatformCRMWeb.GetDrycleaningTimeslotsRequest, $root.PlatformCRMWeb.GetDrycleaningTimeslotsResponse, request, callback);
        }, "name", { value: "GetDrycleaningTimeslots" });

        /**
         * Calls GetDrycleaningTimeslots.
         * @function getDrycleaningTimeslots
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetDrycleaningTimeslotsRequest} request GetDrycleaningTimeslotsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetDrycleaningTimeslotsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#getDrycleaningCards}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef GetDrycleaningCardsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetDrycleaningCardsResponse} [response] GetDrycleaningCardsResponse
         */

        /**
         * Calls GetDrycleaningCards.
         * @function getDrycleaningCards
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetDrycleaningCardsRequest} request GetDrycleaningCardsRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.GetDrycleaningCardsCallback} callback Node-style callback called with the error, if any, and GetDrycleaningCardsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.getDrycleaningCards = function getDrycleaningCards(request, callback) {
            return this.rpcCall(getDrycleaningCards, $root.PlatformCRMWeb.GetDrycleaningCardsRequest, $root.PlatformCRMWeb.GetDrycleaningCardsResponse, request, callback);
        }, "name", { value: "GetDrycleaningCards" });

        /**
         * Calls GetDrycleaningCards.
         * @function getDrycleaningCards
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetDrycleaningCardsRequest} request GetDrycleaningCardsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetDrycleaningCardsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#getOffers}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef GetOffersCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetOffersResponse} [response] GetOffersResponse
         */

        /**
         * Calls GetOffers.
         * @function getOffers
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetOffersRequest} request GetOffersRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.GetOffersCallback} callback Node-style callback called with the error, if any, and GetOffersResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.getOffers = function getOffers(request, callback) {
            return this.rpcCall(getOffers, $root.PlatformCRMWeb.GetOffersRequest, $root.PlatformCRMWeb.GetOffersResponse, request, callback);
        }, "name", { value: "GetOffers" });

        /**
         * Calls GetOffers.
         * @function getOffers
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetOffersRequest} request GetOffersRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetOffersResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#getCrmWebURL}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef GetCrmWebURLCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetCrmWebURLResponse} [response] GetCrmWebURLResponse
         */

        /**
         * Calls GetCrmWebURL.
         * @function getCrmWebURL
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IEmptyRequest} request EmptyRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.GetCrmWebURLCallback} callback Node-style callback called with the error, if any, and GetCrmWebURLResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.getCrmWebURL = function getCrmWebURL(request, callback) {
            return this.rpcCall(getCrmWebURL, $root.PlatformCRMWeb.EmptyRequest, $root.PlatformCRMWeb.GetCrmWebURLResponse, request, callback);
        }, "name", { value: "GetCrmWebURL" });

        /**
         * Calls GetCrmWebURL.
         * @function getCrmWebURL
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IEmptyRequest} request EmptyRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetCrmWebURLResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.MetaService#getPlatformCards}.
         * @memberof PlatformCRMWeb.MetaService
         * @typedef GetPlatformCardsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetPlatformCardsResponse} [response] GetPlatformCardsResponse
         */

        /**
         * Calls GetPlatformCards.
         * @function getPlatformCards
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetPlatformCardsRequest} request GetPlatformCardsRequest message or plain object
         * @param {PlatformCRMWeb.MetaService.GetPlatformCardsCallback} callback Node-style callback called with the error, if any, and GetPlatformCardsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(MetaService.prototype.getPlatformCards = function getPlatformCards(request, callback) {
            return this.rpcCall(getPlatformCards, $root.PlatformCRMWeb.GetPlatformCardsRequest, $root.PlatformCRMWeb.GetPlatformCardsResponse, request, callback);
        }, "name", { value: "GetPlatformCards" });

        /**
         * Calls GetPlatformCards.
         * @function getPlatformCards
         * @memberof PlatformCRMWeb.MetaService
         * @instance
         * @param {PlatformCRMWeb.IGetPlatformCardsRequest} request GetPlatformCardsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetPlatformCardsResponse>} Promise
         * @variation 2
         */

        return MetaService;
    })();

    /**
     * MatrixUnit enum.
     * @name PlatformCRMWeb.MatrixUnit
     * @enum {number}
     * @property {number} UNDEF=0 UNDEF value
     * @property {number} UNIT=1 UNIT value
     * @property {number} SERVICE=2 SERVICE value
     */
    PlatformCRMWeb.MatrixUnit = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEF"] = 0;
        values[valuesById[1] = "UNIT"] = 1;
        values[valuesById[2] = "SERVICE"] = 2;
        return values;
    })();

    /**
     * PriceElementOptionType enum.
     * @name PlatformCRMWeb.PriceElementOptionType
     * @enum {number}
     * @property {number} UNDEF=0 UNDEF value
     * @property {number} BASE=1 BASE value
     * @property {number} OPTION=2 OPTION value
     * @property {number} CROSS_SALE=3 CROSS_SALE value
     */
    PlatformCRMWeb.PriceElementOptionType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEF"] = 0;
        values[valuesById[1] = "BASE"] = 1;
        values[valuesById[2] = "OPTION"] = 2;
        values[valuesById[3] = "CROSS_SALE"] = 3;
        return values;
    })();

    PlatformCRMWeb.PriceElementMatrixItem = (function() {

        /**
         * Properties of a PriceElementMatrixItem.
         * @memberof PlatformCRMWeb
         * @interface IPriceElementMatrixItem
         * @property {string|null} [range] PriceElementMatrixItem range
         * @property {number|null} [value] PriceElementMatrixItem value
         */

        /**
         * Constructs a new PriceElementMatrixItem.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PriceElementMatrixItem.
         * @implements IPriceElementMatrixItem
         * @constructor
         * @param {PlatformCRMWeb.IPriceElementMatrixItem=} [properties] Properties to set
         */
        function PriceElementMatrixItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceElementMatrixItem range.
         * @member {string} range
         * @memberof PlatformCRMWeb.PriceElementMatrixItem
         * @instance
         */
        PriceElementMatrixItem.prototype.range = "";

        /**
         * PriceElementMatrixItem value.
         * @member {number} value
         * @memberof PlatformCRMWeb.PriceElementMatrixItem
         * @instance
         */
        PriceElementMatrixItem.prototype.value = 0;

        /**
         * Encodes the specified PriceElementMatrixItem message. Does not implicitly {@link PlatformCRMWeb.PriceElementMatrixItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PriceElementMatrixItem
         * @static
         * @param {PlatformCRMWeb.IPriceElementMatrixItem} message PriceElementMatrixItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceElementMatrixItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.range != null && Object.hasOwnProperty.call(message, "range"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.range);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.value);
            return writer;
        };

        /**
         * Decodes a PriceElementMatrixItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PriceElementMatrixItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PriceElementMatrixItem} PriceElementMatrixItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceElementMatrixItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PriceElementMatrixItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.range = reader.string();
                    break;
                case 2:
                    message.value = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceElementMatrixItem;
    })();

    PlatformCRMWeb.PriceElementMatrix = (function() {

        /**
         * Properties of a PriceElementMatrix.
         * @memberof PlatformCRMWeb
         * @interface IPriceElementMatrix
         * @property {PlatformCRMWeb.MatrixUnit|null} [per] PriceElementMatrix per
         * @property {Array.<PlatformCRMWeb.IPriceElementMatrixItem>|null} [items] PriceElementMatrix items
         */

        /**
         * Constructs a new PriceElementMatrix.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PriceElementMatrix.
         * @implements IPriceElementMatrix
         * @constructor
         * @param {PlatformCRMWeb.IPriceElementMatrix=} [properties] Properties to set
         */
        function PriceElementMatrix(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceElementMatrix per.
         * @member {PlatformCRMWeb.MatrixUnit} per
         * @memberof PlatformCRMWeb.PriceElementMatrix
         * @instance
         */
        PriceElementMatrix.prototype.per = 0;

        /**
         * PriceElementMatrix items.
         * @member {Array.<PlatformCRMWeb.IPriceElementMatrixItem>} items
         * @memberof PlatformCRMWeb.PriceElementMatrix
         * @instance
         */
        PriceElementMatrix.prototype.items = $util.emptyArray;

        /**
         * Encodes the specified PriceElementMatrix message. Does not implicitly {@link PlatformCRMWeb.PriceElementMatrix.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PriceElementMatrix
         * @static
         * @param {PlatformCRMWeb.IPriceElementMatrix} message PriceElementMatrix message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceElementMatrix.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.per != null && Object.hasOwnProperty.call(message, "per"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.per);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.PlatformCRMWeb.PriceElementMatrixItem.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a PriceElementMatrix message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PriceElementMatrix
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PriceElementMatrix} PriceElementMatrix
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceElementMatrix.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PriceElementMatrix();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.per = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PlatformCRMWeb.PriceElementMatrixItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceElementMatrix;
    })();

    PlatformCRMWeb.PriceElementOption = (function() {

        /**
         * Properties of a PriceElementOption.
         * @memberof PlatformCRMWeb
         * @interface IPriceElementOption
         * @property {PlatformCRMWeb.IPriceElement|null} [priceElement] PriceElementOption priceElement
         * @property {string|null} [slug] PriceElementOption slug
         * @property {number|null} [costLinear] PriceElementOption costLinear
         * @property {PlatformCRMWeb.IPriceElementMatrix|null} [costMatrix] PriceElementOption costMatrix
         * @property {number|null} [durationLinear] PriceElementOption durationLinear
         * @property {PlatformCRMWeb.IPriceElementMatrix|null} [durationMatrix] PriceElementOption durationMatrix
         * @property {boolean|null} [isFree] PriceElementOption isFree
         * @property {number|null} [defaultValue] PriceElementOption defaultValue
         * @property {Array.<string>|null} [tags] PriceElementOption tags
         * @property {string|null} [title] PriceElementOption title
         * @property {string|null} [shortDesc] PriceElementOption shortDesc
         * @property {string|null} [iconName] PriceElementOption iconName
         * @property {PlatformCRMWeb.PriceElementOptionType|null} [type] PriceElementOption type
         * @property {string|null} [display] PriceElementOption display
         * @property {number|null} [defaultFactor] PriceElementOption defaultFactor
         */

        /**
         * Constructs a new PriceElementOption.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PriceElementOption.
         * @implements IPriceElementOption
         * @constructor
         * @param {PlatformCRMWeb.IPriceElementOption=} [properties] Properties to set
         */
        function PriceElementOption(properties) {
            this.tags = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceElementOption priceElement.
         * @member {PlatformCRMWeb.IPriceElement|null|undefined} priceElement
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.priceElement = null;

        /**
         * PriceElementOption slug.
         * @member {string} slug
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.slug = "";

        /**
         * PriceElementOption costLinear.
         * @member {number} costLinear
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.costLinear = 0;

        /**
         * PriceElementOption costMatrix.
         * @member {PlatformCRMWeb.IPriceElementMatrix|null|undefined} costMatrix
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.costMatrix = null;

        /**
         * PriceElementOption durationLinear.
         * @member {number} durationLinear
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.durationLinear = 0;

        /**
         * PriceElementOption durationMatrix.
         * @member {PlatformCRMWeb.IPriceElementMatrix|null|undefined} durationMatrix
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.durationMatrix = null;

        /**
         * PriceElementOption isFree.
         * @member {boolean} isFree
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.isFree = false;

        /**
         * PriceElementOption defaultValue.
         * @member {number} defaultValue
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.defaultValue = 0;

        /**
         * PriceElementOption tags.
         * @member {Array.<string>} tags
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.tags = $util.emptyArray;

        /**
         * PriceElementOption title.
         * @member {string} title
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.title = "";

        /**
         * PriceElementOption shortDesc.
         * @member {string} shortDesc
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.shortDesc = "";

        /**
         * PriceElementOption iconName.
         * @member {string} iconName
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.iconName = "";

        /**
         * PriceElementOption type.
         * @member {PlatformCRMWeb.PriceElementOptionType} type
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.type = 0;

        /**
         * PriceElementOption display.
         * @member {string} display
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.display = "";

        /**
         * PriceElementOption defaultFactor.
         * @member {number} defaultFactor
         * @memberof PlatformCRMWeb.PriceElementOption
         * @instance
         */
        PriceElementOption.prototype.defaultFactor = 0;

        /**
         * Encodes the specified PriceElementOption message. Does not implicitly {@link PlatformCRMWeb.PriceElementOption.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PriceElementOption
         * @static
         * @param {PlatformCRMWeb.IPriceElementOption} message PriceElementOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceElementOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.priceElement != null && Object.hasOwnProperty.call(message, "priceElement"))
                $root.PlatformCRMWeb.PriceElement.encode(message.priceElement, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.slug != null && Object.hasOwnProperty.call(message, "slug"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.slug);
            if (message.costLinear != null && Object.hasOwnProperty.call(message, "costLinear"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.costLinear);
            if (message.costMatrix != null && Object.hasOwnProperty.call(message, "costMatrix"))
                $root.PlatformCRMWeb.PriceElementMatrix.encode(message.costMatrix, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.durationLinear != null && Object.hasOwnProperty.call(message, "durationLinear"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.durationLinear);
            if (message.durationMatrix != null && Object.hasOwnProperty.call(message, "durationMatrix"))
                $root.PlatformCRMWeb.PriceElementMatrix.encode(message.durationMatrix, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.isFree != null && Object.hasOwnProperty.call(message, "isFree"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isFree);
            if (message.defaultValue != null && Object.hasOwnProperty.call(message, "defaultValue"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.defaultValue);
            if (message.tags != null && message.tags.length)
                for (var i = 0; i < message.tags.length; ++i)
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.tags[i]);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.title);
            if (message.shortDesc != null && Object.hasOwnProperty.call(message, "shortDesc"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.shortDesc);
            if (message.iconName != null && Object.hasOwnProperty.call(message, "iconName"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.iconName);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.type);
            if (message.display != null && Object.hasOwnProperty.call(message, "display"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.display);
            if (message.defaultFactor != null && Object.hasOwnProperty.call(message, "defaultFactor"))
                writer.uint32(/* id 15, wireType 5 =*/125).float(message.defaultFactor);
            return writer;
        };

        /**
         * Decodes a PriceElementOption message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PriceElementOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PriceElementOption} PriceElementOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceElementOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PriceElementOption();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.priceElement = $root.PlatformCRMWeb.PriceElement.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.slug = reader.string();
                    break;
                case 3:
                    message.costLinear = reader.int32();
                    break;
                case 4:
                    message.costMatrix = $root.PlatformCRMWeb.PriceElementMatrix.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.durationLinear = reader.int32();
                    break;
                case 6:
                    message.durationMatrix = $root.PlatformCRMWeb.PriceElementMatrix.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.isFree = reader.bool();
                    break;
                case 8:
                    message.defaultValue = reader.int32();
                    break;
                case 9:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push(reader.string());
                    break;
                case 10:
                    message.title = reader.string();
                    break;
                case 11:
                    message.shortDesc = reader.string();
                    break;
                case 12:
                    message.iconName = reader.string();
                    break;
                case 13:
                    message.type = reader.int32();
                    break;
                case 14:
                    message.display = reader.string();
                    break;
                case 15:
                    message.defaultFactor = reader.float();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceElementOption;
    })();

    PlatformCRMWeb.PriceElement = (function() {

        /**
         * Properties of a PriceElement.
         * @memberof PlatformCRMWeb
         * @interface IPriceElement
         * @property {string|null} [id] PriceElement id
         * @property {string|null} [productSlug] PriceElement productSlug
         * @property {Array.<PlatformCRMWeb.IPriceElementOption>|null} [options] PriceElement options
         * @property {number|null} [cost] PriceElement cost
         * @property {number|null} [duration] PriceElement duration
         */

        /**
         * Constructs a new PriceElement.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PriceElement.
         * @implements IPriceElement
         * @constructor
         * @param {PlatformCRMWeb.IPriceElement=} [properties] Properties to set
         */
        function PriceElement(properties) {
            this.options = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PriceElement id.
         * @member {string} id
         * @memberof PlatformCRMWeb.PriceElement
         * @instance
         */
        PriceElement.prototype.id = "";

        /**
         * PriceElement productSlug.
         * @member {string} productSlug
         * @memberof PlatformCRMWeb.PriceElement
         * @instance
         */
        PriceElement.prototype.productSlug = "";

        /**
         * PriceElement options.
         * @member {Array.<PlatformCRMWeb.IPriceElementOption>} options
         * @memberof PlatformCRMWeb.PriceElement
         * @instance
         */
        PriceElement.prototype.options = $util.emptyArray;

        /**
         * PriceElement cost.
         * @member {number} cost
         * @memberof PlatformCRMWeb.PriceElement
         * @instance
         */
        PriceElement.prototype.cost = 0;

        /**
         * PriceElement duration.
         * @member {number} duration
         * @memberof PlatformCRMWeb.PriceElement
         * @instance
         */
        PriceElement.prototype.duration = 0;

        /**
         * Encodes the specified PriceElement message. Does not implicitly {@link PlatformCRMWeb.PriceElement.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PriceElement
         * @static
         * @param {PlatformCRMWeb.IPriceElement} message PriceElement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PriceElement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.productSlug != null && Object.hasOwnProperty.call(message, "productSlug"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.productSlug);
            if (message.options != null && message.options.length)
                for (var i = 0; i < message.options.length; ++i)
                    $root.PlatformCRMWeb.PriceElementOption.encode(message.options[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.cost != null && Object.hasOwnProperty.call(message, "cost"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.cost);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.duration);
            return writer;
        };

        /**
         * Decodes a PriceElement message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PriceElement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PriceElement} PriceElement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PriceElement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PriceElement();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.productSlug = reader.string();
                    break;
                case 3:
                    if (!(message.options && message.options.length))
                        message.options = [];
                    message.options.push($root.PlatformCRMWeb.PriceElementOption.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.cost = reader.int32();
                    break;
                case 5:
                    message.duration = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PriceElement;
    })();

    /**
     * TaskState enum.
     * @name PlatformCRMWeb.TaskState
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} NEW=1 NEW value
     * @property {number} IN_WORK=2 IN_WORK value
     * @property {number} PROCEED=3 PROCEED value
     * @property {number} CANCELLED=4 CANCELLED value
     * @property {number} POSTPONED=5 POSTPONED value
     * @property {number} RESCHEDULED=6 RESCHEDULED value
     * @property {number} NO_COMMUNICATION=7 NO_COMMUNICATION value
     * @property {number} DEDUPLICATED=8 DEDUPLICATED value
     */
    PlatformCRMWeb.TaskState = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "NEW"] = 1;
        values[valuesById[2] = "IN_WORK"] = 2;
        values[valuesById[3] = "PROCEED"] = 3;
        values[valuesById[4] = "CANCELLED"] = 4;
        values[valuesById[5] = "POSTPONED"] = 5;
        values[valuesById[6] = "RESCHEDULED"] = 6;
        values[valuesById[7] = "NO_COMMUNICATION"] = 7;
        values[valuesById[8] = "DEDUPLICATED"] = 8;
        return values;
    })();

    PlatformCRMWeb.Skill = (function() {

        /**
         * Properties of a Skill.
         * @memberof PlatformCRMWeb
         * @interface ISkill
         * @property {string|null} [id] Skill id
         * @property {string|null} [name] Skill name
         * @property {number|null} [priority] Skill priority
         * @property {string|null} [createdAt] Skill createdAt
         * @property {string|null} [updatedAt] Skill updatedAt
         * @property {boolean|null} [isDeleted] Skill isDeleted
         * @property {string|null} [deletedAt] Skill deletedAt
         */

        /**
         * Constructs a new Skill.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Skill.
         * @implements ISkill
         * @constructor
         * @param {PlatformCRMWeb.ISkill=} [properties] Properties to set
         */
        function Skill(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Skill id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Skill
         * @instance
         */
        Skill.prototype.id = "";

        /**
         * Skill name.
         * @member {string} name
         * @memberof PlatformCRMWeb.Skill
         * @instance
         */
        Skill.prototype.name = "";

        /**
         * Skill priority.
         * @member {number} priority
         * @memberof PlatformCRMWeb.Skill
         * @instance
         */
        Skill.prototype.priority = 0;

        /**
         * Skill createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.Skill
         * @instance
         */
        Skill.prototype.createdAt = "";

        /**
         * Skill updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.Skill
         * @instance
         */
        Skill.prototype.updatedAt = "";

        /**
         * Skill isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformCRMWeb.Skill
         * @instance
         */
        Skill.prototype.isDeleted = false;

        /**
         * Skill deletedAt.
         * @member {string} deletedAt
         * @memberof PlatformCRMWeb.Skill
         * @instance
         */
        Skill.prototype.deletedAt = "";

        /**
         * Encodes the specified Skill message. Does not implicitly {@link PlatformCRMWeb.Skill.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Skill
         * @static
         * @param {PlatformCRMWeb.ISkill} message Skill message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Skill.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.priority);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            return writer;
        };

        /**
         * Decodes a Skill message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Skill
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Skill} Skill
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Skill.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Skill();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.priority = reader.int32();
                    break;
                case 4:
                    message.createdAt = reader.string();
                    break;
                case 5:
                    message.updatedAt = reader.string();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Skill;
    })();

    /**
     * EntityType enum.
     * @name PlatformCRMWeb.EntityType
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} PROSPECT=1 PROSPECT value
     * @property {number} ORDER=2 ORDER value
     * @property {number} TICKET=3 TICKET value
     */
    PlatformCRMWeb.EntityType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "PROSPECT"] = 1;
        values[valuesById[2] = "ORDER"] = 2;
        values[valuesById[3] = "TICKET"] = 3;
        return values;
    })();

    PlatformCRMWeb.LinkedTaskEntity = (function() {

        /**
         * Properties of a LinkedTaskEntity.
         * @memberof PlatformCRMWeb
         * @interface ILinkedTaskEntity
         * @property {string|null} [entityId] LinkedTaskEntity entityId
         * @property {PlatformCRMWeb.EntityType|null} [entityType] LinkedTaskEntity entityType
         */

        /**
         * Constructs a new LinkedTaskEntity.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a LinkedTaskEntity.
         * @implements ILinkedTaskEntity
         * @constructor
         * @param {PlatformCRMWeb.ILinkedTaskEntity=} [properties] Properties to set
         */
        function LinkedTaskEntity(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LinkedTaskEntity entityId.
         * @member {string} entityId
         * @memberof PlatformCRMWeb.LinkedTaskEntity
         * @instance
         */
        LinkedTaskEntity.prototype.entityId = "";

        /**
         * LinkedTaskEntity entityType.
         * @member {PlatformCRMWeb.EntityType} entityType
         * @memberof PlatformCRMWeb.LinkedTaskEntity
         * @instance
         */
        LinkedTaskEntity.prototype.entityType = 0;

        /**
         * Encodes the specified LinkedTaskEntity message. Does not implicitly {@link PlatformCRMWeb.LinkedTaskEntity.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.LinkedTaskEntity
         * @static
         * @param {PlatformCRMWeb.ILinkedTaskEntity} message LinkedTaskEntity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LinkedTaskEntity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.entityId);
            if (message.entityType != null && Object.hasOwnProperty.call(message, "entityType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.entityType);
            return writer;
        };

        /**
         * Decodes a LinkedTaskEntity message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.LinkedTaskEntity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.LinkedTaskEntity} LinkedTaskEntity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LinkedTaskEntity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.LinkedTaskEntity();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.entityId = reader.string();
                    break;
                case 2:
                    message.entityType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return LinkedTaskEntity;
    })();

    PlatformCRMWeb.Reason = (function() {

        /**
         * Properties of a Reason.
         * @memberof PlatformCRMWeb
         * @interface IReason
         * @property {string|null} [id] Reason id
         * @property {string|null} [createdAt] Reason createdAt
         * @property {string|null} [updatedAt] Reason updatedAt
         * @property {string|null} [title] Reason title
         * @property {string|null} [category] Reason category
         */

        /**
         * Constructs a new Reason.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Reason.
         * @implements IReason
         * @constructor
         * @param {PlatformCRMWeb.IReason=} [properties] Properties to set
         */
        function Reason(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Reason id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Reason
         * @instance
         */
        Reason.prototype.id = "";

        /**
         * Reason createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.Reason
         * @instance
         */
        Reason.prototype.createdAt = "";

        /**
         * Reason updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.Reason
         * @instance
         */
        Reason.prototype.updatedAt = "";

        /**
         * Reason title.
         * @member {string} title
         * @memberof PlatformCRMWeb.Reason
         * @instance
         */
        Reason.prototype.title = "";

        /**
         * Reason category.
         * @member {string} category
         * @memberof PlatformCRMWeb.Reason
         * @instance
         */
        Reason.prototype.category = "";

        /**
         * Encodes the specified Reason message. Does not implicitly {@link PlatformCRMWeb.Reason.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Reason
         * @static
         * @param {PlatformCRMWeb.IReason} message Reason message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Reason.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.title);
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.category);
            return writer;
        };

        /**
         * Decodes a Reason message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Reason
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Reason} Reason
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Reason.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Reason();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.title = reader.string();
                    break;
                case 5:
                    message.category = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Reason;
    })();

    PlatformCRMWeb.ProductReason = (function() {

        /**
         * Properties of a ProductReason.
         * @memberof PlatformCRMWeb
         * @interface IProductReason
         * @property {string|null} [id] ProductReason id
         * @property {string|null} [createdAt] ProductReason createdAt
         * @property {string|null} [updatedAt] ProductReason updatedAt
         * @property {string|null} [key] ProductReason key
         * @property {Array.<PlatformCRMWeb.IReason>|null} [cancellationReasons] ProductReason cancellationReasons
         */

        /**
         * Constructs a new ProductReason.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ProductReason.
         * @implements IProductReason
         * @constructor
         * @param {PlatformCRMWeb.IProductReason=} [properties] Properties to set
         */
        function ProductReason(properties) {
            this.cancellationReasons = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProductReason id.
         * @member {string} id
         * @memberof PlatformCRMWeb.ProductReason
         * @instance
         */
        ProductReason.prototype.id = "";

        /**
         * ProductReason createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.ProductReason
         * @instance
         */
        ProductReason.prototype.createdAt = "";

        /**
         * ProductReason updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.ProductReason
         * @instance
         */
        ProductReason.prototype.updatedAt = "";

        /**
         * ProductReason key.
         * @member {string} key
         * @memberof PlatformCRMWeb.ProductReason
         * @instance
         */
        ProductReason.prototype.key = "";

        /**
         * ProductReason cancellationReasons.
         * @member {Array.<PlatformCRMWeb.IReason>} cancellationReasons
         * @memberof PlatformCRMWeb.ProductReason
         * @instance
         */
        ProductReason.prototype.cancellationReasons = $util.emptyArray;

        /**
         * Encodes the specified ProductReason message. Does not implicitly {@link PlatformCRMWeb.ProductReason.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ProductReason
         * @static
         * @param {PlatformCRMWeb.IProductReason} message ProductReason message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProductReason.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.updatedAt);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.key);
            if (message.cancellationReasons != null && message.cancellationReasons.length)
                for (var i = 0; i < message.cancellationReasons.length; ++i)
                    $root.PlatformCRMWeb.Reason.encode(message.cancellationReasons[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a ProductReason message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ProductReason
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ProductReason} ProductReason
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProductReason.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ProductReason();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.createdAt = reader.string();
                    break;
                case 3:
                    message.updatedAt = reader.string();
                    break;
                case 4:
                    message.key = reader.string();
                    break;
                case 5:
                    if (!(message.cancellationReasons && message.cancellationReasons.length))
                        message.cancellationReasons = [];
                    message.cancellationReasons.push($root.PlatformCRMWeb.Reason.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProductReason;
    })();

    PlatformCRMWeb.Task = (function() {

        /**
         * Properties of a Task.
         * @memberof PlatformCRMWeb
         * @interface ITask
         * @property {string|null} [id] Task id
         * @property {string|null} [title] Task title
         * @property {string|null} [clientId] Task clientId
         * @property {PlatformCRMWeb.TaskState|null} [state] Task state
         * @property {string|null} [comment] Task comment
         * @property {string|null} [postponedTo] Task postponedTo
         * @property {string|null} [ownerId] Task ownerId
         * @property {string|null} [regionId] Task regionId
         * @property {string|null} [strategyId] Task strategyId
         * @property {string|null} [createdAt] Task createdAt
         * @property {string|null} [updatedAt] Task updatedAt
         * @property {boolean|null} [isDeleted] Task isDeleted
         * @property {PlatformCRMWeb.ISkill|null} [skill] Task skill
         * @property {PlatformCRMWeb.ILinkedTaskEntity|null} [entity] Task entity
         * @property {string|null} [deletedAt] Task deletedAt
         * @property {PlatformCRMWeb.IReason|null} [cancellationReason] Task cancellationReason
         * @property {string|null} [lastMissedCallDate] Task lastMissedCallDate
         */

        /**
         * Constructs a new Task.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Task.
         * @implements ITask
         * @constructor
         * @param {PlatformCRMWeb.ITask=} [properties] Properties to set
         */
        function Task(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Task id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.id = "";

        /**
         * Task title.
         * @member {string} title
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.title = "";

        /**
         * Task clientId.
         * @member {string} clientId
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.clientId = "";

        /**
         * Task state.
         * @member {PlatformCRMWeb.TaskState} state
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.state = 0;

        /**
         * Task comment.
         * @member {string|null|undefined} comment
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.comment = null;

        /**
         * Task postponedTo.
         * @member {string|null|undefined} postponedTo
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.postponedTo = null;

        /**
         * Task ownerId.
         * @member {string|null|undefined} ownerId
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.ownerId = null;

        /**
         * Task regionId.
         * @member {string|null|undefined} regionId
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.regionId = null;

        /**
         * Task strategyId.
         * @member {string} strategyId
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.strategyId = "";

        /**
         * Task createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.createdAt = "";

        /**
         * Task updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.updatedAt = "";

        /**
         * Task isDeleted.
         * @member {boolean} isDeleted
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.isDeleted = false;

        /**
         * Task skill.
         * @member {PlatformCRMWeb.ISkill|null|undefined} skill
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.skill = null;

        /**
         * Task entity.
         * @member {PlatformCRMWeb.ILinkedTaskEntity|null|undefined} entity
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.entity = null;

        /**
         * Task deletedAt.
         * @member {string|null|undefined} deletedAt
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.deletedAt = null;

        /**
         * Task cancellationReason.
         * @member {PlatformCRMWeb.IReason|null|undefined} cancellationReason
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.cancellationReason = null;

        /**
         * Task lastMissedCallDate.
         * @member {string|null|undefined} lastMissedCallDate
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Task.prototype.lastMissedCallDate = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Task _comment.
         * @member {"comment"|undefined} _comment
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Object.defineProperty(Task.prototype, "_comment", {
            get: $util.oneOfGetter($oneOfFields = ["comment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Task _postponedTo.
         * @member {"postponedTo"|undefined} _postponedTo
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Object.defineProperty(Task.prototype, "_postponedTo", {
            get: $util.oneOfGetter($oneOfFields = ["postponedTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Task _ownerId.
         * @member {"ownerId"|undefined} _ownerId
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Object.defineProperty(Task.prototype, "_ownerId", {
            get: $util.oneOfGetter($oneOfFields = ["ownerId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Task _regionId.
         * @member {"regionId"|undefined} _regionId
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Object.defineProperty(Task.prototype, "_regionId", {
            get: $util.oneOfGetter($oneOfFields = ["regionId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Task _entity.
         * @member {"entity"|undefined} _entity
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Object.defineProperty(Task.prototype, "_entity", {
            get: $util.oneOfGetter($oneOfFields = ["entity"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Task _deletedAt.
         * @member {"deletedAt"|undefined} _deletedAt
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Object.defineProperty(Task.prototype, "_deletedAt", {
            get: $util.oneOfGetter($oneOfFields = ["deletedAt"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Task _cancellationReason.
         * @member {"cancellationReason"|undefined} _cancellationReason
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Object.defineProperty(Task.prototype, "_cancellationReason", {
            get: $util.oneOfGetter($oneOfFields = ["cancellationReason"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Task _lastMissedCallDate.
         * @member {"lastMissedCallDate"|undefined} _lastMissedCallDate
         * @memberof PlatformCRMWeb.Task
         * @instance
         */
        Object.defineProperty(Task.prototype, "_lastMissedCallDate", {
            get: $util.oneOfGetter($oneOfFields = ["lastMissedCallDate"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified Task message. Does not implicitly {@link PlatformCRMWeb.Task.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Task
         * @static
         * @param {PlatformCRMWeb.ITask} message Task message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Task.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.clientId);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.state);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.comment);
            if (message.postponedTo != null && Object.hasOwnProperty.call(message, "postponedTo"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.postponedTo);
            if (message.ownerId != null && Object.hasOwnProperty.call(message, "ownerId"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.ownerId);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.regionId);
            if (message.strategyId != null && Object.hasOwnProperty.call(message, "strategyId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.strategyId);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isDeleted);
            if (message.skill != null && Object.hasOwnProperty.call(message, "skill"))
                $root.PlatformCRMWeb.Skill.encode(message.skill, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.PlatformCRMWeb.LinkedTaskEntity.encode(message.entity, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 15, wireType 2 =*/122).string(message.deletedAt);
            if (message.cancellationReason != null && Object.hasOwnProperty.call(message, "cancellationReason"))
                $root.PlatformCRMWeb.Reason.encode(message.cancellationReason, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.lastMissedCallDate != null && Object.hasOwnProperty.call(message, "lastMissedCallDate"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.lastMissedCallDate);
            return writer;
        };

        /**
         * Decodes a Task message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Task
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Task} Task
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Task.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Task();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.title = reader.string();
                    break;
                case 3:
                    message.clientId = reader.string();
                    break;
                case 4:
                    message.state = reader.int32();
                    break;
                case 5:
                    message.comment = reader.string();
                    break;
                case 6:
                    message.postponedTo = reader.string();
                    break;
                case 7:
                    message.ownerId = reader.string();
                    break;
                case 8:
                    message.regionId = reader.string();
                    break;
                case 9:
                    message.strategyId = reader.string();
                    break;
                case 10:
                    message.createdAt = reader.string();
                    break;
                case 11:
                    message.updatedAt = reader.string();
                    break;
                case 12:
                    message.isDeleted = reader.bool();
                    break;
                case 13:
                    message.skill = $root.PlatformCRMWeb.Skill.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.entity = $root.PlatformCRMWeb.LinkedTaskEntity.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.deletedAt = reader.string();
                    break;
                case 16:
                    message.cancellationReason = $root.PlatformCRMWeb.Reason.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.lastMissedCallDate = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Task;
    })();

    /**
     * CommunicationChannel enum.
     * @name PlatformCRMWeb.CommunicationChannel
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} CRM=1 CRM value
     */
    PlatformCRMWeb.CommunicationChannel = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "CRM"] = 1;
        return values;
    })();

    /**
     * CommunicationMethod enum.
     * @name PlatformCRMWeb.CommunicationMethod
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} CALL=1 CALL value
     */
    PlatformCRMWeb.CommunicationMethod = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "CALL"] = 1;
        return values;
    })();

    /**
     * CommunicationType enum.
     * @name PlatformCRMWeb.CommunicationType
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} OPERATOR=1 OPERATOR value
     */
    PlatformCRMWeb.CommunicationType = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "OPERATOR"] = 1;
        return values;
    })();

    /**
     * CommunicationProcess enum.
     * @name PlatformCRMWeb.CommunicationProcess
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} SALES=1 SALES value
     */
    PlatformCRMWeb.CommunicationProcess = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "SALES"] = 1;
        return values;
    })();

    /**
     * CommunicationState enum.
     * @name PlatformCRMWeb.CommunicationState
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} CREATED=1 CREATED value
     * @property {number} ASSIGNED=2 ASSIGNED value
     * @property {number} CLOSED=3 CLOSED value
     * @property {number} POSTPONED=4 POSTPONED value
     * @property {number} NO_COMMUNICATION=5 NO_COMMUNICATION value
     * @property {number} RESCHEDULED=6 RESCHEDULED value
     * @property {number} DECLINED=7 DECLINED value
     * @property {number} NEGATIVE_CANCELLATION=8 NEGATIVE_CANCELLATION value
     */
    PlatformCRMWeb.CommunicationState = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "CREATED"] = 1;
        values[valuesById[2] = "ASSIGNED"] = 2;
        values[valuesById[3] = "CLOSED"] = 3;
        values[valuesById[4] = "POSTPONED"] = 4;
        values[valuesById[5] = "NO_COMMUNICATION"] = 5;
        values[valuesById[6] = "RESCHEDULED"] = 6;
        values[valuesById[7] = "DECLINED"] = 7;
        values[valuesById[8] = "NEGATIVE_CANCELLATION"] = 8;
        return values;
    })();

    /**
     * CancellationReason enum.
     * @name PlatformCRMWeb.CancellationReason
     * @enum {number}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} NUMBER_NOT_EXISTS=1 NUMBER_NOT_EXISTS value
     * @property {number} UNAVAILABLE=2 UNAVAILABLE value
     * @property {number} NO_PICKUP=3 NO_PICKUP value
     * @property {number} BUSY=4 BUSY value
     */
    PlatformCRMWeb.CancellationReason = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "NUMBER_NOT_EXISTS"] = 1;
        values[valuesById[2] = "UNAVAILABLE"] = 2;
        values[valuesById[3] = "NO_PICKUP"] = 3;
        values[valuesById[4] = "BUSY"] = 4;
        return values;
    })();

    PlatformCRMWeb.TaskListItem = (function() {

        /**
         * Properties of a TaskListItem.
         * @memberof PlatformCRMWeb
         * @interface ITaskListItem
         * @property {number|null} [order] TaskListItem order
         * @property {PlatformCRMWeb.ITask|null} [task] TaskListItem task
         */

        /**
         * Constructs a new TaskListItem.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a TaskListItem.
         * @implements ITaskListItem
         * @constructor
         * @param {PlatformCRMWeb.ITaskListItem=} [properties] Properties to set
         */
        function TaskListItem(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TaskListItem order.
         * @member {number} order
         * @memberof PlatformCRMWeb.TaskListItem
         * @instance
         */
        TaskListItem.prototype.order = 0;

        /**
         * TaskListItem task.
         * @member {PlatformCRMWeb.ITask|null|undefined} task
         * @memberof PlatformCRMWeb.TaskListItem
         * @instance
         */
        TaskListItem.prototype.task = null;

        /**
         * Encodes the specified TaskListItem message. Does not implicitly {@link PlatformCRMWeb.TaskListItem.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.TaskListItem
         * @static
         * @param {PlatformCRMWeb.ITaskListItem} message TaskListItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TaskListItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.order);
            if (message.task != null && Object.hasOwnProperty.call(message, "task"))
                $root.PlatformCRMWeb.Task.encode(message.task, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a TaskListItem message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.TaskListItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.TaskListItem} TaskListItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TaskListItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.TaskListItem();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.order = reader.int32();
                    break;
                case 2:
                    message.task = $root.PlatformCRMWeb.Task.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return TaskListItem;
    })();

    PlatformCRMWeb.Communication = (function() {

        /**
         * Properties of a Communication.
         * @memberof PlatformCRMWeb
         * @interface ICommunication
         * @property {string|null} [id] Communication id
         * @property {string|null} [managerSsoId] Communication managerSsoId
         * @property {string|null} [clientId] Communication clientId
         * @property {PlatformCRMWeb.CommunicationChannel|null} [channel] Communication channel
         * @property {PlatformCRMWeb.CommunicationMethod|null} [method] Communication method
         * @property {PlatformCRMWeb.CommunicationType|null} [type] Communication type
         * @property {PlatformCRMWeb.CommunicationProcess|null} [process] Communication process
         * @property {PlatformCRMWeb.CommunicationState|null} [state] Communication state
         * @property {Array.<PlatformCRMWeb.ITaskListItem>|null} [taskList] Communication taskList
         * @property {string|null} [chainPrototypeId] Communication chainPrototypeId
         * @property {string|null} [resolvedAt] Communication resolvedAt
         * @property {PlatformCRMWeb.IReason|null} [failureReason] Communication failureReason
         * @property {string|null} [updatedAt] Communication updatedAt
         * @property {string|null} [createdAt] Communication createdAt
         * @property {number|Long|null} [registrationNumber] Communication registrationNumber
         * @property {PlatformCRMWeb.Communication.IManager|null} [owner] Communication owner
         */

        /**
         * Constructs a new Communication.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a Communication.
         * @implements ICommunication
         * @constructor
         * @param {PlatformCRMWeb.ICommunication=} [properties] Properties to set
         */
        function Communication(properties) {
            this.taskList = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Communication id.
         * @member {string} id
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.id = "";

        /**
         * Communication managerSsoId.
         * @member {string} managerSsoId
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.managerSsoId = "";

        /**
         * Communication clientId.
         * @member {string} clientId
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.clientId = "";

        /**
         * Communication channel.
         * @member {PlatformCRMWeb.CommunicationChannel} channel
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.channel = 0;

        /**
         * Communication method.
         * @member {PlatformCRMWeb.CommunicationMethod} method
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.method = 0;

        /**
         * Communication type.
         * @member {PlatformCRMWeb.CommunicationType} type
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.type = 0;

        /**
         * Communication process.
         * @member {PlatformCRMWeb.CommunicationProcess} process
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.process = 0;

        /**
         * Communication state.
         * @member {PlatformCRMWeb.CommunicationState} state
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.state = 0;

        /**
         * Communication taskList.
         * @member {Array.<PlatformCRMWeb.ITaskListItem>} taskList
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.taskList = $util.emptyArray;

        /**
         * Communication chainPrototypeId.
         * @member {string|null|undefined} chainPrototypeId
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.chainPrototypeId = null;

        /**
         * Communication resolvedAt.
         * @member {string|null|undefined} resolvedAt
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.resolvedAt = null;

        /**
         * Communication failureReason.
         * @member {PlatformCRMWeb.IReason|null|undefined} failureReason
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.failureReason = null;

        /**
         * Communication updatedAt.
         * @member {string} updatedAt
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.updatedAt = "";

        /**
         * Communication createdAt.
         * @member {string} createdAt
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.createdAt = "";

        /**
         * Communication registrationNumber.
         * @member {number|Long} registrationNumber
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.registrationNumber = 0;

        /**
         * Communication owner.
         * @member {PlatformCRMWeb.Communication.IManager|null|undefined} owner
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Communication.prototype.owner = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * Communication _chainPrototypeId.
         * @member {"chainPrototypeId"|undefined} _chainPrototypeId
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Object.defineProperty(Communication.prototype, "_chainPrototypeId", {
            get: $util.oneOfGetter($oneOfFields = ["chainPrototypeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Communication _resolvedAt.
         * @member {"resolvedAt"|undefined} _resolvedAt
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Object.defineProperty(Communication.prototype, "_resolvedAt", {
            get: $util.oneOfGetter($oneOfFields = ["resolvedAt"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Communication _failureReason.
         * @member {"failureReason"|undefined} _failureReason
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Object.defineProperty(Communication.prototype, "_failureReason", {
            get: $util.oneOfGetter($oneOfFields = ["failureReason"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Communication _owner.
         * @member {"owner"|undefined} _owner
         * @memberof PlatformCRMWeb.Communication
         * @instance
         */
        Object.defineProperty(Communication.prototype, "_owner", {
            get: $util.oneOfGetter($oneOfFields = ["owner"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified Communication message. Does not implicitly {@link PlatformCRMWeb.Communication.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.Communication
         * @static
         * @param {PlatformCRMWeb.ICommunication} message Communication message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Communication.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.managerSsoId != null && Object.hasOwnProperty.call(message, "managerSsoId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.managerSsoId);
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.clientId);
            if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.channel);
            if (message.method != null && Object.hasOwnProperty.call(message, "method"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.method);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.type);
            if (message.process != null && Object.hasOwnProperty.call(message, "process"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.process);
            if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.state);
            if (message.taskList != null && message.taskList.length)
                for (var i = 0; i < message.taskList.length; ++i)
                    $root.PlatformCRMWeb.TaskListItem.encode(message.taskList[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.chainPrototypeId != null && Object.hasOwnProperty.call(message, "chainPrototypeId"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.chainPrototypeId);
            if (message.resolvedAt != null && Object.hasOwnProperty.call(message, "resolvedAt"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.resolvedAt);
            if (message.failureReason != null && Object.hasOwnProperty.call(message, "failureReason"))
                $root.PlatformCRMWeb.Reason.encode(message.failureReason, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.updatedAt);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.createdAt);
            if (message.registrationNumber != null && Object.hasOwnProperty.call(message, "registrationNumber"))
                writer.uint32(/* id 15, wireType 0 =*/120).int64(message.registrationNumber);
            if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                $root.PlatformCRMWeb.Communication.Manager.encode(message.owner, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a Communication message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.Communication
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.Communication} Communication
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Communication.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Communication();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.managerSsoId = reader.string();
                    break;
                case 3:
                    message.clientId = reader.string();
                    break;
                case 4:
                    message.channel = reader.int32();
                    break;
                case 5:
                    message.method = reader.int32();
                    break;
                case 6:
                    message.type = reader.int32();
                    break;
                case 7:
                    message.process = reader.int32();
                    break;
                case 8:
                    message.state = reader.int32();
                    break;
                case 9:
                    if (!(message.taskList && message.taskList.length))
                        message.taskList = [];
                    message.taskList.push($root.PlatformCRMWeb.TaskListItem.decode(reader, reader.uint32()));
                    break;
                case 10:
                    message.chainPrototypeId = reader.string();
                    break;
                case 11:
                    message.resolvedAt = reader.string();
                    break;
                case 12:
                    message.failureReason = $root.PlatformCRMWeb.Reason.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.updatedAt = reader.string();
                    break;
                case 14:
                    message.createdAt = reader.string();
                    break;
                case 15:
                    message.registrationNumber = reader.int64();
                    break;
                case 16:
                    message.owner = $root.PlatformCRMWeb.Communication.Manager.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        Communication.Manager = (function() {

            /**
             * Properties of a Manager.
             * @memberof PlatformCRMWeb.Communication
             * @interface IManager
             * @property {string|null} [id] Manager id
             * @property {string|null} [name] Manager name
             * @property {string|null} [ssoUid] Manager ssoUid
             * @property {PlatformCRMWeb.ManagerState|null} [state] Manager state
             * @property {Array.<PlatformCRMWeb.IManagerSkill>|null} [skills] Manager skills
             */

            /**
             * Constructs a new Manager.
             * @memberof PlatformCRMWeb.Communication
             * @classdesc Represents a Manager.
             * @implements IManager
             * @constructor
             * @param {PlatformCRMWeb.Communication.IManager=} [properties] Properties to set
             */
            function Manager(properties) {
                this.skills = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Manager id.
             * @member {string} id
             * @memberof PlatformCRMWeb.Communication.Manager
             * @instance
             */
            Manager.prototype.id = "";

            /**
             * Manager name.
             * @member {string} name
             * @memberof PlatformCRMWeb.Communication.Manager
             * @instance
             */
            Manager.prototype.name = "";

            /**
             * Manager ssoUid.
             * @member {string} ssoUid
             * @memberof PlatformCRMWeb.Communication.Manager
             * @instance
             */
            Manager.prototype.ssoUid = "";

            /**
             * Manager state.
             * @member {PlatformCRMWeb.ManagerState} state
             * @memberof PlatformCRMWeb.Communication.Manager
             * @instance
             */
            Manager.prototype.state = 0;

            /**
             * Manager skills.
             * @member {Array.<PlatformCRMWeb.IManagerSkill>} skills
             * @memberof PlatformCRMWeb.Communication.Manager
             * @instance
             */
            Manager.prototype.skills = $util.emptyArray;

            /**
             * Encodes the specified Manager message. Does not implicitly {@link PlatformCRMWeb.Communication.Manager.verify|verify} messages.
             * @function encode
             * @memberof PlatformCRMWeb.Communication.Manager
             * @static
             * @param {PlatformCRMWeb.Communication.IManager} message Manager message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Manager.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.ssoUid != null && Object.hasOwnProperty.call(message, "ssoUid"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.ssoUid);
                if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.state);
                if (message.skills != null && message.skills.length)
                    for (var i = 0; i < message.skills.length; ++i)
                        $root.PlatformCRMWeb.ManagerSkill.encode(message.skills[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Decodes a Manager message from the specified reader or buffer.
             * @function decode
             * @memberof PlatformCRMWeb.Communication.Manager
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {PlatformCRMWeb.Communication.Manager} Manager
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Manager.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.Communication.Manager();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.ssoUid = reader.string();
                        break;
                    case 4:
                        message.state = reader.int32();
                        break;
                    case 5:
                        if (!(message.skills && message.skills.length))
                            message.skills = [];
                        message.skills.push($root.PlatformCRMWeb.ManagerSkill.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return Manager;
        })();

        return Communication;
    })();

    PlatformCRMWeb.CheckCommunicationRequest = (function() {

        /**
         * Properties of a CheckCommunicationRequest.
         * @memberof PlatformCRMWeb
         * @interface ICheckCommunicationRequest
         */

        /**
         * Constructs a new CheckCommunicationRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CheckCommunicationRequest.
         * @implements ICheckCommunicationRequest
         * @constructor
         * @param {PlatformCRMWeb.ICheckCommunicationRequest=} [properties] Properties to set
         */
        function CheckCommunicationRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified CheckCommunicationRequest message. Does not implicitly {@link PlatformCRMWeb.CheckCommunicationRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CheckCommunicationRequest
         * @static
         * @param {PlatformCRMWeb.ICheckCommunicationRequest} message CheckCommunicationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckCommunicationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes a CheckCommunicationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CheckCommunicationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CheckCommunicationRequest} CheckCommunicationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckCommunicationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CheckCommunicationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CheckCommunicationRequest;
    })();

    PlatformCRMWeb.CheckCommunicationResponse = (function() {

        /**
         * Properties of a CheckCommunicationResponse.
         * @memberof PlatformCRMWeb
         * @interface ICheckCommunicationResponse
         * @property {boolean|null} [hasAvailableTask] CheckCommunicationResponse hasAvailableTask
         * @property {string|null} [activeCommunicationId] CheckCommunicationResponse activeCommunicationId
         */

        /**
         * Constructs a new CheckCommunicationResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CheckCommunicationResponse.
         * @implements ICheckCommunicationResponse
         * @constructor
         * @param {PlatformCRMWeb.ICheckCommunicationResponse=} [properties] Properties to set
         */
        function CheckCommunicationResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CheckCommunicationResponse hasAvailableTask.
         * @member {boolean} hasAvailableTask
         * @memberof PlatformCRMWeb.CheckCommunicationResponse
         * @instance
         */
        CheckCommunicationResponse.prototype.hasAvailableTask = false;

        /**
         * CheckCommunicationResponse activeCommunicationId.
         * @member {string} activeCommunicationId
         * @memberof PlatformCRMWeb.CheckCommunicationResponse
         * @instance
         */
        CheckCommunicationResponse.prototype.activeCommunicationId = "";

        /**
         * Encodes the specified CheckCommunicationResponse message. Does not implicitly {@link PlatformCRMWeb.CheckCommunicationResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CheckCommunicationResponse
         * @static
         * @param {PlatformCRMWeb.ICheckCommunicationResponse} message CheckCommunicationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CheckCommunicationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hasAvailableTask != null && Object.hasOwnProperty.call(message, "hasAvailableTask"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hasAvailableTask);
            if (message.activeCommunicationId != null && Object.hasOwnProperty.call(message, "activeCommunicationId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.activeCommunicationId);
            return writer;
        };

        /**
         * Decodes a CheckCommunicationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CheckCommunicationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CheckCommunicationResponse} CheckCommunicationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CheckCommunicationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CheckCommunicationResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.hasAvailableTask = reader.bool();
                    break;
                case 2:
                    message.activeCommunicationId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CheckCommunicationResponse;
    })();

    PlatformCRMWeb.ApplyCommunicationRequest = (function() {

        /**
         * Properties of an ApplyCommunicationRequest.
         * @memberof PlatformCRMWeb
         * @interface IApplyCommunicationRequest
         * @property {string|null} [taskId] ApplyCommunicationRequest taskId
         */

        /**
         * Constructs a new ApplyCommunicationRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an ApplyCommunicationRequest.
         * @implements IApplyCommunicationRequest
         * @constructor
         * @param {PlatformCRMWeb.IApplyCommunicationRequest=} [properties] Properties to set
         */
        function ApplyCommunicationRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApplyCommunicationRequest taskId.
         * @member {string} taskId
         * @memberof PlatformCRMWeb.ApplyCommunicationRequest
         * @instance
         */
        ApplyCommunicationRequest.prototype.taskId = "";

        /**
         * Encodes the specified ApplyCommunicationRequest message. Does not implicitly {@link PlatformCRMWeb.ApplyCommunicationRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ApplyCommunicationRequest
         * @static
         * @param {PlatformCRMWeb.IApplyCommunicationRequest} message ApplyCommunicationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplyCommunicationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskId);
            return writer;
        };

        /**
         * Decodes an ApplyCommunicationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ApplyCommunicationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ApplyCommunicationRequest} ApplyCommunicationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplyCommunicationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ApplyCommunicationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.taskId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ApplyCommunicationRequest;
    })();

    PlatformCRMWeb.ApplyCommunicationResponse = (function() {

        /**
         * Properties of an ApplyCommunicationResponse.
         * @memberof PlatformCRMWeb
         * @interface IApplyCommunicationResponse
         * @property {boolean|null} [hasAvailableTask] ApplyCommunicationResponse hasAvailableTask
         * @property {string|null} [activeCommunicationId] ApplyCommunicationResponse activeCommunicationId
         */

        /**
         * Constructs a new ApplyCommunicationResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an ApplyCommunicationResponse.
         * @implements IApplyCommunicationResponse
         * @constructor
         * @param {PlatformCRMWeb.IApplyCommunicationResponse=} [properties] Properties to set
         */
        function ApplyCommunicationResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ApplyCommunicationResponse hasAvailableTask.
         * @member {boolean} hasAvailableTask
         * @memberof PlatformCRMWeb.ApplyCommunicationResponse
         * @instance
         */
        ApplyCommunicationResponse.prototype.hasAvailableTask = false;

        /**
         * ApplyCommunicationResponse activeCommunicationId.
         * @member {string} activeCommunicationId
         * @memberof PlatformCRMWeb.ApplyCommunicationResponse
         * @instance
         */
        ApplyCommunicationResponse.prototype.activeCommunicationId = "";

        /**
         * Encodes the specified ApplyCommunicationResponse message. Does not implicitly {@link PlatformCRMWeb.ApplyCommunicationResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ApplyCommunicationResponse
         * @static
         * @param {PlatformCRMWeb.IApplyCommunicationResponse} message ApplyCommunicationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplyCommunicationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.hasAvailableTask != null && Object.hasOwnProperty.call(message, "hasAvailableTask"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.hasAvailableTask);
            if (message.activeCommunicationId != null && Object.hasOwnProperty.call(message, "activeCommunicationId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.activeCommunicationId);
            return writer;
        };

        /**
         * Decodes an ApplyCommunicationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ApplyCommunicationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ApplyCommunicationResponse} ApplyCommunicationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplyCommunicationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ApplyCommunicationResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.hasAvailableTask = reader.bool();
                    break;
                case 2:
                    message.activeCommunicationId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ApplyCommunicationResponse;
    })();

    PlatformCRMWeb.CommunicationByIdRequest = (function() {

        /**
         * Properties of a CommunicationByIdRequest.
         * @memberof PlatformCRMWeb
         * @interface ICommunicationByIdRequest
         * @property {string|null} [id] CommunicationByIdRequest id
         */

        /**
         * Constructs a new CommunicationByIdRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CommunicationByIdRequest.
         * @implements ICommunicationByIdRequest
         * @constructor
         * @param {PlatformCRMWeb.ICommunicationByIdRequest=} [properties] Properties to set
         */
        function CommunicationByIdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommunicationByIdRequest id.
         * @member {string} id
         * @memberof PlatformCRMWeb.CommunicationByIdRequest
         * @instance
         */
        CommunicationByIdRequest.prototype.id = "";

        /**
         * Encodes the specified CommunicationByIdRequest message. Does not implicitly {@link PlatformCRMWeb.CommunicationByIdRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CommunicationByIdRequest
         * @static
         * @param {PlatformCRMWeb.ICommunicationByIdRequest} message CommunicationByIdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommunicationByIdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a CommunicationByIdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CommunicationByIdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CommunicationByIdRequest} CommunicationByIdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommunicationByIdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CommunicationByIdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CommunicationByIdRequest;
    })();

    PlatformCRMWeb.CancelReasonInput = (function() {

        /**
         * Properties of a CancelReasonInput.
         * @memberof PlatformCRMWeb
         * @interface ICancelReasonInput
         * @property {string|null} [cancelReasonId] CancelReasonInput cancelReasonId
         * @property {string|null} [productId] CancelReasonInput productId
         */

        /**
         * Constructs a new CancelReasonInput.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CancelReasonInput.
         * @implements ICancelReasonInput
         * @constructor
         * @param {PlatformCRMWeb.ICancelReasonInput=} [properties] Properties to set
         */
        function CancelReasonInput(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CancelReasonInput cancelReasonId.
         * @member {string} cancelReasonId
         * @memberof PlatformCRMWeb.CancelReasonInput
         * @instance
         */
        CancelReasonInput.prototype.cancelReasonId = "";

        /**
         * CancelReasonInput productId.
         * @member {string} productId
         * @memberof PlatformCRMWeb.CancelReasonInput
         * @instance
         */
        CancelReasonInput.prototype.productId = "";

        /**
         * Encodes the specified CancelReasonInput message. Does not implicitly {@link PlatformCRMWeb.CancelReasonInput.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CancelReasonInput
         * @static
         * @param {PlatformCRMWeb.ICancelReasonInput} message CancelReasonInput message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CancelReasonInput.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.cancelReasonId != null && Object.hasOwnProperty.call(message, "cancelReasonId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.cancelReasonId);
            if (message.productId != null && Object.hasOwnProperty.call(message, "productId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.productId);
            return writer;
        };

        /**
         * Decodes a CancelReasonInput message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CancelReasonInput
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CancelReasonInput} CancelReasonInput
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CancelReasonInput.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CancelReasonInput();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.cancelReasonId = reader.string();
                    break;
                case 2:
                    message.productId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CancelReasonInput;
    })();

    PlatformCRMWeb.CancelTaskRequest = (function() {

        /**
         * Properties of a CancelTaskRequest.
         * @memberof PlatformCRMWeb
         * @interface ICancelTaskRequest
         * @property {string|null} [communicationId] CancelTaskRequest communicationId
         * @property {string|null} [taskId] CancelTaskRequest taskId
         * @property {string|null} [comment] CancelTaskRequest comment
         * @property {PlatformCRMWeb.ICancelReasonInput|null} [cancelReason] CancelTaskRequest cancelReason
         */

        /**
         * Constructs a new CancelTaskRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CancelTaskRequest.
         * @implements ICancelTaskRequest
         * @constructor
         * @param {PlatformCRMWeb.ICancelTaskRequest=} [properties] Properties to set
         */
        function CancelTaskRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CancelTaskRequest communicationId.
         * @member {string} communicationId
         * @memberof PlatformCRMWeb.CancelTaskRequest
         * @instance
         */
        CancelTaskRequest.prototype.communicationId = "";

        /**
         * CancelTaskRequest taskId.
         * @member {string} taskId
         * @memberof PlatformCRMWeb.CancelTaskRequest
         * @instance
         */
        CancelTaskRequest.prototype.taskId = "";

        /**
         * CancelTaskRequest comment.
         * @member {string|null|undefined} comment
         * @memberof PlatformCRMWeb.CancelTaskRequest
         * @instance
         */
        CancelTaskRequest.prototype.comment = null;

        /**
         * CancelTaskRequest cancelReason.
         * @member {PlatformCRMWeb.ICancelReasonInput|null|undefined} cancelReason
         * @memberof PlatformCRMWeb.CancelTaskRequest
         * @instance
         */
        CancelTaskRequest.prototype.cancelReason = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CancelTaskRequest _comment.
         * @member {"comment"|undefined} _comment
         * @memberof PlatformCRMWeb.CancelTaskRequest
         * @instance
         */
        Object.defineProperty(CancelTaskRequest.prototype, "_comment", {
            get: $util.oneOfGetter($oneOfFields = ["comment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified CancelTaskRequest message. Does not implicitly {@link PlatformCRMWeb.CancelTaskRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CancelTaskRequest
         * @static
         * @param {PlatformCRMWeb.ICancelTaskRequest} message CancelTaskRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CancelTaskRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.communicationId != null && Object.hasOwnProperty.call(message, "communicationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.communicationId);
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskId);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.comment);
            if (message.cancelReason != null && Object.hasOwnProperty.call(message, "cancelReason"))
                $root.PlatformCRMWeb.CancelReasonInput.encode(message.cancelReason, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a CancelTaskRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CancelTaskRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CancelTaskRequest} CancelTaskRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CancelTaskRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CancelTaskRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.communicationId = reader.string();
                    break;
                case 2:
                    message.taskId = reader.string();
                    break;
                case 3:
                    message.comment = reader.string();
                    break;
                case 4:
                    message.cancelReason = $root.PlatformCRMWeb.CancelReasonInput.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CancelTaskRequest;
    })();

    PlatformCRMWeb.ProceedTaskRequest = (function() {

        /**
         * Properties of a ProceedTaskRequest.
         * @memberof PlatformCRMWeb
         * @interface IProceedTaskRequest
         * @property {string|null} [communicationId] ProceedTaskRequest communicationId
         * @property {string|null} [taskId] ProceedTaskRequest taskId
         * @property {string|null} [prospectId] ProceedTaskRequest prospectId
         */

        /**
         * Constructs a new ProceedTaskRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ProceedTaskRequest.
         * @implements IProceedTaskRequest
         * @constructor
         * @param {PlatformCRMWeb.IProceedTaskRequest=} [properties] Properties to set
         */
        function ProceedTaskRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProceedTaskRequest communicationId.
         * @member {string} communicationId
         * @memberof PlatformCRMWeb.ProceedTaskRequest
         * @instance
         */
        ProceedTaskRequest.prototype.communicationId = "";

        /**
         * ProceedTaskRequest taskId.
         * @member {string} taskId
         * @memberof PlatformCRMWeb.ProceedTaskRequest
         * @instance
         */
        ProceedTaskRequest.prototype.taskId = "";

        /**
         * ProceedTaskRequest prospectId.
         * @member {string} prospectId
         * @memberof PlatformCRMWeb.ProceedTaskRequest
         * @instance
         */
        ProceedTaskRequest.prototype.prospectId = "";

        /**
         * Encodes the specified ProceedTaskRequest message. Does not implicitly {@link PlatformCRMWeb.ProceedTaskRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ProceedTaskRequest
         * @static
         * @param {PlatformCRMWeb.IProceedTaskRequest} message ProceedTaskRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProceedTaskRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.communicationId != null && Object.hasOwnProperty.call(message, "communicationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.communicationId);
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskId);
            if (message.prospectId != null && Object.hasOwnProperty.call(message, "prospectId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.prospectId);
            return writer;
        };

        /**
         * Decodes a ProceedTaskRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ProceedTaskRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ProceedTaskRequest} ProceedTaskRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProceedTaskRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ProceedTaskRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.communicationId = reader.string();
                    break;
                case 2:
                    message.taskId = reader.string();
                    break;
                case 3:
                    message.prospectId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProceedTaskRequest;
    })();

    PlatformCRMWeb.ProceedTicketTaskRequest = (function() {

        /**
         * Properties of a ProceedTicketTaskRequest.
         * @memberof PlatformCRMWeb
         * @interface IProceedTicketTaskRequest
         * @property {string|null} [communicationId] ProceedTicketTaskRequest communicationId
         * @property {string|null} [taskId] ProceedTicketTaskRequest taskId
         */

        /**
         * Constructs a new ProceedTicketTaskRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ProceedTicketTaskRequest.
         * @implements IProceedTicketTaskRequest
         * @constructor
         * @param {PlatformCRMWeb.IProceedTicketTaskRequest=} [properties] Properties to set
         */
        function ProceedTicketTaskRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProceedTicketTaskRequest communicationId.
         * @member {string} communicationId
         * @memberof PlatformCRMWeb.ProceedTicketTaskRequest
         * @instance
         */
        ProceedTicketTaskRequest.prototype.communicationId = "";

        /**
         * ProceedTicketTaskRequest taskId.
         * @member {string} taskId
         * @memberof PlatformCRMWeb.ProceedTicketTaskRequest
         * @instance
         */
        ProceedTicketTaskRequest.prototype.taskId = "";

        /**
         * Encodes the specified ProceedTicketTaskRequest message. Does not implicitly {@link PlatformCRMWeb.ProceedTicketTaskRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ProceedTicketTaskRequest
         * @static
         * @param {PlatformCRMWeb.IProceedTicketTaskRequest} message ProceedTicketTaskRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProceedTicketTaskRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.communicationId != null && Object.hasOwnProperty.call(message, "communicationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.communicationId);
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskId);
            return writer;
        };

        /**
         * Decodes a ProceedTicketTaskRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ProceedTicketTaskRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ProceedTicketTaskRequest} ProceedTicketTaskRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProceedTicketTaskRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ProceedTicketTaskRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.communicationId = reader.string();
                    break;
                case 2:
                    message.taskId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProceedTicketTaskRequest;
    })();

    PlatformCRMWeb.AddTaskRequest = (function() {

        /**
         * Properties of an AddTaskRequest.
         * @memberof PlatformCRMWeb
         * @interface IAddTaskRequest
         * @property {string|null} [communicationId] AddTaskRequest communicationId
         * @property {PlatformCRMWeb.ICreateCommunicationTaskRequest|null} [taskInput] AddTaskRequest taskInput
         * @property {string|null} [phone] AddTaskRequest phone
         */

        /**
         * Constructs a new AddTaskRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents an AddTaskRequest.
         * @implements IAddTaskRequest
         * @constructor
         * @param {PlatformCRMWeb.IAddTaskRequest=} [properties] Properties to set
         */
        function AddTaskRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddTaskRequest communicationId.
         * @member {string} communicationId
         * @memberof PlatformCRMWeb.AddTaskRequest
         * @instance
         */
        AddTaskRequest.prototype.communicationId = "";

        /**
         * AddTaskRequest taskInput.
         * @member {PlatformCRMWeb.ICreateCommunicationTaskRequest|null|undefined} taskInput
         * @memberof PlatformCRMWeb.AddTaskRequest
         * @instance
         */
        AddTaskRequest.prototype.taskInput = null;

        /**
         * AddTaskRequest phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.AddTaskRequest
         * @instance
         */
        AddTaskRequest.prototype.phone = "";

        /**
         * Encodes the specified AddTaskRequest message. Does not implicitly {@link PlatformCRMWeb.AddTaskRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.AddTaskRequest
         * @static
         * @param {PlatformCRMWeb.IAddTaskRequest} message AddTaskRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTaskRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.communicationId != null && Object.hasOwnProperty.call(message, "communicationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.communicationId);
            if (message.taskInput != null && Object.hasOwnProperty.call(message, "taskInput"))
                $root.PlatformCRMWeb.CreateCommunicationTaskRequest.encode(message.taskInput, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
            return writer;
        };

        /**
         * Decodes an AddTaskRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.AddTaskRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.AddTaskRequest} AddTaskRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTaskRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.AddTaskRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.communicationId = reader.string();
                    break;
                case 2:
                    message.taskInput = $root.PlatformCRMWeb.CreateCommunicationTaskRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.phone = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddTaskRequest;
    })();

    PlatformCRMWeb.CreateCommunicationTaskRequest = (function() {

        /**
         * Properties of a CreateCommunicationTaskRequest.
         * @memberof PlatformCRMWeb
         * @interface ICreateCommunicationTaskRequest
         * @property {string|null} [title] CreateCommunicationTaskRequest title
         * @property {string|null} [strategyId] CreateCommunicationTaskRequest strategyId
         * @property {string|null} [skill] CreateCommunicationTaskRequest skill
         * @property {PlatformCRMWeb.ILinkedTaskEntity|null} [entity] CreateCommunicationTaskRequest entity
         * @property {string|null} [regionId] CreateCommunicationTaskRequest regionId
         */

        /**
         * Constructs a new CreateCommunicationTaskRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CreateCommunicationTaskRequest.
         * @implements ICreateCommunicationTaskRequest
         * @constructor
         * @param {PlatformCRMWeb.ICreateCommunicationTaskRequest=} [properties] Properties to set
         */
        function CreateCommunicationTaskRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateCommunicationTaskRequest title.
         * @member {string} title
         * @memberof PlatformCRMWeb.CreateCommunicationTaskRequest
         * @instance
         */
        CreateCommunicationTaskRequest.prototype.title = "";

        /**
         * CreateCommunicationTaskRequest strategyId.
         * @member {string} strategyId
         * @memberof PlatformCRMWeb.CreateCommunicationTaskRequest
         * @instance
         */
        CreateCommunicationTaskRequest.prototype.strategyId = "";

        /**
         * CreateCommunicationTaskRequest skill.
         * @member {string} skill
         * @memberof PlatformCRMWeb.CreateCommunicationTaskRequest
         * @instance
         */
        CreateCommunicationTaskRequest.prototype.skill = "";

        /**
         * CreateCommunicationTaskRequest entity.
         * @member {PlatformCRMWeb.ILinkedTaskEntity|null|undefined} entity
         * @memberof PlatformCRMWeb.CreateCommunicationTaskRequest
         * @instance
         */
        CreateCommunicationTaskRequest.prototype.entity = null;

        /**
         * CreateCommunicationTaskRequest regionId.
         * @member {string|null|undefined} regionId
         * @memberof PlatformCRMWeb.CreateCommunicationTaskRequest
         * @instance
         */
        CreateCommunicationTaskRequest.prototype.regionId = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * CreateCommunicationTaskRequest _entity.
         * @member {"entity"|undefined} _entity
         * @memberof PlatformCRMWeb.CreateCommunicationTaskRequest
         * @instance
         */
        Object.defineProperty(CreateCommunicationTaskRequest.prototype, "_entity", {
            get: $util.oneOfGetter($oneOfFields = ["entity"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * CreateCommunicationTaskRequest _regionId.
         * @member {"regionId"|undefined} _regionId
         * @memberof PlatformCRMWeb.CreateCommunicationTaskRequest
         * @instance
         */
        Object.defineProperty(CreateCommunicationTaskRequest.prototype, "_regionId", {
            get: $util.oneOfGetter($oneOfFields = ["regionId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified CreateCommunicationTaskRequest message. Does not implicitly {@link PlatformCRMWeb.CreateCommunicationTaskRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CreateCommunicationTaskRequest
         * @static
         * @param {PlatformCRMWeb.ICreateCommunicationTaskRequest} message CreateCommunicationTaskRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateCommunicationTaskRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
            if (message.strategyId != null && Object.hasOwnProperty.call(message, "strategyId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.strategyId);
            if (message.skill != null && Object.hasOwnProperty.call(message, "skill"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.skill);
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.PlatformCRMWeb.LinkedTaskEntity.encode(message.entity, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.regionId);
            return writer;
        };

        /**
         * Decodes a CreateCommunicationTaskRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CreateCommunicationTaskRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CreateCommunicationTaskRequest} CreateCommunicationTaskRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateCommunicationTaskRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CreateCommunicationTaskRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.title = reader.string();
                    break;
                case 2:
                    message.strategyId = reader.string();
                    break;
                case 3:
                    message.skill = reader.string();
                    break;
                case 4:
                    message.entity = $root.PlatformCRMWeb.LinkedTaskEntity.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.regionId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateCommunicationTaskRequest;
    })();

    PlatformCRMWeb.RescheduleRequest = (function() {

        /**
         * Properties of a RescheduleRequest.
         * @memberof PlatformCRMWeb
         * @interface IRescheduleRequest
         * @property {string|null} [communicationId] RescheduleRequest communicationId
         * @property {string|null} [to] RescheduleRequest to
         * @property {string|null} [comment] RescheduleRequest comment
         */

        /**
         * Constructs a new RescheduleRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a RescheduleRequest.
         * @implements IRescheduleRequest
         * @constructor
         * @param {PlatformCRMWeb.IRescheduleRequest=} [properties] Properties to set
         */
        function RescheduleRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RescheduleRequest communicationId.
         * @member {string} communicationId
         * @memberof PlatformCRMWeb.RescheduleRequest
         * @instance
         */
        RescheduleRequest.prototype.communicationId = "";

        /**
         * RescheduleRequest to.
         * @member {string} to
         * @memberof PlatformCRMWeb.RescheduleRequest
         * @instance
         */
        RescheduleRequest.prototype.to = "";

        /**
         * RescheduleRequest comment.
         * @member {string|null|undefined} comment
         * @memberof PlatformCRMWeb.RescheduleRequest
         * @instance
         */
        RescheduleRequest.prototype.comment = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * RescheduleRequest _comment.
         * @member {"comment"|undefined} _comment
         * @memberof PlatformCRMWeb.RescheduleRequest
         * @instance
         */
        Object.defineProperty(RescheduleRequest.prototype, "_comment", {
            get: $util.oneOfGetter($oneOfFields = ["comment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified RescheduleRequest message. Does not implicitly {@link PlatformCRMWeb.RescheduleRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.RescheduleRequest
         * @static
         * @param {PlatformCRMWeb.IRescheduleRequest} message RescheduleRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RescheduleRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.communicationId != null && Object.hasOwnProperty.call(message, "communicationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.communicationId);
            if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.to);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.comment);
            return writer;
        };

        /**
         * Decodes a RescheduleRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.RescheduleRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.RescheduleRequest} RescheduleRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RescheduleRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.RescheduleRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.communicationId = reader.string();
                    break;
                case 2:
                    message.to = reader.string();
                    break;
                case 3:
                    message.comment = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return RescheduleRequest;
    })();

    PlatformCRMWeb.PostponeRequest = (function() {

        /**
         * Properties of a PostponeRequest.
         * @memberof PlatformCRMWeb
         * @interface IPostponeRequest
         * @property {string|null} [communicationId] PostponeRequest communicationId
         * @property {string|null} [comment] PostponeRequest comment
         * @property {PlatformCRMWeb.CancellationReason|null} [cancellationReason] PostponeRequest cancellationReason
         */

        /**
         * Constructs a new PostponeRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PostponeRequest.
         * @implements IPostponeRequest
         * @constructor
         * @param {PlatformCRMWeb.IPostponeRequest=} [properties] Properties to set
         */
        function PostponeRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PostponeRequest communicationId.
         * @member {string} communicationId
         * @memberof PlatformCRMWeb.PostponeRequest
         * @instance
         */
        PostponeRequest.prototype.communicationId = "";

        /**
         * PostponeRequest comment.
         * @member {string} comment
         * @memberof PlatformCRMWeb.PostponeRequest
         * @instance
         */
        PostponeRequest.prototype.comment = "";

        /**
         * PostponeRequest cancellationReason.
         * @member {PlatformCRMWeb.CancellationReason} cancellationReason
         * @memberof PlatformCRMWeb.PostponeRequest
         * @instance
         */
        PostponeRequest.prototype.cancellationReason = 0;

        /**
         * Encodes the specified PostponeRequest message. Does not implicitly {@link PlatformCRMWeb.PostponeRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PostponeRequest
         * @static
         * @param {PlatformCRMWeb.IPostponeRequest} message PostponeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PostponeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.communicationId != null && Object.hasOwnProperty.call(message, "communicationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.communicationId);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.comment);
            if (message.cancellationReason != null && Object.hasOwnProperty.call(message, "cancellationReason"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.cancellationReason);
            return writer;
        };

        /**
         * Decodes a PostponeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PostponeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PostponeRequest} PostponeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PostponeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PostponeRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.communicationId = reader.string();
                    break;
                case 2:
                    message.comment = reader.string();
                    break;
                case 3:
                    message.cancellationReason = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PostponeRequest;
    })();

    PlatformCRMWeb.FailRequest = (function() {

        /**
         * Properties of a FailRequest.
         * @memberof PlatformCRMWeb
         * @interface IFailRequest
         * @property {string|null} [communicationId] FailRequest communicationId
         * @property {string|null} [comment] FailRequest comment
         * @property {string|null} [failureReasonId] FailRequest failureReasonId
         */

        /**
         * Constructs a new FailRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a FailRequest.
         * @implements IFailRequest
         * @constructor
         * @param {PlatformCRMWeb.IFailRequest=} [properties] Properties to set
         */
        function FailRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FailRequest communicationId.
         * @member {string} communicationId
         * @memberof PlatformCRMWeb.FailRequest
         * @instance
         */
        FailRequest.prototype.communicationId = "";

        /**
         * FailRequest comment.
         * @member {string|null|undefined} comment
         * @memberof PlatformCRMWeb.FailRequest
         * @instance
         */
        FailRequest.prototype.comment = null;

        /**
         * FailRequest failureReasonId.
         * @member {string} failureReasonId
         * @memberof PlatformCRMWeb.FailRequest
         * @instance
         */
        FailRequest.prototype.failureReasonId = "";

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * FailRequest _comment.
         * @member {"comment"|undefined} _comment
         * @memberof PlatformCRMWeb.FailRequest
         * @instance
         */
        Object.defineProperty(FailRequest.prototype, "_comment", {
            get: $util.oneOfGetter($oneOfFields = ["comment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified FailRequest message. Does not implicitly {@link PlatformCRMWeb.FailRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.FailRequest
         * @static
         * @param {PlatformCRMWeb.IFailRequest} message FailRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FailRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.communicationId != null && Object.hasOwnProperty.call(message, "communicationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.communicationId);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.comment);
            if (message.failureReasonId != null && Object.hasOwnProperty.call(message, "failureReasonId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.failureReasonId);
            return writer;
        };

        /**
         * Decodes a FailRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.FailRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.FailRequest} FailRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FailRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.FailRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.communicationId = reader.string();
                    break;
                case 2:
                    message.comment = reader.string();
                    break;
                case 3:
                    message.failureReasonId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FailRequest;
    })();

    PlatformCRMWeb.FindCommunicationsRequest = (function() {

        /**
         * Properties of a FindCommunicationsRequest.
         * @memberof PlatformCRMWeb
         * @interface IFindCommunicationsRequest
         * @property {string|null} [clientId] FindCommunicationsRequest clientId
         * @property {string|null} [managerSsoId] FindCommunicationsRequest managerSsoId
         * @property {Array.<PlatformCRMWeb.CommunicationState>|null} [states] FindCommunicationsRequest states
         */

        /**
         * Constructs a new FindCommunicationsRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a FindCommunicationsRequest.
         * @implements IFindCommunicationsRequest
         * @constructor
         * @param {PlatformCRMWeb.IFindCommunicationsRequest=} [properties] Properties to set
         */
        function FindCommunicationsRequest(properties) {
            this.states = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindCommunicationsRequest clientId.
         * @member {string|null|undefined} clientId
         * @memberof PlatformCRMWeb.FindCommunicationsRequest
         * @instance
         */
        FindCommunicationsRequest.prototype.clientId = null;

        /**
         * FindCommunicationsRequest managerSsoId.
         * @member {string|null|undefined} managerSsoId
         * @memberof PlatformCRMWeb.FindCommunicationsRequest
         * @instance
         */
        FindCommunicationsRequest.prototype.managerSsoId = null;

        /**
         * FindCommunicationsRequest states.
         * @member {Array.<PlatformCRMWeb.CommunicationState>} states
         * @memberof PlatformCRMWeb.FindCommunicationsRequest
         * @instance
         */
        FindCommunicationsRequest.prototype.states = $util.emptyArray;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * FindCommunicationsRequest _clientId.
         * @member {"clientId"|undefined} _clientId
         * @memberof PlatformCRMWeb.FindCommunicationsRequest
         * @instance
         */
        Object.defineProperty(FindCommunicationsRequest.prototype, "_clientId", {
            get: $util.oneOfGetter($oneOfFields = ["clientId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * FindCommunicationsRequest _managerSsoId.
         * @member {"managerSsoId"|undefined} _managerSsoId
         * @memberof PlatformCRMWeb.FindCommunicationsRequest
         * @instance
         */
        Object.defineProperty(FindCommunicationsRequest.prototype, "_managerSsoId", {
            get: $util.oneOfGetter($oneOfFields = ["managerSsoId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified FindCommunicationsRequest message. Does not implicitly {@link PlatformCRMWeb.FindCommunicationsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.FindCommunicationsRequest
         * @static
         * @param {PlatformCRMWeb.IFindCommunicationsRequest} message FindCommunicationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindCommunicationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientId);
            if (message.managerSsoId != null && Object.hasOwnProperty.call(message, "managerSsoId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.managerSsoId);
            if (message.states != null && message.states.length) {
                writer.uint32(/* id 3, wireType 2 =*/26).fork();
                for (var i = 0; i < message.states.length; ++i)
                    writer.int32(message.states[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Decodes a FindCommunicationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.FindCommunicationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.FindCommunicationsRequest} FindCommunicationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindCommunicationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.FindCommunicationsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.clientId = reader.string();
                    break;
                case 2:
                    message.managerSsoId = reader.string();
                    break;
                case 3:
                    if (!(message.states && message.states.length))
                        message.states = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.states.push(reader.int32());
                    } else
                        message.states.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindCommunicationsRequest;
    })();

    PlatformCRMWeb.FindCommunicationsResponse = (function() {

        /**
         * Properties of a FindCommunicationsResponse.
         * @memberof PlatformCRMWeb
         * @interface IFindCommunicationsResponse
         * @property {Array.<PlatformCRMWeb.ICommunication>|null} [data] FindCommunicationsResponse data
         */

        /**
         * Constructs a new FindCommunicationsResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a FindCommunicationsResponse.
         * @implements IFindCommunicationsResponse
         * @constructor
         * @param {PlatformCRMWeb.IFindCommunicationsResponse=} [properties] Properties to set
         */
        function FindCommunicationsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindCommunicationsResponse data.
         * @member {Array.<PlatformCRMWeb.ICommunication>} data
         * @memberof PlatformCRMWeb.FindCommunicationsResponse
         * @instance
         */
        FindCommunicationsResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified FindCommunicationsResponse message. Does not implicitly {@link PlatformCRMWeb.FindCommunicationsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.FindCommunicationsResponse
         * @static
         * @param {PlatformCRMWeb.IFindCommunicationsResponse} message FindCommunicationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindCommunicationsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformCRMWeb.Communication.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FindCommunicationsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.FindCommunicationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.FindCommunicationsResponse} FindCommunicationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindCommunicationsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.FindCommunicationsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformCRMWeb.Communication.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindCommunicationsResponse;
    })();

    PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse = (function() {

        /**
         * Properties of a FindAvailableRescheduledCommunicationsResponse.
         * @memberof PlatformCRMWeb
         * @interface IFindAvailableRescheduledCommunicationsResponse
         * @property {Array.<PlatformCRMWeb.ICommunication>|null} [data] FindAvailableRescheduledCommunicationsResponse data
         * @property {boolean|null} [hasNext] FindAvailableRescheduledCommunicationsResponse hasNext
         */

        /**
         * Constructs a new FindAvailableRescheduledCommunicationsResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a FindAvailableRescheduledCommunicationsResponse.
         * @implements IFindAvailableRescheduledCommunicationsResponse
         * @constructor
         * @param {PlatformCRMWeb.IFindAvailableRescheduledCommunicationsResponse=} [properties] Properties to set
         */
        function FindAvailableRescheduledCommunicationsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindAvailableRescheduledCommunicationsResponse data.
         * @member {Array.<PlatformCRMWeb.ICommunication>} data
         * @memberof PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse
         * @instance
         */
        FindAvailableRescheduledCommunicationsResponse.prototype.data = $util.emptyArray;

        /**
         * FindAvailableRescheduledCommunicationsResponse hasNext.
         * @member {boolean} hasNext
         * @memberof PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse
         * @instance
         */
        FindAvailableRescheduledCommunicationsResponse.prototype.hasNext = false;

        /**
         * Encodes the specified FindAvailableRescheduledCommunicationsResponse message. Does not implicitly {@link PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse
         * @static
         * @param {PlatformCRMWeb.IFindAvailableRescheduledCommunicationsResponse} message FindAvailableRescheduledCommunicationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindAvailableRescheduledCommunicationsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformCRMWeb.Communication.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.hasNext != null && Object.hasOwnProperty.call(message, "hasNext"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.hasNext);
            return writer;
        };

        /**
         * Decodes a FindAvailableRescheduledCommunicationsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse} FindAvailableRescheduledCommunicationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindAvailableRescheduledCommunicationsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformCRMWeb.Communication.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.hasNext = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindAvailableRescheduledCommunicationsResponse;
    })();

    PlatformCRMWeb.GetManyReasonsRequest = (function() {

        /**
         * Properties of a GetManyReasonsRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetManyReasonsRequest
         * @property {Array.<string>|null} [categories] GetManyReasonsRequest categories
         * @property {boolean|null} [all] GetManyReasonsRequest all
         */

        /**
         * Constructs a new GetManyReasonsRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetManyReasonsRequest.
         * @implements IGetManyReasonsRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetManyReasonsRequest=} [properties] Properties to set
         */
        function GetManyReasonsRequest(properties) {
            this.categories = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetManyReasonsRequest categories.
         * @member {Array.<string>} categories
         * @memberof PlatformCRMWeb.GetManyReasonsRequest
         * @instance
         */
        GetManyReasonsRequest.prototype.categories = $util.emptyArray;

        /**
         * GetManyReasonsRequest all.
         * @member {boolean} all
         * @memberof PlatformCRMWeb.GetManyReasonsRequest
         * @instance
         */
        GetManyReasonsRequest.prototype.all = false;

        /**
         * Encodes the specified GetManyReasonsRequest message. Does not implicitly {@link PlatformCRMWeb.GetManyReasonsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetManyReasonsRequest
         * @static
         * @param {PlatformCRMWeb.IGetManyReasonsRequest} message GetManyReasonsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetManyReasonsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.categories != null && message.categories.length)
                for (var i = 0; i < message.categories.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.categories[i]);
            if (message.all != null && Object.hasOwnProperty.call(message, "all"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.all);
            return writer;
        };

        /**
         * Decodes a GetManyReasonsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetManyReasonsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetManyReasonsRequest} GetManyReasonsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetManyReasonsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetManyReasonsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.categories && message.categories.length))
                        message.categories = [];
                    message.categories.push(reader.string());
                    break;
                case 2:
                    message.all = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetManyReasonsRequest;
    })();

    PlatformCRMWeb.GetManyReasonsResponse = (function() {

        /**
         * Properties of a GetManyReasonsResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetManyReasonsResponse
         * @property {Array.<PlatformCRMWeb.IReason>|null} [data] GetManyReasonsResponse data
         */

        /**
         * Constructs a new GetManyReasonsResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetManyReasonsResponse.
         * @implements IGetManyReasonsResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetManyReasonsResponse=} [properties] Properties to set
         */
        function GetManyReasonsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetManyReasonsResponse data.
         * @member {Array.<PlatformCRMWeb.IReason>} data
         * @memberof PlatformCRMWeb.GetManyReasonsResponse
         * @instance
         */
        GetManyReasonsResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified GetManyReasonsResponse message. Does not implicitly {@link PlatformCRMWeb.GetManyReasonsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetManyReasonsResponse
         * @static
         * @param {PlatformCRMWeb.IGetManyReasonsResponse} message GetManyReasonsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetManyReasonsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformCRMWeb.Reason.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetManyReasonsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetManyReasonsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetManyReasonsResponse} GetManyReasonsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetManyReasonsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetManyReasonsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformCRMWeb.Reason.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetManyReasonsResponse;
    })();

    PlatformCRMWeb.GetMissCallsCountResponse = (function() {

        /**
         * Properties of a GetMissCallsCountResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetMissCallsCountResponse
         * @property {number|null} [result] GetMissCallsCountResponse result
         */

        /**
         * Constructs a new GetMissCallsCountResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetMissCallsCountResponse.
         * @implements IGetMissCallsCountResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetMissCallsCountResponse=} [properties] Properties to set
         */
        function GetMissCallsCountResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetMissCallsCountResponse result.
         * @member {number} result
         * @memberof PlatformCRMWeb.GetMissCallsCountResponse
         * @instance
         */
        GetMissCallsCountResponse.prototype.result = 0;

        /**
         * Encodes the specified GetMissCallsCountResponse message. Does not implicitly {@link PlatformCRMWeb.GetMissCallsCountResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetMissCallsCountResponse
         * @static
         * @param {PlatformCRMWeb.IGetMissCallsCountResponse} message GetMissCallsCountResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetMissCallsCountResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.result);
            return writer;
        };

        /**
         * Decodes a GetMissCallsCountResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetMissCallsCountResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetMissCallsCountResponse} GetMissCallsCountResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetMissCallsCountResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetMissCallsCountResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.result = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetMissCallsCountResponse;
    })();

    PlatformCRMWeb.GetManyProductReasonsRequest = (function() {

        /**
         * Properties of a GetManyProductReasonsRequest.
         * @memberof PlatformCRMWeb
         * @interface IGetManyProductReasonsRequest
         * @property {Array.<string>|null} [keys] GetManyProductReasonsRequest keys
         */

        /**
         * Constructs a new GetManyProductReasonsRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetManyProductReasonsRequest.
         * @implements IGetManyProductReasonsRequest
         * @constructor
         * @param {PlatformCRMWeb.IGetManyProductReasonsRequest=} [properties] Properties to set
         */
        function GetManyProductReasonsRequest(properties) {
            this.keys = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetManyProductReasonsRequest keys.
         * @member {Array.<string>} keys
         * @memberof PlatformCRMWeb.GetManyProductReasonsRequest
         * @instance
         */
        GetManyProductReasonsRequest.prototype.keys = $util.emptyArray;

        /**
         * Encodes the specified GetManyProductReasonsRequest message. Does not implicitly {@link PlatformCRMWeb.GetManyProductReasonsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetManyProductReasonsRequest
         * @static
         * @param {PlatformCRMWeb.IGetManyProductReasonsRequest} message GetManyProductReasonsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetManyProductReasonsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.keys != null && message.keys.length)
                for (var i = 0; i < message.keys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.keys[i]);
            return writer;
        };

        /**
         * Decodes a GetManyProductReasonsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetManyProductReasonsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetManyProductReasonsRequest} GetManyProductReasonsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetManyProductReasonsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetManyProductReasonsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.keys && message.keys.length))
                        message.keys = [];
                    message.keys.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetManyProductReasonsRequest;
    })();

    PlatformCRMWeb.GetManyProductReasonsResponse = (function() {

        /**
         * Properties of a GetManyProductReasonsResponse.
         * @memberof PlatformCRMWeb
         * @interface IGetManyProductReasonsResponse
         * @property {Array.<PlatformCRMWeb.IProductReason>|null} [data] GetManyProductReasonsResponse data
         */

        /**
         * Constructs a new GetManyProductReasonsResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a GetManyProductReasonsResponse.
         * @implements IGetManyProductReasonsResponse
         * @constructor
         * @param {PlatformCRMWeb.IGetManyProductReasonsResponse=} [properties] Properties to set
         */
        function GetManyProductReasonsResponse(properties) {
            this.data = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetManyProductReasonsResponse data.
         * @member {Array.<PlatformCRMWeb.IProductReason>} data
         * @memberof PlatformCRMWeb.GetManyProductReasonsResponse
         * @instance
         */
        GetManyProductReasonsResponse.prototype.data = $util.emptyArray;

        /**
         * Encodes the specified GetManyProductReasonsResponse message. Does not implicitly {@link PlatformCRMWeb.GetManyProductReasonsResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.GetManyProductReasonsResponse
         * @static
         * @param {PlatformCRMWeb.IGetManyProductReasonsResponse} message GetManyProductReasonsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetManyProductReasonsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && message.data.length)
                for (var i = 0; i < message.data.length; ++i)
                    $root.PlatformCRMWeb.ProductReason.encode(message.data[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a GetManyProductReasonsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.GetManyProductReasonsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.GetManyProductReasonsResponse} GetManyProductReasonsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetManyProductReasonsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.GetManyProductReasonsResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.data && message.data.length))
                        message.data = [];
                    message.data.push($root.PlatformCRMWeb.ProductReason.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return GetManyProductReasonsResponse;
    })();

    PlatformCRMWeb.ProceedTaskResponse = (function() {

        /**
         * Properties of a ProceedTaskResponse.
         * @memberof PlatformCRMWeb
         * @interface IProceedTaskResponse
         * @property {PlatformCRMWeb.ICommunication|null} [communication] ProceedTaskResponse communication
         * @property {string|null} [paymentStatus] ProceedTaskResponse paymentStatus
         * @property {string|null} [orderId] ProceedTaskResponse orderId
         */

        /**
         * Constructs a new ProceedTaskResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a ProceedTaskResponse.
         * @implements IProceedTaskResponse
         * @constructor
         * @param {PlatformCRMWeb.IProceedTaskResponse=} [properties] Properties to set
         */
        function ProceedTaskResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ProceedTaskResponse communication.
         * @member {PlatformCRMWeb.ICommunication|null|undefined} communication
         * @memberof PlatformCRMWeb.ProceedTaskResponse
         * @instance
         */
        ProceedTaskResponse.prototype.communication = null;

        /**
         * ProceedTaskResponse paymentStatus.
         * @member {string|null|undefined} paymentStatus
         * @memberof PlatformCRMWeb.ProceedTaskResponse
         * @instance
         */
        ProceedTaskResponse.prototype.paymentStatus = null;

        /**
         * ProceedTaskResponse orderId.
         * @member {string} orderId
         * @memberof PlatformCRMWeb.ProceedTaskResponse
         * @instance
         */
        ProceedTaskResponse.prototype.orderId = "";

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * ProceedTaskResponse _paymentStatus.
         * @member {"paymentStatus"|undefined} _paymentStatus
         * @memberof PlatformCRMWeb.ProceedTaskResponse
         * @instance
         */
        Object.defineProperty(ProceedTaskResponse.prototype, "_paymentStatus", {
            get: $util.oneOfGetter($oneOfFields = ["paymentStatus"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified ProceedTaskResponse message. Does not implicitly {@link PlatformCRMWeb.ProceedTaskResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.ProceedTaskResponse
         * @static
         * @param {PlatformCRMWeb.IProceedTaskResponse} message ProceedTaskResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ProceedTaskResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.communication != null && Object.hasOwnProperty.call(message, "communication"))
                $root.PlatformCRMWeb.Communication.encode(message.communication, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.paymentStatus != null && Object.hasOwnProperty.call(message, "paymentStatus"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.paymentStatus);
            if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.orderId);
            return writer;
        };

        /**
         * Decodes a ProceedTaskResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.ProceedTaskResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.ProceedTaskResponse} ProceedTaskResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ProceedTaskResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.ProceedTaskResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.communication = $root.PlatformCRMWeb.Communication.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.paymentStatus = reader.string();
                    break;
                case 3:
                    message.orderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ProceedTaskResponse;
    })();

    PlatformCRMWeb.CreateCommunicationRequest = (function() {

        /**
         * Properties of a CreateCommunicationRequest.
         * @memberof PlatformCRMWeb
         * @interface ICreateCommunicationRequest
         * @property {string|null} [phone] CreateCommunicationRequest phone
         * @property {string|null} [element] CreateCommunicationRequest element
         * @property {boolean|null} [isCall] CreateCommunicationRequest isCall
         */

        /**
         * Constructs a new CreateCommunicationRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CreateCommunicationRequest.
         * @implements ICreateCommunicationRequest
         * @constructor
         * @param {PlatformCRMWeb.ICreateCommunicationRequest=} [properties] Properties to set
         */
        function CreateCommunicationRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateCommunicationRequest phone.
         * @member {string} phone
         * @memberof PlatformCRMWeb.CreateCommunicationRequest
         * @instance
         */
        CreateCommunicationRequest.prototype.phone = "";

        /**
         * CreateCommunicationRequest element.
         * @member {string} element
         * @memberof PlatformCRMWeb.CreateCommunicationRequest
         * @instance
         */
        CreateCommunicationRequest.prototype.element = "";

        /**
         * CreateCommunicationRequest isCall.
         * @member {boolean} isCall
         * @memberof PlatformCRMWeb.CreateCommunicationRequest
         * @instance
         */
        CreateCommunicationRequest.prototype.isCall = false;

        /**
         * Encodes the specified CreateCommunicationRequest message. Does not implicitly {@link PlatformCRMWeb.CreateCommunicationRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.CreateCommunicationRequest
         * @static
         * @param {PlatformCRMWeb.ICreateCommunicationRequest} message CreateCommunicationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateCommunicationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.phone);
            if (message.element != null && Object.hasOwnProperty.call(message, "element"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.element);
            if (message.isCall != null && Object.hasOwnProperty.call(message, "isCall"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isCall);
            return writer;
        };

        /**
         * Decodes a CreateCommunicationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.CreateCommunicationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.CreateCommunicationRequest} CreateCommunicationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateCommunicationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.CreateCommunicationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.phone = reader.string();
                    break;
                case 2:
                    message.element = reader.string();
                    break;
                case 3:
                    message.isCall = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return CreateCommunicationRequest;
    })();

    PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest = (function() {

        /**
         * Properties of a FindAvailableRescheduledCommunicationsRequest.
         * @memberof PlatformCRMWeb
         * @interface IFindAvailableRescheduledCommunicationsRequest
         * @property {string|null} [clientId] FindAvailableRescheduledCommunicationsRequest clientId
         * @property {PlatformCRMWeb.IPaginationOptions|null} [pagination] FindAvailableRescheduledCommunicationsRequest pagination
         */

        /**
         * Constructs a new FindAvailableRescheduledCommunicationsRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a FindAvailableRescheduledCommunicationsRequest.
         * @implements IFindAvailableRescheduledCommunicationsRequest
         * @constructor
         * @param {PlatformCRMWeb.IFindAvailableRescheduledCommunicationsRequest=} [properties] Properties to set
         */
        function FindAvailableRescheduledCommunicationsRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindAvailableRescheduledCommunicationsRequest clientId.
         * @member {string} clientId
         * @memberof PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest
         * @instance
         */
        FindAvailableRescheduledCommunicationsRequest.prototype.clientId = "";

        /**
         * FindAvailableRescheduledCommunicationsRequest pagination.
         * @member {PlatformCRMWeb.IPaginationOptions|null|undefined} pagination
         * @memberof PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest
         * @instance
         */
        FindAvailableRescheduledCommunicationsRequest.prototype.pagination = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * FindAvailableRescheduledCommunicationsRequest _pagination.
         * @member {"pagination"|undefined} _pagination
         * @memberof PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest
         * @instance
         */
        Object.defineProperty(FindAvailableRescheduledCommunicationsRequest.prototype, "_pagination", {
            get: $util.oneOfGetter($oneOfFields = ["pagination"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified FindAvailableRescheduledCommunicationsRequest message. Does not implicitly {@link PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest
         * @static
         * @param {PlatformCRMWeb.IFindAvailableRescheduledCommunicationsRequest} message FindAvailableRescheduledCommunicationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindAvailableRescheduledCommunicationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.clientId != null && Object.hasOwnProperty.call(message, "clientId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.clientId);
            if (message.pagination != null && Object.hasOwnProperty.call(message, "pagination"))
                $root.PlatformCRMWeb.PaginationOptions.encode(message.pagination, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FindAvailableRescheduledCommunicationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest} FindAvailableRescheduledCommunicationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindAvailableRescheduledCommunicationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.clientId = reader.string();
                    break;
                case 2:
                    message.pagination = $root.PlatformCRMWeb.PaginationOptions.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindAvailableRescheduledCommunicationsRequest;
    })();

    PlatformCRMWeb.PaginationOptions = (function() {

        /**
         * Properties of a PaginationOptions.
         * @memberof PlatformCRMWeb
         * @interface IPaginationOptions
         * @property {number|null} [skip] PaginationOptions skip
         * @property {number|null} [take] PaginationOptions take
         */

        /**
         * Constructs a new PaginationOptions.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PaginationOptions.
         * @implements IPaginationOptions
         * @constructor
         * @param {PlatformCRMWeb.IPaginationOptions=} [properties] Properties to set
         */
        function PaginationOptions(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PaginationOptions skip.
         * @member {number} skip
         * @memberof PlatformCRMWeb.PaginationOptions
         * @instance
         */
        PaginationOptions.prototype.skip = 0;

        /**
         * PaginationOptions take.
         * @member {number} take
         * @memberof PlatformCRMWeb.PaginationOptions
         * @instance
         */
        PaginationOptions.prototype.take = 0;

        /**
         * Encodes the specified PaginationOptions message. Does not implicitly {@link PlatformCRMWeb.PaginationOptions.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PaginationOptions
         * @static
         * @param {PlatformCRMWeb.IPaginationOptions} message PaginationOptions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PaginationOptions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.skip != null && Object.hasOwnProperty.call(message, "skip"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.skip);
            if (message.take != null && Object.hasOwnProperty.call(message, "take"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.take);
            return writer;
        };

        /**
         * Decodes a PaginationOptions message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PaginationOptions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PaginationOptions} PaginationOptions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PaginationOptions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PaginationOptions();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.skip = reader.int32();
                    break;
                case 2:
                    message.take = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PaginationOptions;
    })();

    PlatformCRMWeb.PushTaskRequest = (function() {

        /**
         * Properties of a PushTaskRequest.
         * @memberof PlatformCRMWeb
         * @interface IPushTaskRequest
         * @property {string|null} [communicationId] PushTaskRequest communicationId
         * @property {string|null} [taskId] PushTaskRequest taskId
         */

        /**
         * Constructs a new PushTaskRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a PushTaskRequest.
         * @implements IPushTaskRequest
         * @constructor
         * @param {PlatformCRMWeb.IPushTaskRequest=} [properties] Properties to set
         */
        function PushTaskRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PushTaskRequest communicationId.
         * @member {string} communicationId
         * @memberof PlatformCRMWeb.PushTaskRequest
         * @instance
         */
        PushTaskRequest.prototype.communicationId = "";

        /**
         * PushTaskRequest taskId.
         * @member {string} taskId
         * @memberof PlatformCRMWeb.PushTaskRequest
         * @instance
         */
        PushTaskRequest.prototype.taskId = "";

        /**
         * Encodes the specified PushTaskRequest message. Does not implicitly {@link PlatformCRMWeb.PushTaskRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.PushTaskRequest
         * @static
         * @param {PlatformCRMWeb.IPushTaskRequest} message PushTaskRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PushTaskRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.communicationId != null && Object.hasOwnProperty.call(message, "communicationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.communicationId);
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.taskId);
            return writer;
        };

        /**
         * Decodes a PushTaskRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.PushTaskRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.PushTaskRequest} PushTaskRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PushTaskRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.PushTaskRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.communicationId = reader.string();
                    break;
                case 2:
                    message.taskId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PushTaskRequest;
    })();

    PlatformCRMWeb.FindRecentCommunicationRequest = (function() {

        /**
         * Properties of a FindRecentCommunicationRequest.
         * @memberof PlatformCRMWeb
         * @interface IFindRecentCommunicationRequest
         * @property {string|null} [taskId] FindRecentCommunicationRequest taskId
         */

        /**
         * Constructs a new FindRecentCommunicationRequest.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a FindRecentCommunicationRequest.
         * @implements IFindRecentCommunicationRequest
         * @constructor
         * @param {PlatformCRMWeb.IFindRecentCommunicationRequest=} [properties] Properties to set
         */
        function FindRecentCommunicationRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindRecentCommunicationRequest taskId.
         * @member {string} taskId
         * @memberof PlatformCRMWeb.FindRecentCommunicationRequest
         * @instance
         */
        FindRecentCommunicationRequest.prototype.taskId = "";

        /**
         * Encodes the specified FindRecentCommunicationRequest message. Does not implicitly {@link PlatformCRMWeb.FindRecentCommunicationRequest.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.FindRecentCommunicationRequest
         * @static
         * @param {PlatformCRMWeb.IFindRecentCommunicationRequest} message FindRecentCommunicationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindRecentCommunicationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.taskId != null && Object.hasOwnProperty.call(message, "taskId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.taskId);
            return writer;
        };

        /**
         * Decodes a FindRecentCommunicationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.FindRecentCommunicationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.FindRecentCommunicationRequest} FindRecentCommunicationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindRecentCommunicationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.FindRecentCommunicationRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.taskId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindRecentCommunicationRequest;
    })();

    PlatformCRMWeb.FindRecentCommunicationResponse = (function() {

        /**
         * Properties of a FindRecentCommunicationResponse.
         * @memberof PlatformCRMWeb
         * @interface IFindRecentCommunicationResponse
         * @property {PlatformCRMWeb.ICommunication|null} [data] FindRecentCommunicationResponse data
         */

        /**
         * Constructs a new FindRecentCommunicationResponse.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a FindRecentCommunicationResponse.
         * @implements IFindRecentCommunicationResponse
         * @constructor
         * @param {PlatformCRMWeb.IFindRecentCommunicationResponse=} [properties] Properties to set
         */
        function FindRecentCommunicationResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindRecentCommunicationResponse data.
         * @member {PlatformCRMWeb.ICommunication|null|undefined} data
         * @memberof PlatformCRMWeb.FindRecentCommunicationResponse
         * @instance
         */
        FindRecentCommunicationResponse.prototype.data = null;

        /**
         * Encodes the specified FindRecentCommunicationResponse message. Does not implicitly {@link PlatformCRMWeb.FindRecentCommunicationResponse.verify|verify} messages.
         * @function encode
         * @memberof PlatformCRMWeb.FindRecentCommunicationResponse
         * @static
         * @param {PlatformCRMWeb.IFindRecentCommunicationResponse} message FindRecentCommunicationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindRecentCommunicationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.PlatformCRMWeb.Communication.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a FindRecentCommunicationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof PlatformCRMWeb.FindRecentCommunicationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PlatformCRMWeb.FindRecentCommunicationResponse} FindRecentCommunicationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindRecentCommunicationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PlatformCRMWeb.FindRecentCommunicationResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.PlatformCRMWeb.Communication.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return FindRecentCommunicationResponse;
    })();

    PlatformCRMWeb.CommunicationService = (function() {

        /**
         * Constructs a new CommunicationService service.
         * @memberof PlatformCRMWeb
         * @classdesc Represents a CommunicationService
         * @extends $protobuf.rpc.Service
         * @constructor
         * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
         * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
         * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
         */
        function CommunicationService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (CommunicationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CommunicationService;

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#check}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef CheckCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.CheckCommunicationResponse} [response] CheckCommunicationResponse
         */

        /**
         * Calls Check.
         * @function check
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.ICheckCommunicationRequest} request CheckCommunicationRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.CheckCallback} callback Node-style callback called with the error, if any, and CheckCommunicationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.check = function check(request, callback) {
            return this.rpcCall(check, $root.PlatformCRMWeb.CheckCommunicationRequest, $root.PlatformCRMWeb.CheckCommunicationResponse, request, callback);
        }, "name", { value: "Check" });

        /**
         * Calls Check.
         * @function check
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.ICheckCommunicationRequest} request CheckCommunicationRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.CheckCommunicationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#apply}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef ApplyCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.ApplyCommunicationResponse} [response] ApplyCommunicationResponse
         */

        /**
         * Calls Apply.
         * @function apply
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IApplyCommunicationRequest} request ApplyCommunicationRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.ApplyCallback} callback Node-style callback called with the error, if any, and ApplyCommunicationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.apply = function apply(request, callback) {
            return this.rpcCall(apply, $root.PlatformCRMWeb.ApplyCommunicationRequest, $root.PlatformCRMWeb.ApplyCommunicationResponse, request, callback);
        }, "name", { value: "Apply" });

        /**
         * Calls Apply.
         * @function apply
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IApplyCommunicationRequest} request ApplyCommunicationRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.ApplyCommunicationResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#getById}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef GetByIdCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.Communication} [response] Communication
         */

        /**
         * Calls GetById.
         * @function getById
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.ICommunicationByIdRequest} request CommunicationByIdRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.GetByIdCallback} callback Node-style callback called with the error, if any, and Communication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.getById = function getById(request, callback) {
            return this.rpcCall(getById, $root.PlatformCRMWeb.CommunicationByIdRequest, $root.PlatformCRMWeb.Communication, request, callback);
        }, "name", { value: "GetById" });

        /**
         * Calls GetById.
         * @function getById
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.ICommunicationByIdRequest} request CommunicationByIdRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.Communication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#cancelTask}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef CancelTaskCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.Communication} [response] Communication
         */

        /**
         * Calls CancelTask.
         * @function cancelTask
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.ICancelTaskRequest} request CancelTaskRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.CancelTaskCallback} callback Node-style callback called with the error, if any, and Communication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.cancelTask = function cancelTask(request, callback) {
            return this.rpcCall(cancelTask, $root.PlatformCRMWeb.CancelTaskRequest, $root.PlatformCRMWeb.Communication, request, callback);
        }, "name", { value: "CancelTask" });

        /**
         * Calls CancelTask.
         * @function cancelTask
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.ICancelTaskRequest} request CancelTaskRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.Communication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#proceedTask}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef ProceedTaskCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.ProceedTaskResponse} [response] ProceedTaskResponse
         */

        /**
         * Calls ProceedTask.
         * @function proceedTask
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IProceedTaskRequest} request ProceedTaskRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.ProceedTaskCallback} callback Node-style callback called with the error, if any, and ProceedTaskResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.proceedTask = function proceedTask(request, callback) {
            return this.rpcCall(proceedTask, $root.PlatformCRMWeb.ProceedTaskRequest, $root.PlatformCRMWeb.ProceedTaskResponse, request, callback);
        }, "name", { value: "ProceedTask" });

        /**
         * Calls ProceedTask.
         * @function proceedTask
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IProceedTaskRequest} request ProceedTaskRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.ProceedTaskResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#proceedTicketTask}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef ProceedTicketTaskCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.Communication} [response] Communication
         */

        /**
         * Calls ProceedTicketTask.
         * @function proceedTicketTask
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IProceedTicketTaskRequest} request ProceedTicketTaskRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.ProceedTicketTaskCallback} callback Node-style callback called with the error, if any, and Communication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.proceedTicketTask = function proceedTicketTask(request, callback) {
            return this.rpcCall(proceedTicketTask, $root.PlatformCRMWeb.ProceedTicketTaskRequest, $root.PlatformCRMWeb.Communication, request, callback);
        }, "name", { value: "ProceedTicketTask" });

        /**
         * Calls ProceedTicketTask.
         * @function proceedTicketTask
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IProceedTicketTaskRequest} request ProceedTicketTaskRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.Communication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#addTask}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef AddTaskCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.Communication} [response] Communication
         */

        /**
         * Calls AddTask.
         * @function addTask
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IAddTaskRequest} request AddTaskRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.AddTaskCallback} callback Node-style callback called with the error, if any, and Communication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.addTask = function addTask(request, callback) {
            return this.rpcCall(addTask, $root.PlatformCRMWeb.AddTaskRequest, $root.PlatformCRMWeb.Communication, request, callback);
        }, "name", { value: "AddTask" });

        /**
         * Calls AddTask.
         * @function addTask
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IAddTaskRequest} request AddTaskRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.Communication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#reschedule}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef RescheduleCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.Communication} [response] Communication
         */

        /**
         * Calls Reschedule.
         * @function reschedule
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IRescheduleRequest} request RescheduleRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.RescheduleCallback} callback Node-style callback called with the error, if any, and Communication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.reschedule = function reschedule(request, callback) {
            return this.rpcCall(reschedule, $root.PlatformCRMWeb.RescheduleRequest, $root.PlatformCRMWeb.Communication, request, callback);
        }, "name", { value: "Reschedule" });

        /**
         * Calls Reschedule.
         * @function reschedule
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IRescheduleRequest} request RescheduleRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.Communication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#postpone}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef PostponeCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.Communication} [response] Communication
         */

        /**
         * Calls Postpone.
         * @function postpone
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IPostponeRequest} request PostponeRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.PostponeCallback} callback Node-style callback called with the error, if any, and Communication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.postpone = function postpone(request, callback) {
            return this.rpcCall(postpone, $root.PlatformCRMWeb.PostponeRequest, $root.PlatformCRMWeb.Communication, request, callback);
        }, "name", { value: "Postpone" });

        /**
         * Calls Postpone.
         * @function postpone
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IPostponeRequest} request PostponeRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.Communication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#fail}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef FailCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.Communication} [response] Communication
         */

        /**
         * Calls Fail.
         * @function fail
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IFailRequest} request FailRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.FailCallback} callback Node-style callback called with the error, if any, and Communication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.fail = function fail(request, callback) {
            return this.rpcCall(fail, $root.PlatformCRMWeb.FailRequest, $root.PlatformCRMWeb.Communication, request, callback);
        }, "name", { value: "Fail" });

        /**
         * Calls Fail.
         * @function fail
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IFailRequest} request FailRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.Communication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#findCommunications}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef FindCommunicationsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.FindCommunicationsResponse} [response] FindCommunicationsResponse
         */

        /**
         * Calls FindCommunications.
         * @function findCommunications
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IFindCommunicationsRequest} request FindCommunicationsRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.FindCommunicationsCallback} callback Node-style callback called with the error, if any, and FindCommunicationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.findCommunications = function findCommunications(request, callback) {
            return this.rpcCall(findCommunications, $root.PlatformCRMWeb.FindCommunicationsRequest, $root.PlatformCRMWeb.FindCommunicationsResponse, request, callback);
        }, "name", { value: "FindCommunications" });

        /**
         * Calls FindCommunications.
         * @function findCommunications
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IFindCommunicationsRequest} request FindCommunicationsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.FindCommunicationsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#findAvailableRescheduledCommunications}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef FindAvailableRescheduledCommunicationsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse} [response] FindAvailableRescheduledCommunicationsResponse
         */

        /**
         * Calls FindAvailableRescheduledCommunications.
         * @function findAvailableRescheduledCommunications
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IFindAvailableRescheduledCommunicationsRequest} request FindAvailableRescheduledCommunicationsRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.FindAvailableRescheduledCommunicationsCallback} callback Node-style callback called with the error, if any, and FindAvailableRescheduledCommunicationsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.findAvailableRescheduledCommunications = function findAvailableRescheduledCommunications(request, callback) {
            return this.rpcCall(findAvailableRescheduledCommunications, $root.PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest, $root.PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse, request, callback);
        }, "name", { value: "FindAvailableRescheduledCommunications" });

        /**
         * Calls FindAvailableRescheduledCommunications.
         * @function findAvailableRescheduledCommunications
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IFindAvailableRescheduledCommunicationsRequest} request FindAvailableRescheduledCommunicationsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#getManyReasons}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef GetManyReasonsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetManyReasonsResponse} [response] GetManyReasonsResponse
         */

        /**
         * Calls GetManyReasons.
         * @function getManyReasons
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IGetManyReasonsRequest} request GetManyReasonsRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.GetManyReasonsCallback} callback Node-style callback called with the error, if any, and GetManyReasonsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.getManyReasons = function getManyReasons(request, callback) {
            return this.rpcCall(getManyReasons, $root.PlatformCRMWeb.GetManyReasonsRequest, $root.PlatformCRMWeb.GetManyReasonsResponse, request, callback);
        }, "name", { value: "GetManyReasons" });

        /**
         * Calls GetManyReasons.
         * @function getManyReasons
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IGetManyReasonsRequest} request GetManyReasonsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetManyReasonsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#getManyProductReasons}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef GetManyProductReasonsCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetManyProductReasonsResponse} [response] GetManyProductReasonsResponse
         */

        /**
         * Calls GetManyProductReasons.
         * @function getManyProductReasons
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IGetManyProductReasonsRequest} request GetManyProductReasonsRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.GetManyProductReasonsCallback} callback Node-style callback called with the error, if any, and GetManyProductReasonsResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.getManyProductReasons = function getManyProductReasons(request, callback) {
            return this.rpcCall(getManyProductReasons, $root.PlatformCRMWeb.GetManyProductReasonsRequest, $root.PlatformCRMWeb.GetManyProductReasonsResponse, request, callback);
        }, "name", { value: "GetManyProductReasons" });

        /**
         * Calls GetManyProductReasons.
         * @function getManyProductReasons
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IGetManyProductReasonsRequest} request GetManyProductReasonsRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetManyProductReasonsResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#getMissCallsCount}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef GetMissCallsCountCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.GetMissCallsCountResponse} [response] GetMissCallsCountResponse
         */

        /**
         * Calls GetMissCallsCount.
         * @function getMissCallsCount
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.ICommunicationByIdRequest} request CommunicationByIdRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.GetMissCallsCountCallback} callback Node-style callback called with the error, if any, and GetMissCallsCountResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.getMissCallsCount = function getMissCallsCount(request, callback) {
            return this.rpcCall(getMissCallsCount, $root.PlatformCRMWeb.CommunicationByIdRequest, $root.PlatformCRMWeb.GetMissCallsCountResponse, request, callback);
        }, "name", { value: "GetMissCallsCount" });

        /**
         * Calls GetMissCallsCount.
         * @function getMissCallsCount
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.ICommunicationByIdRequest} request CommunicationByIdRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.GetMissCallsCountResponse>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#createCommunication}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef CreateCommunicationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.Communication} [response] Communication
         */

        /**
         * Calls CreateCommunication.
         * @function createCommunication
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.ICreateCommunicationRequest} request CreateCommunicationRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.CreateCommunicationCallback} callback Node-style callback called with the error, if any, and Communication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.createCommunication = function createCommunication(request, callback) {
            return this.rpcCall(createCommunication, $root.PlatformCRMWeb.CreateCommunicationRequest, $root.PlatformCRMWeb.Communication, request, callback);
        }, "name", { value: "CreateCommunication" });

        /**
         * Calls CreateCommunication.
         * @function createCommunication
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.ICreateCommunicationRequest} request CreateCommunicationRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.Communication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#pushTask}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef PushTaskCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.Communication} [response] Communication
         */

        /**
         * Calls PushTask.
         * @function pushTask
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IPushTaskRequest} request PushTaskRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.PushTaskCallback} callback Node-style callback called with the error, if any, and Communication
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.pushTask = function pushTask(request, callback) {
            return this.rpcCall(pushTask, $root.PlatformCRMWeb.PushTaskRequest, $root.PlatformCRMWeb.Communication, request, callback);
        }, "name", { value: "PushTask" });

        /**
         * Calls PushTask.
         * @function pushTask
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IPushTaskRequest} request PushTaskRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.Communication>} Promise
         * @variation 2
         */

        /**
         * Callback as used by {@link PlatformCRMWeb.CommunicationService#findRecentCommunication}.
         * @memberof PlatformCRMWeb.CommunicationService
         * @typedef FindRecentCommunicationCallback
         * @type {function}
         * @param {Error|null} error Error, if any
         * @param {PlatformCRMWeb.FindRecentCommunicationResponse} [response] FindRecentCommunicationResponse
         */

        /**
         * Calls FindRecentCommunication.
         * @function findRecentCommunication
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IFindRecentCommunicationRequest} request FindRecentCommunicationRequest message or plain object
         * @param {PlatformCRMWeb.CommunicationService.FindRecentCommunicationCallback} callback Node-style callback called with the error, if any, and FindRecentCommunicationResponse
         * @returns {undefined}
         * @variation 1
         */
        Object.defineProperty(CommunicationService.prototype.findRecentCommunication = function findRecentCommunication(request, callback) {
            return this.rpcCall(findRecentCommunication, $root.PlatformCRMWeb.FindRecentCommunicationRequest, $root.PlatformCRMWeb.FindRecentCommunicationResponse, request, callback);
        }, "name", { value: "FindRecentCommunication" });

        /**
         * Calls FindRecentCommunication.
         * @function findRecentCommunication
         * @memberof PlatformCRMWeb.CommunicationService
         * @instance
         * @param {PlatformCRMWeb.IFindRecentCommunicationRequest} request FindRecentCommunicationRequest message or plain object
         * @returns {Promise<PlatformCRMWeb.FindRecentCommunicationResponse>} Promise
         * @variation 2
         */

        return CommunicationService;
    })();

    return PlatformCRMWeb;
})();

$root.sso = (function() {

    /**
     * Namespace sso.
     * @exports sso
     * @namespace
     */
    var sso = {};

    sso.UuidRequest = (function() {

        /**
         * Properties of an UuidRequest.
         * @memberof sso
         * @interface IUuidRequest
         * @property {string|null} [id] UuidRequest id
         */

        /**
         * Constructs a new UuidRequest.
         * @memberof sso
         * @classdesc Represents an UuidRequest.
         * @implements IUuidRequest
         * @constructor
         * @param {sso.IUuidRequest=} [properties] Properties to set
         */
        function UuidRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UuidRequest id.
         * @member {string} id
         * @memberof sso.UuidRequest
         * @instance
         */
        UuidRequest.prototype.id = "";

        /**
         * Encodes the specified UuidRequest message. Does not implicitly {@link sso.UuidRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.UuidRequest
         * @static
         * @param {sso.IUuidRequest} message UuidRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UuidRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes an UuidRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.UuidRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.UuidRequest} UuidRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UuidRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.UuidRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UuidRequest;
    })();

    sso.Relation = (function() {

        /**
         * Properties of a Relation.
         * @memberof sso
         * @interface IRelation
         * @property {string|null} [id] Relation id
         */

        /**
         * Constructs a new Relation.
         * @memberof sso
         * @classdesc Represents a Relation.
         * @implements IRelation
         * @constructor
         * @param {sso.IRelation=} [properties] Properties to set
         */
        function Relation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Relation id.
         * @member {string} id
         * @memberof sso.Relation
         * @instance
         */
        Relation.prototype.id = "";

        /**
         * Encodes the specified Relation message. Does not implicitly {@link sso.Relation.verify|verify} messages.
         * @function encode
         * @memberof sso.Relation
         * @static
         * @param {sso.IRelation} message Relation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Relation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Decodes a Relation message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Relation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Relation} Relation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Relation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Relation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Relation;
    })();

    sso.Coordinates = (function() {

        /**
         * Properties of a Coordinates.
         * @memberof sso
         * @interface ICoordinates
         * @property {number|null} [lat] Coordinates lat
         * @property {number|null} [lng] Coordinates lng
         */

        /**
         * Constructs a new Coordinates.
         * @memberof sso
         * @classdesc Represents a Coordinates.
         * @implements ICoordinates
         * @constructor
         * @param {sso.ICoordinates=} [properties] Properties to set
         */
        function Coordinates(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Coordinates lat.
         * @member {number} lat
         * @memberof sso.Coordinates
         * @instance
         */
        Coordinates.prototype.lat = 0;

        /**
         * Coordinates lng.
         * @member {number} lng
         * @memberof sso.Coordinates
         * @instance
         */
        Coordinates.prototype.lng = 0;

        /**
         * Encodes the specified Coordinates message. Does not implicitly {@link sso.Coordinates.verify|verify} messages.
         * @function encode
         * @memberof sso.Coordinates
         * @static
         * @param {sso.ICoordinates} message Coordinates message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Coordinates.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.lat);
            if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
                writer.uint32(/* id 2, wireType 1 =*/17).double(message.lng);
            return writer;
        };

        /**
         * Decodes a Coordinates message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Coordinates
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Coordinates} Coordinates
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Coordinates.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Coordinates();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.lat = reader.double();
                    break;
                case 2:
                    message.lng = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Coordinates;
    })();

    sso.Metro = (function() {

        /**
         * Properties of a Metro.
         * @memberof sso
         * @interface IMetro
         * @property {number|null} [distance] Metro distance
         * @property {string|null} [line] Metro line
         * @property {string|null} [name] Metro name
         */

        /**
         * Constructs a new Metro.
         * @memberof sso
         * @classdesc Represents a Metro.
         * @implements IMetro
         * @constructor
         * @param {sso.IMetro=} [properties] Properties to set
         */
        function Metro(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Metro distance.
         * @member {number} distance
         * @memberof sso.Metro
         * @instance
         */
        Metro.prototype.distance = 0;

        /**
         * Metro line.
         * @member {string} line
         * @memberof sso.Metro
         * @instance
         */
        Metro.prototype.line = "";

        /**
         * Metro name.
         * @member {string} name
         * @memberof sso.Metro
         * @instance
         */
        Metro.prototype.name = "";

        /**
         * Encodes the specified Metro message. Does not implicitly {@link sso.Metro.verify|verify} messages.
         * @function encode
         * @memberof sso.Metro
         * @static
         * @param {sso.IMetro} message Metro message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Metro.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.distance);
            if (message.line != null && Object.hasOwnProperty.call(message, "line"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.line);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };

        /**
         * Decodes a Metro message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Metro
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Metro} Metro
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Metro.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Metro();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.distance = reader.double();
                    break;
                case 2:
                    message.line = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Metro;
    })();

    sso.AddressDetail = (function() {

        /**
         * Properties of an AddressDetail.
         * @memberof sso
         * @interface IAddressDetail
         * @property {string|null} [floor] AddressDetail floor
         * @property {string|null} [comment] AddressDetail comment
         * @property {string|null} [intercom] AddressDetail intercom
         * @property {string|null} [entrance] AddressDetail entrance
         * @property {string|null} [region] AddressDetail region
         * @property {string|null} [city] AddressDetail city
         * @property {string|null} [area] AddressDetail area
         * @property {string|null} [street] AddressDetail street
         * @property {string|null} [block] AddressDetail block
         * @property {string|null} [house] AddressDetail house
         * @property {string|null} [flat] AddressDetail flat
         */

        /**
         * Constructs a new AddressDetail.
         * @memberof sso
         * @classdesc Represents an AddressDetail.
         * @implements IAddressDetail
         * @constructor
         * @param {sso.IAddressDetail=} [properties] Properties to set
         */
        function AddressDetail(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddressDetail floor.
         * @member {string} floor
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.floor = "";

        /**
         * AddressDetail comment.
         * @member {string} comment
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.comment = "";

        /**
         * AddressDetail intercom.
         * @member {string} intercom
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.intercom = "";

        /**
         * AddressDetail entrance.
         * @member {string} entrance
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.entrance = "";

        /**
         * AddressDetail region.
         * @member {string} region
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.region = "";

        /**
         * AddressDetail city.
         * @member {string} city
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.city = "";

        /**
         * AddressDetail area.
         * @member {string} area
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.area = "";

        /**
         * AddressDetail street.
         * @member {string} street
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.street = "";

        /**
         * AddressDetail block.
         * @member {string} block
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.block = "";

        /**
         * AddressDetail house.
         * @member {string} house
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.house = "";

        /**
         * AddressDetail flat.
         * @member {string} flat
         * @memberof sso.AddressDetail
         * @instance
         */
        AddressDetail.prototype.flat = "";

        /**
         * Encodes the specified AddressDetail message. Does not implicitly {@link sso.AddressDetail.verify|verify} messages.
         * @function encode
         * @memberof sso.AddressDetail
         * @static
         * @param {sso.IAddressDetail} message AddressDetail message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddressDetail.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.floor != null && Object.hasOwnProperty.call(message, "floor"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.floor);
            if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.comment);
            if (message.intercom != null && Object.hasOwnProperty.call(message, "intercom"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.intercom);
            if (message.entrance != null && Object.hasOwnProperty.call(message, "entrance"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.entrance);
            if (message.region != null && Object.hasOwnProperty.call(message, "region"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.region);
            if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.city);
            if (message.area != null && Object.hasOwnProperty.call(message, "area"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.area);
            if (message.street != null && Object.hasOwnProperty.call(message, "street"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.street);
            if (message.block != null && Object.hasOwnProperty.call(message, "block"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.block);
            if (message.house != null && Object.hasOwnProperty.call(message, "house"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.house);
            if (message.flat != null && Object.hasOwnProperty.call(message, "flat"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.flat);
            return writer;
        };

        /**
         * Decodes an AddressDetail message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddressDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddressDetail} AddressDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddressDetail.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddressDetail();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.floor = reader.string();
                    break;
                case 2:
                    message.comment = reader.string();
                    break;
                case 3:
                    message.intercom = reader.string();
                    break;
                case 4:
                    message.entrance = reader.string();
                    break;
                case 5:
                    message.region = reader.string();
                    break;
                case 6:
                    message.city = reader.string();
                    break;
                case 7:
                    message.area = reader.string();
                    break;
                case 8:
                    message.street = reader.string();
                    break;
                case 9:
                    message.block = reader.string();
                    break;
                case 10:
                    message.house = reader.string();
                    break;
                case 11:
                    message.flat = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddressDetail;
    })();

    sso.Address = (function() {

        /**
         * Properties of an Address.
         * @memberof sso
         * @interface IAddress
         * @property {string|null} [id] Address id
         * @property {string|null} [label] Address label
         * @property {string|null} [value] Address value
         * @property {string|null} [unrestrictedValue] Address unrestrictedValue
         * @property {string|null} [object] Address object
         * @property {sso.IAddressDetail|null} [detail] Address detail
         * @property {sso.ICoordinates|null} [coordinates] Address coordinates
         * @property {Array.<sso.IMetro>|null} [metro] Address metro
         * @property {sso.ADDRESS_TYPE|null} [type] Address type
         * @property {string|null} [createdAt] Address createdAt
         * @property {string|null} [updatedAt] Address updatedAt
         * @property {boolean|null} [isDeleted] Address isDeleted
         * @property {string|null} [deletedAt] Address deletedAt
         * @property {boolean|null} [isReadonly] Address isReadonly
         */

        /**
         * Constructs a new Address.
         * @memberof sso
         * @classdesc Represents an Address.
         * @implements IAddress
         * @constructor
         * @param {sso.IAddress=} [properties] Properties to set
         */
        function Address(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Address id.
         * @member {string} id
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.id = "";

        /**
         * Address label.
         * @member {string} label
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.label = "";

        /**
         * Address value.
         * @member {string} value
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.value = "";

        /**
         * Address unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.unrestrictedValue = "";

        /**
         * Address object.
         * @member {string} object
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.object = "";

        /**
         * Address detail.
         * @member {sso.IAddressDetail|null|undefined} detail
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.detail = null;

        /**
         * Address coordinates.
         * @member {sso.ICoordinates|null|undefined} coordinates
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.coordinates = null;

        /**
         * Address metro.
         * @member {Array.<sso.IMetro>} metro
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.metro = $util.emptyArray;

        /**
         * Address type.
         * @member {sso.ADDRESS_TYPE} type
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.type = 0;

        /**
         * Address createdAt.
         * @member {string} createdAt
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.createdAt = "";

        /**
         * Address updatedAt.
         * @member {string} updatedAt
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.updatedAt = "";

        /**
         * Address isDeleted.
         * @member {boolean} isDeleted
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.isDeleted = false;

        /**
         * Address deletedAt.
         * @member {string} deletedAt
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.deletedAt = "";

        /**
         * Address isReadonly.
         * @member {boolean} isReadonly
         * @memberof sso.Address
         * @instance
         */
        Address.prototype.isReadonly = false;

        /**
         * Encodes the specified Address message. Does not implicitly {@link sso.Address.verify|verify} messages.
         * @function encode
         * @memberof sso.Address
         * @static
         * @param {sso.IAddress} message Address message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Address.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.unrestrictedValue);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.object);
            if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                $root.sso.AddressDetail.encode(message.detail, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                $root.sso.Coordinates.encode(message.coordinates, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.sso.Metro.encode(message.metro[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.type);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.deletedAt);
            if (message.isReadonly != null && Object.hasOwnProperty.call(message, "isReadonly"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.isReadonly);
            return writer;
        };

        /**
         * Decodes an Address message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Address
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Address} Address
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Address.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Address();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.unrestrictedValue = reader.string();
                    break;
                case 5:
                    message.object = reader.string();
                    break;
                case 6:
                    message.detail = $root.sso.AddressDetail.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.coordinates = $root.sso.Coordinates.decode(reader, reader.uint32());
                    break;
                case 8:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.sso.Metro.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.type = reader.int32();
                    break;
                case 10:
                    message.createdAt = reader.string();
                    break;
                case 11:
                    message.updatedAt = reader.string();
                    break;
                case 12:
                    message.isDeleted = reader.bool();
                    break;
                case 13:
                    message.deletedAt = reader.string();
                    break;
                case 14:
                    message.isReadonly = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Address;
    })();

    sso.AddressRel = (function() {

        /**
         * Properties of an AddressRel.
         * @memberof sso
         * @interface IAddressRel
         * @property {string|null} [id] AddressRel id
         * @property {string|null} [label] AddressRel label
         * @property {string|null} [value] AddressRel value
         * @property {string|null} [unrestrictedValue] AddressRel unrestrictedValue
         * @property {string|null} [object] AddressRel object
         * @property {sso.IAddressDetail|null} [detail] AddressRel detail
         * @property {sso.ICoordinates|null} [coordinates] AddressRel coordinates
         * @property {Array.<sso.IMetro>|null} [metro] AddressRel metro
         * @property {sso.ADDRESS_TYPE|null} [type] AddressRel type
         * @property {boolean|null} [isDeleted] AddressRel isDeleted
         */

        /**
         * Constructs a new AddressRel.
         * @memberof sso
         * @classdesc Represents an AddressRel.
         * @implements IAddressRel
         * @constructor
         * @param {sso.IAddressRel=} [properties] Properties to set
         */
        function AddressRel(properties) {
            this.metro = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddressRel id.
         * @member {string} id
         * @memberof sso.AddressRel
         * @instance
         */
        AddressRel.prototype.id = "";

        /**
         * AddressRel label.
         * @member {string} label
         * @memberof sso.AddressRel
         * @instance
         */
        AddressRel.prototype.label = "";

        /**
         * AddressRel value.
         * @member {string} value
         * @memberof sso.AddressRel
         * @instance
         */
        AddressRel.prototype.value = "";

        /**
         * AddressRel unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof sso.AddressRel
         * @instance
         */
        AddressRel.prototype.unrestrictedValue = "";

        /**
         * AddressRel object.
         * @member {string} object
         * @memberof sso.AddressRel
         * @instance
         */
        AddressRel.prototype.object = "";

        /**
         * AddressRel detail.
         * @member {sso.IAddressDetail|null|undefined} detail
         * @memberof sso.AddressRel
         * @instance
         */
        AddressRel.prototype.detail = null;

        /**
         * AddressRel coordinates.
         * @member {sso.ICoordinates|null|undefined} coordinates
         * @memberof sso.AddressRel
         * @instance
         */
        AddressRel.prototype.coordinates = null;

        /**
         * AddressRel metro.
         * @member {Array.<sso.IMetro>} metro
         * @memberof sso.AddressRel
         * @instance
         */
        AddressRel.prototype.metro = $util.emptyArray;

        /**
         * AddressRel type.
         * @member {sso.ADDRESS_TYPE} type
         * @memberof sso.AddressRel
         * @instance
         */
        AddressRel.prototype.type = 0;

        /**
         * AddressRel isDeleted.
         * @member {boolean} isDeleted
         * @memberof sso.AddressRel
         * @instance
         */
        AddressRel.prototype.isDeleted = false;

        /**
         * Encodes the specified AddressRel message. Does not implicitly {@link sso.AddressRel.verify|verify} messages.
         * @function encode
         * @memberof sso.AddressRel
         * @static
         * @param {sso.IAddressRel} message AddressRel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddressRel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.label != null && Object.hasOwnProperty.call(message, "label"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.unrestrictedValue);
            if (message.object != null && Object.hasOwnProperty.call(message, "object"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.object);
            if (message.detail != null && Object.hasOwnProperty.call(message, "detail"))
                $root.sso.AddressDetail.encode(message.detail, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.coordinates != null && Object.hasOwnProperty.call(message, "coordinates"))
                $root.sso.Coordinates.encode(message.coordinates, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.metro != null && message.metro.length)
                for (var i = 0; i < message.metro.length; ++i)
                    $root.sso.Metro.encode(message.metro[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 9, wireType 0 =*/72).int32(message.type);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes an AddressRel message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddressRel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddressRel} AddressRel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddressRel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddressRel();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.label = reader.string();
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                case 4:
                    message.unrestrictedValue = reader.string();
                    break;
                case 5:
                    message.object = reader.string();
                    break;
                case 6:
                    message.detail = $root.sso.AddressDetail.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.coordinates = $root.sso.Coordinates.decode(reader, reader.uint32());
                    break;
                case 8:
                    if (!(message.metro && message.metro.length))
                        message.metro = [];
                    message.metro.push($root.sso.Metro.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.type = reader.int32();
                    break;
                case 10:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddressRel;
    })();

    sso.Contact = (function() {

        /**
         * Properties of a Contact.
         * @memberof sso
         * @interface IContact
         * @property {string|null} [value] Contact value
         * @property {sso.CONTACT_TYPE|null} [type] Contact type
         * @property {string|null} [id] Contact id
         * @property {string|null} [createdAt] Contact createdAt
         * @property {string|null} [updatedAt] Contact updatedAt
         * @property {boolean|null} [isDeleted] Contact isDeleted
         * @property {string|null} [deletedAt] Contact deletedAt
         * @property {boolean|null} [isVerified] Contact isVerified
         * @property {boolean|null} [isMain] Contact isMain
         */

        /**
         * Constructs a new Contact.
         * @memberof sso
         * @classdesc Represents a Contact.
         * @implements IContact
         * @constructor
         * @param {sso.IContact=} [properties] Properties to set
         */
        function Contact(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Contact value.
         * @member {string} value
         * @memberof sso.Contact
         * @instance
         */
        Contact.prototype.value = "";

        /**
         * Contact type.
         * @member {sso.CONTACT_TYPE} type
         * @memberof sso.Contact
         * @instance
         */
        Contact.prototype.type = 0;

        /**
         * Contact id.
         * @member {string} id
         * @memberof sso.Contact
         * @instance
         */
        Contact.prototype.id = "";

        /**
         * Contact createdAt.
         * @member {string} createdAt
         * @memberof sso.Contact
         * @instance
         */
        Contact.prototype.createdAt = "";

        /**
         * Contact updatedAt.
         * @member {string} updatedAt
         * @memberof sso.Contact
         * @instance
         */
        Contact.prototype.updatedAt = "";

        /**
         * Contact isDeleted.
         * @member {boolean} isDeleted
         * @memberof sso.Contact
         * @instance
         */
        Contact.prototype.isDeleted = false;

        /**
         * Contact deletedAt.
         * @member {string} deletedAt
         * @memberof sso.Contact
         * @instance
         */
        Contact.prototype.deletedAt = "";

        /**
         * Contact isVerified.
         * @member {boolean} isVerified
         * @memberof sso.Contact
         * @instance
         */
        Contact.prototype.isVerified = false;

        /**
         * Contact isMain.
         * @member {boolean} isMain
         * @memberof sso.Contact
         * @instance
         */
        Contact.prototype.isMain = false;

        /**
         * Encodes the specified Contact message. Does not implicitly {@link sso.Contact.verify|verify} messages.
         * @function encode
         * @memberof sso.Contact
         * @static
         * @param {sso.IContact} message Contact message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Contact.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.type);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.updatedAt);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            if (message.deletedAt != null && Object.hasOwnProperty.call(message, "deletedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.deletedAt);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isVerified);
            if (message.isMain != null && Object.hasOwnProperty.call(message, "isMain"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isMain);
            return writer;
        };

        /**
         * Decodes a Contact message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Contact
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Contact} Contact
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Contact.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Contact();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.value = reader.string();
                    break;
                case 2:
                    message.type = reader.int32();
                    break;
                case 3:
                    message.id = reader.string();
                    break;
                case 4:
                    message.createdAt = reader.string();
                    break;
                case 5:
                    message.updatedAt = reader.string();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                case 7:
                    message.deletedAt = reader.string();
                    break;
                case 8:
                    message.isVerified = reader.bool();
                    break;
                case 9:
                    message.isMain = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Contact;
    })();

    sso.ContactRel = (function() {

        /**
         * Properties of a ContactRel.
         * @memberof sso
         * @interface IContactRel
         * @property {string|null} [id] ContactRel id
         * @property {string|null} [value] ContactRel value
         * @property {sso.CONTACT_TYPE|null} [type] ContactRel type
         * @property {boolean|null} [isVerified] ContactRel isVerified
         * @property {boolean|null} [isMain] ContactRel isMain
         * @property {boolean|null} [isDeleted] ContactRel isDeleted
         */

        /**
         * Constructs a new ContactRel.
         * @memberof sso
         * @classdesc Represents a ContactRel.
         * @implements IContactRel
         * @constructor
         * @param {sso.IContactRel=} [properties] Properties to set
         */
        function ContactRel(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ContactRel id.
         * @member {string} id
         * @memberof sso.ContactRel
         * @instance
         */
        ContactRel.prototype.id = "";

        /**
         * ContactRel value.
         * @member {string} value
         * @memberof sso.ContactRel
         * @instance
         */
        ContactRel.prototype.value = "";

        /**
         * ContactRel type.
         * @member {sso.CONTACT_TYPE} type
         * @memberof sso.ContactRel
         * @instance
         */
        ContactRel.prototype.type = 0;

        /**
         * ContactRel isVerified.
         * @member {boolean} isVerified
         * @memberof sso.ContactRel
         * @instance
         */
        ContactRel.prototype.isVerified = false;

        /**
         * ContactRel isMain.
         * @member {boolean} isMain
         * @memberof sso.ContactRel
         * @instance
         */
        ContactRel.prototype.isMain = false;

        /**
         * ContactRel isDeleted.
         * @member {boolean} isDeleted
         * @memberof sso.ContactRel
         * @instance
         */
        ContactRel.prototype.isDeleted = false;

        /**
         * Encodes the specified ContactRel message. Does not implicitly {@link sso.ContactRel.verify|verify} messages.
         * @function encode
         * @memberof sso.ContactRel
         * @static
         * @param {sso.IContactRel} message ContactRel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ContactRel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.type);
            if (message.isVerified != null && Object.hasOwnProperty.call(message, "isVerified"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isVerified);
            if (message.isMain != null && Object.hasOwnProperty.call(message, "isMain"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isMain);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes a ContactRel message from the specified reader or buffer.
         * @function decode
         * @memberof sso.ContactRel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.ContactRel} ContactRel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ContactRel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.ContactRel();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    message.type = reader.int32();
                    break;
                case 4:
                    message.isVerified = reader.bool();
                    break;
                case 5:
                    message.isMain = reader.bool();
                    break;
                case 6:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return ContactRel;
    })();

    sso.PassportAttachments = (function() {

        /**
         * Properties of a PassportAttachments.
         * @memberof sso
         * @interface IPassportAttachments
         * @property {string|null} [id] PassportAttachments id
         * @property {string|null} [name] PassportAttachments name
         * @property {string|null} [url] PassportAttachments url
         * @property {boolean|null} [isDeleted] PassportAttachments isDeleted
         * @property {string|null} [deletedAd] PassportAttachments deletedAd
         * @property {string|null} [createdAt] PassportAttachments createdAt
         * @property {string|null} [updatedAt] PassportAttachments updatedAt
         */

        /**
         * Constructs a new PassportAttachments.
         * @memberof sso
         * @classdesc Represents a PassportAttachments.
         * @implements IPassportAttachments
         * @constructor
         * @param {sso.IPassportAttachments=} [properties] Properties to set
         */
        function PassportAttachments(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PassportAttachments id.
         * @member {string} id
         * @memberof sso.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.id = "";

        /**
         * PassportAttachments name.
         * @member {string} name
         * @memberof sso.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.name = "";

        /**
         * PassportAttachments url.
         * @member {string} url
         * @memberof sso.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.url = "";

        /**
         * PassportAttachments isDeleted.
         * @member {boolean} isDeleted
         * @memberof sso.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.isDeleted = false;

        /**
         * PassportAttachments deletedAd.
         * @member {string} deletedAd
         * @memberof sso.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.deletedAd = "";

        /**
         * PassportAttachments createdAt.
         * @member {string} createdAt
         * @memberof sso.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.createdAt = "";

        /**
         * PassportAttachments updatedAt.
         * @member {string} updatedAt
         * @memberof sso.PassportAttachments
         * @instance
         */
        PassportAttachments.prototype.updatedAt = "";

        /**
         * Encodes the specified PassportAttachments message. Does not implicitly {@link sso.PassportAttachments.verify|verify} messages.
         * @function encode
         * @memberof sso.PassportAttachments
         * @static
         * @param {sso.IPassportAttachments} message PassportAttachments message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PassportAttachments.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.url);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            if (message.deletedAd != null && Object.hasOwnProperty.call(message, "deletedAd"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.deletedAd);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.updatedAt);
            return writer;
        };

        /**
         * Decodes a PassportAttachments message from the specified reader or buffer.
         * @function decode
         * @memberof sso.PassportAttachments
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.PassportAttachments} PassportAttachments
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PassportAttachments.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.PassportAttachments();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.url = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                case 5:
                    message.deletedAd = reader.string();
                    break;
                case 6:
                    message.createdAt = reader.string();
                    break;
                case 7:
                    message.updatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PassportAttachments;
    })();

    sso.PassportAttachmentsRel = (function() {

        /**
         * Properties of a PassportAttachmentsRel.
         * @memberof sso
         * @interface IPassportAttachmentsRel
         * @property {string|null} [id] PassportAttachmentsRel id
         * @property {string|null} [name] PassportAttachmentsRel name
         * @property {string|null} [url] PassportAttachmentsRel url
         */

        /**
         * Constructs a new PassportAttachmentsRel.
         * @memberof sso
         * @classdesc Represents a PassportAttachmentsRel.
         * @implements IPassportAttachmentsRel
         * @constructor
         * @param {sso.IPassportAttachmentsRel=} [properties] Properties to set
         */
        function PassportAttachmentsRel(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PassportAttachmentsRel id.
         * @member {string} id
         * @memberof sso.PassportAttachmentsRel
         * @instance
         */
        PassportAttachmentsRel.prototype.id = "";

        /**
         * PassportAttachmentsRel name.
         * @member {string} name
         * @memberof sso.PassportAttachmentsRel
         * @instance
         */
        PassportAttachmentsRel.prototype.name = "";

        /**
         * PassportAttachmentsRel url.
         * @member {string} url
         * @memberof sso.PassportAttachmentsRel
         * @instance
         */
        PassportAttachmentsRel.prototype.url = "";

        /**
         * Encodes the specified PassportAttachmentsRel message. Does not implicitly {@link sso.PassportAttachmentsRel.verify|verify} messages.
         * @function encode
         * @memberof sso.PassportAttachmentsRel
         * @static
         * @param {sso.IPassportAttachmentsRel} message PassportAttachmentsRel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PassportAttachmentsRel.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.url);
            return writer;
        };

        /**
         * Decodes a PassportAttachmentsRel message from the specified reader or buffer.
         * @function decode
         * @memberof sso.PassportAttachmentsRel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.PassportAttachmentsRel} PassportAttachmentsRel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PassportAttachmentsRel.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.PassportAttachmentsRel();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.url = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PassportAttachmentsRel;
    })();

    sso.AddPhotoManagementRequest = (function() {

        /**
         * Properties of an AddPhotoManagementRequest.
         * @memberof sso
         * @interface IAddPhotoManagementRequest
         * @property {string|null} [name] AddPhotoManagementRequest name
         * @property {string|null} [original] AddPhotoManagementRequest original
         * @property {string|null} [id] AddPhotoManagementRequest id
         * @property {boolean|null} [isDeleted] AddPhotoManagementRequest isDeleted
         */

        /**
         * Constructs a new AddPhotoManagementRequest.
         * @memberof sso
         * @classdesc Represents an AddPhotoManagementRequest.
         * @implements IAddPhotoManagementRequest
         * @constructor
         * @param {sso.IAddPhotoManagementRequest=} [properties] Properties to set
         */
        function AddPhotoManagementRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddPhotoManagementRequest name.
         * @member {string} name
         * @memberof sso.AddPhotoManagementRequest
         * @instance
         */
        AddPhotoManagementRequest.prototype.name = "";

        /**
         * AddPhotoManagementRequest original.
         * @member {string} original
         * @memberof sso.AddPhotoManagementRequest
         * @instance
         */
        AddPhotoManagementRequest.prototype.original = "";

        /**
         * AddPhotoManagementRequest id.
         * @member {string} id
         * @memberof sso.AddPhotoManagementRequest
         * @instance
         */
        AddPhotoManagementRequest.prototype.id = "";

        /**
         * AddPhotoManagementRequest isDeleted.
         * @member {boolean} isDeleted
         * @memberof sso.AddPhotoManagementRequest
         * @instance
         */
        AddPhotoManagementRequest.prototype.isDeleted = false;

        /**
         * Encodes the specified AddPhotoManagementRequest message. Does not implicitly {@link sso.AddPhotoManagementRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.AddPhotoManagementRequest
         * @static
         * @param {sso.IAddPhotoManagementRequest} message AddPhotoManagementRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddPhotoManagementRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.original != null && Object.hasOwnProperty.call(message, "original"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.original);
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
            if (message.isDeleted != null && Object.hasOwnProperty.call(message, "isDeleted"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isDeleted);
            return writer;
        };

        /**
         * Decodes an AddPhotoManagementRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.AddPhotoManagementRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.AddPhotoManagementRequest} AddPhotoManagementRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddPhotoManagementRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.AddPhotoManagementRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.original = reader.string();
                    break;
                case 3:
                    message.id = reader.string();
                    break;
                case 4:
                    message.isDeleted = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return AddPhotoManagementRequest;
    })();

    sso.PhotoManagement = (function() {

        /**
         * Properties of a PhotoManagement.
         * @memberof sso
         * @interface IPhotoManagement
         * @property {string|null} [id] PhotoManagement id
         * @property {string|null} [tag] PhotoManagement tag
         * @property {string|null} [userId] PhotoManagement userId
         * @property {string|null} [original] PhotoManagement original
         * @property {string|null} [name] PhotoManagement name
         * @property {string|null} [url] PhotoManagement url
         * @property {string|null} [thumb] PhotoManagement thumb
         * @property {string|null} [createdAt] PhotoManagement createdAt
         * @property {string|null} [updatedAt] PhotoManagement updatedAt
         */

        /**
         * Constructs a new PhotoManagement.
         * @memberof sso
         * @classdesc Represents a PhotoManagement.
         * @implements IPhotoManagement
         * @constructor
         * @param {sso.IPhotoManagement=} [properties] Properties to set
         */
        function PhotoManagement(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PhotoManagement id.
         * @member {string} id
         * @memberof sso.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.id = "";

        /**
         * PhotoManagement tag.
         * @member {string} tag
         * @memberof sso.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.tag = "";

        /**
         * PhotoManagement userId.
         * @member {string} userId
         * @memberof sso.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.userId = "";

        /**
         * PhotoManagement original.
         * @member {string} original
         * @memberof sso.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.original = "";

        /**
         * PhotoManagement name.
         * @member {string} name
         * @memberof sso.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.name = "";

        /**
         * PhotoManagement url.
         * @member {string} url
         * @memberof sso.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.url = "";

        /**
         * PhotoManagement thumb.
         * @member {string} thumb
         * @memberof sso.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.thumb = "";

        /**
         * PhotoManagement createdAt.
         * @member {string} createdAt
         * @memberof sso.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.createdAt = "";

        /**
         * PhotoManagement updatedAt.
         * @member {string} updatedAt
         * @memberof sso.PhotoManagement
         * @instance
         */
        PhotoManagement.prototype.updatedAt = "";

        /**
         * Encodes the specified PhotoManagement message. Does not implicitly {@link sso.PhotoManagement.verify|verify} messages.
         * @function encode
         * @memberof sso.PhotoManagement
         * @static
         * @param {sso.IPhotoManagement} message PhotoManagement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PhotoManagement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.tag);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.userId);
            if (message.original != null && Object.hasOwnProperty.call(message, "original"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.original);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.url);
            if (message.thumb != null && Object.hasOwnProperty.call(message, "thumb"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.thumb);
            if (message.createdAt != null && Object.hasOwnProperty.call(message, "createdAt"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.createdAt);
            if (message.updatedAt != null && Object.hasOwnProperty.call(message, "updatedAt"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.updatedAt);
            return writer;
        };

        /**
         * Decodes a PhotoManagement message from the specified reader or buffer.
         * @function decode
         * @memberof sso.PhotoManagement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.PhotoManagement} PhotoManagement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PhotoManagement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.PhotoManagement();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.tag = reader.string();
                    break;
                case 3:
                    message.userId = reader.string();
                    break;
                case 4:
                    message.original = reader.string();
                    break;
                case 5:
                    message.name = reader.string();
                    break;
                case 6:
                    message.url = reader.string();
                    break;
                case 7:
                    message.thumb = reader.string();
                    break;
                case 8:
                    message.createdAt = reader.string();
                    break;
                case 9:
                    message.updatedAt = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return PhotoManagement;
    })();

    sso.BlockingReasonRelation = (function() {

        /**
         * Properties of a BlockingReasonRelation.
         * @memberof sso
         * @interface IBlockingReasonRelation
         * @property {string|null} [id] BlockingReasonRelation id
         * @property {string|null} [name] BlockingReasonRelation name
         * @property {string|null} [type] BlockingReasonRelation type
         */

        /**
         * Constructs a new BlockingReasonRelation.
         * @memberof sso
         * @classdesc Represents a BlockingReasonRelation.
         * @implements IBlockingReasonRelation
         * @constructor
         * @param {sso.IBlockingReasonRelation=} [properties] Properties to set
         */
        function BlockingReasonRelation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BlockingReasonRelation id.
         * @member {string} id
         * @memberof sso.BlockingReasonRelation
         * @instance
         */
        BlockingReasonRelation.prototype.id = "";

        /**
         * BlockingReasonRelation name.
         * @member {string} name
         * @memberof sso.BlockingReasonRelation
         * @instance
         */
        BlockingReasonRelation.prototype.name = "";

        /**
         * BlockingReasonRelation type.
         * @member {string} type
         * @memberof sso.BlockingReasonRelation
         * @instance
         */
        BlockingReasonRelation.prototype.type = "";

        /**
         * Encodes the specified BlockingReasonRelation message. Does not implicitly {@link sso.BlockingReasonRelation.verify|verify} messages.
         * @function encode
         * @memberof sso.BlockingReasonRelation
         * @static
         * @param {sso.IBlockingReasonRelation} message BlockingReasonRelation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BlockingReasonRelation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.type);
            return writer;
        };

        /**
         * Decodes a BlockingReasonRelation message from the specified reader or buffer.
         * @function decode
         * @memberof sso.BlockingReasonRelation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.BlockingReasonRelation} BlockingReasonRelation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BlockingReasonRelation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.BlockingReasonRelation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.type = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return BlockingReasonRelation;
    })();

    sso.IdRequest = (function() {

        /**
         * Properties of an IdRequest.
         * @memberof sso
         * @interface IIdRequest
         * @property {number|null} [id] IdRequest id
         */

        /**
         * Constructs a new IdRequest.
         * @memberof sso
         * @classdesc Represents an IdRequest.
         * @implements IIdRequest
         * @constructor
         * @param {sso.IIdRequest=} [properties] Properties to set
         */
        function IdRequest(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IdRequest id.
         * @member {number} id
         * @memberof sso.IdRequest
         * @instance
         */
        IdRequest.prototype.id = 0;

        /**
         * Encodes the specified IdRequest message. Does not implicitly {@link sso.IdRequest.verify|verify} messages.
         * @function encode
         * @memberof sso.IdRequest
         * @static
         * @param {sso.IIdRequest} message IdRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IdRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
            return writer;
        };

        /**
         * Decodes an IdRequest message from the specified reader or buffer.
         * @function decode
         * @memberof sso.IdRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.IdRequest} IdRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IdRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.IdRequest();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return IdRequest;
    })();

    sso.SortField = (function() {

        /**
         * Properties of a SortField.
         * @memberof sso
         * @interface ISortField
         * @property {string|null} [field] SortField field
         * @property {sso.SortOrder|null} [order] SortField order
         */

        /**
         * Constructs a new SortField.
         * @memberof sso
         * @classdesc Represents a SortField.
         * @implements ISortField
         * @constructor
         * @param {sso.ISortField=} [properties] Properties to set
         */
        function SortField(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SortField field.
         * @member {string} field
         * @memberof sso.SortField
         * @instance
         */
        SortField.prototype.field = "";

        /**
         * SortField order.
         * @member {sso.SortOrder} order
         * @memberof sso.SortField
         * @instance
         */
        SortField.prototype.order = 0;

        /**
         * Encodes the specified SortField message. Does not implicitly {@link sso.SortField.verify|verify} messages.
         * @function encode
         * @memberof sso.SortField
         * @static
         * @param {sso.ISortField} message SortField message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SortField.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.field != null && Object.hasOwnProperty.call(message, "field"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.field);
            if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.order);
            return writer;
        };

        /**
         * Decodes a SortField message from the specified reader or buffer.
         * @function decode
         * @memberof sso.SortField
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.SortField} SortField
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SortField.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.SortField();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.field = reader.string();
                    break;
                case 2:
                    message.order = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return SortField;
    })();

    /**
     * SortOrder enum.
     * @name sso.SortOrder
     * @enum {number}
     * @property {number} NONE=0 NONE value
     * @property {number} ASC=1 ASC value
     * @property {number} DESC=2 DESC value
     */
    sso.SortOrder = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NONE"] = 0;
        values[valuesById[1] = "ASC"] = 1;
        values[valuesById[2] = "DESC"] = 2;
        return values;
    })();

    sso.UuidCondition = (function() {

        /**
         * Properties of an UuidCondition.
         * @memberof sso
         * @interface IUuidCondition
         * @property {string|null} [eq] UuidCondition eq
         * @property {string|null} [not] UuidCondition not
         * @property {Array.<string>|null} ["in"] UuidCondition in
         * @property {Array.<string>|null} [notIn] UuidCondition notIn
         * @property {boolean|null} [isNull] UuidCondition isNull
         * @property {boolean|null} [isNotNull] UuidCondition isNotNull
         */

        /**
         * Constructs a new UuidCondition.
         * @memberof sso
         * @classdesc Represents an UuidCondition.
         * @implements IUuidCondition
         * @constructor
         * @param {sso.IUuidCondition=} [properties] Properties to set
         */
        function UuidCondition(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UuidCondition eq.
         * @member {string} eq
         * @memberof sso.UuidCondition
         * @instance
         */
        UuidCondition.prototype.eq = "";

        /**
         * UuidCondition not.
         * @member {string} not
         * @memberof sso.UuidCondition
         * @instance
         */
        UuidCondition.prototype.not = "";

        /**
         * UuidCondition in.
         * @member {Array.<string>} in
         * @memberof sso.UuidCondition
         * @instance
         */
        UuidCondition.prototype["in"] = $util.emptyArray;

        /**
         * UuidCondition notIn.
         * @member {Array.<string>} notIn
         * @memberof sso.UuidCondition
         * @instance
         */
        UuidCondition.prototype.notIn = $util.emptyArray;

        /**
         * UuidCondition isNull.
         * @member {boolean} isNull
         * @memberof sso.UuidCondition
         * @instance
         */
        UuidCondition.prototype.isNull = false;

        /**
         * UuidCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof sso.UuidCondition
         * @instance
         */
        UuidCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified UuidCondition message. Does not implicitly {@link sso.UuidCondition.verify|verify} messages.
         * @function encode
         * @memberof sso.UuidCondition
         * @static
         * @param {sso.IUuidCondition} message UuidCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UuidCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.not);
            if (message["in"] != null && message["in"].length)
                for (var i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (var i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.notIn[i]);
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes an UuidCondition message from the specified reader or buffer.
         * @function decode
         * @memberof sso.UuidCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.UuidCondition} UuidCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UuidCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.UuidCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.string();
                    break;
                case 2:
                    message.not = reader.string();
                    break;
                case 3:
                    if (!(message["in"] && message["in"].length))
                        message["in"] = [];
                    message["in"].push(reader.string());
                    break;
                case 4:
                    if (!(message.notIn && message.notIn.length))
                        message.notIn = [];
                    message.notIn.push(reader.string());
                    break;
                case 6:
                    message.isNull = reader.bool();
                    break;
                case 7:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return UuidCondition;
    })();

    sso.DateCondition = (function() {

        /**
         * Properties of a DateCondition.
         * @memberof sso
         * @interface IDateCondition
         * @property {string|null} [eq] DateCondition eq
         * @property {string|null} [not] DateCondition not
         * @property {string|null} [less] DateCondition less
         * @property {string|null} [lessEq] DateCondition lessEq
         * @property {string|null} [more] DateCondition more
         * @property {string|null} [moreEq] DateCondition moreEq
         * @property {sso.IStringBetweenOperation|null} [between] DateCondition between
         * @property {sso.IStringBetweenOperation|null} [notBetween] DateCondition notBetween
         * @property {boolean|null} [isNull] DateCondition isNull
         * @property {boolean|null} [isNotNull] DateCondition isNotNull
         */

        /**
         * Constructs a new DateCondition.
         * @memberof sso
         * @classdesc Represents a DateCondition.
         * @implements IDateCondition
         * @constructor
         * @param {sso.IDateCondition=} [properties] Properties to set
         */
        function DateCondition(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DateCondition eq.
         * @member {string} eq
         * @memberof sso.DateCondition
         * @instance
         */
        DateCondition.prototype.eq = "";

        /**
         * DateCondition not.
         * @member {string} not
         * @memberof sso.DateCondition
         * @instance
         */
        DateCondition.prototype.not = "";

        /**
         * DateCondition less.
         * @member {string} less
         * @memberof sso.DateCondition
         * @instance
         */
        DateCondition.prototype.less = "";

        /**
         * DateCondition lessEq.
         * @member {string} lessEq
         * @memberof sso.DateCondition
         * @instance
         */
        DateCondition.prototype.lessEq = "";

        /**
         * DateCondition more.
         * @member {string} more
         * @memberof sso.DateCondition
         * @instance
         */
        DateCondition.prototype.more = "";

        /**
         * DateCondition moreEq.
         * @member {string} moreEq
         * @memberof sso.DateCondition
         * @instance
         */
        DateCondition.prototype.moreEq = "";

        /**
         * DateCondition between.
         * @member {sso.IStringBetweenOperation|null|undefined} between
         * @memberof sso.DateCondition
         * @instance
         */
        DateCondition.prototype.between = null;

        /**
         * DateCondition notBetween.
         * @member {sso.IStringBetweenOperation|null|undefined} notBetween
         * @memberof sso.DateCondition
         * @instance
         */
        DateCondition.prototype.notBetween = null;

        /**
         * DateCondition isNull.
         * @member {boolean} isNull
         * @memberof sso.DateCondition
         * @instance
         */
        DateCondition.prototype.isNull = false;

        /**
         * DateCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof sso.DateCondition
         * @instance
         */
        DateCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified DateCondition message. Does not implicitly {@link sso.DateCondition.verify|verify} messages.
         * @function encode
         * @memberof sso.DateCondition
         * @static
         * @param {sso.IDateCondition} message DateCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DateCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.not);
            if (message.less != null && Object.hasOwnProperty.call(message, "less"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.less);
            if (message.lessEq != null && Object.hasOwnProperty.call(message, "lessEq"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lessEq);
            if (message.more != null && Object.hasOwnProperty.call(message, "more"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.more);
            if (message.moreEq != null && Object.hasOwnProperty.call(message, "moreEq"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.moreEq);
            if (message.between != null && Object.hasOwnProperty.call(message, "between"))
                $root.sso.StringBetweenOperation.encode(message.between, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.notBetween != null && Object.hasOwnProperty.call(message, "notBetween"))
                $root.sso.StringBetweenOperation.encode(message.notBetween, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes a DateCondition message from the specified reader or buffer.
         * @function decode
         * @memberof sso.DateCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.DateCondition} DateCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DateCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.DateCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.string();
                    break;
                case 2:
                    message.not = reader.string();
                    break;
                case 3:
                    message.less = reader.string();
                    break;
                case 4:
                    message.lessEq = reader.string();
                    break;
                case 5:
                    message.more = reader.string();
                    break;
                case 6:
                    message.moreEq = reader.string();
                    break;
                case 7:
                    message.between = $root.sso.StringBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.notBetween = $root.sso.StringBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.isNull = reader.bool();
                    break;
                case 10:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return DateCondition;
    })();

    sso.StringCondition = (function() {

        /**
         * Properties of a StringCondition.
         * @memberof sso
         * @interface IStringCondition
         * @property {string|null} [eq] StringCondition eq
         * @property {string|null} [not] StringCondition not
         * @property {Array.<string>|null} ["in"] StringCondition in
         * @property {Array.<string>|null} [notIn] StringCondition notIn
         * @property {string|null} [like] StringCondition like
         * @property {boolean|null} [isNull] StringCondition isNull
         * @property {boolean|null} [isNotNull] StringCondition isNotNull
         */

        /**
         * Constructs a new StringCondition.
         * @memberof sso
         * @classdesc Represents a StringCondition.
         * @implements IStringCondition
         * @constructor
         * @param {sso.IStringCondition=} [properties] Properties to set
         */
        function StringCondition(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StringCondition eq.
         * @member {string} eq
         * @memberof sso.StringCondition
         * @instance
         */
        StringCondition.prototype.eq = "";

        /**
         * StringCondition not.
         * @member {string} not
         * @memberof sso.StringCondition
         * @instance
         */
        StringCondition.prototype.not = "";

        /**
         * StringCondition in.
         * @member {Array.<string>} in
         * @memberof sso.StringCondition
         * @instance
         */
        StringCondition.prototype["in"] = $util.emptyArray;

        /**
         * StringCondition notIn.
         * @member {Array.<string>} notIn
         * @memberof sso.StringCondition
         * @instance
         */
        StringCondition.prototype.notIn = $util.emptyArray;

        /**
         * StringCondition like.
         * @member {string} like
         * @memberof sso.StringCondition
         * @instance
         */
        StringCondition.prototype.like = "";

        /**
         * StringCondition isNull.
         * @member {boolean} isNull
         * @memberof sso.StringCondition
         * @instance
         */
        StringCondition.prototype.isNull = false;

        /**
         * StringCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof sso.StringCondition
         * @instance
         */
        StringCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified StringCondition message. Does not implicitly {@link sso.StringCondition.verify|verify} messages.
         * @function encode
         * @memberof sso.StringCondition
         * @static
         * @param {sso.IStringCondition} message StringCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.not);
            if (message["in"] != null && message["in"].length)
                for (var i = 0; i < message["in"].length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message["in"][i]);
            if (message.notIn != null && message.notIn.length)
                for (var i = 0; i < message.notIn.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.notIn[i]);
            if (message.like != null && Object.hasOwnProperty.call(message, "like"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.like);
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes a StringCondition message from the specified reader or buffer.
         * @function decode
         * @memberof sso.StringCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.StringCondition} StringCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.StringCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.string();
                    break;
                case 2:
                    message.not = reader.string();
                    break;
                case 3:
                    if (!(message["in"] && message["in"].length))
                        message["in"] = [];
                    message["in"].push(reader.string());
                    break;
                case 4:
                    if (!(message.notIn && message.notIn.length))
                        message.notIn = [];
                    message.notIn.push(reader.string());
                    break;
                case 5:
                    message.like = reader.string();
                    break;
                case 6:
                    message.isNull = reader.bool();
                    break;
                case 7:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return StringCondition;
    })();

    sso.NumberCondition = (function() {

        /**
         * Properties of a NumberCondition.
         * @memberof sso
         * @interface INumberCondition
         * @property {number|null} [eq] NumberCondition eq
         * @property {number|null} [not] NumberCondition not
         * @property {number|null} [less] NumberCondition less
         * @property {number|null} [lessEq] NumberCondition lessEq
         * @property {number|null} [more] NumberCondition more
         * @property {number|null} [moreEq] NumberCondition moreEq
         * @property {sso.INumberBetweenOperation|null} [between] NumberCondition between
         * @property {sso.INumberBetweenOperation|null} [notBetween] NumberCondition notBetween
         * @property {Array.<number>|null} ["in"] NumberCondition in
         * @property {Array.<number>|null} [notIn] NumberCondition notIn
         * @property {boolean|null} [isNull] NumberCondition isNull
         * @property {boolean|null} [isNotNull] NumberCondition isNotNull
         */

        /**
         * Constructs a new NumberCondition.
         * @memberof sso
         * @classdesc Represents a NumberCondition.
         * @implements INumberCondition
         * @constructor
         * @param {sso.INumberCondition=} [properties] Properties to set
         */
        function NumberCondition(properties) {
            this["in"] = [];
            this.notIn = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NumberCondition eq.
         * @member {number} eq
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype.eq = 0;

        /**
         * NumberCondition not.
         * @member {number} not
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype.not = 0;

        /**
         * NumberCondition less.
         * @member {number} less
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype.less = 0;

        /**
         * NumberCondition lessEq.
         * @member {number} lessEq
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype.lessEq = 0;

        /**
         * NumberCondition more.
         * @member {number} more
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype.more = 0;

        /**
         * NumberCondition moreEq.
         * @member {number} moreEq
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype.moreEq = 0;

        /**
         * NumberCondition between.
         * @member {sso.INumberBetweenOperation|null|undefined} between
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype.between = null;

        /**
         * NumberCondition notBetween.
         * @member {sso.INumberBetweenOperation|null|undefined} notBetween
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype.notBetween = null;

        /**
         * NumberCondition in.
         * @member {Array.<number>} in
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype["in"] = $util.emptyArray;

        /**
         * NumberCondition notIn.
         * @member {Array.<number>} notIn
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype.notIn = $util.emptyArray;

        /**
         * NumberCondition isNull.
         * @member {boolean} isNull
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype.isNull = false;

        /**
         * NumberCondition isNotNull.
         * @member {boolean} isNotNull
         * @memberof sso.NumberCondition
         * @instance
         */
        NumberCondition.prototype.isNotNull = false;

        /**
         * Encodes the specified NumberCondition message. Does not implicitly {@link sso.NumberCondition.verify|verify} messages.
         * @function encode
         * @memberof sso.NumberCondition
         * @static
         * @param {sso.INumberCondition} message NumberCondition message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NumberCondition.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eq != null && Object.hasOwnProperty.call(message, "eq"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.eq);
            if (message.not != null && Object.hasOwnProperty.call(message, "not"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.not);
            if (message.less != null && Object.hasOwnProperty.call(message, "less"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.less);
            if (message.lessEq != null && Object.hasOwnProperty.call(message, "lessEq"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lessEq);
            if (message.more != null && Object.hasOwnProperty.call(message, "more"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.more);
            if (message.moreEq != null && Object.hasOwnProperty.call(message, "moreEq"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.moreEq);
            if (message.between != null && Object.hasOwnProperty.call(message, "between"))
                $root.sso.NumberBetweenOperation.encode(message.between, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.notBetween != null && Object.hasOwnProperty.call(message, "notBetween"))
                $root.sso.NumberBetweenOperation.encode(message.notBetween, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message["in"] != null && message["in"].length) {
                writer.uint32(/* id 9, wireType 2 =*/74).fork();
                for (var i = 0; i < message["in"].length; ++i)
                    writer.int32(message["in"][i]);
                writer.ldelim();
            }
            if (message.notIn != null && message.notIn.length) {
                writer.uint32(/* id 10, wireType 2 =*/82).fork();
                for (var i = 0; i < message.notIn.length; ++i)
                    writer.int32(message.notIn[i]);
                writer.ldelim();
            }
            if (message.isNull != null && Object.hasOwnProperty.call(message, "isNull"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isNull);
            if (message.isNotNull != null && Object.hasOwnProperty.call(message, "isNotNull"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isNotNull);
            return writer;
        };

        /**
         * Decodes a NumberCondition message from the specified reader or buffer.
         * @function decode
         * @memberof sso.NumberCondition
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.NumberCondition} NumberCondition
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NumberCondition.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.NumberCondition();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eq = reader.int32();
                    break;
                case 2:
                    message.not = reader.int32();
                    break;
                case 3:
                    message.less = reader.int32();
                    break;
                case 4:
                    message.lessEq = reader.int32();
                    break;
                case 5:
                    message.more = reader.int32();
                    break;
                case 6:
                    message.moreEq = reader.int32();
                    break;
                case 7:
                    message.between = $root.sso.NumberBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.notBetween = $root.sso.NumberBetweenOperation.decode(reader, reader.uint32());
                    break;
                case 9:
                    if (!(message["in"] && message["in"].length))
                        message["in"] = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message["in"].push(reader.int32());
                    } else
                        message["in"].push(reader.int32());
                    break;
                case 10:
                    if (!(message.notIn && message.notIn.length))
                        message.notIn = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.notIn.push(reader.int32());
                    } else
                        message.notIn.push(reader.int32());
                    break;
                case 11:
                    message.isNull = reader.bool();
                    break;
                case 12:
                    message.isNotNull = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return NumberCondition;
    })();

    sso.StringBetweenOperation = (function() {

        /**
         * Properties of a StringBetweenOperation.
         * @memberof sso
         * @interface IStringBetweenOperation
         * @property {string|null} [low] StringBetweenOperation low
         * @property {string|null} [high] StringBetweenOperation high
         */

        /**
         * Constructs a new StringBetweenOperation.
         * @memberof sso
         * @classdesc Represents a StringBetweenOperation.
         * @implements IStringBetweenOperation
         * @constructor
         * @param {sso.IStringBetweenOperation=} [properties] Properties to set
         */
        function StringBetweenOperation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StringBetweenOperation low.
         * @member {string} low
         * @memberof sso.StringBetweenOperation
         * @instance
         */
        StringBetweenOperation.prototype.low = "";

        /**
         * StringBetweenOperation high.
         * @member {string} high
         * @memberof sso.StringBetweenOperation
         * @instance
         */
        StringBetweenOperation.prototype.high = "";

        /**
         * Encodes the specified StringBetweenOperation message. Does not implicitly {@link sso.StringBetweenOperation.verify|verify} messages.
         * @function encode
         * @memberof sso.StringBetweenOperation
         * @static
         * @param {sso.IStringBetweenOperation} message StringBetweenOperation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringBetweenOperation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.low != null && Object.hasOwnProperty.call(message, "low"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.low);
            if (message.high != null && Object.hasOwnProperty.call(message, "high"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.high);
            return writer;
        };

        /**
         * Decodes a StringBetweenOperation message from the specified reader or buffer.
         * @function decode
         * @memberof sso.StringBetweenOperation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.StringBetweenOperation} StringBetweenOperation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringBetweenOperation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.StringBetweenOperation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.low = reader.string();
                    break;
                case 2:
                    message.high = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return StringBetweenOperation;
    })();

    sso.NumberBetweenOperation = (function() {

        /**
         * Properties of a NumberBetweenOperation.
         * @memberof sso
         * @interface INumberBetweenOperation
         * @property {number|null} [low] NumberBetweenOperation low
         * @property {number|null} [high] NumberBetweenOperation high
         */

        /**
         * Constructs a new NumberBetweenOperation.
         * @memberof sso
         * @classdesc Represents a NumberBetweenOperation.
         * @implements INumberBetweenOperation
         * @constructor
         * @param {sso.INumberBetweenOperation=} [properties] Properties to set
         */
        function NumberBetweenOperation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NumberBetweenOperation low.
         * @member {number} low
         * @memberof sso.NumberBetweenOperation
         * @instance
         */
        NumberBetweenOperation.prototype.low = 0;

        /**
         * NumberBetweenOperation high.
         * @member {number} high
         * @memberof sso.NumberBetweenOperation
         * @instance
         */
        NumberBetweenOperation.prototype.high = 0;

        /**
         * Encodes the specified NumberBetweenOperation message. Does not implicitly {@link sso.NumberBetweenOperation.verify|verify} messages.
         * @function encode
         * @memberof sso.NumberBetweenOperation
         * @static
         * @param {sso.INumberBetweenOperation} message NumberBetweenOperation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NumberBetweenOperation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.low != null && Object.hasOwnProperty.call(message, "low"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.low);
            if (message.high != null && Object.hasOwnProperty.call(message, "high"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.high);
            return writer;
        };

        /**
         * Decodes a NumberBetweenOperation message from the specified reader or buffer.
         * @function decode
         * @memberof sso.NumberBetweenOperation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.NumberBetweenOperation} NumberBetweenOperation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NumberBetweenOperation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.NumberBetweenOperation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.low = reader.int32();
                    break;
                case 2:
                    message.high = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return NumberBetweenOperation;
    })();

    /**
     * APP_TYPE enum.
     * @name sso.APP_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_APP_TYPE=0 UNDEFINED_APP_TYPE value
     * @property {number} NATIVE=1 NATIVE value
     * @property {number} SPA=2 SPA value
     * @property {number} REGULAR=3 REGULAR value
     * @property {number} MACHINE=4 MACHINE value
     */
    sso.APP_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_APP_TYPE"] = 0;
        values[valuesById[1] = "NATIVE"] = 1;
        values[valuesById[2] = "SPA"] = 2;
        values[valuesById[3] = "REGULAR"] = 3;
        values[valuesById[4] = "MACHINE"] = 4;
        return values;
    })();

    /**
     * SEND enum.
     * @name sso.SEND
     * @enum {number}
     * @property {number} UNDEFINED_SEND=0 UNDEFINED_SEND value
     * @property {number} CODE=1 CODE value
     * @property {number} VERIFICATION_LINK=2 VERIFICATION_LINK value
     */
    sso.SEND = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_SEND"] = 0;
        values[valuesById[1] = "CODE"] = 1;
        values[valuesById[2] = "VERIFICATION_LINK"] = 2;
        return values;
    })();

    /**
     * USER_TYPE enum.
     * @name sso.USER_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_USER_TYPE=0 UNDEFINED_USER_TYPE value
     * @property {number} CUSTOMER=1 CUSTOMER value
     * @property {number} CONTRACTOR=2 CONTRACTOR value
     * @property {number} EMPLOYEE=3 EMPLOYEE value
     * @property {number} PARTNER=4 PARTNER value
     */
    sso.USER_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_USER_TYPE"] = 0;
        values[valuesById[1] = "CUSTOMER"] = 1;
        values[valuesById[2] = "CONTRACTOR"] = 2;
        values[valuesById[3] = "EMPLOYEE"] = 3;
        values[valuesById[4] = "PARTNER"] = 4;
        return values;
    })();

    /**
     * CONNECTION enum.
     * @name sso.CONNECTION
     * @enum {number}
     * @property {number} UNDEFINED_CONNECTION=0 UNDEFINED_CONNECTION value
     * @property {number} CREDENTIAL=1 CREDENTIAL value
     * @property {number} M2M=2 M2M value
     * @property {number} PASSWORDLESS=3 PASSWORDLESS value
     * @property {number} YCLIENTS=4 YCLIENTS value
     */
    sso.CONNECTION = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_CONNECTION"] = 0;
        values[valuesById[1] = "CREDENTIAL"] = 1;
        values[valuesById[2] = "M2M"] = 2;
        values[valuesById[3] = "PASSWORDLESS"] = 3;
        values[valuesById[4] = "YCLIENTS"] = 4;
        return values;
    })();

    /**
     * CONTACT_TYPE enum.
     * @name sso.CONTACT_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_CONTACT_TYPE=0 UNDEFINED_CONTACT_TYPE value
     * @property {number} MOBILE=1 MOBILE value
     * @property {number} EMAIL=2 EMAIL value
     * @property {number} TELEGRAM=3 TELEGRAM value
     * @property {number} WHATSAPP=4 WHATSAPP value
     * @property {number} FACEBOOK=5 FACEBOOK value
     * @property {number} VK=6 VK value
     * @property {number} OK=7 OK value
     * @property {number} INSTAGRAM=8 INSTAGRAM value
     * @property {number} YOUTUBE=9 YOUTUBE value
     * @property {number} TWITTER=10 TWITTER value
     * @property {number} MY_MAIL=11 MY_MAIL value
     * @property {number} LINKED_IN=12 LINKED_IN value
     */
    sso.CONTACT_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_CONTACT_TYPE"] = 0;
        values[valuesById[1] = "MOBILE"] = 1;
        values[valuesById[2] = "EMAIL"] = 2;
        values[valuesById[3] = "TELEGRAM"] = 3;
        values[valuesById[4] = "WHATSAPP"] = 4;
        values[valuesById[5] = "FACEBOOK"] = 5;
        values[valuesById[6] = "VK"] = 6;
        values[valuesById[7] = "OK"] = 7;
        values[valuesById[8] = "INSTAGRAM"] = 8;
        values[valuesById[9] = "YOUTUBE"] = 9;
        values[valuesById[10] = "TWITTER"] = 10;
        values[valuesById[11] = "MY_MAIL"] = 11;
        values[valuesById[12] = "LINKED_IN"] = 12;
        return values;
    })();

    /**
     * ADDRESS_TYPE enum.
     * @name sso.ADDRESS_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_ADDRESS_TYPE=0 UNDEFINED_ADDRESS_TYPE value
     * @property {number} DEFAULT=1 DEFAULT value
     * @property {number} BIRTH_ADDRESS=2 BIRTH_ADDRESS value
     * @property {number} REG_ADDRESS=3 REG_ADDRESS value
     * @property {number} RESIDENCE_ADDRESS=4 RESIDENCE_ADDRESS value
     * @property {number} PICKUP_POINT=5 PICKUP_POINT value
     */
    sso.ADDRESS_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_ADDRESS_TYPE"] = 0;
        values[valuesById[1] = "DEFAULT"] = 1;
        values[valuesById[2] = "BIRTH_ADDRESS"] = 2;
        values[valuesById[3] = "REG_ADDRESS"] = 3;
        values[valuesById[4] = "RESIDENCE_ADDRESS"] = 4;
        values[valuesById[5] = "PICKUP_POINT"] = 5;
        return values;
    })();

    /**
     * GENDER_TYPE enum.
     * @name sso.GENDER_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_GENDER_TYPE=0 UNDEFINED_GENDER_TYPE value
     * @property {number} MALE=1 MALE value
     * @property {number} FEMALE=2 FEMALE value
     */
    sso.GENDER_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_GENDER_TYPE"] = 0;
        values[valuesById[1] = "MALE"] = 1;
        values[valuesById[2] = "FEMALE"] = 2;
        return values;
    })();

    /**
     * API_TYPE enum.
     * @name sso.API_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_API_TYPE=0 UNDEFINED_API_TYPE value
     * @property {number} PLATFORM=1 PLATFORM value
     * @property {number} CUSTOM=2 CUSTOM value
     * @property {number} MANAGEMENT=3 MANAGEMENT value
     */
    sso.API_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_API_TYPE"] = 0;
        values[valuesById[1] = "PLATFORM"] = 1;
        values[valuesById[2] = "CUSTOM"] = 2;
        values[valuesById[3] = "MANAGEMENT"] = 3;
        return values;
    })();

    /**
     * LOGIN_ENTRY_STATUS enum.
     * @name sso.LOGIN_ENTRY_STATUS
     * @enum {number}
     * @property {number} UNDEFINED_LOGIN_ENTRY_STATUS=0 UNDEFINED_LOGIN_ENTRY_STATUS value
     * @property {number} PENDING=1 PENDING value
     * @property {number} SUCCESS=2 SUCCESS value
     * @property {number} ERROR=3 ERROR value
     */
    sso.LOGIN_ENTRY_STATUS = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_LOGIN_ENTRY_STATUS"] = 0;
        values[valuesById[1] = "PENDING"] = 1;
        values[valuesById[2] = "SUCCESS"] = 2;
        values[valuesById[3] = "ERROR"] = 3;
        return values;
    })();

    /**
     * BAN_RULE_SLUG enum.
     * @name sso.BAN_RULE_SLUG
     * @enum {number}
     * @property {number} UNDEFINED_RULE_SLUG=0 UNDEFINED_RULE_SLUG value
     * @property {number} MAX_ATTEMPTS_COUNT_BY_CONTACT_REACHED=1 MAX_ATTEMPTS_COUNT_BY_CONTACT_REACHED value
     * @property {number} MAX_ATTEMPTS_COUNT_BY_IP_REACHED=2 MAX_ATTEMPTS_COUNT_BY_IP_REACHED value
     * @property {number} MAX_IP_COUNT_BY_CONTACT_REACHED=3 MAX_IP_COUNT_BY_CONTACT_REACHED value
     */
    sso.BAN_RULE_SLUG = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_RULE_SLUG"] = 0;
        values[valuesById[1] = "MAX_ATTEMPTS_COUNT_BY_CONTACT_REACHED"] = 1;
        values[valuesById[2] = "MAX_ATTEMPTS_COUNT_BY_IP_REACHED"] = 2;
        values[valuesById[3] = "MAX_IP_COUNT_BY_CONTACT_REACHED"] = 3;
        return values;
    })();

    /**
     * BLOCKING_REASON_SLUG enum.
     * @name sso.BLOCKING_REASON_SLUG
     * @enum {number}
     * @property {number} UNDEFINED_BLOCKING_REASON_SLUG=0 UNDEFINED_BLOCKING_REASON_SLUG value
     * @property {number} REVOCATION_PERS_DATA=1 REVOCATION_PERS_DATA value
     * @property {number} END_COOPERATION=2 END_COOPERATION value
     */
    sso.BLOCKING_REASON_SLUG = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_BLOCKING_REASON_SLUG"] = 0;
        values[valuesById[1] = "REVOCATION_PERS_DATA"] = 1;
        values[valuesById[2] = "END_COOPERATION"] = 2;
        return values;
    })();

    /**
     * TENANT_TYPE enum.
     * @name sso.TENANT_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_TENANT_TYPE=0 UNDEFINED_TENANT_TYPE value
     * @property {number} TENANT=1 TENANT value
     * @property {number} ECOSYSTEM=2 ECOSYSTEM value
     * @property {number} SUBTENANT=3 SUBTENANT value
     */
    sso.TENANT_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_TENANT_TYPE"] = 0;
        values[valuesById[1] = "TENANT"] = 1;
        values[valuesById[2] = "ECOSYSTEM"] = 2;
        values[valuesById[3] = "SUBTENANT"] = 3;
        return values;
    })();

    /**
     * ACCESS_BLOCKING_TYPE enum.
     * @name sso.ACCESS_BLOCKING_TYPE
     * @enum {number}
     * @property {number} UNDEFINED_ACCESS_BLOCKING_TYPE=0 UNDEFINED_ACCESS_BLOCKING_TYPE value
     * @property {number} BLOCK=1 BLOCK value
     * @property {number} FREEZE=2 FREEZE value
     */
    sso.ACCESS_BLOCKING_TYPE = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNDEFINED_ACCESS_BLOCKING_TYPE"] = 0;
        values[valuesById[1] = "BLOCK"] = 1;
        values[valuesById[2] = "FREEZE"] = 2;
        return values;
    })();

    sso.Empty = (function() {

        /**
         * Properties of an Empty.
         * @memberof sso
         * @interface IEmpty
         */

        /**
         * Constructs a new Empty.
         * @memberof sso
         * @classdesc Represents an Empty.
         * @implements IEmpty
         * @constructor
         * @param {sso.IEmpty=} [properties] Properties to set
         */
        function Empty(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Encodes the specified Empty message. Does not implicitly {@link sso.Empty.verify|verify} messages.
         * @function encode
         * @memberof sso.Empty
         * @static
         * @param {sso.IEmpty} message Empty message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Empty.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Decodes an Empty message from the specified reader or buffer.
         * @function decode
         * @memberof sso.Empty
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.Empty} Empty
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Empty.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.Empty();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Empty;
    })();

    sso.DadataFIO = (function() {

        /**
         * Properties of a DadataFIO.
         * @memberof sso
         * @interface IDadataFIO
         * @property {string|null} [value] DadataFIO value
         * @property {string|null} [unrestrictedValue] DadataFIO unrestrictedValue
         * @property {sso.DadataFIO.IData|null} [data] DadataFIO data
         */

        /**
         * Constructs a new DadataFIO.
         * @memberof sso
         * @classdesc Represents a DadataFIO.
         * @implements IDadataFIO
         * @constructor
         * @param {sso.IDadataFIO=} [properties] Properties to set
         */
        function DadataFIO(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DadataFIO value.
         * @member {string} value
         * @memberof sso.DadataFIO
         * @instance
         */
        DadataFIO.prototype.value = "";

        /**
         * DadataFIO unrestrictedValue.
         * @member {string} unrestrictedValue
         * @memberof sso.DadataFIO
         * @instance
         */
        DadataFIO.prototype.unrestrictedValue = "";

        /**
         * DadataFIO data.
         * @member {sso.DadataFIO.IData|null|undefined} data
         * @memberof sso.DadataFIO
         * @instance
         */
        DadataFIO.prototype.data = null;

        /**
         * Encodes the specified DadataFIO message. Does not implicitly {@link sso.DadataFIO.verify|verify} messages.
         * @function encode
         * @memberof sso.DadataFIO
         * @static
         * @param {sso.IDadataFIO} message DadataFIO message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DadataFIO.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
            if (message.unrestrictedValue != null && Object.hasOwnProperty.call(message, "unrestrictedValue"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.unrestrictedValue);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.sso.DadataFIO.Data.encode(message.data, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Decodes a DadataFIO message from the specified reader or buffer.
         * @function decode
         * @memberof sso.DadataFIO
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {sso.DadataFIO} DadataFIO
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DadataFIO.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.DadataFIO();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.value = reader.string();
                    break;
                case 2:
                    message.unrestrictedValue = reader.string();
                    break;
                case 3:
                    message.data = $root.sso.DadataFIO.Data.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        DadataFIO.Data = (function() {

            /**
             * Properties of a Data.
             * @memberof sso.DadataFIO
             * @interface IData
             * @property {string|null} [surname] Data surname
             * @property {string|null} [name] Data name
             * @property {string|null} [patronymic] Data patronymic
             * @property {string|null} [gender] Data gender
             * @property {string|null} [source] Data source
             * @property {string|null} [qc] Data qc
             */

            /**
             * Constructs a new Data.
             * @memberof sso.DadataFIO
             * @classdesc Represents a Data.
             * @implements IData
             * @constructor
             * @param {sso.DadataFIO.IData=} [properties] Properties to set
             */
            function Data(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Data surname.
             * @member {string} surname
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.surname = "";

            /**
             * Data name.
             * @member {string} name
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.name = "";

            /**
             * Data patronymic.
             * @member {string} patronymic
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.patronymic = "";

            /**
             * Data gender.
             * @member {string} gender
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.gender = "";

            /**
             * Data source.
             * @member {string} source
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.source = "";

            /**
             * Data qc.
             * @member {string} qc
             * @memberof sso.DadataFIO.Data
             * @instance
             */
            Data.prototype.qc = "";

            /**
             * Encodes the specified Data message. Does not implicitly {@link sso.DadataFIO.Data.verify|verify} messages.
             * @function encode
             * @memberof sso.DadataFIO.Data
             * @static
             * @param {sso.DadataFIO.IData} message Data message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Data.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.surname != null && Object.hasOwnProperty.call(message, "surname"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.surname);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                if (message.patronymic != null && Object.hasOwnProperty.call(message, "patronymic"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.patronymic);
                if (message.gender != null && Object.hasOwnProperty.call(message, "gender"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.gender);
                if (message.source != null && Object.hasOwnProperty.call(message, "source"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.source);
                if (message.qc != null && Object.hasOwnProperty.call(message, "qc"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.qc);
                return writer;
            };

            /**
             * Decodes a Data message from the specified reader or buffer.
             * @function decode
             * @memberof sso.DadataFIO.Data
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {sso.DadataFIO.Data} Data
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Data.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sso.DadataFIO.Data();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.surname = reader.string();
                        break;
                    case 2:
                        message.name = reader.string();
                        break;
                    case 3:
                        message.patronymic = reader.string();
                        break;
                    case 4:
                        message.gender = reader.string();
                        break;
                    case 5:
                        message.source = reader.string();
                        break;
                    case 6:
                        message.qc = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            return Data;
        })();

        return DadataFIO;
    })();

    return sso;
})();

module.exports = $root;
