import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Card } from 'react-bootstrap';

import {CalculateOptionDto, ProspectDto} from 'src/store/ProspectStore';
import { FIELDS } from 'src/components/Forms/form.interface';
import DynamicForm from 'src/components/Forms/DynamicForm';
import {
  dictionary,
  labsServiceTypes,
  serviceTypesWithoutRegularity,
  externalCrms,
  IPaymentType,
  getDrycleaningPlan,
  isDrycleaning,
  isSubscription,
  serviceTypesWithContractorPaymentTypes,
  getTimeslotsSource,
  B2BServices,
  lmsServiceTypes,
  ServiceType, INumberOfPayments, getPaymentType, getPaymentTypesForService
} from 'src/utils/dictionary';

import 'src/vendor/styles/pages/users.scss';
import ProspectStore from 'src/store/ProspectStore';
import CustomerStore from 'src/store/CustomerStore';
import {
  ERROR_MESSAGES,
  isValidAddress,
  isValidExternalId,
  isValidRegularity,
  ClientErrorType,
} from 'src/utils/validation';
import {
  valuesFromAddresses,
  toParsedAddress,
} from 'src/components/Fields/Address/AddressField.utls';
import { ITimeSlotValue } from 'src/components/Fields/TimeSlot/TimeSlotField';
import { IParsedAddress } from 'src/components/Fields/Address/interfaces/address.interface';
import { PlatformCRMWeb } from '../../../services/GrpcService';
import metaStore from '../../../store/MetaStore';
import { logger } from '@qlean/front-logger';
import moment from 'moment';

interface IProps {
  prospect: ProspectDto;
  isReadonly?: boolean;
}

const ProspectData = observer(({ prospect, isReadonly }: IProps) => {
  const { upsertProspect, errors, disabledSlots, setDisabledSlots } = ProspectStore;
  const { dryCleaningSlotsLoading, dryCleaningCards, offers = [], platformCards } = metaStore;
  const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([]);
  const [numberOfPayments, setNumberOfPayments] = useState<INumberOfPayments[]>([]);

  const { clientAddresses, clientIdForAddresses, getClientAddresses } = CustomerStore;
  const { attachCard } = CustomerStore;
  const [timeSlotValue, setTimeSlotValue] = useState<ITimeSlotValue>({
    date: prospect?.startDate
      ? new Date(prospect?.startDate).toISOString().substring(0, 10)
      : undefined,
    startDate: prospect.startDate,
  });

  useEffect(() => {
    const product = prospect?.price?.products?.[0];
    const deliveryOption: CalculateOptionDto | undefined = (product?.options || []).find((option) => option.slug === 'delivery');
    const isB2BService: boolean = B2BServices.includes(product?.productSlug);

    if (!deliveryOption && isB2BService) {
      setDisabledSlots(true);
      if (!prospect.startDate) {
        setTimeSlotValue({
          date: moment().toISOString(),
          startDate: moment().toISOString(),
        })
      }
    } else {
      setDisabledSlots(false);
    }
  }, [ProspectStore.prospect?.price?.products?.[0]?.options?.length]);

  useEffect(() => {
    if (prospect.person?.ssoId) {
      getClientAddresses({ uid: prospect.person.ssoId });
    }
  }, [prospect?.person?.ssoId]);

  const onButtonClick = () => {
    if (prospect?.person?.id) {
      attachCard({ personId: prospect.person.id });
    }
  };

  useEffect(() => {
    if (
      prospect?.address?.value &&
      !dryCleaningSlotsLoading &&
      isDrycleaning(prospect.serviceType)
    ) {
      const plan = getDrycleaningPlan(prospect.serviceType);
      metaStore.getDryCleaningSlots({
        planId: plan[prospect.promocode] || plan.basic,
        address: prospect.address.value.replace(/(,\sкв\s.*)$/, ''),
      });
    }
  }, [prospect.id, prospect?.address?.value, prospect?.promocode, prospect.serviceType]);

  useEffect(() => {
    if (prospect?.person?.ssoId) {
      if (isDrycleaning(prospect.serviceType)) {
      metaStore.getDryCleaningCards({ userId: prospect?.person?.ssoId });
    } else if (prospect.serviceType === ServiceType.SellGoodsNew) {
      metaStore.getPlatformCards({userId: prospect?.person?.ssoId});
    }
  }
  }, [prospect?.person?.ssoId, prospect.serviceType]);

  useEffect(() => {
    if (prospect?.serviceType && prospect?.regionId) {
      metaStore.getOffers({
        product: prospect?.serviceType,
        userId: prospect?.person?.ssoId,
        promocode: prospect?.promocode,
        // TODO хак, нужно передалть, как только будет интеграция с регионами
        regionId: prospect.regionId === 1 ? '454a9355-f441-487c-9c5b-5f356b71a8ae' : 'c14a209e-624d-4427-be76-1a2cfff8f405',
      });
    } else {
      metaStore.setOffers([]);
    }
  }, [prospect.id, prospect?.serviceType, prospect?.regionId]);

  useEffect(() => {
    const items: IPaymentType[] = getPaymentTypesForService(prospect.serviceType) || [getPaymentType(prospect.serviceType)];

    if (!labsServiceTypes.includes(prospect.serviceType)) {
      const cards = dryCleaningCards || [];

      if (prospect.person?.qleanCreditCardNumber && !isDrycleaning(prospect.serviceType)) {
        items.push({ id: 'credit_card', value: `Карта ${prospect.person?.qleanCreditCardNumber}` });
      }

      if (isDrycleaning(prospect.serviceType)) {
        for (const card of cards) {
          items.push({
            id: card.id + '',
            value: card.mask + '',
            default: !!card.default,
          });
        }
      }
    }

    if (lmsServiceTypes.includes(prospect.serviceType)) {
      for (const card of platformCards || []) {
      items.push({
        id: 'credit_card',
        value: `Карта ${card.cardNumber}`,
        meta: card.cardId + '',
      });
    }
    }

    if (serviceTypesWithContractorPaymentTypes.includes(prospect.serviceType)) {
      items.push(
        {
          id: 'installment_plan',
          value: 'Рассрочка',
        },
      );
    }

    if (items) {
      logger.info('set_payments_type', items, dryCleaningCards);
      setPaymentTypes(items);
    }
  }, [prospect.id, dryCleaningCards, platformCards]);

  useEffect(() => {
    if (serviceTypesWithContractorPaymentTypes.includes(prospect.serviceType)) {
      setNumberOfPayments([{id: '2', value: 2}, {id: '4', value: 4}]);
    }
  }, [prospect.id]);

  const getInitialPaymentType = () => {
    let paymentType = paymentTypes.find((type) => type.id === prospect.paymentType);

    // если сервис drycleaning
    if (isDrycleaning(prospect.serviceType) && prospect.drycleaningMeta?.creditCardId) {
      paymentType = paymentTypes.find((item) => item.id === prospect.drycleaningMeta?.creditCardId);
    }

    if (isDrycleaning(prospect.serviceType) && !paymentType) {
      paymentType = paymentTypes.find((item) => item.default);
    }

      if (prospect.serviceType === ServiceType.SellGoodsNew && prospect.paymentType === 'credit_card') {
      paymentType = paymentTypes.find((type) => type.meta === prospect.paymentTypeMeta)
    }

    return paymentType || paymentTypes[0];
  };

  const getInitialNumberOfPayments = () => {
    if (prospect.numberOfPayments) {
      return numberOfPayments?.find(({value}) => value === prospect.numberOfPayments);
    }

    return numberOfPayments?.find(({value}) => value === 4);
  };

  const getSlotState = (): boolean => {
    if (labsServiceTypes.includes(prospect.serviceType) && !B2BServices.includes(prospect.serviceType)) {
      return !!isReadonly;
    }

    if (disabledSlots) {
      return disabledSlots;
    }

    return !(
        prospect.address.value &&
        isValidAddress(toParsedAddress(prospect.address))
    ) || !!isReadonly
  }

  const dynamicForm = () => {
    if (isSubscription(prospect.serviceType)) {
      return {
        name: 'prospect-edit',
        rows: [
          [
            {
              type: FIELDS.STRING,
              name: 'commentForSupport',
              label: {
                label: 'Комментарий для заказа',
              },
              placeholder: 'Введите комментарий',
              disabled: isReadonly,
              initialValue: prospect.commentForSupport,
            },
          ],
        ],
      };
    }
    return externalCrms.includes(prospect.serviceType)
      ? {
        name: 'prospect-edit',
        rows: [
          [
            {
              type: FIELDS.STRING,
              name: 'externalId',
              label: {
                label: 'ID заказа',
              },
              placeholder: 'Введите ID заказа',
              disabled: isReadonly,
              initialValue: prospect.externalId,
              defaultError: ERROR_MESSAGES.EXTERNAL_ID,
              isValid: isValidExternalId,
              errors: errors.find((error) => error.type === 'externalId')?.message,
            },
          ],
          [
            {
              type: FIELDS.STRING,
              name: 'commentForSupport',
              label: {
                label: 'Комментарий для заказа',
              },
              placeholder: 'Введите комментарий',
              disabled: isReadonly,
              initialValue: prospect.commentForSupport,
            },
          ],
        ],
      }
      : {
        name: 'prospect-edit',
        rows: [
          [
            {
              type: FIELDS.ADDRESS,
              name: 'addressWithRegion',
              label: {
                label: 'Адрес',
              },
              placeholder: 'Введите адрес',
              isReadonly,
              initialValue: {
                favourites:
                  clientIdForAddresses === prospect.person?.ssoId
                    ? valuesFromAddresses(clientAddresses || [])
                    : [],
                value: { address: prospect?.address, regionId: prospect.regionId },
                isShortForm: false,
              },
              withRegion: true,
              errors: {
                regionId: errors.find((error) => error.type === 'regionId')?.message,
                address: errors.find((error) => error.type === 'address')?.message,
              },
            },
          ],
          isDrycleaning(prospect.serviceType)
            ? [
              {
                type: FIELDS.DRYCLEANING_SLOTS,
                name: 'pickupTimeslot',
                variant: 'pickup',
                isReadonly,
                errors: errors.find((error) => error.type === ClientErrorType.PICKUP_TIMESLOT)
                  ?.message,
                initialValue: prospect.drycleaningMeta?.pickupTimeslot,
              },
              {
                type: FIELDS.DRYCLEANING_SLOTS,
                name: 'returnTimeslot',
                variant: 'return',
                isReadonly,
                errors: errors.find((error) => error.type === ClientErrorType.RETURN_TIMESLOT)
                  ?.message,
                initialValue: prospect.drycleaningMeta?.returnTimeslot,
              },
            ]
            : [
              {
                type: FIELDS.SELECT,
                name: 'regularity',
                label: {
                  label: 'Регулярность',
                },
                placeholder: 'Выберите регулярность',
                optionValueKey: 'id',
                optionLabelKey: 'value',
                isReadonly:
                  serviceTypesWithoutRegularity.includes(prospect.serviceType) || isReadonly,
                options: dictionary.selectOptions.regularity,
                variant: 'dropdown',
                initialValue: dictionary.selectOptions.regularity.find(
                  (regularity) => regularity.id === prospect.regularity
                ),
                defaultError: ERROR_MESSAGES.REGULARITY,
                isValid: isValidRegularity,
                errors: errors.find((error) => error.type === 'regularity')?.message,
              },
              {
                type: FIELDS.TIMESLOT,
                name: 'timeslot',
                isReadonly: getSlotState(),
                initialValue: timeSlotValue,
                prospectId: prospect.id,
                serviceType: prospect.serviceType,
                errors: errors.find((error) => error.type === 'startDate')?.message,
                timeslotsSource: getTimeslotsSource({ paymentType: prospect.paymentType, serviceType: prospect.serviceType }) ,
              },
            ],
          [
            {
              type: FIELDS.PAYMENT_TYPE,
              name: 'paymentType',
              label: {
                label: 'Тип оплаты',
              },
              placeholder: 'Выберите тип оплаты',
              options: paymentTypes,
              initialValue: getInitialPaymentType(),
              buttonTitle: paymentTypes.find(
                (paymentType) => paymentType.id === prospect.paymentType
              )?.button,
              onButtonClick,
              isReadonly,
              errors: errors.find((error) => error.type === 'paymentType')?.message,
            },
            {
              type: FIELDS.NUMBER_OF_PAYMENTS,
              name: 'numberOfPayments',
              label: {
                label: 'Кол-во платежей',
              },
              placeholder: 'Выберите кол-во платежей',
              options: numberOfPayments,
              initialValue: getInitialNumberOfPayments(),
              isReadonly,
              errors: errors.find((error) => error.type === 'numberOfPayments')?.message,
            },
            {
              type: FIELDS.DISCOUNT_WITH_PROMOCODE,
              name: 'discount',
              initialValue: {
                promocode: prospect?.promocode,
                discount: offers.find((discount) => discount.promocode === prospect.promocode),
              },
              isReadonly,
              options: offers,
              errors: errors.find((error) => error.type.includes('promocode'))?.message,
            },
          ],
          [
            {
              type: FIELDS.STRING,
              name: 'commentForSupport',
              label: {
                label: 'Комментарий к заказу для поддержки',
              },
              placeholder: 'Введите комментарий к заказу для поддержки',
              initialValue: prospect.commentForSupport,
              disabled: isReadonly,
              variant: 'textarea',
            },
            {
              type: FIELDS.STRING,
              name: 'commentForExecutor',
              label: {
                label: 'Комментарий к заказу для исполнителя',
              },
              placeholder: 'Введите комментарий к заказу для исполнителя',
              disabled: isReadonly,
              initialValue: prospect.commentForExecutor,
              variant: 'textarea',
            },
          ],
        ],
      };
  }

  const isAddressWasCleanedOrRegionChanged = (
    address?: IParsedAddress,
    regionId?: number
  ): boolean => {
    return (
      (Boolean(prospect.address.value) && !Boolean(address?.value)) ||
      regionId !== prospect.regionId
    );
  };

  const onClickSubmit = (values) => {
    if (isReadonly) {
      return;
    }

    const { id } = prospect;

    if (externalCrms.includes(prospect.serviceType)) {
      upsertProspect({
        ...values,
        phone: prospect.person?.phone || prospect.phone,
        serviceType: prospect.serviceType,
        id: prospect.id,
      });
    } else {
      const isNeedToClearTimeslot = isAddressWasCleanedOrRegionChanged(
        values?.addressWithRegion?.value?.address,
        values?.addressWithRegion?.value?.regionId
      );
      const startDate: string = isNeedToClearTimeslot
        ? ''
        : values?.timeslot?.startDate?.toISOString() || '';
      const date = isNeedToClearTimeslot ? null : values?.timeslot?.date;

      setTimeSlotValue({ date, startDate });

      const req: PlatformCRMWeb.IUpsertProspectRequest = {
        ...values,
        id,
        regularity: values?.regularity?.id,
        paymentType: values?.paymentType?.id,
        paymentTypeMeta: values?.paymentType?.meta,
        numberOfPayments: values?.numberOfPayments?.value,
        startDate,
        regionId: values?.addressWithRegion?.value?.regionId,
        address: values?.addressWithRegion?.value?.address || {},
        discount: values?.discount?.discount,
        promocode: values?.discount?.promocode,
        serviceType: prospect.serviceType,
      };

      if (isDrycleaning(prospect.serviceType)) {
        const drycleaningPlan = getDrycleaningPlan(prospect.serviceType);
        req.drycleaningMeta = {
          pickupTimeslot: values.pickupTimeslot || prospect.drycleaningMeta?.pickupTimeslot,
          returnTimeslot: values.returnTimeslot || prospect.drycleaningMeta?.returnTimeslot,
          planId: drycleaningPlan[req.promocode || prospect.promocode] || drycleaningPlan.basic,
        };

        const dt =
          values.pickupTimeslot?.startAt || prospect.drycleaningMeta?.pickupTimeslot?.startAt;

        if (dt) {
          req.startDate = dt;
        }

        if (!req.drycleaningMeta.pickupTimeslot?.id) {
          delete req.drycleaningMeta.pickupTimeslot;
        }

        if (!req.drycleaningMeta.returnTimeslot?.id) {
          delete req.drycleaningMeta.returnTimeslot;
        }

        if (req.paymentType !== 'cash') {
          req.drycleaningMeta.creditCardId = req.paymentType;
          req.paymentType = 'credit_card';
        } else {
          req.drycleaningMeta.creditCardId = null;
        }
      }

      upsertProspect(req);
    }
  };

  return paymentTypes.length ? (
    <Card className="mb-4" key={prospect.id}>
      <Card.Header as="h6" className="d-flex justify-content-between">
        <span>Заявка</span>
        {prospect.status && (
          <div>
            {externalCrms.includes(prospect.serviceType) && (
              <a
                className="mr-2"
                target="_blank"
                rel="noopener noreferrer"
                href={prospect.adminPanelLink}
              >
                Ссылка на админку
              </a>
            )}
          </div>
        )}
      </Card.Header>
      <Card.Body>
        {/* @ts-ignore */}
        <DynamicForm form={dynamicForm()} onSubmit={onClickSubmit} isAutoSubmit />
      </Card.Body>
    </Card>
  ) : null;
});

export default ProspectData;
